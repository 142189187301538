import React, {FC, MutableRefObject} from "react";
import {useTranslation} from "react-i18next";
import {Accessibility} from "../../../Models/Accessibility";
import {AccessibilityUnit} from "../../../Models/AccessibilityUnits";
import MixpanelTrackingList from "../../../components/MixpanelTrackingList";
import ContentBlock from "../../../components/ContentBlock";
import AccessibilityList from "../../../components/AccessibilityList";
import SkeletonList from "../../../components/SkeletonList";

type PropsType = {
  arrayOfPropertyItemAccessibilityUnits: AccessibilityUnit[]
  itemAccessibilites: Accessibility[]
  locatedOn?: {
    type: string
    triggeredOnPage: string,
    section: string
  }
  refs: MutableRefObject<HTMLDivElement[] | null[]>
}

const GeneralItemInfoContainer: FC<PropsType> = ({
  arrayOfPropertyItemAccessibilityUnits,
  itemAccessibilites,
  locatedOn,
  refs,
}) => {
  const {t} = useTranslation()

  return (
    <ContentBlock label={t("mainContainer_generalInformation")}>
      <MixpanelTrackingList locatedOn={locatedOn}>
        {arrayOfPropertyItemAccessibilityUnits && arrayOfPropertyItemAccessibilityUnits.length > 0 ? (
          <AccessibilityList
            accessibilityUnits={arrayOfPropertyItemAccessibilityUnits}
            accessibilities={itemAccessibilites}
            refs={refs}
          />
        ) : (
          <SkeletonList/>
        )}
      </MixpanelTrackingList>
    </ContentBlock>
  )
}

export default GeneralItemInfoContainer
