import { Query } from "./utils";

export enum RelatedModel {
    buildingAreaGeneralInfo = 'building_area_general_info',
    buildingAreaAccessibilityInfo = 'building_area_accessibility_info',
    propertyAccessibilityInfo = 'property_accessibility_info'
}

export interface Question {
    id: string,
    question_text: string,
    question_type: string[],
    answer_text_yes: string,
    answer_text_no: string,
    related_model: RelatedModel,
    unit_type: string,
    created_at: Date,
    updated_at: Date,
}

export interface AccessibilityInfo {
    id: string,
    property:string,
    created_at: Date,
    updated_at: Date,
    answer: 'yes' | 'no',
    question: string
}

export type AccessibilityInfoQuery = Query<AccessibilityInfo>