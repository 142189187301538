import {useEffect, useState} from "react";

const getData = (key: string, defaultState: string | null = null) => {
  const types: string | null = localStorage.getItem(key)
  return typeof types === 'string' ? JSON.parse(types) : defaultState
}

export const useStorageData = (key: string, defaultState: string | null = null) => {
  const [data, setData] = useState(getData(key, defaultState));

  useEffect(() => {
    const handleChangeStorage = () => {
      setData(getData(key, defaultState));
    }

    window.addEventListener('storage', handleChangeStorage);
    return () => window.removeEventListener('storage', handleChangeStorage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
}
