import React, {useState} from "react";
import styled from "styled-components";
import iconImg from "../../../images/icons/person-icon.svg"
import { Theme, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {InputAdornment, OutlinedInput as MuiOutlinedInput, useMediaQuery} from "@mui/material";
import VisualIcon from "../../../images/icons/visual-icon.svg";
import MobilityIcon from "../../../images/icons/mobility-icon.svg";
import HearingIcon from "../../../images/icons/hearing-icon.svg";
import AllergyIcon from "../../../images/icons/allergy-icon.svg";
import CognitiveIcon from "../../../images/icons/cognitive-icon.svg";
import OtherIcon from "../../../images/icons/other-icon.svg";
import Check from "../../../images/icons/check.svg";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import {Mixpanel} from "../../../Mixpanel";
import {MetricMessage} from "../../../utils/metrics";
import {DisabilityTypes} from "../../../constants/constants";
import {useTranslation} from "react-i18next";

const OutlinedInput = styled(MuiOutlinedInput)`
  height: 42px;
  padding-left: 10px;
  border-radius: 6px !important;
  cursor: pointer !important;
  .MuiSelect-select {
    display: flex;
    align-items: center;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    height: 40px !important;
  }
`

const StyledSelect = styled(Select)`
  & fieldset {
    inset: 0px 0px 0px !important;
  }
  & fieldset > legend {
    display: none !important;
  }
`

const LabelName = styled.span`
  font-size: 16px !important;
`

const IconAdornment = styled.img`
  width: 21px !important;
  height: 21px !important;
  background: #141518 !important;
  cursor: pointer !important;
  border-radius: 50% !important;
  padding: 5px !important;
  max-width: unset !important;
`

const SelectedIcon = styled.img`
  height: 30px !important;
  width: 30px !important;
  background: #2F80ED;
  margin-left: 7px;
  cursor: pointer;
  border-radius: 50%;
  max-width: unset !important;
`

const Etc = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
  font-size: 14px;
  color: #808080FF;
  height: 100%;
`

const Icon = styled.img`
  width: 30px;
  height: 30px;
  background: #141518;
  cursor: pointer;
  border-radius: 50%;
`

const CheckIcon = styled.img`
  margin-left: auto;
`

// const TextAdornment = styled.div`
//   height: 37px;
//   margin-left: 41px;
//   position: absolute;
//   p {
//     font-size: 12px !important;
//     color: #5E6366 !important;
//     margin: 0 !important;
//     height: 14px !important;
//   }
// `

type IconsType = {
  [key: string]: {
    name: string,
    icon: string,
  }
}

const AccessibilityTypes: IconsType = {
  V: {
    name: "selectDisabilityType_visual",
    icon: VisualIcon
  },
  M: {
    name: "selectDisabilityType_mobility",
    icon: MobilityIcon
  },
  H: {
    name: "selectDisabilityType_hearing",
    icon: HearingIcon
  },
  AR: {
    name: "selectDisabilityType_allergy",
    icon: AllergyIcon
  },
  C: {
    name: "selectDisabilityType_cognitive",
    icon: CognitiveIcon
  },
  O: {
    name: "selectDisabilityType_other",
    icon: OtherIcon
  }
}

const types: string[] = [
  'M',
  'H',
  'V',
  'C',
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const SelectDisabilityType = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [disabilityTypes, setDisabilityTypes] = useLocalStorage<string[]>("disability_types", []);

  const handleChange = (event: SelectChangeEvent<typeof disabilityTypes>) => {
    const { target: { value } } = event;
    setDisabilityTypes(typeof value === 'string' ? value.split(',') : value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const getSelectDisabilityTypeText = () => {
  //   if (disabilityTypes.length === 0) {
  //     return (
  //       <TextAdornment onClick={handleOpen}>
  //         <p>{t('selectDisabilityType_select')}</p>
  //         <p><b>{t('selectDisabilityType_disability_type')}</b></p>
  //       </TextAdornment>
  //     )
  //   }
  //   return null
  // }

  const getSelectedIcons = () => {
    if (disabilityTypes.length > 0) {
      const mappedIcons = disabilityTypes.map((type: string, index: number) => (
        <SelectedIcon key={index} src={AccessibilityTypes[type].icon} />
      ))

      if (smallScreen && mappedIcons.length > 1) {
        const slicedIcons = mappedIcons.slice(0, 1)
        return (
          <>
            {slicedIcons}
            <Etc>+ {mappedIcons.length - slicedIcons.length}</Etc>
          </>
        )
      }
      if (mappedIcons.length > 3) {
        const slicedIcons = mappedIcons.slice(0, 3)
        return (
          <>
            {slicedIcons}
            <Etc>+ {mappedIcons.length - slicedIcons.length}</Etc>
          </>
        )
      }
      return mappedIcons
    }
  }

  const getLabel = (type: string, arr: string[]) => (
    <>
      {arr.indexOf(type) === -1 ? (
        <Icon
          src={AccessibilityTypes[type].icon}
          style={{marginRight: '7px'}}
        />
      ) : (
        <SelectedIcon
          src={AccessibilityTypes[type].icon}
          style={{marginLeft: 0, marginRight: '7px'}}
        />
      )}
      <LabelName>{t(AccessibilityTypes[type].name)}</LabelName>
      {arr.indexOf(type) !== -1 && <CheckIcon src={Check}/>}
    </>
  )

  const mixpanelEvent = (type: string): void => {
    if (disabilityTypes.indexOf(type) === -1) {
      Mixpanel.track(
        `${MetricMessage.selected_disability_type}`,
        {
          selectedType: DisabilityTypes[type],
          allSelectedTypes: [...disabilityTypes, type].map((dt: string) => DisabilityTypes[dt])
        }
      )
    }
  }

  return (
    <div>
      <FormControl sx={{
        m: 0,
        width: '100%',
        // minWidth: 157
      }}>
        <StyledSelect
          id="select-disability-type"
          title={t('selectDisabilityType_select_disability_type')}
          multiple
          value={disabilityTypes}
          onChange={handleChange}
          open={open}
          onClose={() => {
            handleClose()
          }}
          onOpen={handleOpen}
          startAdornment={
            <InputAdornment
              sx={{m: 0}}
              onClick={handleOpen}
              position="start"
            >
              <IconAdornment
                src={iconImg} />
              {/*{getSelectDisabilityTypeText()}*/}
            </InputAdornment>
          }
          renderValue={getSelectedIcons}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
        >
          {types.map((type) => (
            <MenuItem
              onClick={() => mixpanelEvent(type)}
              key={type}
              value={type}
              style={getStyles(type, disabilityTypes, theme)}
            >
              {getLabel(type, disabilityTypes)}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  )
}

export default SelectDisabilityType
