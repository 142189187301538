import * as React from 'react';
import { createPortal } from 'react-dom';
import { create, SheetsRegistry } from 'jss';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { StyleSheetManager } from 'styled-components';
import { jssPreset, StylesProvider } from '@mui/styles';
import { useTheme, styled, createTheme, ThemeProvider } from '@mui/material/styles';
import {cloneElement, FC, useCallback, useEffect, useMemo, useReducer, useRef} from "react";

type FramedDemoType = {
  children: JSX.Element,
  document: Document
}

const FramedDemo: FC<FramedDemoType> = ({children, document}) => {
  const theme = useTheme();

  useEffect(() => {
    document.body.dir = theme.direction;
  }, [document, theme.direction]);

  const { jss, sheetsManager } = useMemo(() => {
    return {
      jss: create({
        plugins: [...jssPreset().plugins],
        insertionPoint: document.head,
      }),
      sheetsManager: new Map(),
      sheetsRegistry: new SheetsRegistry(),
    };
  }, [document]);

  const cache = useMemo(
    () =>
      createCache({
        key: `iframe-handiscover-${theme.direction}`,
        prepend: true,
        container: document.head,
        stylisPlugins: [],
      }),
    [document, theme.direction],
  );

  const getWindow = useCallback(() => document.defaultView, [document]);

  return (
    <StylesProvider jss={jss} sheetsManager={sheetsManager}>
      <StyleSheetManager
        target={document.head}
      >
        <CacheProvider value={cache}>
          {cloneElement(children, {
            window: getWindow,
          })}
        </CacheProvider>
      </StyleSheetManager>
    </StylesProvider>
  );
}

const Frame = styled('iframe')`
  height: 100%;
  width: 100%;
  top: 0;
  bottom: unset;
  left: 0;
  right: unset;
  position: fixed;
  flex-grow: 1;
  border: 0;
  margin: 0;
  z-index: 2147483647;
`;

type DemoFrameType = {
  children: JSX.Element,
  name: string,
  dialogOpen: number
}

const DemoFrame:FC<DemoFrameType> = ({children, name, dialogOpen}) => {
  const frameRef = useRef<HTMLIFrameElement>(null);

  // If we portal content into the iframe before the load event then that content
  // is dropped in firefox.
  const [iframeLoaded, onLoad] = useReducer(() => true, false);

  useEffect(() => {
    const document = frameRef?.current?.contentDocument;

    // When we hydrate the iframe then the load event is already dispatched
    // once the iframe markup is parsed (maybe later but the important part is
    // that it happens before React can attach event listeners).
    // We need to check the readyState of the document once the iframe is mounted
    // and "replay" the missed load event.
    // See https://github.com/facebook/react/pull/13862 for ongoing effort in React
    // (though not with iframes in mind).

    if (document) {
      const fontsRoboto = document.createElement('link')
      fontsRoboto.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900'
      fontsRoboto.rel = "stylesheet";
      fontsRoboto.type = "text/css";
      document.head.appendChild(fontsRoboto)
      const fontsManrope = document.createElement('link')
      fontsManrope.href = 'https://fonts.googleapis.com/css?family=Manrope:100,300,400,500,700,900'
      fontsManrope.rel = "stylesheet";
      fontsManrope.type = "text/css";
      document.head.appendChild(fontsManrope)
      const reactImageGallery = document.createElement('link')
      reactImageGallery.href = 'https://cdn.jsdelivr.net/npm/react-image-gallery@1.0.3/styles/css/image-gallery.css'
      reactImageGallery.rel = "stylesheet";
      reactImageGallery.type = "text/css";
      document.head.appendChild(reactImageGallery)
    }

    if (document != null && document.readyState === 'complete' && !iframeLoaded) {
      onLoad();
    }
  }, [iframeLoaded]);

  const document = frameRef.current?.contentDocument;
  return (
    <React.Fragment>
      <Frame
        id='handiscover-iframe'
        onLoad={onLoad}
        ref={frameRef}
        title={name}
        style={{display: dialogOpen === 1 ? 'block' : 'none'}}
      />
      {iframeLoaded !== false && document !== null && document !== undefined
        ? createPortal(
          <FramedDemo document={document}>{children}</FramedDemo>,
          document.body,
        ) : null}
    </React.Fragment>
  );
}

// Use the default MUI theme for the demos
const getTheme = (outerTheme: any) => {
  const resultTheme = createTheme({
    typography: {
      fontFamily: [
        'Roboto',
        "Manrope",
        "sans-serif"
      ].join(",") + ' !important'
    },
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
            overflowY: "hidden",
          }
        }
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            zIndex: "99999"
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          popper: {
            zIndex: "99999"
          }
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            margin: "0 !important",
          }
        }
      }
    }
  })

  if (outerTheme.direction) {
    resultTheme.direction = outerTheme.direction;
  }
  if (outerTheme.spacing) {
    resultTheme.spacing = outerTheme.spacing;
  }
  return resultTheme;
};

// TODO: Let demos decide whether they need JSS
const jss = create({
  plugins: [...jssPreset().plugins],
  // insertionPoint:
  //   typeof window !== 'undefined' ? document.querySelector('#insertion-point-jss') : null,
});

/**
 * Isolates the demo component as best as possible. Additional props are spread
 * to an `iframe` if `iframe={true}`.
 */

type IFramerType = {
  component: JSX.Element,
  dialogOpen: number,
  name: any,
}

const IFramer: FC<IFramerType> = ({component, dialogOpen, name}) => {
  return (
      <StylesProvider jss={jss}>
        <ThemeProvider theme={(outerTheme) => getTheme(outerTheme)}>
          <DemoFrame name={name} dialogOpen={dialogOpen}>
            {component}
          </DemoFrame>
        </ThemeProvider>
      </StylesProvider>
  );
}

export default React.memo(IFramer);