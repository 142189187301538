export const TRANSLATIONS_DA = {
  "accessibility_button": "Tilgængelighed",
  "sidebar_Hotel": "Hotel",
  "sidebar_Rooms": "Værelser",
  "sidebar_verified": "Verificeret af Handiscover",
  "hotel_floors": "Grundplan",
  "hotel_totalRooms": "Antal værelser",
  "hotel_accessibleRooms": "Tilgængelighedstilpassede lokaler",
  "hotel_allergyRooms": "Allergier",
  "mainContainer_accessibilityInformation": "Information om tilgængelighed",
  "mainContainer_pictures": "Billeder",
  "mainContainer_informationByDisability": "Information til besøgende",
  "mainContainer_GeneralHotelInformation": "Generel information om hotellet",
  "informationByDisability_mobility": "Mobilitet",
  "informationByDisability_hearing": "Høring",
  "informationByDisability_vision": "Syn",
  "informationByDisability_other": "Andet",
  "rooms_header": "Værelsesinformation",
  "accessibility_room_icons": "Tilgængelighedsikoner for værelser",
  "roomInformation_generalRoomInformation": "Generel information om værelser",
  "shopping_mall_mallFloors": "Plantegning i indkøbscenteret",
  "shopping_mall_totalShops": "Beholdning butikker",
  "shopping_mall_accessibleParking": "Tilgængelighedstilpassede parkeringspladser",
  "shopping_mall_foodCourts": "Food Court",
  "mainContainer_GeneralMallInformation": "Generel information om indkøbscenteret",
  "rooms_noPictures": "Dette værelse har ingen billeder",
  "rooms_noInformation": "Dette værelse har ingen information",
  "rooms_noIcons": "Ingen tilgængelighedsikoner",
  "property_noPictures": "Der blev ikke fundet nogen billeder for denne facilitet",
  "disability_noPictures": "Ingen billeder for det valgte handicap",
  "accessibilityIcons_notPresent": "Ingen tilgængelighedsikoner",
  "shopping_mall": "Indkøbscenter",
  "building_area_buildings": "Bygninger",
  "building_area_shops": "Beholdning butikker",
  "building_area_parking_spaces": "Tilgængelighedstilpassede parkeringspladser",
  "building_area_food_courts": "Madtorv",
  "mainContainer_generalInformation": "Generel information",
  "mainContainer_GeneralOfficeInformation": "Generel kontorinformation",
  "mainContainer_GeneralShopInformation": "Generel butiksinformation",
  "header_buildingInformation": "Bygningsinformation",
  "a11yStandard_shopping_mall": "Butik",
  "a11yStandard_shopping_mall_plural": "Butikker",
  "property_floorSignular": "Gulv",
  "property_floorPlural": "Gulve",
  "property_tabTitle_shops": "Butiksinformation",
  "general_invalid_API_CALL_errorMessage": "Der opstod et problem! Kunne ikke hente data.",
  "general_missingInfo_forItem": "Det ser ud til, at oplysningerne ikke er tilføjet endnu",
  "translated_text": "Titlen er blevet oversat",
  "accessibility_button_open_ariaLabel": "Åbn tilgængelighedsoplysninger",
  "mainContainer_mapView": "Kortvisning",
  "mainContainer_generalAccessibility": "Generel tilgængelighed",
  "mapView_filterBy": "Sorter efter",
  "mapView_filter_all": "Alle",
  "mapView_filter_entrance": "Indgang",
  "mapView_filter_parking": "Parkering",
  "mapView_filter_toilet": "Toilet",
  "mapView_floor": "Etage",
  "selectDisabilityType_mobility": "Mobilitet",
  "selectDisabilityType_hearing": "Høring",
  "selectDisabilityType_allergy": "Allergi",
  "selectDisabilityType_cognitive": "Kognitiv",
  "selectDisabilityType_other": "Andet",
  "selectDisabilityType_select": "Vælg",
  "selectDisabilityType_disability_type": "type af funktionsnedsættelse ",
  "selectDisabilityType_select_disability_type": "Vælg type af funktionsnedsættelse",
  "selectDisabilityType_select_what_type": "Filtrer efter funktionsnedsættelse",
  "selectDisabilityType_visual": "Syn",
  "go_back": "Gå tilbage",
  "tabTitle_meetingRooms": "Mødeværelser",
  "tabTitle_hospitalityRooms": "Hotelværelse",
  "generalAccessibility_noGeneralAccessibilitiesForThisProperty": "Der blev ikke fundet nogen Generelle tilgængeligheder for denne facilitet",
  "generalAccessibility_noGeneralAccessibilitiesForTheseDisabilityFilters": "Der blev ikke fundet nogen Generelle tilgængeligheder for disse funktionsnedsættelse filtre",
  "informationByDisabilityContainer_noDataIsFound": "Ingen data er fundet",
  "informationByDisabilityContainer_weDoNotHaveTheData": "Vi har endnu ikke data for denne ejendom",
  "informationByDisabilityContainer_tryAnotherDisabilityFiltering": "Prøv en anden handicapfiltrering",
  "playground_information": "Legepladsinformation",
  "total_playgrounds": "Totalt antal legepladser",
  "inventoried_playgrounds": "Inventariserede legepladser",
  "general_accessibility_and_information": "Generel tilgængelighed og information",
  "detailed_accessibility": "Detaljeret tilgængelighed",
  "helsingborg_city_has_reviewed": "Helsingborg Kommune har gennemgået tilgængeligheden af 26 ud af sine 200 legepladser. Her får du information om parkering, tilgængeligheden af toiletter og forskellige legeområder.",
  "for_detailed_accessibility_information": "For detaljeret tilgængelighedsinformation, vælg den pågældende legeplads.",
  "amount_of_accessible_toilets": "Antal tilgængelige toiletter",
  "mapView_filter_elevator": "Elevator",
  "mapView_filter_reception_infodesk": "Reception",
  "mapView_filter_playground": "Legeplads",
  "mapView_filter_garden": "Have",
  "mapView_filter_shop": "Butik",
  "mapView_filter_restaurant": "Restaurant",
  "mapView_filter_conference_room": "Konferencerum",
  "mapView_filter_auditorium": "Auditorium",
  "jarfalla_municipality_has_reviewed": "Järfälla Kommune City har gennemgået tilgængeligheden af 55 legepladser, herunder 2 eventyrlegepladser, der fremmer kreativitet. Her får du information om parkering, tilgængelighed til toiletter og de forskellige legeområder.",
  "mapView_filter_vac": "Ændring af gulvniveau",
  "languages": "Sprog",
  "english": "Engelsk",
  "swedish": "Svensk",
  "danish": "Dansk",
  "german": "Tysk",
  "spanish": "Spansk",
  "french": "Fransk",
  "norwegian": "Norsk",
  "portuguese": "Portugisisk",
  "ukrainian": "Ukrainsk",
  "accessibility_room": "Tilgængelighedsrum",
  "pool": "Pool",
  "spa": "Spa",
  "bar_restaurant": "Bar/Restaurant",
  "bathrooms": "Badeværelser",
  "shop": "Butik",
  "exhibition": "Udstilling",
  "rate_your_experience": "Kan du hjælpe os med at blive bedre🧑‍🦼🦮🦻? 🙏",
  "did_you_find_the_accessibility_information_you_needed": "Fandt du de tilgængelighedsoplysninger, du havde brug for?",
  "how_satisfied_are_you_with_your_experience": "Hvor tilfreds er du med din oplevelse af vores tilgængelighedsguide?",
  "what_do_you_like_the_most": "Hvad kan du bedst lide?",
  "sorry_to_hear_that": "Kommenter venligst hvorfor, for at indsende din vurdering",
  "we_would_love_to_hear_your_suggestions": "Vi vil meget gerne høre dine forslag",
  "submit": "Indsend",
  "book_now": "Book nu",
  "very_bad": "Meget dårligt",
  "bad": "Dårligt",
  "neutral": "Neutral",
  "good": "godt",
  "great": "Store"
}