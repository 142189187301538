import {AccessibilityUnit} from "../Models/AccessibilityUnits";
import {Accessibility} from "../Models/Accessibility";
import React, {FC, MutableRefObject} from "react";
import styled from "styled-components";
import {filterAccessibilitiesFromId, getTextByCurrentLanguage} from "../utils/utils";
import Icons from "./Icons";
import {Box} from "@mui/system";
import {useStorageData} from "../hooks/useStorageData";

const Block = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  background-color: #edf5ff;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 700;
`

const Text = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 13.5px 10px;
`

type PropsType = {
  accessibilityUnits: AccessibilityUnit[],
  accessibilities: Accessibility[],
  refs: MutableRefObject<HTMLDivElement[] | null[]>
}

const AccessibilityList: FC<PropsType> = ({
  accessibilityUnits,
  accessibilities,
  refs
}) => {
  const disabilityTypes: string[] = useStorageData('disability_types')

  const getFilteredAccessibilities = (id: string, accessibilities: Accessibility[]) => {
    let filteredAccessibilities: Accessibility[] = []
    if (accessibilities) {
      filteredAccessibilities = accessibilities.filter((unit) => unit.accessibility_unit === id);
      if (filteredAccessibilities.length > 0 && disabilityTypes?.length > 0) {
        filteredAccessibilities = filteredAccessibilities.filter((i) => {
          if (i.disability_types) {
            return disabilityTypes.some((dt) => i.disability_types.includes(dt))
          } else {
            return false
          }
        })
      }
    }
    return filteredAccessibilities
  }

  return (
    <Box id={'detailed-information'}>
      {accessibilityUnits
        .filter((unit) => accessibilities && getFilteredAccessibilities(unit.id, accessibilities).length > 0)
        .map((unit, index) => (
        <Block
          key={index}
          id={unit.id}
          ref={(r) => refs.current[index] = r}
        >
          <Title>{unit.title} - {getTextByCurrentLanguage(unit.name)}</Title>
          {accessibilities && getFilteredAccessibilities(unit.id, accessibilities).map((el, elIndex) => (
            <Row key={elIndex}>
              <Text>{`${el.item_title} - ${el.attribute_title}`}</Text>
              <Icons.CheckIcon style={{paddingLeft: 10}}/>
            </Row>
          ))}
        </Block>
      ))}
    </Box>
  )
}

export default AccessibilityList