import {useState, useRef, SetStateAction, Dispatch, useContext, useEffect, FC} from "react";
import GoogleMapReact, {Coords, MapOptions} from "google-map-react";
import styled from "styled-components";
import { Property } from "../../Models/Property";
import { AccessibilityUnit } from "../../Models/AccessibilityUnits";
import useSupercluster from "use-supercluster";
import IconButton from "@mui/material/IconButton";
import Icons from "../Icons";
import Button from "@mui/material/Button";
import {TabsContext} from "../MainPage";
import MyLocationImgBlue from "./icons/my_current-location-blue.svg";
import MyLocationImgBlack from "./icons/my_current-location-black.svg";
import {getTextByCurrentLanguage, isIFrame} from "../../utils/utils";
// import CornerUpRight from "./icons/corner-up-right.svg"
// import CornerUpRightBlack from "./icons/corner-up-right-black.svg"
// import {iOS} from "../../utils/utils";
import MyGeoLocation from "./components/MyGeoLocation";
import {a11yStandardKeys} from "../../constants/constants";
import {Accessibility} from "../../Models/Accessibility";
import POI from "./components/POI";
import {getZoomOptions} from "./utils/zoomOptions";
import {Theme, useMediaQuery} from "@mui/material";

type Cluster = {
  type: string,
  properties: {
    cluster: boolean,
    poiId: string,
    poiType: string,
    name: string,
    fromBuildingArea: boolean
  },
  geometry: {
    type: string,
    coordinates: number[]
  }
}

type GoogleMapReactWrapperType = {
  isBuildingArea?: boolean
}

const GoogleMapReactWrapper = styled.div<GoogleMapReactWrapperType>`
  height: 100%;
  width: 100%;
  position: relative;
  ${(props) => props.isBuildingArea ? `@media (max-width: 942px) {
      height: 500px !important;
    }` : null};

  @media (max-width: 768px) {
    height: 350px !important;
  }

  @keyframes glowing {
    0% {
      filter: drop-shadow(1px 1px 2px #76232f) drop-shadow(-1px -1px 2px #76232f);
    }
    50% {
      filter: drop-shadow(1px 1px 2px rgba(118, 35, 47, 0.5)) drop-shadow(-1px -1px 2px rgba(118, 35, 47, 0.5));
    }
    100% {
      filter: drop-shadow(1px 1px 2px #76232f) drop-shadow(-1px -1px 2px #76232f);
    }
  }

  .selected {
    z-index: 1;
  }
`;

type ClusterMarkerProps = {
  clustersize: string,
}

type StyledIconButtonProps = {
  scaletransform: string | undefined;
  scalesize?: string | undefined;
}

const StyledIconButton = styled(IconButton)<StyledIconButtonProps>`
  transition: all 0.2s;
  transform: ${({ scaletransform }) => scaletransform ? scaletransform : "translate(-50%,-90%)"};
  & > img {
    width: ${({ scalesize }) => scalesize ? scalesize : "40px"};
    height: ${({ scalesize }) => scalesize ? scalesize : "40px"};
  }
  &:focus {
    border-color: #eaeaea;
    background-color: transparent;
  }
`;

const ClusterMarker = styled(Button)<ClusterMarkerProps>`
  border-radius: 50%;
  min-width: unset;
  padding: 10px;
  width: ${({ clustersize }) => clustersize ? clustersize : "14px"};
  height: ${({ clustersize }) => clustersize ? clustersize : "14px"};
`;

type GoogleIconProps = {
  bottom?: string;
}

const GoogleIcon = styled.div<GoogleIconProps>`
  background: none;
  z-index: 4;
  position: absolute;
  bottom: ${(props) => props.bottom ? props.bottom : '114px'};
  right: 10px;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 2px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
`;

const GoogleBtn = styled.div`
  background: none;
  border: 0px;
  margin: 0px;
  padding: 0px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  width: 40px;
  height: 40px;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img:nth-child(2) {
    display: none;
  }
  &:hover > img:nth-child(1), &:focus > img:nth-child(1) {
    display: none;
  }
  &:hover > img:nth-child(2), &:focus > img:nth-child(2) {
    display: block
  }
`;

// add this to map-resource folder and import this instead
const customMapStyle = [
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

// add this to map-resource folder and import this instead
const mapOptions = (maps: GoogleMapReact.Maps) => {
  return {
    fullscreenControl: false,
    mapTypeControl: false,
    mapTypeId: maps.MapTypeId.ROADMAP,
    scaleControl: true,
    scrollwheel: true,
    streetViewControl: false,
    clickableIcons: true,
    controlSize: 40,
    gestureHandling: "greedy",
    styles: customMapStyle
  };
};

// const BuildARoute = ({ myGeoLocationCords, activePoiCords }: any) => {
//   const isValid = myGeoLocationCords.lat !== 0 && myGeoLocationCords.lng !== 0 &&
//     activePoiCords.lat !== 0 && activePoiCords.lng !== 0
//
//   const handleClick = () => {
//     const from = `${myGeoLocationCords.lat},${myGeoLocationCords.lng}`
//     const to = `${activePoiCords.lat},${activePoiCords.lng}`
//     const url = iOS() ?
//         `https://maps.apple.com/?saddr=${from}&daddr=${to}` :
//         `https://www.google.com/maps/dir/${from}/${to}`
//     window.open(url)
//   }
//
//   return (
//     <GoogleIcon
//       bottom={'164px'}
//       style={{display: isValid ? 'flex' : 'none' }}
//     >
//       <GoogleBtn onClick={handleClick}>
//         <img src={CornerUpRight} alt="Build a route" />
//         <img src={CornerUpRightBlack} alt="Build a route" />
//       </GoogleBtn>
//     </GoogleIcon>
//   )
// }

const Marker = ({
  children,
  lat,
  lng
}: {
  children: JSX.Element,
  lat: number,
  lng: number
}) => children



type MapType = MapOptions & {
  setZoom: (zoom: number) => void,
  panTo:(obj: { lat: number, lng: number }) => void
}

type PropsType = {
  id: string;
  mapCenterCoordinates: [number, number];
  poisFromProperty: AccessibilityUnit[];
  poisFromBuildingArea: Property[] | undefined;
  activePoiId: string
  onSetActivePoiId: Dispatch<SetStateAction<string>>;
  setClickedMapPoiName: Dispatch<SetStateAction<string>> | undefined;
  googleMapRef: any;
  standard: string;
  selectedPropertyImageFromBuildingArea?: string
  accessibilities?: Accessibility[]
}

export const GoogleMap: FC<PropsType> = ({
  id,
  mapCenterCoordinates,
  poisFromProperty,
  poisFromBuildingArea,
  activePoiId,
  onSetActivePoiId,
  setClickedMapPoiName,
  googleMapRef,
  standard,
  selectedPropertyImageFromBuildingArea,
  accessibilities,
}) => {
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mapRef = useRef<MapType>();
  const zoomOptions = getZoomOptions(id, standard, poisFromBuildingArea, sm)
  const [zoom, setZoom] = useState(zoomOptions);
  const [bounds, setBounds] = useState<number[] | null>(null);
  const [myGeoLocationCords, setMyGeoLocationCords] = useState<{ lat: number, lng: number }>({ lat: 0, lng: 0 });
  const [, setField] = useContext(TabsContext);
  const [centerByProperty, setCenterByProperty] = useState<undefined | Coords>(undefined)

  useEffect(() => {
    if (selectedPropertyImageFromBuildingArea && selectedPropertyImageFromBuildingArea?.length > 0) {
      onSetActivePoiId(selectedPropertyImageFromBuildingArea)
      const foundPois = poisFromBuildingArea?.find((pois) => (
        pois.id === selectedPropertyImageFromBuildingArea
      ))
      if (foundPois && foundPois.point) {
        const coordinates = foundPois.point.coordinates
        if (coordinates.length > 0) {
          setCenterByProperty({lat: coordinates[1], lng: coordinates[0]})
        }
      }
    }
  }, [selectedPropertyImageFromBuildingArea])

  const points = (poisFromBuildingArea) ?
    poisFromBuildingArea?.map((poi) => ({
      type: "Feature",
      properties: {
        cluster: false,
        poiId: poi.id,
        poiType: poi.category,
        name: getTextByCurrentLanguage(poi.name),
        fromBuildingArea: true
        // we can add more custom properties if we want
      },
      geometry: {
        type: "Point",
        coordinates: [
          poi.point?.coordinates[0],
          poi.point?.coordinates[1]
        ]
      }
    })) :
    poisFromProperty?.map((poi) => ({
      type: "Feature",
      properties: {
        cluster: false,
        poiId: poi.id,
        poiType: poi.type,
        name: getTextByCurrentLanguage(poi.name)
        // we can add more custom properties if we want
      },
      geometry: {
        type: "Point",
        coordinates: [
          poi.point?.coordinates[0],
          poi.point?.coordinates[1]
        ]
      }
    }))

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: 75,
      maxZoom: 14,
    }
  });

  const goToProperty = (cluster: Cluster) => {
    if (poisFromBuildingArea && poisFromBuildingArea.length > 0 && cluster?.properties?.poiId) {
      const findIndexProperty = poisFromBuildingArea
        .findIndex((i) => i.id === cluster.properties.poiId)
      if (findIndexProperty !== -1) {
        setField(findIndexProperty + 1)
      }
    }
  }

  return (
    <GoogleMapReactWrapper
      ref={(e) => googleMapRef(e)}
      isBuildingArea={poisFromBuildingArea && poisFromBuildingArea.length > 0}
    >
      {(mapCenterCoordinates && points && clusters) ?
        <>
          {mapRef ? (
            <MyGeoLocation
              map={mapRef.current}
              setMyGeoLocationCords={setMyGeoLocationCords}
            />
          ) : null}
          {/*{mapRef ? (*/}
          {/*  <BuildARoute*/}
          {/*    myGeoLocationCords={myGeoLocationCords}*/}
          {/*    activePoiCords={activePoiCords}*/}
          {/*  />*/}
          {/*) : null}*/}
          <GoogleMapReact
            options={mapOptions}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_KEY as string
            }}
            center={centerByProperty}
            defaultCenter={{
              lat: mapCenterCoordinates[1],
              lng: mapCenterCoordinates[0]
            }}
            defaultZoom={zoomOptions}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({map}) => mapRef.current = map}
            onChange={({zoom, bounds}) => {
              if (selectedPropertyImageFromBuildingArea) {
                setZoom(40)
              } else {
                setZoom(zoom);
              }
              setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat
              ])
            }}
          >
            {(myGeoLocationCords &&
              (myGeoLocationCords.lat !== 0 ||
              myGeoLocationCords.lng !== 0)) ?
                <Marker
                  lat={myGeoLocationCords.lat}
                  lng={myGeoLocationCords.lng}
                >
                  <img src={MyLocationImgBlue} alt="my location" />
                </Marker> : null
            }
            {clusters.map(cluster => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount } = cluster.properties;

              if (isCluster) {
                return (
                  <Marker
                    key={`cluster-${cluster.id}`}
                    lat={latitude}
                    lng={longitude}
                  >
                    <ClusterMarker
                      clustersize={`${10 + (pointCount / points.length) * 30}px`}
                      variant="contained"
                      onClick={() => {
                        const expansionZoom = Math.min(
                          supercluster.getClusterExpansionZoom(cluster.id),
                          20
                        );
                        mapRef.current?.setZoom(expansionZoom);
                        mapRef.current?.panTo({ lat: latitude, lng: longitude });
                      }}
                    >
                      {pointCount}
                    </ClusterMarker>
                  </Marker>
                );
              }

              const selectedIcon = selectedPropertyImageFromBuildingArea === cluster.properties.poiId

              return (
                <Marker
                  key={cluster.properties.poiId}
                  lat={latitude}
                  lng={longitude}
                >
                  <StyledIconButton
                    onClick={(e) => {
                      onSetActivePoiId(cluster.properties.poiId)
                      setClickedMapPoiName?.(cluster.properties.name);
                      goToProperty(cluster)
                    }}
                    aria-label={`${cluster.properties.name} - ${cluster.properties.poiType}`}
                    scaletransform={activePoiId === cluster.properties.poiId ? "translate(-50%,-100%) scale(1.3)" : undefined}
                    className={selectedIcon || activePoiId === cluster.properties.poiId ? 'selected' : ''}
                  >
                    <POI
                      selected={selectedIcon || activePoiId === cluster.properties.poiId}
                      standard={standard}
                      data={cluster.properties}
                      accessibilities={accessibilities}
                    />
                  </StyledIconButton>
              </Marker>
              );
            })}
          </GoogleMapReact>
        </>
        : null
      }
    </GoogleMapReactWrapper>
  );
};
