import React, { useEffect, useState } from "react"

export const IconLoader = (source: string) => (p: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
>) => {
    const { src, ...rest } = p
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img {...rest} src={source} />
}

type SvgProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    source: string
}

const Svg = ({ source, ...rest }: SvgProps) => {
    const [svg, setSvg] = useState<string>(null!);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isErrored, setIsErrored] = useState(false);
    useEffect(() => {
        console.log({ source });
        fetch(source)
            .then(res => {
                console.log({ res });
                return res.text();
            })
            .then(setSvg)
            .catch(setIsErrored)
            .then(() => setIsLoaded(true));
    }, [source]);
    console.log({ svg, isErrored, isLoaded });
    return <div {...rest} dangerouslySetInnerHTML={{ __html: svg }}></div>
}
export const SvgLoader = (source: string) => (p: Omit<SvgProps, "source">) => <Svg {...p} source={source} />

// https://ams-dev1.s3.eu-west-1.amazonaws.com/ams-widget-icons/Handiscover_SVG.svg
const S3 = process.env.REACT_APP_AWS_S3_BUCKET;
const Icons = {
    // Handiscover: IconLoader(`${S3}/ams-widget-icons/Handiscover_SVG.svg`),
    Handiscover_Logo: IconLoader(`${S3}/ams-widget-icons/HDLogo.png`),
    Hotel: IconLoader(`${S3}/ams-widget-icons/Hotel_SVG.svg`),
    Rooms: IconLoader(`${S3}/ams-widget-icons/Rooms_SVG.svg`),
    CarretLeft: IconLoader(`${S3}/ams-widget-icons/CarretLeft.svg`),
    CarretRight: IconLoader(`${S3}/ams-widget-icons/CarretRight.svg`),
    Checked: IconLoader(`${S3}/ams-widget-icons/CheckedSVG.svg`),
    FoodAllergy1Svg: IconLoader(`${S3}/ams-widget-icons/Food Allergies 1.svg`),
    FoodAllergy2Svg: IconLoader(`${S3}/ams-widget-icons/Food Allergies 2.svg`),
    FoodAllergy3Svg: IconLoader(`${S3}/ams-widget-icons/Food Allergies 3.svg`),
    Hearing1Svg: IconLoader(`${S3}/ams-widget-icons/Hearing 1.svg`),
    Hearing2Svg: IconLoader(`${S3}/ams-widget-icons/Hearing 2.svg`),
    Hearing3Svg: IconLoader(`${S3}/ams-widget-icons/Hearing 3.svg`),
    Mobility1Svg: IconLoader(`${S3}/ams-widget-icons/Mobility 1.svg`),
    Mobility2Svg: IconLoader(`${S3}/ams-widget-icons/Mobility 2.svg`),
    Mobility3Svg: IconLoader(`${S3}/ams-widget-icons/Mobility 3.svg`),
    RoomAllergies1Svg: IconLoader(`${S3}/ams-widget-icons/Room Allergies 1.svg`),
    RoomAllergies2Svg: IconLoader(`${S3}/ams-widget-icons/Room Allergies 2.svg`),
    RoomAllergies3Svg: IconLoader(`${S3}/ams-widget-icons/Room Allergies 3.svg`),
    Visual1Svg: IconLoader(`${S3}/ams-widget-icons/Visual 1.svg`),
    Visual2Svg: IconLoader(`${S3}/ams-widget-icons/Visual 2.svg`),
    Visual3Svg: IconLoader(`${S3}/ams-widget-icons/Visual 3.svg`),
    Cognitive1: IconLoader(`${S3}/ams-widget-icons/Cognitive1.png`),
    Cognitive2: IconLoader(`${S3}/ams-widget-icons/Cognitive2.png`),
    Cognitive3: IconLoader(`${S3}/ams-widget-icons/Cognitive3.png`),
    CloseIcon:IconLoader(`${S3}/ams-widget-icons/CloseIcon.svg`),
    HamburgerIcon:IconLoader(`${S3}/ams-widget-icons/hamburger-menu-svg.svg`),
    CheckIcon: IconLoader(`${S3}/ams-widget-icons/checkIcon.svg`),
    ChevronRight: IconLoader(`${S3}/ams-widget-icons/chevron-right.svg`),
    ChevronDown: IconLoader(`${S3}/ams-widget-icons/chevron-down.svg`),
    EnFlag: IconLoader(`${S3}/ams-widget-icons/GB.svg`),
    SweFlag: IconLoader(`${S3}/ams-widget-icons/SV.svg`),
    DanFlag: IconLoader(`${S3}/ams-widget-icons/DA.svg`),
    GerFlag: IconLoader(`${S3}/ams-widget-icons/DE.svg`),
    SpaFlag: IconLoader(`${S3}/ams-widget-icons/ES.svg`),
    FreFlag: IconLoader(`${S3}/ams-widget-icons/FR.svg`),
    NorFlag: IconLoader(`${S3}/ams-widget-icons/NO.svg`),
    PorFlag: IconLoader(`${S3}/ams-widget-icons/PT.svg`),
    UkrFlag: IconLoader(`${S3}/ams-widget-icons/UA.svg`),
    Translation: IconLoader(`${S3}/ams-widget-icons/translation.png`),
    MapIconToilet: IconLoader(`${S3}/ams-widget-icons/toilet_map_icon.svg`),
    MapIconShop: IconLoader(`${S3}/ams-widget-icons/shop_map_icon.svg`),
    MapIconParking: IconLoader(`${S3}/ams-widget-icons/parking_map_icon.svg`),
    MapIconEntrance: IconLoader(`${S3}/ams-widget-icons/entrance_map_icon.svg`),
    GoBackChevron: IconLoader(`${S3}/ams-widget-icons/go_back_chevron.svg`),
    Union: IconLoader(`${S3}/ams-widget-icons/Union.svg`),
    GeoPinBlue: IconLoader(`${S3}/ams-widget-icons/geo_pin_blue.png`)
}
export default Icons;