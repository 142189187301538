import {SvgIcon} from "@mui/material";
import {FC} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";

const AccessibleRoomSvg:FC<SvgIconProps> = (p) => (
    <SvgIcon {...p}>
        <svg {...p} viewBox="0 0 110 110" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1890_287)">
                <mask id="mask0_1890_287" maskUnits="userSpaceOnUse" x="0" y="10" width="58" height="58">
                    <path d="M57.8 10.2H0V68H57.8V10.2Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_1890_287)">
                    <path d="M34.6107 27.0164H23.1892C20.2353 27.0164 17.832 29.4196 17.832 32.3736V35.4577H39.9681V32.3736C39.9681 29.4198 37.5646 27.0164 34.6107 27.0164Z"/>
                    <path d="M47.5157 10.7554H10.2769C6.56683 10.7554 3.54858 13.7737 3.54858 17.4838V35.7291C4.27979 35.5533 5.04156 35.4575 5.82594 35.4575H13.9185V32.3735C13.9185 27.2636 18.0757 23.1064 23.1856 23.1064H34.6069C39.7171 23.1064 43.8743 27.2636 43.8743 32.3735V35.4575H51.9666C52.751 35.4575 53.5129 35.5532 54.2443 35.7291V17.4838C54.2439 13.7737 51.2254 10.7554 47.5157 10.7554Z"/>
                    <path d="M51.9654 39.3677H41.918H15.8722H5.82477C2.61023 39.3675 -0.00488281 41.9828 -0.00488281 45.1972V56.851H57.795V45.1972C57.795 41.9828 55.1801 39.3677 51.9654 39.3677Z"/>
                    <path d="M3.54858 60.7612V65.4893C3.54858 66.5691 4.42385 67.4443 5.50352 67.4443C6.58319 67.4443 7.45845 66.5691 7.45845 65.4893V60.7612H3.54858Z"/>
                    <path d="M50.3425 60.7612V65.4893C50.3425 66.5691 51.218 67.4443 52.2975 67.4443C53.3774 67.4443 54.2525 66.5691 54.2525 65.4893V60.7612H50.3425Z"/>
                </g>
                <path d="M79.4165 40.866C75.62 40.866 72.5662 37.82 72.5662 34.033C72.5662 30.246 75.62 27.2 79.4165 27.2C83.2133 27.2 86.2671 30.246 86.2671 34.033C86.2671 37.7376 83.2133 40.866 79.4165 40.866Z"/>
                <path d="M47.8053 78.7359C48.4656 77.4187 49.7036 77.0896 51.272 77.4187L53.2528 77.9127L60.4333 62.7647C60.7634 62.1884 61.1762 61.6947 61.7538 61.283C62.4967 60.8713 63.3223 60.7067 64.1474 60.789H72.5662C73.3091 60.789 73.9693 60.1304 73.9693 59.3895V48.0284C73.9693 45.1473 76.2803 42.842 79.169 42.842H79.9945C82.8831 42.842 85.1941 45.1473 85.1941 48.0284V63.0942C85.1941 66.3048 82.553 68.9391 79.3342 68.9391H65.0552C65.0552 68.9391 58.3698 83.0994 58.2875 83.1817C57.6273 84.4989 56.1415 84.9929 54.8209 84.4166L49.2085 81.9468C47.9702 81.5351 47.31 80.1357 47.7227 78.9008C47.8053 78.8185 47.8053 78.7359 47.8053 78.7359Z"/>
                <path d="M88.3306 56.014V60.6241C88.3306 60.9535 88.4955 61.2827 88.743 61.4472C95.016 66.1399 96.254 75.1135 91.5494 81.3702C86.8448 87.6269 77.8484 88.8618 71.5757 84.1694C68.7694 82.0288 66.7885 78.9827 66.1282 75.4426C66.1282 75.2781 65.963 75.1135 65.7981 75.1958C65.7155 75.1958 65.6329 75.2781 65.5506 75.3604L63.0744 80.4645C62.9091 80.7939 62.9091 81.1234 63.0744 81.4525C67.9438 90.7552 79.4165 94.4599 88.8256 89.7675C98.2348 84.9926 101.949 73.4669 97.2444 64.0819C95.4284 60.4595 92.4572 57.578 88.8256 55.6846C88.6607 55.6846 88.4129 55.7669 88.3306 56.014C88.3306 55.9314 88.3306 55.9314 88.3306 56.014Z"/>
            </g>
            <defs>
                <clipPath id="clip0_1890_287">
                    <rect width="102" height="102" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
)

export default AccessibleRoomSvg