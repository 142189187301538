import {PropertyPageTableHeader} from "../../../StyledWrappers/Wrappers";
// import {CustomTooltip} from "../../../components/Tooltip";
import {GeneralInformationContainer} from "../../../components/GeneralInformationContainer";
import React, {FC} from "react";
import styled from "styled-components";
import {Accessibility} from "../../../Models/Accessibility";
import {useStorageData} from "../../../hooks/useStorageData";

const StyledTable = styled.table`
  width: 100%;
`;

const StyledBody = styled.tbody`
  width: 100vw
`

const StyledTr = styled.tr`
  width: 100vw
`

const StyledTd = styled.td`
  width: 100vw
`

type PropsType = {
  id: string
  name: string
  title: string
  accessibilities:  Accessibility[] | undefined
  onSetRef: (r: HTMLTableHeaderCellElement | null) => void
}

const ElementOfTable: FC<PropsType> = ({
  id,
  name,
  title,
  accessibilities,
  onSetRef
}) => {
  const disabilityTypes: string[] = useStorageData('disability_types')

  const getFilteredAccessibilities = () => {
    let array = []
    if (accessibilities) {
      array = accessibilities.filter((unit) => unit.accessibility_unit === id);
      if (array.length > 0 && disabilityTypes?.length > 0) {
        array = array.filter((i) => {
          if (i.disability_types) {
            return disabilityTypes.some((dt) => i.disability_types.includes(dt))
          } else {
            return false
          }
        })
      }
      return array.map((el) => (
        <GeneralInformationContainer
          padding={'10px'}
          key={el.id}
          text={el.attribute_title}
        />
      ))
    }
    return []
  }

  const filteredAccessibilities = getFilteredAccessibilities()

  return (
    <table key={id} id={id} ref={(r: any) => onSetRef(r)}>
      {filteredAccessibilities.length > 0 ? (
        <StyledBody>
          <StyledTr>
            <PropertyPageTableHeader>
              {title} - {name}
              {/*<CustomTooltip toolTipText={`${t("translated_text")}`}>*/}
              {/*  <TranslationIcon />*/}
              {/*</CustomTooltip>*/}
            </PropertyPageTableHeader>
          </StyledTr>
          {filteredAccessibilities}
        </StyledBody>
      ) : (<></>)}
    </table>
  )
}

export default ElementOfTable
