{
  "blacklist": [
    "185.223.237.177",
    "89.160.112.89",
    "217.31.187.220",
    "90.224.55.25",
    "80.216.83.175",
    "82.197.239.85",
    "161.230.115.108",
    "212.162.171.111",
    "213.114.110.105",
    "78.72.196.244",
    "90.235.55.66",
    "94.234.75.1",
    "94.254.48.71",
    "84.216.33.34",
    "178.36.23.232"
  ]
}
