import {CustomImageGallery} from "../../../components/image-gallery/CustomImageGallery";
import {Skeleton, Typography} from "@mui/material";
import {HotelInfoContainer} from "../../../components/HotelInfoContainer";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {ReactImageGalleryItem} from "react-image-gallery";
import styled from "styled-components";
import {PropertyAccessibilityImages} from "../../../Models/PropertyAccessibilityImages";

const Wrapper = styled.div`
  height: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF;
`

type PropsType = {
  carouselImages: ReactImageGalleryItem[],
  propertyImages?:  PropertyAccessibilityImages[] | any
  onSlide?: (currentIndex: number) => void
  showCloseButton?: boolean
  onClose?: () => void
  showThumbnails?: boolean
}

const ImageContainer: FC<PropsType> = ({
  carouselImages,
  propertyImages,
  onSlide,
  showCloseButton,
  onClose,
  showThumbnails = true,
  children
}) => {

  const { t } = useTranslation()

  return (
    <HotelInfoContainer
      label={t("mainContainer_pictures")}
      onClose={onClose}
      showCloseButton={showCloseButton}
    >
      {children}
      {carouselImages.length > 0 ? (
        <CustomImageGallery
          showThumbnails={showThumbnails}
          items={carouselImages}
          onSlide={onSlide}
        />
      ) : null}

      {propertyImages && propertyImages.length > 0 && carouselImages.length === 0 ? (
        <Wrapper style={{border: '1px solid #E3E5E5'}}>
          <Typography color="gray !important">No pictures available</Typography>
        </Wrapper>
      ) : null}

      {propertyImages && propertyImages.length === 0 && carouselImages.length === 0 ? (
        <Wrapper style={{border: '1px solid #E3E5E5'}}>
          <Typography color="gray !important">No pictures available</Typography>
        </Wrapper>
      ) : null}

      {carouselImages.length === 0 && !propertyImages ? (
        <Wrapper>
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            animation="wave"
          />
        </Wrapper>
      ) : null}
    </HotelInfoContainer>
  )
}

export default ImageContainer
