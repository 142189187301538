import {a11yStandardKeys, unitTypeKeys} from "./constants";

enum ICONS {
  ACCESSIBLE_CAR_RENT = 'accessible_car_rent',
  ACCESSIBLE_WHEELCHAIR_SYMBOL = 'accessible_wheelchair_symbol',
  ACCESSIBLE_WIDE = 'accessible_wide',
  AISLE_COLOUR_CONTRAST = 'aisle_colour_contrast',
  AISLE_WIDTH__GTE_90_CM = 'aisle_width__gte_90_cm',
  ALARM_BUTTON_OR_CORD = 'alarm_button_or_cord',
  ASSISTANCE_ANIMAL_ALLOWED = 'assistance_animal_allowed',
  BATHTUB_BENCH_AVAILABLE = 'bathtub_bench_available',
  BATH_OR_SHOWER_AVAILABLE__BATH = 'bath_or_shower_available__bath',
  HANDLES_AVAILABLE = 'handles_available',
  BED_HEIGHT = 'bed_height',
  BED_ROOM_CEILING_HOIST = 'bed_room_ceiling_hoist',
  BED_SPACE_TO_WALL = 'bed_space_to_wall',
  BED_SPACE_UNDER = 'bed_space_under',
  BED_TYPE__ADJUSTABLE_OR_PROFILING = 'bed_type__adjustable_or_profiling',
  BUFFET_WHEELCHAIR_ACCESSIBLE_HEIGHT = 'buffet_wheelchair_accessible_height',
  CONTRAST_MARKINGS_LEVEL_DIFFERENCES = 'contrast_markings_level_differences',
  DESK_CONTRAST_MARKINGS = 'desk_contrast_markings',
  DESK_HEIGHT_ACCESSIBLE = 'desk_height_accessible',
  DOOR_WIDTH__GTE_75_LT_90_CM = 'door_width__gte_75_lt_90_cm',
  DOOR_WIDTH__GTE_90_CM = 'door_width__gte_90_cm',
  DOOR_WIDTH__LT_75_CM = 'door_width__lt_75_cm',
  ELECTRIC_WHEELCHAIR_HIRE = 'electric_wheelchair_hire',
  FREE_AREA_NEXT_TO_SEATS_FOR_WHEELCHAIR_PERMOBILE_STROLLER = 'free_area_next_to_seats_for_wheelchair_permobile_stroller',
  FREE_PARKING_WITH_SPECIAL_PARKING_PERMITS = 'free_parking_with_special_parking_permits',
  GUIDANCE_PATHS_NATURAL_OR_TACTILE_INSIDE_THE_PLAYGROUND = 'guidance_paths_natural_or_tactile_inside_the_playground',
  HEARING_LOOP_WITH_SIGN = 'hearing_loop_with_sign',
  IS_THERE_A_SLOPE_SLIDE = 'is_there_a_slope_slide',
  IS_THERE_BABY_SWING = 'is_there_baby_swing',
  IS_THERE_NEST_SWING = 'is_there_nest_swing',
  IS_THERE_SWING_WITHOUT_SEAT_SUPPORT = 'is_there_swing_without_seat_support',
  IS_THERE_SWING_WITH_SEAT_SUPPORT = 'is_there_swing_with_seat_support',
  KITCHEN_TYPE_ADJUSTABLE = 'kitchen_type__adjustable',
  LIFT_ANNOUNCEMENTS__VISUAL = 'lift_announcements__visual',
  LIFT_ANNOUNCEMENTS__VISUAL_AND_SOUND = 'lift_announcements__visual_and_sound',
  LIFT_ANNOUNCEMENTS__VISUAL_AND_VOICE = 'lift_announcements__visual_and_voice',
  LIFT_BUTTONS__WITHOUT_CONTRAST = 'lift_buttons__without_contrast',
  LIFT_BUTTONS__WITH_CONTRAST = 'lift_buttons__with_contrast',
  LIFT_BUTTONS__WITH_CONTRAST_AND_TACTILE = 'lift_buttons__with_constrast_and_tactile',
  MOBILITY_SCOOTER_HIRE = 'mobility_scooter_hire',
  PLAYGROUND_HAVE_FIXED_PROTECTION_FENCE_PLANK = 'playground_have_fixed_protection_fence_plank',
  PLAYGROUND_HAVE_SCREENING_GREENERY_BUSHES_HILLS_OTHER_VEGETATION = 'playground_have_screening_greenery_bushes_hills_other_vegetation',
  POOL_HOIST_AVAILABLE = 'pool_hoist_available',
  SAFETY_ALARM_SOUND = 'safety_alarm_sound',
  SAFETY_ALARM_VIBRATION = 'safety_alarm_vibration',
  SAFETY_ALARM_VISUAL = 'safety_alarm_visual',
  SEATS_WITH_ARMRESTS_BACKRESTS_INSIDE_PLAYGROUND = 'seats_with_armrests_backrests_inside_playground',
  STAIRS_STEPS_COUNT__LTE_15_STEPS = 'stairs_steps_count__lte_15_steps',
  STAIRS_STEPS_COUNT__GT_15_STEPS = 'stairs_steps_count__gt_15_steps',
  STAIRS_STEPS_COUNT__SINGLE_STEPS_WITHOUT_RAMP = 'stairs_steps_count__single_steps_without_ramp',
  SHOWER_AREA_TYPE__BATHTUB_GRAB_RAILS_WITH = 'shower_area_type__bathtub_grab_rails_with',
  SHOWER_AREA_TYPE__BATHTUB_GRAB_RAILS_WITHOUT = 'shower_area_type__bathtub_grab_rails_without',
  SHOWER_AREA_TYPE__BATHTUB_GRAB_RAILS_WITH_AND_CHAIR = 'shower_area_type__bathtub_grab_rails_with_and_chair',
  SHOWER_AREA_TYPE__REGULAR = 'shower_area_type__regular',
  SHOWER_AREA_TYPE__SHOWER_CHAIR_FIXED = 'shower_area_type__shower_chair_fixed',
  SHOWER_AREA_TYPE__SHOWER_CHAIR_WHEELS = 'shower_area_type__shower_chair_wheels',
  SHOWER_AREA_TYPE__SHOWER_ROLLIN = 'shower_area_type__shower_rollin',
  SHOWER_AREA_TYPE__SHOWER_ROLLIN_WITH_CHAIR = 'shower_area_type__shower_rollin_with_chair',
  SHOWER_AREA_TYPE__SHOWER_WALKIN = 'shower_area_type__shower_walkin',
  SHOWER_AREA_TYPE__SHOWER_WALKIN_WITH_CHAIR = 'shower_area_type__shower_walkin_with_chair',
  SHOWER_CHAIR_TYPE__SHOWER_CHAIR_PORTABLE = 'shower_chair_type__shower_chair_portable',
  SHOWER_CURTAIN_OR_GLASS_WALL = 'shower_curtain_or_glass_wall',
  SIGN_WITH_INFORMATION_ABOUT_PLAYGROUND = 'sign_with_information_about_playground',
  VERTICAL_ACCESS_TYPE__RAMP = 'vertical_access_type__ramp',
  VERTICAL_ACCESS_TYPE__STEP_FREE = 'vertical_access_type__step_free',
  VERTICAL_ACCESS_TYPE__STAIR_LIFT = 'vertical_access_type__stair_lift',
  VERTICAL_ACCESS_TYPE__LIFT = 'vertical_access_type__lift',
  SURFACE_TYPE__ARTIFICIAL_GRASS = 'surface_type__artificial_grass',
  SURFACE_TYPE__ASPHALT = 'surface_type__asphalt',
  SURFACE_TYPE__GRASS = 'surface_type__grass',
  SURFACE_TYPE__LOOSE_GRAVEL = 'surface_type__loose_gravel',
  SURFACE_TYPE__STONE_FLOUR = 'surface_type__stone_flour',
  TOILET_AREA_TYPE__GRAB_RAILS_BOTH_SIDES = 'toilet_area_type__grab_rails_both_sides',
  TOILET_AREA_TYPE__GRAB_RAILS_ONE_SIDE = 'toilet_area_type__grab_rails_one_side',
  TOILET_AREA_TYPE__REGULAR = 'toilet_area_type__regular',
  TOILET_AREA_TYPE__SUPPORT_ONE_SIDE = 'toilet_area_type__support_one_side',
  TOILET_WITH_CEILING_HOIST = 'toilet_with_ceiliing_hoist',
  TURNING_CIRCLE_150CM = 'turning_circle_150cm',
  TYPE_OF_TALL_PROTECTION_BASE__ARTIFICIAL_GRASS = 'type_of_fall_protection_base__artificial_grass',
  TYPE_OF_TALL_PROTECTION_BASE__ASPHALT = 'type_of_fall_protection_base__asphalt',
  TYPE_OF_TALL_PROTECTION_BASE__GRASS = 'type_of_fall_protection_base__grass',
  TYPE_OF_TALL_PROTECTION_BASE__LOOSE_GRAVEL = 'type_of_fall_protection_base__loose_gravel',
  TYPE_OF_TALL_PROTECTION_BASE__LOOSE__STONE_FLOUR = 'type_of_fall_protection_base__stone_flour',
  WC_CONTRAST_MARKING_BEHIND = 'wc_contrast_marking_behind',
  WC_FOLDING_GRAB_BAR = 'wc_folding_grab_bar',
  WC_FOLDING_GRAB_BARS_BOTH_SIDE = 'wc_folding_grab_bars_both_side',
  WHEELCHAIR_BORROW = 'wheelchair_borrow',
  WHEELCHAIR_HIRE = 'wheelchair_hire',
  WHEELCHAIR_REACHABLE = 'wheelchair_reachable',
  POSSIBLE_TO_GET_AUDIO_GUIDANCE = 'possible_to_get_audio_guidance',
  RAMP_GRADIENT__RAMP_GRADIENT_LT_8 = 'ramp_gradient__ramp_gradient_lt_8',
  RAMP_GRADIENT__RAMP_GRADIENT_GT_8 = 'ramp_gradient__ramp_gradient_gt_8',
}

// The alarm_button_or_cord icon is a copied safety_alarm_vibration icon

type Group = {
  [key: string]: {
    GROUP: number
    PRIORITY: number
  }
}

// G - general
// M - mobility
// V - visual
// H - hearing
// C - cognitive
// BA - building area

export type PriorityType = {
  [key: string]: {
    G: number | null,
    M: number | null,
    V: number | null,
    H: number | null,
    C: number | null,
    BA: number | null,
    SORT_GROUP?: {
      GROUP: number,
      PRIORITY: number
    },
    FILTER_GROUP?: {
      GROUP: number,
      PRIORITY: number
    }
  }
};

const filterGroup = (group: number, priority: number): Group => ({
  FILTER_GROUP: {
    GROUP: group,
    PRIORITY: priority
  }
})

const sortGroup = (group: number, priority: number): Group => ({
  SORT_GROUP: {
    GROUP: group,
    PRIORITY: priority
  }
})

// 2, 1 vertical_access_type__step_free                              ,  ...sortGroup(2, 1) (done)
// 2, 2 vertical_access_type__ramp -> ramp_gradient lt gt            ,  ...sortGroup(2, 2) (done)
// 2, 3 vertical_access_type__stair_lift                             ,  ...sortGroup(2, 3) (done)
// 2, 4 vertical_access_type__lift                                   ,  ...sortGroup(2, 4) (done)
// 2, 5 (all lift stuff) -> EXIST                                    ,  ...sortGroup(2, 5) (done)
// 2, 6 (escalator) -> NOT EXIST                                     ,  ...sortGroup(2, 6)
// 2, 7 stairs_steps_count__lte_15_steps                             ,  ...sortGroup(2, 7)
// 2, 8 stairs_steps_count__gt_15_steps                              ,  ...sortGroup(2, 8)


const shoppingMallIcons: PriorityType = {
  [ICONS.ACCESSIBLE_WIDE]:                                     { BA: 2,    G: 2,    M: 2,    V: null, H: null, C: 2    },
  [ICONS.ACCESSIBLE_WHEELCHAIR_SYMBOL]:                        { BA: 1,    G: 1,    M: 1,    V: null, H: null, C: 1    },
  [ICONS.FREE_PARKING_WITH_SPECIAL_PARKING_PERMITS]:           { BA: 1,    G: 1,    M: 1,    V: null, H: null, C: 1    },
  [ICONS.VERTICAL_ACCESS_TYPE__RAMP]:                          { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...filterGroup(3, 1)},
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_LT_8]:                   { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...filterGroup(3, 2)},
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_GT_8]:                   { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...filterGroup(3, 2)},
  [ICONS.VERTICAL_ACCESS_TYPE__STAIR_LIFT]:                    { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 3)},
  [ICONS.VERTICAL_ACCESS_TYPE__LIFT]:                          { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 4)},
  [ICONS.VERTICAL_ACCESS_TYPE__STEP_FREE]:                     { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 1)},
  [ICONS.STAIRS_STEPS_COUNT__LTE_15_STEPS]:                    { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 7)},
  [ICONS.STAIRS_STEPS_COUNT__GT_15_STEPS]:                     { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 8)},
  [ICONS.DOOR_WIDTH__GTE_90_CM]:                               { BA: 4,    G: 4,    M: 4,    V: 3,    H: null, C: 4,    ...sortGroup(1, 1)},
  [ICONS.DOOR_WIDTH__LT_75_CM]:                                { BA: 4,    G: 4,    M: 4,    V: 3,    H: null, C: 4,    ...sortGroup(1, 2)},
  [ICONS.DOOR_WIDTH__GTE_75_LT_90_CM]:                         { BA: 4,    G: 4,    M: 4,    V: 3,    H: null, C: 4,    ...sortGroup(1, 3)},
  [ICONS.DESK_CONTRAST_MARKINGS]:                              { BA: null, G: null, M: null, V: 4,    H: null, C: 4    },
  [ICONS.DESK_HEIGHT_ACCESSIBLE]:                              { BA: null, G: null, M: 9,    V: null, H: null, C: null },
  [ICONS.HEARING_LOOP_WITH_SIGN]:                              { BA: 5,    G: 5,    M: null, V: null, H: 1,    C: null },
  [ICONS.LIFT_BUTTONS__WITHOUT_CONTRAST]:                      { BA: null, G: null, M: null, V: 5,    H: null, C: 6,    ...sortGroup(2, 5)},
  [ICONS.LIFT_BUTTONS__WITH_CONTRAST]:                         { BA: null, G: null, M: null, V: 5,    H: null, C: 6,    ...sortGroup(2, 5)},
  [ICONS.LIFT_BUTTONS__WITH_CONTRAST_AND_TACTILE]:             { BA: null, G: null, M: null, V: 5,    H: null, C: 6,    ...sortGroup(2, 5)},
  [ICONS.LIFT_ANNOUNCEMENTS__VISUAL]:                          { BA: null, G: null, M: null, V: 6,    H: 5,    C: 7,    ...sortGroup(2, 5)},
  [ICONS.LIFT_ANNOUNCEMENTS__VISUAL_AND_SOUND]:                { BA: null, G: null, M: null, V: 6,    H: 5,    C: 7,    ...sortGroup(2, 5)},
  [ICONS.LIFT_ANNOUNCEMENTS__VISUAL_AND_VOICE]:                { BA: null, G: null, M: null, V: 6,    H: 5,    C: 7,    ...sortGroup(2, 5)},
  [ICONS.ASSISTANCE_ANIMAL_ALLOWED]:                           { BA: 6,    G: 6,    M: null, V: 1,    H: null, C: 8    },
  [ICONS.SAFETY_ALARM_SOUND]:                                  { BA: null, G: null, M: null, V: 7,    H: 4,    C: 9    },
  [ICONS.SAFETY_ALARM_VISUAL]:                                 { BA: null, G: null, M: null, V: null, H: 3,    C: 9    },
  [ICONS.SAFETY_ALARM_VIBRATION]:                              { BA: null, G: null, M: null, V: 7,    H: 3,    C: 9    },
  [ICONS.WHEELCHAIR_BORROW]:                                   { BA: 11,   G: 11,   M: 10,   V: null, H: null, C: 10   },
  [ICONS.WHEELCHAIR_HIRE]:                                     { BA: 11,   G: 11,   M: 10,   V: null, H: null, C: 10   },
  [ICONS.ELECTRIC_WHEELCHAIR_HIRE]:                            { BA: null, G: null, M: 11,   V: null, H: null, C: 10   },
  [ICONS.MOBILITY_SCOOTER_HIRE]:                               { BA: 12,   G: 12,   M: 11,   V: null, H: null, C: null },
  [ICONS.BUFFET_WHEELCHAIR_ACCESSIBLE_HEIGHT]:                 { BA: null, G: null, M: 12,   V: null, H: null, C: null },
  [ICONS.TURNING_CIRCLE_150CM]:                                { BA: 8,    G: 8,    M: 6,    V: null, H: null, C: null },
  [ICONS.TOILET_AREA_TYPE__REGULAR]:                           { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 13,   ...filterGroup(1, 1) },
  [ICONS.TOILET_AREA_TYPE__SUPPORT_ONE_SIDE]:                  { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 13,   ...filterGroup(1, 2) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_ONE_SIDE]:               { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 13,   ...filterGroup(1, 3) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_BOTH_SIDES]:             { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 13,   ...filterGroup(1, 4) },
  [ICONS.WC_FOLDING_GRAB_BAR]:                                 { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 13,   ...filterGroup(1, 5) },
  [ICONS.WC_FOLDING_GRAB_BARS_BOTH_SIDE]:                      { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 13,   ...filterGroup(1, 6) },
  // [ICONS.TOILET_WITH_CEILING_HOIST]:                        { BA: null, G: 10, M: 8, V: null, H: null, C: null },
}

const museumIcons: PriorityType = {
  [ICONS.ACCESSIBLE_WIDE]:                                     { BA: 2,    G: 2,    M: 2,    V: null, H: null, C: 2    },
  [ICONS.ACCESSIBLE_WHEELCHAIR_SYMBOL]:                        { BA: 1,    G: 1,    M: 1,    V: null, H: null, C: 1    },
  [ICONS.FREE_PARKING_WITH_SPECIAL_PARKING_PERMITS]:           { BA: 1,    G: 1,    M: 1,    V: null, H: null, C: 1    },
  [ICONS.VERTICAL_ACCESS_TYPE__LIFT]:                          { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 4)},
  [ICONS.VERTICAL_ACCESS_TYPE__RAMP]:                          { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...filterGroup(3, 1), ...sortGroup(2, 2)},
  [ICONS.VERTICAL_ACCESS_TYPE__STEP_FREE]:                     { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 1)},
  [ICONS.STAIRS_STEPS_COUNT__LTE_15_STEPS]:                    { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 7)},
  [ICONS.STAIRS_STEPS_COUNT__GT_15_STEPS]:                     { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 8)},
  [ICONS.DOOR_WIDTH__GTE_90_CM]:                               { BA: 4,    G: 4,    M: 4,    V: 3,    H: null, C: 4,    ...sortGroup(1, 1)},
  [ICONS.DOOR_WIDTH__LT_75_CM]:                                { BA: 4,    G: 4,    M: 4,    V: 3,    H: null, C: 4,    ...sortGroup(1, 2)},
  [ICONS.DOOR_WIDTH__GTE_75_LT_90_CM]:                         { BA: 4,    G: 4,    M: 4,    V: 3,    H: null, C: 4,    ...sortGroup(1, 3)},
  [ICONS.DESK_CONTRAST_MARKINGS]:                              { BA: null, G: null, M: null, V: 4,    H: null, C: 4    },
  [ICONS.DESK_HEIGHT_ACCESSIBLE]:                              { BA: null, G: null, M: 9,    V: null, H: null, C: null },
  [ICONS.HEARING_LOOP_WITH_SIGN]:                              { BA: 5,    G: 5,    M: null, V: null, H: 1,    C: null },
  [ICONS.LIFT_BUTTONS__WITHOUT_CONTRAST]:                      { BA: null, G: null, M: null, V: 5,    H: null, C: 6,    ...sortGroup(2, 5)},
  [ICONS.LIFT_BUTTONS__WITH_CONTRAST]:                         { BA: null, G: null, M: null, V: 5,    H: null, C: 6,    ...sortGroup(2, 5)},
  [ICONS.LIFT_BUTTONS__WITH_CONTRAST_AND_TACTILE]:             { BA: null, G: null, M: null, V: 5,    H: null, C: 6,    ...sortGroup(2, 5)},
  [ICONS.LIFT_ANNOUNCEMENTS__VISUAL]:                          { BA: null, G: null, M: null, V: 6,    H: null, C: 7,    ...sortGroup(2, 5)},
  [ICONS.LIFT_ANNOUNCEMENTS__VISUAL_AND_SOUND]:                { BA: null, G: null, M: null, V: 6,    H: null, C: 7,    ...sortGroup(2, 5)},
  [ICONS.LIFT_ANNOUNCEMENTS__VISUAL_AND_VOICE]:                { BA: null, G: null, M: null, V: 6,    H: null, C: 7,    ...sortGroup(2, 5)},
  [ICONS.ASSISTANCE_ANIMAL_ALLOWED]:                           { BA: 6,    G: 6,    M: null, V: 1,    H: null, C: 8    },
  [ICONS.SAFETY_ALARM_SOUND]:                                  { BA: null, G: null, M: null, V: null, H: 4,    C: 9    },
  [ICONS.SAFETY_ALARM_VISUAL]:                                 { BA: null, G: null, M: null, V: null, H: 3,    C: 9    },
  [ICONS.SAFETY_ALARM_VIBRATION]:                              { BA: null, G: null, M: null, V: 7,    H: 3,    C: 9    },
  [ICONS.WHEELCHAIR_BORROW]:                                   { BA: 11,   G: 11,   M: 10,   V: null, H: null, C: 10   },
  [ICONS.WHEELCHAIR_HIRE]:                                     { BA: 11,   G: 11,   M: 10,   V: null, H: null, C: 10   },
  [ICONS.ELECTRIC_WHEELCHAIR_HIRE]:                            { BA: null, G: null, M: 11,   V: null, H: null, C: 10   },
  [ICONS.MOBILITY_SCOOTER_HIRE]:                               { BA: 12,   G: 12,   M: 11,   V: null, H: null, C: null },
  [ICONS.BUFFET_WHEELCHAIR_ACCESSIBLE_HEIGHT]:                 { BA: null, G: null, M: 12,   V: null, H: null, C: null },
  [ICONS.TURNING_CIRCLE_150CM]:                                { BA: 8,    G: 8,    M: 6,    V: null, H: null, C: null },
  [ICONS.TOILET_AREA_TYPE__REGULAR]:                           { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 13,   ...filterGroup(1, 1) },
  [ICONS.TOILET_AREA_TYPE__SUPPORT_ONE_SIDE]:                  { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 13,   ...filterGroup(1, 2) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_ONE_SIDE]:               { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 13,   ...filterGroup(1, 3) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_BOTH_SIDES]:             { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 13,   ...filterGroup(1, 4) },
  [ICONS.WC_FOLDING_GRAB_BAR]:                                 { BA: 9,    G: 9,    M: 8,    V: null, H: null, C: 13,   ...filterGroup(1, 5) },
  [ICONS.WC_FOLDING_GRAB_BARS_BOTH_SIDE]:                      { BA: 9,    G: 9,    M: 8,    V: null, H: null, C: 13,   ...filterGroup(1, 6) },
  [ICONS.VERTICAL_ACCESS_TYPE__STAIR_LIFT]:                    { BA: 3,    G: 3,    M: 3,    V: 3,    H: 3,    C: 3,  ...sortGroup(2, 3)},
  [ICONS.STAIRS_STEPS_COUNT__SINGLE_STEPS_WITHOUT_RAMP]:       { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,  },
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_LT_8]:                   { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,  ...filterGroup(3, 2), ...sortGroup(2, 2)},
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_GT_8]:                   { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,  ...filterGroup(3, 2), ...sortGroup(2, 2)},
}

const hotelIcons: PriorityType = {
  [ICONS.ACCESSIBLE_WIDE]:                                     { BA: 2,    G: 2,    M: 2,    V: null, H: null, C: 2    },
  [ICONS.ACCESSIBLE_WHEELCHAIR_SYMBOL]:                        { BA: 1,    G: 1,    M: 1,    V: null, H: null, C: 1    },
  [ICONS.FREE_PARKING_WITH_SPECIAL_PARKING_PERMITS]:           { BA: 1,    G: 1,    M: 1,    V: null, H: null, C: 1    },
  [ICONS.VERTICAL_ACCESS_TYPE__RAMP]:                          { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...filterGroup(3, 1), ...sortGroup(2, 2)},
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_LT_8]:                   { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...filterGroup(3, 2), ...sortGroup(2, 2)},
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_GT_8]:                   { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...filterGroup(3, 2), ...sortGroup(2, 2)},
  [ICONS.VERTICAL_ACCESS_TYPE__LIFT]:                          { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 4)},
  [ICONS.VERTICAL_ACCESS_TYPE__STAIR_LIFT]:                    { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 3)},
  [ICONS.VERTICAL_ACCESS_TYPE__STEP_FREE]:                     { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 1)},
  [ICONS.STAIRS_STEPS_COUNT__LTE_15_STEPS]:                    { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 7)},
  [ICONS.STAIRS_STEPS_COUNT__GT_15_STEPS]:                     { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 8)},
  [ICONS.DOOR_WIDTH__GTE_90_CM]:                               { BA: 4,    G: 4,    M: 4,    V: 3,    H: null, C: 4,    ...sortGroup(1, 1)},
  [ICONS.DOOR_WIDTH__LT_75_CM]:                                { BA: 4,    G: 4,    M: 4,    V: 3,    H: null, C: 4,    ...sortGroup(1, 2)},
  [ICONS.DOOR_WIDTH__GTE_75_LT_90_CM]:                         { BA: 4,    G: 4,    M: 4,    V: 3,    H: null, C: 4,    ...sortGroup(1, 3)},
  [ICONS.DESK_CONTRAST_MARKINGS]:                              { BA: null, G: null, M: null, V: 4,    H: null, C: null },
  [ICONS.DESK_HEIGHT_ACCESSIBLE]:                              { BA: null, G: null, M: 14,   V: null, H: null, C: null },
  [ICONS.HEARING_LOOP_WITH_SIGN]:                              { BA: 5,    G: 5,    M: null, V: null, H: 1,    C: null },
  [ICONS.LIFT_BUTTONS__WITHOUT_CONTRAST]:                      { BA: null, G: null, M: null, V: 5,    H: null, C: 10,   ...sortGroup(2, 5)},
  [ICONS.LIFT_BUTTONS__WITH_CONTRAST]:                         { BA: null, G: null, M: null, V: 5,    H: null, C: 10,   ...sortGroup(2, 5)},
  [ICONS.LIFT_BUTTONS__WITH_CONTRAST_AND_TACTILE]:             { BA: null, G: null, M: null, V: 5,    H: null, C: 10,   ...sortGroup(2, 5)},
  [ICONS.LIFT_ANNOUNCEMENTS__VISUAL]:                          { BA: null, G: null, M: null, V: 6,    H: 3,    C: 11,   ...sortGroup(2, 5)},
  [ICONS.LIFT_ANNOUNCEMENTS__VISUAL_AND_SOUND]:                { BA: null, G: null, M: null, V: 6,    H: 3,    C: 11,   ...sortGroup(2, 5)},
  [ICONS.LIFT_ANNOUNCEMENTS__VISUAL_AND_VOICE]:                { BA: null, G: null, M: null, V: 6,    H: 3,    C: 11,   ...sortGroup(2, 5)},
  [ICONS.ASSISTANCE_ANIMAL_ALLOWED]:                           { BA: 6,    G: 6,    M: null, V: 1,    H: null, C: 12   },
  [ICONS.SAFETY_ALARM_SOUND]:                                  { BA: 13,   G: 13,   M: null, V: 7,    H: 4,    C: 13   },
  [ICONS.SAFETY_ALARM_VISUAL]:                                 { BA: 13,   G: 13,   M: null, V: 7,    H: 4,    C: 13   },
  [ICONS.SAFETY_ALARM_VIBRATION]:                              { BA: 13,   G: 13,   M: null, V: 7,    H: 4,    C: 13   },
  [ICONS.WHEELCHAIR_BORROW]:                                   { BA: 11,   G: 11,   M: 11,   V: null, H: null, C: 14   },
  [ICONS.WHEELCHAIR_HIRE]:                                     { BA: 11,   G: 11,   M: 11,   V: null, H: null, C: 14   },
  [ICONS.ELECTRIC_WHEELCHAIR_HIRE]:                            { BA: null, G: null, M: 15,   V: null, H: null, C: null },
  [ICONS.MOBILITY_SCOOTER_HIRE]:                               { BA: 12,   G: 12,   M: 12,   V: null, H: null, C: 15   },
  [ICONS.BUFFET_WHEELCHAIR_ACCESSIBLE_HEIGHT]:                 { BA: null, G: null, M: 13,   V: null, H: null, C: null },
  [ICONS.TURNING_CIRCLE_150CM]:                                { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 7    },
  [ICONS.TOILET_AREA_TYPE__REGULAR]:                           { BA: 10,   G: 10,   M: 8,    V: null, H: null, C: 8,   ...filterGroup(1,1) },
  [ICONS.TOILET_AREA_TYPE__SUPPORT_ONE_SIDE]:                  { BA: 10,   G: 10,   M: 8,    V: null, H: null, C: 8,   ...filterGroup(1,2) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_ONE_SIDE]:               { BA: 10,   G: 10,   M: 8,    V: null, H: null, C: 8,   ...filterGroup(1,3) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_BOTH_SIDES]:             { BA: 10,   G: 10,   M: 8,    V: null, H: null, C: 8,   ...filterGroup(1,4) },
  [ICONS.WC_FOLDING_GRAB_BAR]:                                 { BA: 10,   G: 10,   M: 8,    V: null, H: null, C: 8,   ...filterGroup(1,5) },
  [ICONS.WC_FOLDING_GRAB_BARS_BOTH_SIDE]:                      { BA: 10,   G: 10,   M: 8,    V: null, H: null, C: 8,   ...filterGroup(1,6) },
  [ICONS.BATH_OR_SHOWER_AVAILABLE__BATH]:                      { BA: 7,    G: 7,    M: 5,    V: null, H: null, C: 5,   ...filterGroup(2,1) },
  [ICONS.HANDLES_AVAILABLE]:                                   { BA: 7,    G: 7,    M: 5,    V: null, H: null, C: 5,   ...filterGroup(2,2) },
  [ICONS.SHOWER_AREA_TYPE__REGULAR]:                           { BA: 7,    G: 7,    M: 5,    V: null, H: null, C: 5    },
  [ICONS.SHOWER_AREA_TYPE__BATHTUB_GRAB_RAILS_WITHOUT]:        { BA: 7,    G: 7,    M: 5,    V: null, H: null, C: 5    },
  [ICONS.SHOWER_AREA_TYPE__BATHTUB_GRAB_RAILS_WITH]:           { BA: 7,    G: 7,    M: 5,    V: null, H: null, C: 5    },
  [ICONS.SHOWER_AREA_TYPE__BATHTUB_GRAB_RAILS_WITH_AND_CHAIR]: { BA: 7,    G: 7,    M: 5,    V: null, H: null, C: 5    },
  [ICONS.SHOWER_AREA_TYPE__SHOWER_WALKIN]:                     { BA: 7,    G: 7,    M: 5,    V: null, H: null, C: 5    },
  [ICONS.SHOWER_AREA_TYPE__SHOWER_ROLLIN]:                     { BA: 7,    G: 7,    M: 5,    V: null, H: null, C: 5    },
  [ICONS.SHOWER_AREA_TYPE__SHOWER_CHAIR_FIXED]:                { BA: 8,    G: 8,    M: 6,    V: null, H: null, C: 6    },
  [ICONS.SHOWER_AREA_TYPE__SHOWER_CHAIR_WHEELS]:               { BA: 8,    G: 8,    M: 6,    V: null, H: null, C: 6    },
  [ICONS.SHOWER_CHAIR_TYPE__SHOWER_CHAIR_PORTABLE]:            { BA: 8,    G: 8,    M: 6,    V: null, H: null, C: 6    },
  [ICONS.BATHTUB_BENCH_AVAILABLE]:                             { BA: 8,    G: 8,    M: 6,    V: null, H: null, C: 6    },
}

const hospitalityRoomIcons: PriorityType = {
  [ICONS.DOOR_WIDTH__LT_75_CM]:                                { BA: 1,    G: 1,    M: 1,    V: 2,    H: null, C: 1,  ...sortGroup(1,2) },
  [ICONS.DOOR_WIDTH__GTE_75_LT_90_CM]:                         { BA: 1,    G: 1,    M: 1,    V: 2,    H: null, C: 1,  ...sortGroup(1, 3)},
  [ICONS.DOOR_WIDTH__GTE_90_CM]:                               { BA: 1,    G: 1,    M: 1,    V: 2,    H: null, C: 1,  ...sortGroup(1, 1)},
  [ICONS.VERTICAL_ACCESS_TYPE__RAMP]:                          { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,  ...filterGroup(3, 1), ...sortGroup(2, 2)},
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_LT_8]:                   { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,  ...filterGroup(3, 2), ...sortGroup(2, 2)},
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_GT_8]:                   { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,  ...filterGroup(3, 2), ...sortGroup(2, 2)},
  [ICONS.VERTICAL_ACCESS_TYPE__STEP_FREE]:                     { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,  ...sortGroup(2, 1)},
  [ICONS.VERTICAL_ACCESS_TYPE__STAIR_LIFT]:                    { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,  ...sortGroup(2, 3)},
  [ICONS.VERTICAL_ACCESS_TYPE__LIFT]:                          { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,  ...sortGroup(2, 4)},
  [ICONS.ASSISTANCE_ANIMAL_ALLOWED]:                           { BA: 7,    G: 7,    M: 7,    V: 1,    H: null, C: 7    },
  [ICONS.SAFETY_ALARM_SOUND]:                                  { BA: 8,    G: 8,    M: 8,    V: 3,    H: 1,    C: 8    },
  [ICONS.SAFETY_ALARM_VISUAL]:                                 { BA: 8,    G: 8,    M: 8,    V: 3,    H: 1,    C: 8    },
  [ICONS.SAFETY_ALARM_VIBRATION]:                              { BA: 8,    G: 8,    M: 8,    V: 3,    H: 1,    C: 8    },
  [ICONS.TURNING_CIRCLE_150CM]:                                { BA: 4,    G: 4,    M: 4,    V: 5,    H: null, C: 4    },
  [ICONS.TOILET_AREA_TYPE__REGULAR]:                           { BA: 5,    G: 5,    M: 5,    V: 6,    H: null, C: 5,    ...filterGroup(1, 1) },
  [ICONS.TOILET_AREA_TYPE__SUPPORT_ONE_SIDE]:                  { BA: 5,    G: 5,    M: 5,    V: 6,    H: null, C: 5,    ...filterGroup(1, 2) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_ONE_SIDE]:               { BA: 5,    G: 5,    M: 5,    V: 6,    H: null, C: 5,    ...filterGroup(1, 3) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_BOTH_SIDES]:             { BA: 5,    G: 5,    M: 5,    V: 6,    H: null, C: 5,    ...filterGroup(1, 4) },
  [ICONS.WC_FOLDING_GRAB_BAR]:                                 { BA: 5,    G: 5,    M: 5,    V: null, H: null, C: 5,    ...filterGroup(1, 5) },
  [ICONS.WC_FOLDING_GRAB_BARS_BOTH_SIDE]:                      { BA: 5,    G: 5,    M: 5,    V: null, H: null, C: 5,    ...filterGroup(1, 6) },
  [ICONS.WC_CONTRAST_MARKING_BEHIND]:                          { BA: null, G: null, M: null, V: 7,    H: null, C: null },
  [ICONS.SHOWER_AREA_TYPE__REGULAR]:                           { BA: 2,    G: 2,    M: 2,    V: 8,    H: null, C: 2    },
  [ICONS.SHOWER_AREA_TYPE__BATHTUB_GRAB_RAILS_WITHOUT]:        { BA: 2,    G: 2,    M: 2,    V: 8,    H: null, C: 2    },
  [ICONS.SHOWER_AREA_TYPE__BATHTUB_GRAB_RAILS_WITH]:           { BA: 2,    G: 2,    M: 2,    V: 8,    H: null, C: 2    },
  [ICONS.SHOWER_AREA_TYPE__BATHTUB_GRAB_RAILS_WITH_AND_CHAIR]: { BA: 2,    G: 2,    M: 2,    V: 8,    H: null, C: 2    },
  [ICONS.SHOWER_AREA_TYPE__SHOWER_WALKIN]:                     { BA: 2,    G: 2,    M: 2,    V: 8,    H: null, C: 2    },
  [ICONS.SHOWER_AREA_TYPE__SHOWER_ROLLIN]:                     { BA: 2,    G: 2,    M: 2,    V: 8,    H: null, C: 2    },
  [ICONS.SHOWER_AREA_TYPE__SHOWER_CHAIR_FIXED]:                { BA: 3,    G: 3,    M: 3,    V: null, H: null, C: 3    },
  [ICONS.SHOWER_AREA_TYPE__SHOWER_CHAIR_WHEELS]:               { BA: 3,    G: 3,    M: 3,    V: null, H: null, C: 3    },
  [ICONS.SHOWER_CURTAIN_OR_GLASS_WALL]:                        { BA: 9,    G: 9,    M: 9,    V: 9,    H: null, C: 9    },
  [ICONS.BED_HEIGHT]:                                          { BA: 10,   G: 10,   M: 10,   V: 10,   H: null, C: 10   },
  [ICONS.BED_SPACE_UNDER]:                                     { BA: 11,   G: 11,   M: 11,   V: null, H: null, C: 11   },
  [ICONS.BED_SPACE_TO_WALL]:                                   { BA: 12,   G: 12,   M: 12,   V: 11,   H: null, C: 12   },
  [ICONS.BATH_OR_SHOWER_AVAILABLE__BATH]:                      { BA: 2,    G: 2,    M: 2,    V: 8,    H: null, C: 2,   ...filterGroup(2, 1) },
  [ICONS.HANDLES_AVAILABLE]:                                   { BA: 2,    G: 2,    M: 2,    V: 8,    H: null, C: 5,   ...filterGroup(2, 2) },
  [ICONS.SHOWER_CHAIR_TYPE__SHOWER_CHAIR_PORTABLE]:            { BA: 3,    G: 3,    M: 3,    V: null, H: null, C: 3    },
  [ICONS.BATHTUB_BENCH_AVAILABLE]:                             { BA: 3,    G: 3,    M: 3,    V: null, H: null, C: 3    },
  [ICONS.STAIRS_STEPS_COUNT__LTE_15_STEPS]:                    { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,  ...sortGroup(2, 7) },
  [ICONS.STAIRS_STEPS_COUNT__GT_15_STEPS]:                     { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,  ...sortGroup(2, 8) },
}

const meetingRoomIcons: PriorityType = {
  [ICONS.VERTICAL_ACCESS_TYPE__RAMP]:                          { BA: 1,    G: 1,    M: 1,    V: 1,    H: null, C: 1, ...filterGroup(3, 1), ...sortGroup(2, 2) },
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_LT_8]:                   { BA: 1,    G: 1,    M: 1,    V: 1,    H: null, C: 1, ...filterGroup(3, 2), ...sortGroup(2, 2) },
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_GT_8]:                   { BA: 1,    G: 1,    M: 1,    V: 1,    H: null, C: 1, ...filterGroup(3, 2), ...sortGroup(2, 2) },
  [ICONS.VERTICAL_ACCESS_TYPE__LIFT]:                          { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3, ...sortGroup(2, 4)},
  [ICONS.VERTICAL_ACCESS_TYPE__STAIR_LIFT]:                    { BA: 1,    G: 1,    M: 1,    V: 1,    H: null, C: 1, ...sortGroup(2, 3) },
  [ICONS.VERTICAL_ACCESS_TYPE__STEP_FREE]:                     { BA: 1,    G: 1,    M: 1,    V: 1,    H: null, C: 1, ...sortGroup(2, 1) },
  [ICONS.DOOR_WIDTH__LT_75_CM]:                                { BA: 2,    G: 2,    M: 2,    V: 2,    H: null, C: 2, ...sortGroup(1, 2) },
  [ICONS.DOOR_WIDTH__GTE_75_LT_90_CM]:                         { BA: 2,    G: 2,    M: 2,    V: 2,    H: null, C: 2, ...sortGroup(1, 3) },
  [ICONS.DOOR_WIDTH__GTE_90_CM]:                               { BA: 2,    G: 2,    M: 2,    V: 2,    H: null, C: 2, ...sortGroup(1, 1) },
  [ICONS.HEARING_LOOP_WITH_SIGN]:                              { BA: 4,    G: 4,    M: null, V: null, H: 1,    C: 4    },
  [ICONS.ASSISTANCE_ANIMAL_ALLOWED]:                           { BA: 5,    G: 5,    M: null, V: null, H: null, C: 5    },
  [ICONS.SAFETY_ALARM_SOUND]:                                  { BA: 3,    G: 3,    M: 3,    V: 3,    H: 2,    C: 3    },
  [ICONS.SAFETY_ALARM_VISUAL]:                                 { BA: 3,    G: 3,    M: 3,    V: 3   , H: 2,    C: 3    },
  [ICONS.SAFETY_ALARM_VIBRATION]:                              { BA: 3,    G: 3,    M: 3,    V: 3   , H: 2,    C: 3    },
  [ICONS.STAIRS_STEPS_COUNT__LTE_15_STEPS]:                    { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,  ...sortGroup(2, 7) },
  [ICONS.STAIRS_STEPS_COUNT__GT_15_STEPS]:                     { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,  ...sortGroup(2, 8) },
  [ICONS.STAIRS_STEPS_COUNT__SINGLE_STEPS_WITHOUT_RAMP]:       { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,   },
  [ICONS.TOILET_AREA_TYPE__REGULAR]:                           { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 1) },
  [ICONS.TOILET_AREA_TYPE__SUPPORT_ONE_SIDE]:                  { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 2) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_ONE_SIDE]:               { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 3) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_BOTH_SIDES]:             { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 4) },
  [ICONS.WC_FOLDING_GRAB_BAR]:                                 { BA: 9,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 5) },
  [ICONS.WC_FOLDING_GRAB_BARS_BOTH_SIDE]:                      { BA: 9,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 6) },
}

const outdoorPlaygroundIcons: PriorityType = {
  [ICONS.ACCESSIBLE_WIDE]:                                                  { BA: 2,    G: 2,    M: 2,    V: null, H: null, C: 2    },
  [ICONS.ACCESSIBLE_WHEELCHAIR_SYMBOL]:                                     { BA: 1,    G: 1,    M: 1,    V: null, H: null, C: 1    },
  [ICONS.FREE_PARKING_WITH_SPECIAL_PARKING_PERMITS]:                        { BA: null, G: 1,    M: 1,    V: null, H: null, C: 1    },
  [ICONS.ASSISTANCE_ANIMAL_ALLOWED]:                                        { BA: 3,    G: 3,    M: null, V: 1,    H: null, C: null },
  [ICONS.TURNING_CIRCLE_150CM]:                                             { BA: null, G: 13,   M: 3,    V: null, H: null, C: 3    },
  [ICONS.TOILET_AREA_TYPE__REGULAR]:                                        { BA: 14,   G: 14,   M: 4,    V: null, H: null, C: 4,    ...filterGroup(1, 1) },
  [ICONS.TOILET_AREA_TYPE__SUPPORT_ONE_SIDE]:                               { BA: 14,   G: 14,   M: 4,    V: null, H: null, C: 4,    ...filterGroup(1, 2) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_ONE_SIDE]:                            { BA: 14,   G: 14,   M: 4,    V: null, H: null, C: 4,    ...filterGroup(1, 3) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_BOTH_SIDES]:                          { BA: 14,   G: 14,   M: 4,    V: null, H: null, C: 4,    ...filterGroup(1, 4) },
  [ICONS.WC_FOLDING_GRAB_BAR]:                                              { BA: 14,   G: 14,   M: 4,    V: null, H: null, C: 4,    ...filterGroup(1, 5) },
  [ICONS.WC_FOLDING_GRAB_BARS_BOTH_SIDE]:                                   { BA: 14,   G: 14,   M: 4,    V: null, H: null, C: 4,    ...filterGroup(1, 6) },
  [ICONS.ALARM_BUTTON_OR_CORD]:                                             { BA: 16,   G: 13,   M: null, V: 7,    H: 4,    C: 13   },
  [ICONS.SURFACE_TYPE__ASPHALT]:                                            { BA: null, G: 4,    M: 6,    V: 2,    H: null, C: 6    },
  [ICONS.SURFACE_TYPE__LOOSE_GRAVEL]:                                       { BA: null, G: 4,    M: 6,    V: 2,    H: null, C: 6    },
  [ICONS.SURFACE_TYPE__GRASS]:                                              { BA: null, G: 4,    M: 6,    V: 2,    H: null, C: 6    },
  [ICONS.SURFACE_TYPE__STONE_FLOUR]:                                        { BA: null, G: 4,    M: 6,    V: 2,    H: null, C: 6    },
  [ICONS.SURFACE_TYPE__ARTIFICIAL_GRASS]:                                   { BA: null, G: 4,    M: 6,    V: 2,    H: null, C: 6    },
  [ICONS.SIGN_WITH_INFORMATION_ABOUT_PLAYGROUND]:                           { BA: 4,    G: 5,    M: 7,    V: null, H: 1,    C: 7    },
  [ICONS.GUIDANCE_PATHS_NATURAL_OR_TACTILE_INSIDE_THE_PLAYGROUND]:          { BA: 5,    G: 6,    M: null, V: 3,    H: null, C: null },
  [ICONS.CONTRAST_MARKINGS_LEVEL_DIFFERENCES]:                              { BA: 6,    G: 7,    M: null, V: 4,    H: null, C: null },
  [ICONS.SEATS_WITH_ARMRESTS_BACKRESTS_INSIDE_PLAYGROUND]:                  { BA: 7,    G: 8,    M: 8,    V: null, H: 2,    C: 8    },
  [ICONS.FREE_AREA_NEXT_TO_SEATS_FOR_WHEELCHAIR_PERMOBILE_STROLLER]:        { BA: null, G: null, M: null, V: 5,    H: null, C: null },
  [ICONS.PLAYGROUND_HAVE_FIXED_PROTECTION_FENCE_PLANK]:                     { BA: 8,    G: 9,    M: null, V: 6,    H: 3,    C: 9    },
  [ICONS.PLAYGROUND_HAVE_SCREENING_GREENERY_BUSHES_HILLS_OTHER_VEGETATION]: { BA: 8,    G: 9,    M: null, V: 7,    H: 3,    C: 9    },
  [ICONS.IS_THERE_SWING_WITH_SEAT_SUPPORT]:                                 { BA: 9,    G: 11,   M: 10,   V: 7,    H: 4,    C: 10   },
  [ICONS.IS_THERE_SWING_WITHOUT_SEAT_SUPPORT]:                              { BA: null, G: null, M: null, V: null, H: 5,    C: null },
  [ICONS.IS_THERE_BABY_SWING]:                                              { BA: 10,   G: 14,   M: 12,   V: 8,    H: 6,    C: 11   },
  [ICONS.IS_THERE_NEST_SWING]:                                              { BA: 11,   G: 15,   M: 13,   V: 9,    H: 7,    C: 12   },
  [ICONS.IS_THERE_A_SLOPE_SLIDE]:                                           { BA: null, G: null, M: null, V: 11,   H: 10,   C: 13   },
}

const indoorPlaygroundIcons: PriorityType = {
  [ICONS.ACCESSIBLE_WIDE]:                                      { BA: 2,    G: 2,    M: 2,    V: null, H: null, C: 2    },
  [ICONS.ACCESSIBLE_WHEELCHAIR_SYMBOL]:                         { BA: 1,    G: 1,    M: 1,    V: null, H: null, C: 1    },
  [ICONS.FREE_PARKING_WITH_SPECIAL_PARKING_PERMITS]:            { BA: 1,    G: 1,    M: 1,    V: null, H: null, C: 1    },
  [ICONS.VERTICAL_ACCESS_TYPE__RAMP]:                           { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 2)},
  [ICONS.VERTICAL_ACCESS_TYPE__STEP_FREE]:                      { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 1)},
  [ICONS.VERTICAL_ACCESS_TYPE__STAIR_LIFT]:                     { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 3)},
  [ICONS.VERTICAL_ACCESS_TYPE__LIFT]:                           { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 4)},
  [ICONS.STAIRS_STEPS_COUNT__LTE_15_STEPS]:                     { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 7)},
  [ICONS.STAIRS_STEPS_COUNT__GT_15_STEPS]:                      { BA: 3,    G: 3,    M: 3,    V: 2,    H: null, C: 3,    ...sortGroup(2, 8)},
  [ICONS.DOOR_WIDTH__LT_75_CM]:                                 { BA: 4,    G: 4,    M: 4,    V: 3,    H: null, C: 4,    ...sortGroup(1, 2)},
  [ICONS.DOOR_WIDTH__GTE_75_LT_90_CM]:                          { BA: 4,    G: 4,    M: 4,    V: 3,    H: null, C: 4,    ...sortGroup(1, 3)},
  [ICONS.DOOR_WIDTH__GTE_90_CM]:                                { BA: 4,    G: 4,    M: 4,    V: 3,    H: null, C: 4,    ...sortGroup(1, 1)},
  [ICONS.ASSISTANCE_ANIMAL_ALLOWED]:                            { BA: 5,    G: 5,    M: null, V: 1,    H: null, C: null },
  [ICONS.HEARING_LOOP_WITH_SIGN]:                               { BA: 6,    G: 6,    M: null, V: null, H: 1,    C: null },
  [ICONS.DESK_CONTRAST_MARKINGS]:                               { BA: null, G: null, M: null, V: 5,    H: null, C: 5    },
  [ICONS.DESK_HEIGHT_ACCESSIBLE]:                               { BA: 7,    G: 7,    M: 5,    V: null, H: null, C: 6    },
  [ICONS.TURNING_CIRCLE_150CM]:                                 { BA: 8,    G: 8,    M: 6,    V: null, H: null, C: 7    },
  [ICONS.ALARM_BUTTON_OR_CORD]:                                 { BA: null, G: null, M: null, V: null, H: 2,    C: null },
  [ICONS.WC_CONTRAST_MARKING_BEHIND]:                           { BA: null, G: null, M: null, V: null, H: null, C: null },
  [ICONS.TOILET_AREA_TYPE__REGULAR]:                            { BA: 14,   G: 14,   M: 4,    V: null, H: null, C: 4,    ...filterGroup(1, 1) },
  [ICONS.TOILET_AREA_TYPE__SUPPORT_ONE_SIDE]:                   { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 8,    ...filterGroup(1, 2) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_ONE_SIDE]:                { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 8,    ...filterGroup(1, 3) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_BOTH_SIDES]:              { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 8,    ...filterGroup(1, 4) },
  [ICONS.WC_FOLDING_GRAB_BAR]:                                  { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 8,    ...filterGroup(1, 5) },
  [ICONS.WC_FOLDING_GRAB_BARS_BOTH_SIDE]:                       { BA: 9,    G: 9,    M: 7,    V: null, H: null, C: 8,    ...filterGroup(1, 6) },
  [ICONS.TOILET_WITH_CEILING_HOIST]:                            { BA: 10,   G: 10,   M: 8,    V: null, H: null, C: 9    },
  [ICONS.SAFETY_ALARM_SOUND]:                                   { BA: null, G: null, M: null, V: 4,    H: null, C: 10   },
  [ICONS.SAFETY_ALARM_VISUAL]:                                  { BA: null, G: null, M: null, V: null, H: 3,    C: 10   },
  [ICONS.SAFETY_ALARM_VIBRATION]:                               { BA: null, G: null, M: null, V: 4,    H: 3,    C: 10   },
  [ICONS.WHEELCHAIR_BORROW]:                                    { BA: 11,   G: 11,   M: 9,    V: null, H: null, C: 11   },
  [ICONS.WHEELCHAIR_HIRE]:                                      { BA: 11,   G: 11,   M: 9,    V: null, H: null, C: 11   },
  [ICONS.ELECTRIC_WHEELCHAIR_HIRE]:                             { BA: null, G: null, M: null, V: null, H: null, C: null },
  [ICONS.MOBILITY_SCOOTER_HIRE]:                                { BA: 12,   G: 12,   M: 10,   V: null, H: null, C: 12   },
  [ICONS.BUFFET_WHEELCHAIR_ACCESSIBLE_HEIGHT]:                  { BA: null, G: null, M: null, V: null, H: null, C: null },
}

const shopIcons: PriorityType = {
  [ICONS.VERTICAL_ACCESS_TYPE__STAIR_LIFT]:  { BA: 2,    G: 2,    M: 2,    V: 2,    H: 2,    C: 2,  ...sortGroup(2, 3)},
  [ICONS.VERTICAL_ACCESS_TYPE__STEP_FREE]:   { BA: 2,    G: 2,    M: 2,    V: 2,    H: 2,    C: 2,  ...sortGroup(2, 1) },
  [ICONS.VERTICAL_ACCESS_TYPE__RAMP]:        { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...filterGroup(3, 1), ...sortGroup(2, 2)},
  [ICONS.VERTICAL_ACCESS_TYPE__LIFT]:        { BA: 3,    G: 3,    M: 3,    V: 2,    H: 1,    C: 3,  ...sortGroup(2, 4)},
  [ICONS.WHEELCHAIR_REACHABLE]:              { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1 },
  [ICONS.STAIRS_STEPS_COUNT__LTE_15_STEPS]:  { BA: 2,    G: 2,    M: 2,    V: 2,    H: 2,    C: 2,  ...sortGroup(2, 7) },
  [ICONS.STAIRS_STEPS_COUNT__GT_15_STEPS]:   { BA: 2,    G: 2,    M: 2,    V: 2,    H: 2,    C: 2,  ...sortGroup(2, 8) },
  [ICONS.ASSISTANCE_ANIMAL_ALLOWED]:         { BA: 3,    G: 3,    M: 3,    V: 3,    H: 3,    C: 3 },
  [ICONS.AISLE_WIDTH__GTE_90_CM]:            { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4 },
  [ICONS.AISLE_COLOUR_CONTRAST]:             { BA: 5,    G: 5,    M: 5,    V: 5,    H: 5,    C: 5 },
  [ICONS.DESK_CONTRAST_MARKINGS]:            { BA: 6,    G: 6,    M: 6,    V: 6,    H: 6,    C: 6 },
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_LT_8]: { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...filterGroup(3, 2), ...sortGroup(2, 2)},
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_GT_8]: { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...filterGroup(3, 2), ...sortGroup(2, 2)},
}

const exhibitionIcons: PriorityType = {
  [ICONS.VERTICAL_ACCESS_TYPE__LIFT]:                     { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 3,  ...sortGroup(2, 4)},
  [ICONS.VERTICAL_ACCESS_TYPE__STAIR_LIFT]:               { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...sortGroup(2, 3)},
  [ICONS.VERTICAL_ACCESS_TYPE__RAMP]:                     { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...filterGroup(3, 1), ...sortGroup(2, 2)},
  [ICONS.VERTICAL_ACCESS_TYPE__STEP_FREE]:                { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...sortGroup(2, 1) },
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_LT_8]:              { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...filterGroup(3, 2), ...sortGroup(2, 2)},
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_GT_8]:              { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...filterGroup(3, 2), ...sortGroup(2, 2)},
  [ICONS.STAIRS_STEPS_COUNT__LTE_15_STEPS]:               { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...sortGroup(2, 7) },
  [ICONS.STAIRS_STEPS_COUNT__GT_15_STEPS]:                { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...sortGroup(2, 8) },
  [ICONS.DOOR_WIDTH__LT_75_CM]:                           { BA: 2,    G: 2,    M: 2,    V: 2,    H: 2,    C: 2,  ...sortGroup(1, 2) },
  [ICONS.DOOR_WIDTH__GTE_75_LT_90_CM]:                    { BA: 2,    G: 2,    M: 2,    V: 2,    H: 2,    C: 2,  ...sortGroup(1, 3) },
  [ICONS.DOOR_WIDTH__GTE_90_CM]:                          { BA: 2,    G: 2,    M: 2,    V: 2,    H: 2,    C: 2,  ...sortGroup(1, 1) },
  [ICONS.HEARING_LOOP_WITH_SIGN]:                         { BA: 7,    G: 7,    M: 7,    V: 7,    H: 7,    C: 7    },
  [ICONS.ASSISTANCE_ANIMAL_ALLOWED]:                      { BA: 5,    G: 5,    M: 5,    V: 5,    H: 5,    C: 5    },
  [ICONS.SAFETY_ALARM_SOUND]:                             { BA: 6,    G: 6,    M: 6,    V: 6,    H: 6,    C: 6    },
  [ICONS.SAFETY_ALARM_VISUAL]:                            { BA: 6,    G: 6,    M: 6,    V: 6,    H: 6,    C: 6    },
  [ICONS.SAFETY_ALARM_VIBRATION]:                         { BA: 6,    G: 6,    M: 6,    V: 6,    H: 6,    C: 6    },
  [ICONS.POSSIBLE_TO_GET_AUDIO_GUIDANCE]:                 { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,   },
  [ICONS.STAIRS_STEPS_COUNT__SINGLE_STEPS_WITHOUT_RAMP]:  { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,   },
}

const museumShopIcons: PriorityType = {
  [ICONS.VERTICAL_ACCESS_TYPE__LIFT]:                     { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...sortGroup(2, 4)},
  [ICONS.VERTICAL_ACCESS_TYPE__STAIR_LIFT]:               { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...sortGroup(2, 3)},
  [ICONS.VERTICAL_ACCESS_TYPE__RAMP]:                     { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...filterGroup(3, 1), ...sortGroup(2, 2) },
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_LT_8]:              { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...filterGroup(3, 2), ...sortGroup(2, 2) },
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_GT_8]:              { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...filterGroup(3, 2), ...sortGroup(2, 2) },
  [ICONS.VERTICAL_ACCESS_TYPE__STEP_FREE]:                { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...sortGroup(2, 1) },
  [ICONS.STAIRS_STEPS_COUNT__LTE_15_STEPS]:               { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...sortGroup(2, 7) },
  [ICONS.STAIRS_STEPS_COUNT__GT_15_STEPS]:                { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...sortGroup(2, 8) },
  [ICONS.DOOR_WIDTH__LT_75_CM]:                           { BA: 2,    G: 2,    M: 2,    V: 2,    H: 2,    C: 2,  ...sortGroup(1, 2) },
  [ICONS.DOOR_WIDTH__GTE_75_LT_90_CM]:                    { BA: 2,    G: 2,    M: 2,    V: 2,    H: 2,    C: 2,  ...sortGroup(1, 3) },
  [ICONS.DOOR_WIDTH__GTE_90_CM]:                          { BA: 2,    G: 2,    M: 2,    V: 2,    H: 2,    C: 2,  ...sortGroup(1, 1) },
  [ICONS.HEARING_LOOP_WITH_SIGN]:                         { BA: 8,    G: 8,    M: 8,    V: 8,    H: 8,    C: 8    },
  [ICONS.ASSISTANCE_ANIMAL_ALLOWED]:                      { BA: 5,    G: 5,    M: 5,    V: 5,    H: 5,    C: 5    },
  [ICONS.SAFETY_ALARM_SOUND]:                             { BA: 6,    G: 6,    M: 6,    V: 6,    H: 6,    C: 6    },
  [ICONS.SAFETY_ALARM_VISUAL]:                            { BA: 6,    G: 6,    M: 6,    V: 6,    H: 6,    C: 6    },
  [ICONS.SAFETY_ALARM_VIBRATION]:                         { BA: 6,    G: 6,    M: 6,    V: 6,    H: 6,    C: 6    },
  [ICONS.STAIRS_STEPS_COUNT__SINGLE_STEPS_WITHOUT_RAMP]:  { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,   },
  [ICONS.TOILET_AREA_TYPE__REGULAR]:                      { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 1) },
  [ICONS.TOILET_AREA_TYPE__SUPPORT_ONE_SIDE]:             { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 2) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_ONE_SIDE]:          { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 3) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_BOTH_SIDES]:        { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 4) },
  [ICONS.WC_FOLDING_GRAB_BAR]:                            { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 5) },
  [ICONS.WC_FOLDING_GRAB_BARS_BOTH_SIDE]:                 { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 6) },
}

const toiletCommonIcons: PriorityType = {
  [ICONS.VERTICAL_ACCESS_TYPE__LIFT]:                     { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,    ...sortGroup(2, 4)},
  [ICONS.VERTICAL_ACCESS_TYPE__STAIR_LIFT]:               { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...sortGroup(2, 3)},
  [ICONS.VERTICAL_ACCESS_TYPE__RAMP]:                     { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...filterGroup(3, 1), ...sortGroup(2, 2) },
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_LT_8]:              { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...filterGroup(3, 2), ...sortGroup(2, 2) },
  [ICONS.RAMP_GRADIENT__RAMP_GRADIENT_GT_8]:              { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...filterGroup(3, 2), ...sortGroup(2, 2) },
  [ICONS.VERTICAL_ACCESS_TYPE__STEP_FREE]:                { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...sortGroup(2, 1) },
  [ICONS.STAIRS_STEPS_COUNT__LTE_15_STEPS]:               { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...sortGroup(2, 7) },
  [ICONS.STAIRS_STEPS_COUNT__GT_15_STEPS]:                { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,  ...sortGroup(2, 8) },
  [ICONS.DOOR_WIDTH__LT_75_CM]:                           { BA: 2,    G: 2,    M: 2,    V: 2,    H: 2,    C: 2,  ...sortGroup(1, 2) },
  [ICONS.DOOR_WIDTH__GTE_75_LT_90_CM]:                    { BA: 2,    G: 2,    M: 2,    V: 2,    H: 2,    C: 2,  ...sortGroup(1, 3) },
  [ICONS.DOOR_WIDTH__GTE_90_CM]:                          { BA: 2,    G: 2,    M: 2,    V: 2,    H: 2,    C: 2,  ...sortGroup(1, 1) },
  [ICONS.TOILET_AREA_TYPE__REGULAR]:                      { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 1) },
  [ICONS.TOILET_AREA_TYPE__SUPPORT_ONE_SIDE]:             { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 2) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_ONE_SIDE]:          { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 3) },
  [ICONS.TOILET_AREA_TYPE__GRAB_RAILS_BOTH_SIDES]:        { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 4,  ...filterGroup(1, 4) },
  [ICONS.WC_FOLDING_GRAB_BAR]:                            { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 8,  ...filterGroup(1, 5) },
  [ICONS.WC_FOLDING_GRAB_BARS_BOTH_SIDE]:                 { BA: 4,    G: 4,    M: 4,    V: 4,    H: 4,    C: 8,  ...filterGroup(1, 6) },
  [ICONS.STAIRS_STEPS_COUNT__SINGLE_STEPS_WITHOUT_RAMP]:  { BA: 1,    G: 1,    M: 1,    V: 1,    H: 1,    C: 1,   },
  [ICONS.TURNING_CIRCLE_150CM]:                           { BA: 5,    G: 5,    M: 5,    V: 5,    H: 5,    C: 5,   },
}

export const iconsPriority: {[key: string]: PriorityType} = {
  [a11yStandardKeys.shoppingMall]: shoppingMallIcons,
  [a11yStandardKeys.museum]: museumIcons,
  [a11yStandardKeys.hotel]: hotelIcons,
  [a11yStandardKeys.shop]: shopIcons,
  [a11yStandardKeys.office]: shoppingMallIcons,
  [a11yStandardKeys.outdoorPlayground]: outdoorPlaygroundIcons,
  [a11yStandardKeys.indoorPlayground]: indoorPlaygroundIcons,
  [unitTypeKeys.hotelRoomType]: hospitalityRoomIcons,
  [unitTypeKeys.meetingRoom]: meetingRoomIcons,
  [unitTypeKeys.exhibition]: exhibitionIcons,
  [unitTypeKeys.museumShop]: museumShopIcons,
  [unitTypeKeys.toiletRoomCommon]: toiletCommonIcons,
}
