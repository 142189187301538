import {PropertyPageTable} from "../../../StyledWrappers/Wrappers";
import {HotelInfoContainer} from "../../../components/HotelInfoContainer";
import React, {FC} from "react";
import {a11yStandardKeys} from "../../../constants/constants";
import {useTranslation} from "react-i18next";
import {A11yStandardType} from "../../../Models/utils";
import SkeletonList from "../../../components/SkeletonList";

type PropsType = {
  a11yStandard: A11yStandardType
  accessibilityTable: JSX.Element[] | undefined
  locatedOn: {
    type?: string
    triggeredOnPage: string,
    section: string
  }
}

const GeneralRoomInformationContainer: FC<PropsType> = ({
  a11yStandard,
  accessibilityTable,
  locatedOn
}) => {
  const {t} = useTranslation()

  const getContainerLabel = (standardKey: string) => {
    switch (standardKey) {
      case a11yStandardKeys.hotel:
        return `${t("mainContainer_GeneralHotelInformation")}`;
      case a11yStandardKeys.shoppingMall:
        return `${t("mainContainer_GeneralMallInformation")}`;
      case a11yStandardKeys.office:
        return `${t("mainContainer_GeneralOfficeInformation")}`;
      case a11yStandardKeys.shop:
        return `${t("mainContainer_GeneralShopInformation")}`;
      default:
        return "";
    }
  }

  return (
    // TODO: change names on All HotelInfoContainer because they ddn't have anything to do with hotel
    <HotelInfoContainer
      id="detailed-information"
      label={getContainerLabel(a11yStandard.key)}
      labelPosition="sticky"
      showMapToggle={true}
      padding={"0px"}
      locatedOn={locatedOn}
    >
      {accessibilityTable && accessibilityTable.length > 0 ? (
        <>{accessibilityTable}</>
      ) : (
        <SkeletonList />
      )}
    </HotelInfoContainer>
  )
}

export default GeneralRoomInformationContainer
