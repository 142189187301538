import VisualIcon from "../images/icons/visual-icon.svg";
import MobilityIcon from "../images/icons/mobility-icon.svg";
import HearingIcon from "../images/icons/hearing-icon.svg";
import AllergyIcon from "../images/icons/allergy-icon.svg";
import CognitiveIcon from "../images/icons/cognitive-icon.svg";
import OtherIcon from "../images/icons/other-icon.svg";

export const borderRadius = 0;
export const drawerWidth = 80;

export const prioritisedIconsRoomAttributes = [
  "door_markings",
  "ramp_markings",
  "door_electric_opening_arc_markings",
  "door_glass_surfaces_markings",
  "door_automatic_push_plate",
  "lift_buttons",
  "lift_announcements",
  "corridors_colour_contrast",
  "stairs_contrast_markings_steps",
  "stairs_contrast_markings_handrails",
  "stairs_non_slip",
  "assistance_animal_allowed",
  "facility_online_info",
  "facility_online_info_visual",
  "safety_alarm_sound",
  "safety_alarm_visual",
  "safety_alarm_vibration",
  "food_menu_allergies",
  "allergy_fragrant_plants_nearby_none",
  "allergy_tobacco",
  "allergy_no_fur_floor",
  "floors_wooden",
  "pillows_and_quilts_synthetic",
  "rugs_removed",
  "mattress_synthetic",
  "guest_room_no_decorative_fabric",
];

export const prioritisedIconsShopAttributes = [
  "wheelchair_reachable",
  "stairs_steps_count__step_free",
  "assistance_animal_allowed",
  "aisle_width__gte_90_cm",
  "aisle_colour_contrast",
  "desk_contrast_markings",
  // added new
  'accessible_wide',
  'accessible_wheelchair_symbol',
  'stairs_steps_count__ramp',
  'door_width__lt_75_cm',
  'door_width__gte_75_lt_90_cm',
  'door_width__gte_90_cm',
  'desk_contrast_markings',
  'desk_height_accessible',
  'hearing_loop_with_sign',
  'lift_buttons__without_contrast',
  'lift_buttons__with_contrast',
  'lift_buttons__with_constrast_and_tactile',
  'lift_announcements__visual',
  'lift_announcements__visual_and_sound',
  'lift_announcements__visual_and_voice',
  'safety_alarm_sound',
  'safety_alarm_visual',
  'safety_alarm_vibration',
  'wheelchair_borrow',
  'wheelchair_hire',
  'electric_wheelchair_hire',
  'mobility_scooter_hire',
  'buffet_wheelchair_accessible_height',
  'turning_circle_150cm',
  'toilet_area_type__grab_rails_one_side',
  'toilet_area_type__grab_rails_both_sides',
];

export const disability_types_map = {
  allergy_food_class: "AF",
  allergy_room_class: "AR",
  mobility_class: "M",
  hearing_class: "H",
  cognitive_class: "C",
  visual_class: "V",
} as const;

export type DisabilityTypesMap = typeof disability_types_map;

export type LevelSizeMap = {
  amount_of_floor_levels: number;
  property_rooms: number;
  disabled_rooms: number;
  allergy_rooms: number;
};

export enum a11yStandardKeys {
  shoppingMall = 'shopping_mall',
  hotel = 'hotel',
  shop = 'shop',
  office = 'office',
  indoorPlayground = 'indoor_playground',
  outdoorPlayground = 'outdoor_playground',
  museum = 'museum',
  arena = 'arena'
}

export enum A11Y_EXCLUDE_INFO_BLOCK {
  shop = a11yStandardKeys.shop,
  // office = a11yStandardKeys.office,
  indoorPlayground = a11yStandardKeys.indoorPlayground,
  // outdoorPlayground = a11yStandardKeys.outdoorPlayground,
  // museum = a11yStandardKeys.museum
}

export enum unitTypeKeys {
  hotelRoomType = 'hotel_room_type',
  meetingRoom = 'meeting_room',
  exhibition = 'exhibition',
  museumShop = 'museum_shop',
  toiletRoomCommon = 'toilet_room__common',
}

export enum facilityType {
  buildingArea = 'buildingArea',
  property = 'property',
}

export enum LanguagesType {
  English = 'en',
  Swedish = 'sv',
  Danish = 'da',
  German = 'de',
  Spanish = 'es',
  French = 'fr',
  Norwegian = 'no',
  Portuguese = 'pt',
  Ukrainian = 'uk'
}

export const LanguagesISO = {
  [LanguagesType.English]: LanguagesType.English,
  [LanguagesType.Swedish]: LanguagesType.Swedish,
  [LanguagesType.Danish]: LanguagesType.Danish,
  [LanguagesType.German]: LanguagesType.German,
  [LanguagesType.Spanish]: LanguagesType.Spanish,
  [LanguagesType.French]: LanguagesType.French,
  [LanguagesType.Norwegian]: LanguagesType.Norwegian,
  [LanguagesType.Portuguese]: LanguagesType.Portuguese,
  [LanguagesType.Ukrainian]: LanguagesType.Ukrainian
}

export const PATH_NAMES = {
  BUILDING_AREA_DEMO: "demo/buildingarea/",
  PROPERTY_DEMO: "demo/property/",
  BUILDING_AREA_PAGE: "page/buildingarea/",
  PROPERTY_PAGE: "page/property/"
}

export enum domains {
  live = 'https://ha11y-api.handiscover.com',
  dev = 'https://ams-api-dev.handiscover.com'
}


// export const levelSizeMap = {
//   amount_of_floor_levels: `${t("hotel_floors")}`,
//   property_rooms: `${t("hotel_totalRooms")}`,
//   disabled_rooms: `${t("hotel_accessibleRooms")}`,
//   allergy_rooms: `${t("hotel_allergyRooms")}`,
// };

export enum METRIC_TRIGGERED_ON {
  // pages
  PROPERTY_PAGE_BUILDING_INFO = 'property_page_building_info',
  PROPERTY_PAGE_UNIT_LIST = "property_page_unit_list",
  PROPERTY_PAGE_SINGLE_UNIT = "property_page_single_unit",
  PROPERTY_PAGE_SINGLE_PROPERTY = "property_page_single_property",
  PROPERTY_PAGE_MAP_VIEW = 'property_page_map_view',
  // section
  GENERAL_INFO_TABLE = 'general_info_table',
}

export const DisabilityTypes: {[key: string]: string} = {
  M: 'Mobility',
  V: 'Visual',
  C: 'Cognitive',
  H: 'Hearing',
  AR: 'Allergy'
}


export type IconsType = {
  type: string,
  name: string,
  disabilityType: string,
  translateKey: string,
  icon: string,
}

export const IconsAccessibility: IconsType[] = [
  {
    type: "visual",
    name: "Visual",
    disabilityType: 'V',
    translateKey: 'selectDisabilityType_visual',
    icon: VisualIcon
  },
  {
    type: "mobility",
    name: "Mobility",
    translateKey: 'selectDisabilityType_mobility',
    disabilityType: 'M',
    icon: MobilityIcon
  },
  {
    type: "hearing",
    name: "Hearing",
    disabilityType: 'H',
    translateKey: 'selectDisabilityType_hearing',
    icon: HearingIcon
  },
  {
    type: "allergy_room",
    name: "Allergy",
    translateKey: 'selectDisabilityType_allergy',
    disabilityType: 'AR',
    icon: AllergyIcon
  },
  {
    type: "allergy_food",
    name: "Allergy",
    translateKey: 'selectDisabilityType_allergy',
    disabilityType: 'AF',
    icon: AllergyIcon
  },
  {
    type: "cognitive",
    name: "Cognitive",
    translateKey: 'selectDisabilityType_cognitive',
    disabilityType: 'C',
    icon: CognitiveIcon
  },
  {
    type: "other",
    name: "Other",
    disabilityType: 'O',
    translateKey: 'selectDisabilityType_other',
    icon: OtherIcon
  },
]

export const helsingborgPropertiesIds = [
  'c4e640e6-9faa-4475-87e1-200981452223', // 436
  '1d9d87cb-07b6-4d65-9431-d220e607421b', // 434
  '15f81ade-4d8b-4cbd-8f06-bd0cce021dbd', // 432
  '51407801-ce46-4b02-838e-5c17fa16eebe', // 416
  '2d6a1f02-b095-4266-a704-b692bcb7fd2f', // 360
  '18660c49-8051-438d-a1e9-51f9d63b04d7', // 337
  'a4e6ac54-1b5c-48b1-bad8-697bd967bf3b', // 280
  'a7ebdb5a-4e3b-4934-851d-eef46bdb7b39', // 218
  'f9de5976-d03a-45b2-8999-61ed60e80fdd', // 213
  '0870daa5-3167-477d-8956-b7f0c9ee51ca', // 210
  'e58c567b-86b7-44bf-bc04-c7ad1563e8c7', // 209
  '8a7df59f-06d8-4a13-a44d-b68f66873b28', // 206
  '3025dfbe-6c4a-4fc0-b620-7cd0b7cc94f7', // 44
  '0c06fcde-1c71-45db-ba26-44cb132cf60f', // 20
  'f5a69d47-3ead-4a50-aecc-a38d4ccff8d9', // 10
  '218567ff-9827-4c08-9c10-29d6fbc7f301', // 427
  '2eac853c-d428-4972-89d5-93ba20125274', // 304
  '201c0901-e6ca-4766-b571-1fddcbacacde', // 328
  '42e237c5-6890-4284-96e3-60f5f9f67605', // 317
  '50a51698-a9a6-4e5d-8255-fd20de174657', // 466
  'db54f58a-45a1-48fa-a6f6-5182a1e3579d', // 124
  'e6f63775-ec1c-4582-b40a-a3d843fd3765', // 16
  '505221a7-34d2-4798-b5ec-85cd7471a0b6', // 108
  'c59b1077-9b4f-435f-97ff-96ff99218d70', // 144
  '855e4a0f-9913-4625-b3db-f4b71135e953', // 258
  'ba469f27-2523-4574-8e00-ed1acf85349b', // 263
  '505221a7-34d2-4798-b5ec-85cd7471a0b6', // 108
]