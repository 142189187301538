import {
  IconsAccessibility, IconsType,
  LanguagesType,
  PATH_NAMES,
} from "../constants/constants";
import {
  iconsPriority, PriorityType
} from "../constants/icons";
import {Accessibility} from "../Models/Accessibility";
import {AccessibilityUnit} from "../Models/AccessibilityUnits";
import {AccessibilityInfo, RelatedModel} from "../Models/AccessibilityInfo";
import {retrieveProperty} from "../services/property.service";
import {retrieveBuildingAreaById} from "../services/buildingAreas.service";
import {retrieveWidgetSettings, retrieveWidgetSettingsWithCompany} from "../services/settings.service"
import {A11yStandardType, WidgetCredentials} from "../Models/utils";
import {MergedAccessibilityAttr} from "../Models/MergedAccessibilityAttr";
import {PropertyAccessibilityImages} from "../Models/PropertyAccessibilityImages";
import {Question} from "../Models/Question";
import {MutableRefObject} from "react";
import {AccessibilityUnitLevel} from "../Models/AccessibilityLevel";
import i18n from "../translations/i18n";

// const regexp = "(handiscover.com)|(localhost:3000)|(localhost:80)|(localhost)({ADD_YOUR_OWN_IP_ADDRESS_FOR_MOBILE_TEST})$"
const regexp = "(handiscover.com)|(localhost:3000)|(localhost:80)|(localhost)$";

export const config: {
  origin: string;
} = {
  origin: null!,
};
export function humanize(str: string) {
  let string = str.replace(/_/g, " ");
  return string.substr(0, 1).toUpperCase() + string.substr(1);
}

export const getAttribute = (text: string) => {
  return text.split("a11y_icons/")[1].replace(/\.png|\.jpg/g, "");
};
export const getAttributeText = (text: string) => {
  return humanize(getAttribute(text));
};
function getImageOrFallback(url: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(url);
    img.onerror = (ev) => {
      reject(ev);
    };
  });
}
async function asyncFilter<T>(
  arr: T[],
  predicate: (obj: T, index?: number, array?: T[]) => {}
) {
  const results = await Promise.all(arr.map(predicate));

  return arr.filter((_v, index) => results[index]);
}

const filterByGroupAttribute = (accessibilities: MergedAccessibilityAttr[], priorities: PriorityType) => {
  let copyAccessibilities = accessibilities
  const groupedAccessibilities: {
    accessibility: MergedAccessibilityAttr,
    GROUP: number,
    PRIORITY: number
  }[] = []
  copyAccessibilities.forEach((i) => {
    const priority = priorities[i.attribute]
    if (priority && priority.FILTER_GROUP) {
      groupedAccessibilities.push({
        ...priority.FILTER_GROUP,
        accessibility: i,
      })
    }
  })

  if (groupedAccessibilities.length > 0) {
    // Filter out elements based on priority and group
    copyAccessibilities = copyAccessibilities.filter((i) => {
      const findInGrouped = groupedAccessibilities
        .find((item) => item.accessibility.id === i.id);
      if (findInGrouped) {
        const filterByGroup = groupedAccessibilities
          .filter((item) => findInGrouped.GROUP === item.GROUP);
        // Check if any element in the same group has higher priority
        const hasHigherPriority = filterByGroup.some((item) => (
          item.PRIORITY > findInGrouped.PRIORITY
        ));
        // Check if any element in the same group has equal priority but different id
        const hasEqualPriorityDifferentId = filterByGroup.some((item) => (
          item.PRIORITY === findInGrouped.PRIORITY && item.accessibility.id !== findInGrouped.accessibility.id
        ));
        // Return false if there's a higher priority
        // or there's equal priority but different id
        return !hasHigherPriority || hasEqualPriorityDifferentId;
      }
      return true;
    });
  }


  return copyAccessibilities
}

const sortByGroupAttribute = (accessibilities: MergedAccessibilityAttr[], priorities: PriorityType) => {
  let copyAccessibilities = [...accessibilities];

  const groupedAccessibilities: {
    accessibility: MergedAccessibilityAttr,
    GROUP: number,
    PRIORITY: number
  }[] = [];

  copyAccessibilities.forEach((i) => {
    const priority = priorities[i.attribute];
    if (priority && priority.SORT_GROUP) {
      groupedAccessibilities.push({
        ...priority.SORT_GROUP,
        accessibility: i,
      });
    }
  });

  if (groupedAccessibilities.length > 0) {
    copyAccessibilities = copyAccessibilities.sort((a, b) => {
      const groupA = groupedAccessibilities.find(item => item.accessibility.id === a.id);
      const groupB = groupedAccessibilities.find(item => item.accessibility.id === b.id);
      if (!groupA || !groupB || groupA.GROUP !== groupB.GROUP) {
        return 0;
      }
      return groupA.PRIORITY - groupB.PRIORITY;
    });
  }
  return copyAccessibilities;
}

const getPriorityAttribute = (
  attribute: string,
  disabilityTypes: string[],
  iconsPriority: any,
  isBuildingArea: boolean
) => {
  let filterTypes = ['G']

  if (disabilityTypes?.length > 0) {
    filterTypes = disabilityTypes
  }

  if (isBuildingArea) {
    filterTypes = ['BA']
  }

  if (attribute) {
    const priorities = iconsPriority[attribute]
    if (priorities && filterTypes.length > 1) {
      // From this it turns out that if we use multiple disabilities, then we will always give priority to the
      // icons that are more important for the selected disabilities
      const sortedArr = Object.entries(priorities).sort(
        (a, b) => (a[1] as number) - (b[1] as number)
      );
      const filterArrByDisabilityTypes = sortedArr.filter((item) => (
        filterTypes.includes(item[0])
      ))
      const filterOutNull = filterArrByDisabilityTypes?.filter((item) => item[1] !== null)

      if (filterOutNull?.length > 0) {
        return filterOutNull[0][1]
      }
      return null
    } else {
      return priorities[filterTypes[0]]
    }
  }
  return null
}

export const getIcons = async (
  accessibilities: Accessibility[],
  a11yStandardOrUnitType: string,
  accessibilityUnit: AccessibilityUnit[],
  disabilityTypes: string[],
  isBuildingArea: boolean,
): Promise<MergedAccessibilityAttr[]> => {

  // [plug for removing duplicate icons]
  // if we are using for example type_of_fall_protection_base__artificial_grass icon
  // then we will get duplicate with surface_type_playground__artificial_grass
  const attributeReplacement: { [key: string]: string } = {
    'type_of_fall_protection_base__artificial_grass': 'surface_type_playground__artificial_grass',
    'type_of_fall_protection_base__asphalt': 'surface_type_playground__asphalt',
    'type_of_fall_protection_base__grass': 'surface_type_playground__grass',
    'type_of_fall_protection_base__loose_gravel': 'surface_type_playground__loose_gravel',
    'type_of_fall_protection_base__stone_flour': 'surface_type_playground__stone_flour',
  }

  const accessibilitiesReplacement = accessibilities.map((i) => {
    if (Object.keys(attributeReplacement).includes(i.attribute)) {
      i.attribute = attributeReplacement[i.attribute]
    }
    return i
  })

  const iconsPriorityByStandard = iconsPriority[a11yStandardOrUnitType]

  const filterByStandard = accessibilitiesReplacement.filter((acc) => (
    Object.keys(iconsPriorityByStandard).includes(acc.attribute)
  ))

  const sortByPriority = filterByStandard.sort((a, b) => (
    getPriorityAttribute(a.attribute, disabilityTypes, iconsPriorityByStandard, isBuildingArea) -
    getPriorityAttribute(b.attribute, disabilityTypes, iconsPriorityByStandard, isBuildingArea)
  ))

  const filterOutNull = sortByPriority.filter((acc) => (
    getPriorityAttribute(acc.attribute, disabilityTypes, iconsPriorityByStandard, isBuildingArea) !== null
  ))

  const filtered = await asyncFilter(filterOutNull, async (acc) => {
    try {
      await getImageOrFallback(acc.attribute_icon);
      return true;
    } catch (e) {
      console.log("image fallback in catch");
      return false;
    }
  });

  const mergedArray: MergedAccessibilityAttr[] = []

  filtered.forEach((acc) => {
    const unit = accessibilityUnit.find((unit) =>
      unit.id === acc.accessibility_unit);
    if (unit) {
      mergedArray.push({
        id: acc.id,
        item: acc.item,
        attribute: acc.attribute,
        item_title: acc.item_title,
        attribute_title: acc.attribute_title,
        attribute_icon: acc.attribute_icon,
        accessibility_unit: acc.accessibility_unit,
        is_absent: acc.is_absent,
        unit_names: getTextByCurrentLanguage(unit.name),
        unit_titles: unit.title
      })
    }
  })

  mergedArray.filter((access) => access && !access.is_absent);

  let withoutDublicate = mergedArray.filter((obj, index, arr) =>
    index === arr.findIndex((x) => (
      (x && obj) && (x.attribute === obj.attribute)
    ))
  )
  const filteredByGroupAttribute = filterByGroupAttribute(withoutDublicate, iconsPriorityByStandard)
  const sortedByGroupAttribute = sortByGroupAttribute(filteredByGroupAttribute, iconsPriorityByStandard)

  const addedUnitIds = sortedByGroupAttribute.map((i) => {
    return {
      ...i,
      unit_ids: accessibilities.filter(x =>
        (x && i) && i.attribute === x.attribute).map((i) => i && i.accessibility_unit),
      disability_types: [...new Set(accessibilities.filter(x =>
        (x && i) && i.attribute === x.attribute).flatMap((i) => i && i.disability_types))]
    }
  })

  return addedUnitIds.map(obj => {
    const filteredArr = mergedArray.filter(x =>
      (x && obj) && obj.attribute === x.attribute);
    return {
      ...obj,
      unit_names: filteredArr.map(y =>
        (y) && y.unit_names).toString().replaceAll(",", ", "),
      unit_titles: filteredArr.map(y =>
        (y) && y.unit_titles).toString().replaceAll(",", ", "),
    }
  });
};

export const filterAccessibilityInfoByDisabilities = (
  accessibilityInfo: AccessibilityInfo[],
  disabilityTypes: string[],
  questionsList: Question[]
): Array<{answerText: string, titleText: string | null, iconsAccessibility: (IconsType | undefined)[], relatedModel: string}> => {
  try {
    if (disabilityTypes?.length > 0) {
      const filteredIconsAccessibility = IconsAccessibility.filter(({ disabilityType }) => (
        disabilityTypes.includes(disabilityType)
      ))
      accessibilityInfo = accessibilityInfo.filter(({ question }) => {
        const foundQuestion = questionsList.find((i) => i.id === question)
        if (foundQuestion) {
          return filteredIconsAccessibility.some(({type})=> foundQuestion.question_type.includes(type))
        }
        return true
      })
    }

    const result: Array<{answerText: string, titleText: string | null, iconsAccessibility: (IconsType | undefined)[], relatedModel: string}> = []
    accessibilityInfo.forEach(({answer, question}) => {
      const foundQuestion = questionsList.find((i) => i.id === question)
      if (foundQuestion) {
        result.push({
          answerText: foundQuestion[`answer_text_${answer}` as keyof Omit<Question, 'created_at' | 'updated_at' | 'question_type'>],
          titleText: foundQuestion.related_model === RelatedModel.buildingAreaGeneralInfo ? foundQuestion.unit_type : null,
          iconsAccessibility: foundQuestion.question_type.map((type) => IconsAccessibility.find((icon) => type === icon.type)),
          relatedModel: foundQuestion.related_model
        })
      }
    })
    return result
  } catch (e) {
    console.error(e)
    return []
  }
};

export const accessibilityInformationIds = (
  accessibilities: Accessibility[]
) => {
  return [...new Set(accessibilities.map((item) => item.accessibility_unit))];
};

export const filterAccessibilitiesFromId = (
  accessibilities: Accessibility[],
  element: string
) => {
  return accessibilities.filter(
    (unit) => unit.accessibility_unit === element
  );
};

export const getAreaId = (widgetCredentials: WidgetCredentials) => {

  const {pathname, host} = document.location;
  let buildingAreaId, propertyId;

  if (Boolean(host.match(regexp)) && (pathname.includes(PATH_NAMES.BUILDING_AREA_DEMO) || pathname.includes(PATH_NAMES.BUILDING_AREA_PAGE)) ) {
    buildingAreaId = pathname.split("/")[3]
  } else {
    buildingAreaId = widgetCredentials.buildingAreaID;
  }
  
  if (Boolean(host.match(regexp)) && (pathname.includes(PATH_NAMES.PROPERTY_DEMO) || pathname.includes(PATH_NAMES.PROPERTY_PAGE))) {
    propertyId = pathname.split("/")[3]
  } else {
    propertyId = widgetCredentials.propertyID;
  }

  return {
    buildingAreaId,
    propertyId
  }
}

export const isURLPage = () => {
  const {pathname, host} = document.location;
  const companyName: string | undefined = pathname.split('/').pop()?.toLowerCase();
  if (Boolean(host.match(regexp)) && pathname.includes(PATH_NAMES.BUILDING_AREA_PAGE)) {
    return true;
  } else if (Boolean(host.match(regexp)) && pathname.includes(PATH_NAMES.PROPERTY_PAGE)) {
    return true;
  } else if (
    companyName?.includes("emporia") ||
    companyName?.includes("ica-simrishamn" || 
    companyName?.includes("nackaforum")) ||
    companyName?.includes("etage-shopping") ||
    companyName?.includes("helsingborg-lekplatser")
  ) {
    return true;
  } else {
    return false;
  }
}

export const isURLDemo = () => {
  const {pathname, host} = document.location;
  if (Boolean(host.match(regexp)) && pathname.includes(PATH_NAMES.BUILDING_AREA_DEMO)) {
    return true;
  } else if (Boolean(host.match(regexp)) && pathname.includes(PATH_NAMES.PROPERTY_DEMO)) {
    return true;
  } else {
    return false;
  }
}

export const fetchWidgetSettings = async (buildingAreaId: string | null, propertyId: string | null) => {
  /* If it's a demo/page link we need to first fetch the property or building area to be able to receive the company id
    because if we fetch retrieveWidgetSettings() without argument like we do below if it isn't not a demo/page it will fetch
    settings from company related to the companyIdAPIKey which is Handiscover API key in demo/page, and this works if client has embedded script.
  */

  if (isURLDemo() || isURLPage()) {
    let facility;
    if (buildingAreaId) {
      facility = await retrieveBuildingAreaById(buildingAreaId);
    } else if (propertyId) {
      facility = await retrieveProperty(propertyId);
    }
    if (facility) {
      const settings = await retrieveWidgetSettingsWithCompany(facility.company);
      if (settings.results.length !== 0) {
        return [settings.results[0]]
      } else {
        return undefined;
      }
    }
  } else {
    const settings = await retrieveWidgetSettings();
    if (settings.results.length !== 0) {
      return [settings.results[0]];
    } else {
      return undefined;
    }
  }
}

export const getSupportedLangFromCompany = (supportedLangArr: string[], currentI18nLang: string) => {
  const rmCountryCode = currentI18nLang.split('-')[0]
  const langs = (supportedLangArr && supportedLangArr.length > 0) ? supportedLangArr : ['en', 'da', 'sv']
  return langs.includes(rmCountryCode) ?
    rmCountryCode : supportedLangArr.includes(LanguagesType.English) ?
      LanguagesType.English : supportedLangArr[0]
}

export const iOS = () => {
  return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
  input !== null && input.tagName === 'IFRAME';


export const getElementByIdFromIframe = (elementId: string) => {
  let element = null
  const iframe = document.getElementById('handiscover-iframe')
  if (isIFrame(iframe) && iframe?.contentWindow) {
    element = iframe.contentWindow.document.getElementById(elementId)
  }
  return element
}

export const getCompanyName = (): string | undefined => {
  // Custom solution for Emporia, Ica and Nacka Forum
  const {pathname} = document.location;
  return pathname.split('/')[1]?.toLowerCase();
}

export const getFilteredPropertyImagesByDisabilityType = (
  propertyImages: PropertyAccessibilityImages[],
  disabilityTypes: Array<string>,
  accessibilities: Accessibility[] | undefined
): PropertyAccessibilityImages[] => {
  let filteredPropertyImages = propertyImages
  if (disabilityTypes?.length > 0 && filteredPropertyImages?.length > 0) {
    filteredPropertyImages = filteredPropertyImages.filter((image) => {
      if (image.items) {
        if (image.items.length > 0) {
          const filteredAccessibilities = accessibilities?.filter((a) => (
            a.accessibility_unit === image.accessibility_unit
          ))
          const disabilityTypesFromAccessibilities: any = []

          image.items.forEach((i) => {
            const filteredByItem = filteredAccessibilities?.filter((acc) => i === acc.item)
            if (filteredByItem && filteredByItem.length > 0) {
              filteredByItem.forEach((filteredItem) => {
                if (filteredItem.disability_types) {
                  disabilityTypesFromAccessibilities.push(...filteredItem.disability_types)
                }
              })
            }
          })
          const uniqDisabilityTypes = [...new Set(disabilityTypesFromAccessibilities)]
          if (uniqDisabilityTypes.length > 0) {
            return disabilityTypes.some((item: string) => uniqDisabilityTypes.includes(item))
          } else {
            return true
          }
        } else {
          return true
        }
      }
      return false
    })
  }
  return filteredPropertyImages
}

export const getIframeDocument = () => {
  const iframe = document.getElementById('handiscover-iframe')
  if (isIFrame(iframe) && iframe.contentWindow) {
    const { document } = iframe.contentWindow
    return document
  }
  return null
}

export const getAttributeValueFromCurrentURL = (attributeName: string): string | null => {
  const searchParams = new URL(window.location.href).searchParams;
  return searchParams.get(attributeName);
}


export const getReplacedTextForStandards = (standards: A11yStandardType[]): A11yStandardType[] => {
  const replaceText = (text: string, itemTitle: string, unitTitle: string) => {
    if (!text) {
      return text
    }

    return text
      .replace(/{item__title}/g, itemTitle.toLowerCase())
      .replace(/{unit__title}/g, unitTitle.toLowerCase())
  }

  return standards.map((standard) => ({
    ...standard,
    values: standard.values.map((a11yUnit) => ({
      ...a11yUnit,
      values: a11yUnit.values.map((a11yItem) => ({
        ...a11yItem,
        values: a11yItem.values.map((a11yAttribute) => ({
          ...a11yAttribute,
          q_title: replaceText(a11yAttribute.q_title, a11yItem.title, a11yUnit.title),
          title: replaceText(a11yAttribute.title, a11yItem.title, a11yUnit.title),
          values: a11yAttribute.values && a11yAttribute.values.map((a11yAttributeValue) => ({
            ...a11yAttributeValue,
            title: replaceText(a11yAttributeValue.title, a11yItem.title, a11yUnit.title)
          }))
        }))
      }))
    }))
  }))
}


export const replaceAccessibilitiesTitle = (accessibilities: Accessibility[], units: any, standardTitle: string = '') => {
  const replaceText = (text: string, itemTitle: string = '', unitTitle: string = '', standardTitle: string = '') => {
    if (!text) {
      return text
    }

    return text
      .replace(/{item__title}/g, itemTitle.toLowerCase())
      .replace(/{unit__title}/g, unitTitle.toLowerCase())
      .replace(/{standard__title}/g, standardTitle.toLowerCase())
  }

  return accessibilities.map((accessibility) => {
    const foundUnit = units.find((i: any) => i.id === accessibility.accessibility_unit)

    return {
      ...accessibility,
      attribute_title: replaceText(accessibility.attribute_title, accessibility.item_title, foundUnit?.title, standardTitle)
    }
  })
}

export const replaceNestedAccessibilities = (accessibilities: Accessibility[], accessibleUnits: AccessibilityUnit[]) => {
  return accessibilities?.map((acc) => {
    let accessibilityUnitId = acc.accessibility_unit
    const relatedUnit = accessibleUnits
      .find((unit) => unit.id === acc.accessibility_unit)
    if (relatedUnit && relatedUnit.related_to.length > 0) {
      accessibilityUnitId = relatedUnit.related_to[0]
    }
    return {
      ...acc,
      accessibility_unit: accessibilityUnitId
    }
  })
}
export const replaceNestedAccessibilityImages = (images: PropertyAccessibilityImages[], accessibleUnits: AccessibilityUnit[]) => {
  return images?.map((image) => {
    let accessibilityUnitId = image.accessibility_unit
    let related = false
    const relatedUnit = accessibleUnits
      .find((unit) => unit.id === image.accessibility_unit)
    if (relatedUnit && relatedUnit.related_to.length > 0) {
      accessibilityUnitId = relatedUnit.related_to[0]
      related = true
    }
    return {
      ...image,
      accessibility_unit: accessibilityUnitId,
      related: related
    }
  })
}

export const sortPropertyImages = (propertyImages: PropertyAccessibilityImages[], accessibleUnits: AccessibilityUnit[]) => {
  const sortedPropertyImages: PropertyAccessibilityImages[] = []
  const sortedAccessibleUnits = accessibleUnits
    .sort((a,b) => a.order - b.order)
  sortedAccessibleUnits.forEach((unit) => {
    const filteredImages = propertyImages
      .filter((i) => i.accessibility_unit === unit.id)
    if (filteredImages.length > 0) {
      sortedPropertyImages.push(...filteredImages)
    }
  })
  return sortedPropertyImages
}

export const scrollToUnitByCurrentImage = (
  refs: MutableRefObject<HTMLDivElement[] | null[]>,
  currentImageData: {index: number, accessibility_unit: string, name: string},
  containerId: string
) => {
  const foundIndexForScroll = refs.current.findIndex((i: any) => (
    i?.id === currentImageData.accessibility_unit
  ))
  if (foundIndexForScroll !== -1) {
    const elementForScroll = refs.current[foundIndexForScroll]
    if (elementForScroll) {
      const container = getElementByIdFromIframe(containerId)
      if (container && container.children[1]) {
        const containerRect = container?.children[1].getBoundingClientRect()
        // scrollIntoView behaves strangely if the widget is in an iframe
        if (window.self !== window.top) {
          if (container?.children[1] && containerRect) {
            container?.children[1].scrollTo({
              top: elementForScroll?.offsetTop - containerRect.top,
              behavior: 'smooth'
            })
          }
        } else {
          elementForScroll.scrollIntoView({behavior: 'smooth'})
        }
      }
    }
  }
}

export const getAccessibilityUnitLevelNumber = (unitLevel: AccessibilityUnitLevel[] | undefined, units: AccessibilityUnit[]) => {
  if (unitLevel) {
    const filterAccessibleUnit = unitLevel.filter((i) => {
      if (i.accessibility_unit) {
        const findUnit = units.find((u) => u.id === i.accessibility_unit)
        return findUnit ? findUnit.is_accessible_unit : false
      }
      return true
    })

    return filterAccessibleUnit.reduce(
      (accum, item) => accum + item.number_of_units,
      0
    );
  }
  return 0;
}

export const getTextByCurrentLanguage = (object: {[key: string]: string} | undefined | string) => {
  const { language } = i18n
  if (typeof object === 'string') {
    return object
  }
  let text = object ? object['en'] : ''
  if (object && object[language]) {
    text = object[language]
  }
  return text
}
