import styled from "styled-components";
import {Skeleton as MuiSkeleton, Typography as MuiTypography, useMediaQuery} from "@mui/material";
import React, {FC, useEffect, useRef, useState} from "react";
import {MergedAccessibilityAttr} from "../Models/MergedAccessibilityAttr";
import {getIcons} from "../utils/utils";
import {A11yStandardType} from "../Models/utils";
import {AccessibilityUnit} from "../Models/AccessibilityUnits";
import {AccessibilityImage} from "./AccessibilityImage";
import {Mixpanel} from "../Mixpanel";
import {MetricMessage} from "../utils/metrics";
import ScrollableView from "./ScrollableView";
import {useElementHeight} from "../hooks/useElementHeight";
import {useTranslation} from "react-i18next";
import {Accessibility} from "../Models/Accessibility";
import {useTheme} from "@mui/material/styles";

type WrapperType = {
  fullHeight: boolean
  display: string;
}

const Wrapper = styled.div<WrapperType>`
  display: ${(props) => props.display};
  flex-direction: column;
  height: ${(props) => props.fullHeight ? '100%' : 'unset'};
`

type ScrollableViewBoxProps = {
  max_height: number
  overflow_y: number
  nr_columns: number
  nr_rows: number
}

const DetailedAccSpacing = styled.div`
  flex: 1
`

const StyledContainer = styled.div`
  padding: 12px
`;

const IconDescWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconDescText = styled.p`
  line-height: unset !important;
  margin: 0 !important;
  font-weight: bold !important;
`;

const IconDescInner = styled.span`
  font-weight: normal !important;
`;

type WrapperImageProps = {
  backgroundStyle?: boolean
}

const WrapperImage = styled.div<WrapperImageProps>`
  padding: 5px;
  max-height: 49px;
  max-width: 49px;
  transition: border 200ms;
  border: ${(props) => props.backgroundStyle ? "1px solid #1976d2" : "1px solid #DDE1E6"};
  border-radius: 5px;
  background: ${(props) => props.backgroundStyle ? '#EDF5FF' : '#FFF'}
`

const ScrollableViewBox = styled(ScrollableView)<ScrollableViewBoxProps>`
  display: grid;
  gap: 12px;
  max-height: ${(props) => `${props.max_height}px`};
  overflow-y: ${(props) => Boolean(props.overflow_y) ? "scroll": "hidden"};
  grid-template-rows: ${(props) => `repeat(${props.nr_rows}, 1fr)`};
  grid-template-columns: ${(props) => `repeat(${props.nr_columns}, 1fr)`};
`;

type IconDescriptionContainerProps = {
  selectedIcon: boolean
}

const IconDescriptionContainer = styled.div<IconDescriptionContainerProps>`
  padding-top: 10px;
  flex-grow: 1;
  align-items: center;
  height: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  display: ${({ selectedIcon }) => selectedIcon ? "flex" : "none"}
`;


type TitleBlockProps = {
  fullHeight: boolean
}

const TitleBlock = styled.div<TitleBlockProps>`
  display: flex;
  align-items: center;
  height: ${(props) => props.fullHeight ? '50px' : '40px'};
  max-height: ${(props) => props.fullHeight ? '50px' : '40px'};
  background: #FFFFFF;
  border: 1px solid #E3E5E5;
  margin-bottom: 8px;
  p {
    font-size: 16px !important;
  }
`

type ContentBlockType = {
  fullHeight: boolean;
  marginBottom: boolean;
  borderBottom: boolean;
}

const ContentBlock = styled.div<ContentBlockType>`
  background: #FFFFFF;
  margin-bottom: ${(props) => props.fullHeight || !props.marginBottom ? 'unset' : '10px'};;
  border-top: 1px solid #E3E5E5;
  border-left: 1px solid #E3E5E5;
  border-right: 1px solid #E3E5E5;
  border-bottom: ${(props) => props.borderBottom ? '1px solid #E3E5E5' : ''};
  
  height: ${(props) => props.fullHeight ? '100%' : 'unset'};
`

const Typography = styled(MuiTypography)`
  padding: 0 12px;
  line-height: 28px;
  letter-spacing: 0.15px;
`

const Skeleton = styled(MuiSkeleton)`
  transform: none;
  border-radius: unset;
`

const InfoTextBlock =styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  p {
    margin: 0 !important;
    padding: 0 !important;
    text-align: center !important;
    color: #4c4c4c !important;
    font-size: 13px !important;
  }
`

type PropsType = {
  accessibilities: Accessibility[] | undefined;
  a11yStandard: A11yStandardType;
  a11yStandardKey?: string;
  propertyItemAccessibilityUnits: AccessibilityUnit[];
  currentImageData?: { index: number, accessibility_unit: string, name: string }
  disabilityTypes: string[]
  title?: string;
  fullHeight?: boolean;
  marginBottom?: boolean;
  borderBottom?: boolean;
  fullscreen?: boolean;
  showOnlySelected?: boolean;
  isBuildingArea?: boolean;
}

enum EventStatus {
  clickOnIcon = 'icon',
  clickOnImage = 'image'
}

const MainAccessibility: FC<PropsType> = ({
  accessibilities,
  a11yStandard,
  propertyItemAccessibilityUnits,
  currentImageData,
  disabilityTypes,
  a11yStandardKey,
  title = 'mainContainer_generalAccessibility',
  fullHeight = false,
  marginBottom = true,
  borderBottom= true,
  fullscreen,
  showOnlySelected = false,
  isBuildingArea = false
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [accessibilitiesIcons, setAccessibilitiesIcons] = useState<MergedAccessibilityAttr[]>(null!);
  const [selectedIcon, setSelectedIcon] = useState<MergedAccessibilityAttr[]>([]);
  const [eventStatus, setEventStatus] = useState<EventStatus>(EventStatus.clickOnIcon)
  const [isLoading, setIsLoading] = useState(true)
  const refs = useRef<HTMLDivElement[] | null[]>([])
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (accessibilitiesIcons && currentImageData?.accessibility_unit) {
      const filterAccessibilitiesIcons = accessibilitiesIcons.filter((i) => (
        i.unit_ids?.includes(currentImageData.accessibility_unit)
      ))
      if (filterAccessibilitiesIcons.length > 0) {
        setEventStatus(EventStatus.clickOnImage)
        setSelectedIcon(filterAccessibilitiesIcons)
        if (isDesktop) {
          const indexForScroll = accessibilitiesIcons.findIndex((i) => (
            i.id === filterAccessibilitiesIcons[0].id
          ))
          if (refs.current.length > 0 && indexForScroll !== -1 && window.self === window.top) {
            refs.current[indexForScroll]?.scrollIntoView()
          }
        }
      } else {
        setSelectedIcon([])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImageData, accessibilitiesIcons])

  useEffect(() => {
    fetchIcons();
    return () => setAccessibilitiesIcons([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessibilities, propertyItemAccessibilityUnits, disabilityTypes])

  const fetchIcons = async () => {
    setIsLoading(true)
    if (accessibilities && accessibilities.length > 0 && a11yStandard && propertyItemAccessibilityUnits.length > 0) {
      const a11yKey = a11yStandardKey ? a11yStandardKey : a11yStandard.key
      getIcons(accessibilities, a11yKey, propertyItemAccessibilityUnits, disabilityTypes, isBuildingArea)
        .then((res) => {
          let result = res;
          // rm. icons by disabilityTypes (localstorage) if we have match or disability types is 0
          if (disabilityTypes?.length > 0) {
            result = result.filter((i) => (
              disabilityTypes.some((dt) => i.disability_types?.includes(dt)) ||
              i.disability_types?.length === 0
            ))
          }
          setIsLoading(false)
          setAccessibilitiesIcons(result)
        })
    }
  }

  const clickImg = (img: MergedAccessibilityAttr) => () => {
    Mixpanel.track(`${MetricMessage.accessibilities_icons_click}`, {
      accessibilityIconName: `${img.attribute}`
    });
    setEventStatus(EventStatus.clickOnIcon)
    setSelectedIcon([img]);
  };

  const isSelectedIcon = (item: MergedAccessibilityAttr) => {
    if (selectedIcon.length > 0) {
      return !!selectedIcon.find((i) => item.id === i?.id);
    }
    return false
  }

  const getAccessibilitiesIcons = () => {
    if (accessibilitiesIcons && accessibilitiesIcons.length > 0) {
      let cachedAccessibilitiesIcons = accessibilitiesIcons
      if (showOnlySelected) {
        cachedAccessibilitiesIcons = cachedAccessibilitiesIcons
          .filter((item) => isSelectedIcon(item))
      }
      return (
        cachedAccessibilitiesIcons.map((item, index) => (
          <WrapperImage
            key={index}
            ref={el => refs.current[index] = el}
            backgroundStyle={isSelectedIcon(item)}
          >
            <AccessibilityImage
              key={index}
              src={item.attribute_icon}
              title={item.attribute_title}
              onClick={showOnlySelected ? () => {} : clickImg(item)}
            />
          </WrapperImage>
        ))
      )
    }
    return []
  }

  const { width } = useElementHeight(
    {
      elemId: "detailed-acc-spacing",
      onResize: true,
    },
    [accessibilitiesIcons]
  );

  const NR_COLUMNS = 6;
  const NR_ROWS = (getAccessibilitiesIcons() && getAccessibilitiesIcons().length > 6) ? 2 : 1;

  const getIconContainerSize = (containerWidth: number, rows: number, fullscreen: boolean | undefined) => {
    const padding = 2 * 10; //padding is 10 , for padding on both sides
    const innerGaps = (NR_COLUMNS - 1) * 6 // gap is 6
    return ((containerWidth - padding - innerGaps) / NR_COLUMNS) * rows + 10;
  }

  const getDescription = () => {
    let result: {
      names: string | string[],
      title: string | string[]
    } = {
      names: '',
      title: ''
    }

    if (eventStatus === EventStatus.clickOnIcon) {
      result = {
        names: selectedIcon.map((i) => {
          if (i.unit_names.length > 107 && accessibilitiesIcons && accessibilitiesIcons.length > 6) {
            return i.unit_names.slice(0, 107) + '... and more'
          }
          return i.unit_names
        }),
        title: selectedIcon.map((i) => i.attribute_title)
      }
    }
    if (eventStatus === EventStatus.clickOnImage) {
      result = {
        names: currentImageData ? currentImageData.name : '',
        title: selectedIcon.map((i) => `${i.attribute_title} `)
      }
    }

    return result
  }

  const getInfoText = () => {
    let textKey = 'generalAccessibility_noGeneralAccessibilitiesForThisProperty'
    if (disabilityTypes?.length > 0) {
      textKey = 'generalAccessibility_noGeneralAccessibilitiesForTheseDisabilityFilters'
    }
    return (
      <InfoTextBlock>
        <Typography>{t(textKey)}</Typography>
      </InfoTextBlock>
    )
  }

  return (
    <Wrapper
      fullHeight={fullHeight}
      display={!isLoading && getAccessibilitiesIcons()?.length === 0 && showOnlySelected ? 'none' : 'flex'}>
      {title ? (
        <TitleBlock fullHeight={fullHeight}>
          <Typography>{t(title)}</Typography>
        </TitleBlock>
      ) : null}
      <ContentBlock
        fullHeight={fullHeight}
        marginBottom={marginBottom}
        borderBottom={borderBottom}
      >
        <StyledContainer>
          <ScrollableViewBox
            max_height={showOnlySelected ? 115 : getIconContainerSize(width, NR_ROWS, fullscreen)}
            overflow_y={getAccessibilitiesIcons()?.length > 10 ? 1 : 0}
            nr_columns={NR_COLUMNS}
            nr_rows={NR_ROWS}
          >
            {getAccessibilitiesIcons()}
            {isLoading ? (
              <>
                <Skeleton variant="text" animation="wave" height={42}/>
                <Skeleton variant="text" animation="wave" height={42}/>
                <Skeleton variant="text" animation="wave" height={42}/>
                <Skeleton variant="text" animation="wave" height={42}/>
                <Skeleton variant="text" animation="wave" height={42}/>
                <Skeleton variant="text" animation="wave" height={42}/>
              </>
            ) : null}
          </ScrollableViewBox>
          {!isLoading && getAccessibilitiesIcons()?.length === 0 ? (
            getInfoText()
          ) : null}
          <DetailedAccSpacing id="detailed-acc-spacing" />
          <IconDescWrapper>
            {accessibilitiesIcons && accessibilitiesIcons.length > 0 ? (
              <IconDescriptionContainer selectedIcon={!!selectedIcon}>
                {(selectedIcon.length > 0) ?
                  <IconDescText>
                    {getDescription().names}:{" "}
                    <IconDescInner>{getDescription().title}</IconDescInner>
                  </IconDescText>
                  : null
                }
              </IconDescriptionContainer>
            ) : null}
            {isLoading ? (
              <div style={{marginTop: '12px', width: '100%'}}>
                <Skeleton variant="text" animation="wave" width={'100%'} height={25}/>
              </div>
            ) : null}
          </IconDescWrapper>
        </StyledContainer>
      </ContentBlock>
    </Wrapper>
  )
}

export default MainAccessibility
