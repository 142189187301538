import {FC} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";
import {SvgIcon} from "@mui/material";

const PlaygroundSvg: FC<SvgIconProps> = (p) => (
    <SvgIcon {...p}>
        <svg {...p} viewBox="0 0 101 101" xmlns="http://www.w3.org/2000/svg">
            <path d="M98.2158 95.3936H88.0943L49.2785 50.6615C48.7416 50.0452 47.9661 49.6874 47.1508 49.6874H36.5918V44.7569H38.461C40.012 44.7569 41.2648 43.5044 41.2648 41.9537C41.2648 40.741 40.5092 39.727 39.4354 39.3294V22.033H40.1114C41.2847 22.033 42.3187 21.3173 42.7363 20.2239C43.1539 19.1304 42.8357 17.8978 41.9608 17.1224L23.3284 0.700802C22.2745 -0.233601 20.6837 -0.233601 19.6297 0.700802L0.957596 17.1224C0.0826491 17.8978 -0.235513 19.1304 0.182075 20.2239C0.599663 21.3173 1.63369 22.033 2.80691 22.033H3.48301V39.3294C2.40921 39.727 1.65358 40.741 1.65358 41.9537C1.65358 43.5044 2.90634 44.7569 4.45738 44.7569H6.32659V98.1968C6.32659 99.7475 7.57935 101 9.13039 101C10.6814 101 11.9342 99.7475 11.9342 98.1968V86.9442H30.9643V98.1968C30.9643 99.7475 32.217 101 33.7681 101C35.3191 101 36.5719 99.7475 36.5719 98.1968V55.2938H45.8583L84.6741 100.026C85.211 100.642 85.9865 101 86.8018 101H98.196C99.747 101 101 99.7475 101 98.1968C101.02 96.6461 99.7669 95.3936 98.2158 95.3936ZM11.9541 58.8127H30.9842V67.2621H11.9541V58.8127ZM21.4791 6.54579L32.6943 16.4266H10.244L21.4791 6.54579ZM33.8277 22.033L33.788 39.1505H9.11051V22.033H33.8277ZM30.9842 44.7569V53.2063H11.9541V44.7569H30.9842ZM11.9541 81.3179V72.8685H30.9842V81.3179H11.9541Z" fill={p.fill}/>
        </svg>
    </SvgIcon>
)

export default PlaygroundSvg