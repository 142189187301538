import { useContext, useEffect, useState } from "react";
import { refreshContext } from "../contexts/refreshContext";
import { Accessibility } from "../Models/Accessibility";
import { PropertyAccessibilityImages } from "../Models/PropertyAccessibilityImages";
import { AccessibilityUnitLevelQuery } from "../Models/AccessibilityLevel";
import {
  AccessibilityUnit,
} from "../Models/AccessibilityUnits";
import { Property } from "../Models/Property";
import {
  retrieveAccessibilityUnitsByTypeWithFields,
  listAccessbilityImagesByPropertyId,
  retrieveAccesibilityUnitsLevelsByUnitId,
  retrieveAccessibilitiyByUnitId, retrieveRelatedAccessibilityUnitsByUnit,
} from "../services/property.service";
import {replaceAccessibilitiesTitle} from "../utils/utils";
import {A11yStandardType} from "../Models/utils";

export const useFetchDetailedUnitData = (property: Property | undefined, unitItem: AccessibilityUnit, a11yStandard: A11yStandardType) => {

  const [loading, setLoading] = useState(false);
  const [refresh] = useContext(refreshContext);

  const [propertyItemAccessibilityUnits, setPropertyItemAccessibilityUnits] = useState<AccessibilityUnit[]>([]);
  const [itemAccessibilites, setItemAccessibilites] = useState<Accessibility[]>([]);
  const [propertyItemImages, setPropertyItemImages] = useState<PropertyAccessibilityImages[]>([]);
  const [unitItemFloors, setUnitItemFloors] = useState<string>("");

  const handlerFloors = (unitLevel: AccessibilityUnitLevelQuery) => {
    if (unitLevel.results && unitLevel.results.length > 1) {
      let minFloor = unitLevel.results[0].level;
      let maxFloor = unitLevel.results[0].level;
      unitLevel.results.forEach((floorObj) => {
        if (floorObj.level < minFloor) {
          minFloor = floorObj.level;
        }
        if (floorObj.level > maxFloor) {
          maxFloor = floorObj.level;
        }
      })
      if (minFloor === maxFloor) {
        setUnitItemFloors(unitLevel.results[0].level.toString());
      } else {
        setUnitItemFloors(`${minFloor} - ${maxFloor}`);
      }
    } else if (unitLevel.results && unitLevel.results.length === 1) {
      setUnitItemFloors(unitLevel.results[0].level.toString());
    } else {
      setUnitItemFloors("");
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      const preparedUnits = await getPropertyItemAccessibilityUnits()
      const roomAccessibilities = await getRoomAccessibilities(preparedUnits)
      const images = await getImages()

      await getUnitLevels()
      setItemAccessibilites(roomAccessibilities);
      setPropertyItemAccessibilityUnits(preparedUnits)
      setPropertyItemImages(images)

      setLoading(false)
    }
    fetchData().catch(console.error)
    return () => {
      setPropertyItemAccessibilityUnits([]);
      setItemAccessibilites([]);
      setPropertyItemImages([]);
      setUnitItemFloors("");
      setLoading(false)

    }
  }, [refresh, unitItem, property])

  const getPropertyItemAccessibilityUnits = async () => {
    let accessibilityUnits = property ? (
      await retrieveAccessibilityUnitsByTypeWithFields(property.id, unitItem.type ,"id,name,accessibility_unit,title,related_to,is_accessible_unit")
    ) : null;

    const relatedAccessibilityUnits = property ? (
      await retrieveRelatedAccessibilityUnitsByUnit(unitItem.id)
    ) : null

    let preparedUnits: AccessibilityUnit[] = []
    if (accessibilityUnits && accessibilityUnits.results) {
      preparedUnits = accessibilityUnits.results
      if (relatedAccessibilityUnits && relatedAccessibilityUnits?.results.length > 0) {
        preparedUnits = preparedUnits.concat(relatedAccessibilityUnits.results)
      }
    }
    return preparedUnits
  }

  const getRoomAccessibilities = async (preparedUnits: AccessibilityUnit[]) => {
    let roomAccessibilities = (
      await retrieveAccessibilitiyByUnitId(unitItem.id)
    ).results

    if (preparedUnits.length > 0) {
      const relatedAccessibilityUnits = preparedUnits.filter((item) => (
        item.related_to && item.related_to.some((related) => related === unitItem.id)
      ))
      if (relatedAccessibilityUnits.length > 0) {
        for await (let relatedAccessibilityUnit of relatedAccessibilityUnits) {
          const relatedRoom = await retrieveAccessibilitiyByUnitId(relatedAccessibilityUnit.id)
          if (relatedRoom.results.length > 0) {
            roomAccessibilities = roomAccessibilities.concat(relatedRoom.results)
          }
        }
      }
    }

    const filteredOnlyAbsent = roomAccessibilities.filter(
      (access) => access.is_absent === false
    );

    return replaceAccessibilitiesTitle(filteredOnlyAbsent, preparedUnits, a11yStandard.title)
  }

  const getImages = async () => {
    const images = property ? (
      await listAccessbilityImagesByPropertyId(property.id)
    ) : null;
    return images ? images.results : []
  }

  const getUnitLevels = async () => {
    const floors = unitItem ? (
      await retrieveAccesibilityUnitsLevelsByUnitId(unitItem.id)
    ) : null;
    if (floors) {
      if (floors.results && floors.results.length > 1) {
        let minFloor = floors.results[0].level;
        let maxFloor = floors.results[0].level;
        floors.results.forEach((floorObj) => {
          if (floorObj.level < minFloor) {
            minFloor = floorObj.level;
          }
          if (floorObj.level > maxFloor) {
            maxFloor = floorObj.level;
          }
        })
        if (minFloor === maxFloor) {
          setUnitItemFloors(floors.results[0].level.toString());
        } else {
          setUnitItemFloors(`${minFloor} - ${maxFloor}`);
        }
      } else if (floors.results && floors.results.length === 1) {
        setUnitItemFloors(floors.results[0].level.toString());
      } else {
        setUnitItemFloors("");
      }
    }
  }

  // TODO what we have before
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     let accessibilityUnits = property ? (
  //       await retrieveAccessibilityUnitsByTypeWithFields(property.id, unitItem.type ,"id,name,accessibility_unit,title,related_to")
  //     ) : null;
  //
  //     const relatedAccessibilityUnits = property ? (
  //       await retrieveRelatedAccessibilityUnitsByUnit(unitItem.id)
  //     ) : null
  //
  //     let preparedUnits: AccessibilityUnit[] = []
  //     if (accessibilityUnits && accessibilityUnits.results) {
  //       preparedUnits = accessibilityUnits.results
  //       if (relatedAccessibilityUnits && relatedAccessibilityUnits?.results.length > 0) {
  //         preparedUnits = preparedUnits.concat(relatedAccessibilityUnits.results)
  //       }
  //     }
  //
  //     setPropertyItemAccessibilityUnits(preparedUnits)
  //     setLoading(false);
  //   }
  //   fetchData().catch(console.error);
  //   return () => {
  //     setPropertyItemAccessibilityUnits([]);
  //     setLoading(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [refresh, property])
  //
  // // useEffect(() => {
  // //   const fetchData = async () => {
  // //     setLoading(true);
  // //     const roomAccessibilities = (
  // //       await retrieveAccessibilitiyByUnitId(unitItem.id)
  // //     ).results
  // //     const filteredRoomAccessibilities = roomAccessibilities.filter(
  // //       (access) => access.is_absent === false
  // //     );
  // //     setItemAccessibilites(filteredRoomAccessibilities);
  // //     setLoading(false);
  // //   };
  // //
  // //   fetchData().catch(console.error);
  // //   return () => {
  // //     setItemAccessibilites([]);
  // //     setLoading(false);
  // //   }
  // // }, [refresh, unitItem])
  //
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     let roomAccessibilities = (
  //       await retrieveAccessibilitiyByUnitId(unitItem.id)
  //     ).results
  //
  //     if (propertyItemAccessibilityUnits.length > 0) {
  //       const relatedAccessibilityUnits = propertyItemAccessibilityUnits.filter((item) => (
  //         item.related_to && item.related_to.some((related) => related === unitItem.id)
  //       ))
  //       if (relatedAccessibilityUnits.length > 0) {
  //         for await (let relatedAccessibilityUnit of relatedAccessibilityUnits) {
  //           const relatedRoom = await retrieveAccessibilitiyByUnitId(relatedAccessibilityUnit.id)
  //           if (relatedRoom.results.length > 0) {
  //             roomAccessibilities = roomAccessibilities.concat(relatedRoom.results)
  //           }
  //         }
  //       }
  //     }
  //
  //     const filteredRoomAccessibilities = roomAccessibilities.filter(
  //       (access) => access.is_absent === false
  //     );
  //     setItemAccessibilites(filteredRoomAccessibilities);
  //     setLoading(false);
  //   };
  //   fetchData().catch(console.error);
  //   return () => {
  //     setItemAccessibilites([]);
  //     setLoading(false);
  //   }
  // }, [refresh, propertyItemAccessibilityUnits]);
  //
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const images = property ? (
  //       await listAccessbilityImagesByPropertyId(property.id)
  //     ) : null;
  //     if (images) {
  //       setPropertyItemImages(images.results);
  //     }
  //     setLoading(false);
  //   }
  //   fetchData().catch(console.error);
  //   return () => {
  //     setPropertyItemImages([]);
  //     setLoading(false);
  //   }
  // }, [refresh, property])
  //
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const floors = unitItem ? (
  //       await retrieveAccesibilityUnitsLevelsByUnitId(unitItem.id)
  //     ) : null;
  //     if (floors) {
  //       handlerFloors(floors)
  //     }
  //     setLoading(false);
  //   }
  //   fetchData().catch(console.error);
  //   return () => {
  //     setUnitItemFloors("");
  //     setLoading(false);
  //   }
  // }, [refresh, unitItem])

  return {
    loading,
    propertyItemAccessibilityUnits,
    itemAccessibilites,
    propertyItemImages, 
    unitItemFloors,
  };
};