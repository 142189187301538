import {createTheme, Theme} from "@mui/material";


declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}


export const theme = createTheme({
    typography: {
        fontFamily: [
            'Roboto',
            "Manrope",
            "sans-serif"
        ].join(",") + ' !important'
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "transparent !important",
                    boxShadow: "none !important",
                    // height: "calc(100% - 100px)",
                    // maxHeight: "1200px",
                    overflowY: "hidden",
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    zIndex: "99999"
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    zIndex: "99999"
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                content: {
                    margin: "0 !important",
                }
            }
        }
    }
});