export const TRANSLATIONS_PT = {
  "accessibility_button": "Acessibilidade",
  "sidebar_Hotel": "Hotel",
  "sidebar_Rooms": "Quartos",
  "sidebar_verified": "Verificado por Handiscover",
  "hotel_floors": "Chão",
  "hotel_totalRooms": "Total de quartos",
  "hotel_accessibleRooms": "Quarto Acessível",
  "hotel_allergyRooms": "Quartos para alergias",
  "mainContainer_accessibilityInformation": "Informações sobre a acessibilidade",
  "mainContainer_pictures": "Imagens",
  "mainContainer_informationByDisability": "Informações por deficiência",
  "mainContainer_GeneralHotelInformation": "Informações gerais sobre o hotel",
  "informationByDisability_mobility": "Mobilidade",
  "informationByDisability_hearing": "Audição",
  "informationByDisability_vision": "Visão",
  "informationByDisability_other": "Outros",
  "rooms_header": "Informações sobre o quarto",
  "accessibility_room_icons": "Ícones de acessibilidade para a sala",
  "roomInformation_generalRoomInformation": "Informações gerais sobre o quarto",
  "shopping_mall_mallFloors": "Pisos do centro comercial",
  "shopping_mall_totalShops": "Lojas inventariadas",
  "shopping_mall_accessibleParking": "Estacionamento Acessível",
  "shopping_mall_foodCourts": "Praças de alimentação",
  "mainContainer_GeneralMallInformation": "Informações gerais sobre o centro comercial",
  "rooms_noPictures": "Esta sala não tem imagens",
  "rooms_noInformation": "Esta sala não tem informações",
  "rooms_noIcons": "Sem ícones de acessibilidade",
  "property_noPictures": "Não foram encontradas imagens para este imóvel",
  "disability_noPictures": "Não há imagens para a Deficiência selecionada",
  "accessibilityIcons_notPresent": "Sem ícones de acessibilidade",
  "shopping_mall": "Centro comercial",
  "building_area_buildings": "Edifícios",
  "building_area_shops": "Lojas inventariadas",
  "building_area_parking_spaces": "Estacionamento Acessível",
  "building_area_food_courts": "Praças de alimentação",
  "mainContainer_generalInformation": "Informações gerais",
  "mainContainer_GeneralOfficeInformation": "Informações gerais sobre o escritório",
  "mainContainer_GeneralShopInformation": "Informações gerais sobre a loja",
  "header_buildingInformation": "Informações sobre o edifício",
  "a11yStandard_shopping_mall": "Loja",
  "a11yStandard_shopping_mall_plural": "Lojas",
  "property_floorSignular": "Chão",
  "property_floorPlural": "Pavimentos",
  "property_tabTitle_shops": "Informações sobre as lojas",
  "general_invalid_API_CALL_errorMessage": "Ocorreu um problema! Não foi possível obter os dados.",
  "general_missingInfo_forItem": "Parece que a informação ainda não foi adicionada",
  "translated_text": "O título foi traduzido",
  "accessibility_button_open_ariaLabel": "Informação de acessibilidade aberta",
  "mainContainer_mapView": "Vista do mapa",
  "mainContainer_generalAccessibility": "Acessibilidade geral",
  "mapView_filterBy": "Filtrar por",
  "mapView_filter_all": "Todos",
  "mapView_filter_entrance": "Entrada",
  "mapView_filter_parking": "Estacionamento",
  "mapView_filter_toilet": "Sanita",
  "mapView_floor": "Chão",
  "selectDisabilityType_mobility": "Mobilidade",
  "selectDisabilityType_hearing": "Audição",
  "selectDisabilityType_allergy": "Alergia",
  "selectDisabilityType_cognitive": "Cognitivo",
  "selectDisabilityType_other": "Outros",
  "selectDisabilityType_select": "Selecionar",
  "selectDisabilityType_disability_type": "tipo de deficiência",
  "selectDisabilityType_select_disability_type": "Selecionar o tipo de deficiência",
  "selectDisabilityType_select_what_type": "Filtrar por deficiência",
  "selectDisabilityType_visual": "Visual",
  "go_back": "Voltar atrás",
  "tabTitle_meetingRooms": "Salas de reuniões",
  "tabTitle_hospitalityRooms": "Quartos de hotelaria",
  "generalAccessibility_noGeneralAccessibilitiesForThisProperty": "Não foram encontradas Acessibilidades Gerais para esta propriedade",
  "generalAccessibility_noGeneralAccessibilitiesForTheseDisabilityFilters": "Não foram encontradas Acessibilidades Gerais para estes filtros de incapacidade",
  "informationByDisabilityContainer_noDataIsFound": "Não foram encontrados dados",
  "informationByDisabilityContainer_weDoNotHaveTheData": "Ainda não dispomos dos dados relativos a este imóvel",
  "informationByDisabilityContainer_tryAnotherDisabilityFiltering": "Tentar outra filtragem de deficiências",
  "playground_information": "Informações sobre os parques infantis",
  "total_playgrounds": "Total de parques infantis",
  "inventoried_playgrounds": "Parques infantis inventariados",
  "general_accessibility_and_information": "Acessibilidade e informações gerais",
  "detailed_accessibility": "Acessibilidade pormenorizada",
  "helsingborg_city_has_reviewed": "A cidade de Helsingborg analisou a disponibilidade de 26 dos seus 200 parques infantis. Aí se obtêm informações sobre estacionamento, disponibilidade de casas de banho e várias áreas de recreio.",
  "for_detailed_accessibility_information": "Para obter informações pormenorizadas sobre a acessibilidade, seleccione o respetivo parque infantil.",
  "amount_of_accessible_toilets": "Número de casas de banho acessíveis",
  "mapView_filter_elevator": "Elevador",
  "mapView_filter_reception_infodesk": "Receção",
  "mapView_filter_playground": "Parque infantil",
  "mapView_filter_garden": "Jardim",
  "mapView_filter_shop": "Loja",
  "mapView_filter_restaurant": "Restaurante",
  "mapView_filter_conference_room": "Sala de conferências",
  "mapView_filter_auditorium": "Auditório",
  "jarfalla_municipality_has_reviewed": "O município de Järfälla analisou a acessibilidade de 55 parques infantis, incluindo 2 parques infantis de aventura que promovem a criatividade. Aqui pode obter informações sobre estacionamento, acessibilidade das casas de banho e as diferentes áreas de jogo.",
  "mapView_filter_vac": "Alteração do nível do pavimento",
  "languages": "Língua",
  "english": "Inglês",
  "swedish": "Sueco",
  "danish": "Dinamarquês",
  "german": "Alemão",
  "spanish": "Espanhol",
  "french": "Francês",
  "norwegian": "Norueguês",
  "portuguese": "Português",
  "ukrainian": "Ucraniano",
  "accessibility_room": "Sala de acessibilidade",
  "pool": "Piscina",
  "spa": "Spa",
  "bar_restaurant": "Bar/Restaurante",
  "bathrooms": "Casas de banho",
  "shop": "Loja",
  "exhibition": "Exposição",
  "rate_your_experience": "Você pode nos ajudar a melhorar🧑‍🦼🦮🦻? 🙏",
  "did_you_find_the_accessibility_information_you_needed": "Encontrou as informações de acessibilidade que precisava?",
  "how_satisfied_are_you_with_your_experience": "Qual é o seu nível de satisfação com a sua experiência com o nosso widget de acessibilidade?",
  "what_do_you_like_the_most": "O que gostou mais?",
  "sorry_to_hear_that": "Lamento ouvir isso! Por favor, comente o porquê, para enviar sua classificação",
  "we_would_love_to_hear_your_suggestions": "Gostaríamos de ouvir as suas sugestões",
  "submit": "Enviar",
  "book_now": "Reservar agora",
  "very_bad": "Muito mal",
  "bad": "Ruim",
  "neutral": "Neutro",
  "good": "Bom",
  "great": "Ótimo"
}