import PropertyItem from "./PropertyItem";
import {Grid as MuiGrid} from "@mui/material";
import React, {FC, useState} from "react";
import {Property} from "../../../Models/Property";
import styled from "styled-components";
import { useFetchProperties } from "../../../hooks/useFetchProperties";
import SkeletonListOfItems from "./SkeletonListOfItems";
import MissingInformation from "./MissingInformation";
// import LoadingBlock from "./LoadingBlock";
import { A11yStandardType } from "../../../Models/utils";
import DetailedPropertyInfoItem from "./DetailedPropertyInfoItem";
import { Mixpanel } from "../../../Mixpanel";
import { MetricMessage } from "../../../utils/metrics";
import { AccessibilityWidgetInfoContainer } from "../../../components/AccessibilityWidgetInfoContainer";
import useWindowDimensions from "../../../hooks/useWindowDementions";
import {getTextByCurrentLanguage} from "../../../utils/utils";

const Grid = styled(MuiGrid)`
  height: 57vh;
  margin-top: 15px;
  @media (max-width: 768px) {
    height: 100%
  }
`

type PropsType = {
  a11yStandard: A11yStandardType,
  property: Property,
  fullscreen: boolean,
  displayShops: boolean,
  buildingAreaId: string | null,
}

const TabContentProperties: FC<PropsType> = ({
  a11yStandard,
  property,
  fullscreen,
  displayShops,
  buildingAreaId
}) => {

  const [propertyItem, setPropertyItem] = useState<Property | undefined>();
  const [showDetailedInfoPage, setShowDetailedInfoPage] = useState(false);
  const { height } = useWindowDimensions();

  const {
    propertyItems,
    loading,
    noItemsreceived,
    //infiniteScrollloading,
    errorFetchingItems,
    lastItemRef,
  } = useFetchProperties(property.id);

  const fetchPropertyItemData = (item: Property) => {
    Mixpanel.track(`${MetricMessage.accessed_single_unit}`, {
      unitId: item.id,
      a11yStandard: item.a11y_standard,
      unitType: null,
      unitStandardCategory: item.category,
      unitStandardSubCategory: item.sub_category,
    });
    setPropertyItem(item);
    setShowDetailedInfoPage(true);
  }

  return (
    <AccessibilityWidgetInfoContainer
      showMap={false}
      height={height}
      fullscreen={fullscreen}
      displayShops={displayShops}
      buildingAreaId={buildingAreaId}
      a11yStandard={a11yStandard}
      displayedOnListTab={true}
      displayedOnListTabDetailedPage={!!showDetailedInfoPage && !!propertyItem}
    >
      <SkeletonListOfItems visible={loading} />
      {(noItemsreceived && propertyItems && propertyItems.length === 0 && !loading) ? (
        <MissingInformation />
      ) : ((propertyItems && propertyItems.length > 0) && !errorFetchingItems && !showDetailedInfoPage) ? (
        <>
          <Grid container spacing={0}>
            {(propertyItems && propertyItems.length > 0) ? propertyItems.map((item, idx) => (
              <PropertyItem
                id={item.id}
                key={idx}
                logo={item.logo}
                name={getTextByCurrentLanguage(item.name)}
                lastItemRef={(propertyItems.length === idx + 1) ? lastItemRef : null}
                onFetchPropertyItemData={(id) => {                  
                  const foundItem = propertyItems.find((p) => p.id === id)
                  if (foundItem) {
                    fetchPropertyItemData(foundItem)
                  }
                }}
                lastItem={propertyItems.length === idx + 1}
              />
            )) : null
          }
          </Grid>
          {/* <LoadingBlock visible={infiniteScrollloading}/> Commented it out, not working, because we set height: 56vh on Grid, need fix though*/}
        </>
      ) : (showDetailedInfoPage && propertyItem) ?
        <DetailedPropertyInfoItem
          type={propertyItem.a11y_standard}
          a11yStandard={a11yStandard}
          propertyItem={propertyItem}
          setShowDetailedInfoPage={setShowDetailedInfoPage}
        />
      : null}
    </AccessibilityWidgetInfoContainer>
  )
}

export default TabContentProperties
