import styled from "styled-components";
import {A11Y_EXCLUDE_INFO_BLOCK, a11yStandardKeys} from "../constants/constants";
import { A11yStandardType } from "../Models/utils";

type Props = {
  showMap: boolean;
  height: number;
  fullscreen: boolean;
  buildingAreaId: string | null;
  displayShops?: boolean;
  a11yStandard: A11yStandardType;
  displayedOnListTab?: boolean;
  isBuildingArea?: boolean;
  displayedOnListTabDetailedPage?: boolean;
  informationBlockStatusData?: boolean;
}

const calcHeight = (
  height: number, 
  fullscreen: boolean, 
  displayShops: boolean, 
  buildingAreaId: string | null, 
  a11yStandard: A11yStandardType,
  displayedOnListTab: boolean,
  isBuildingArea?: boolean,
  tabletViewPort?: boolean,
  informationBlockStatusData?: boolean,
) => {
  if (height > 800 && !fullscreen && !tabletViewPort) {
    return '500px';
  } else if (fullscreen) {
    let srnHeight = height - 170;
    if (displayShops) {
      // - minus height unit tabs height
      srnHeight = srnHeight - 50;
    }
    if (isBuildingArea) {
      // - minus height buildingarea tabs
      srnHeight = srnHeight - 50;
    }

    if (Object.values(A11Y_EXCLUDE_INFO_BLOCK).includes(a11yStandard.key)) {
      // + plus if information block is not there
      srnHeight = srnHeight + 60;
    }

    // If the widget has an Information Block then we minus the total height
    if (buildingAreaId && informationBlockStatusData) {
      srnHeight = srnHeight - 50;
    }

    if (!buildingAreaId && !informationBlockStatusData) {
      srnHeight = srnHeight + 50;
    }

    if (displayedOnListTab && !informationBlockStatusData) {
      // - minus if component is displayed on tab with units/properties
      srnHeight = srnHeight - 60;
    }
    return srnHeight + 'px';
  } else {
    return '58vh'
  }
}

export const AccessibilityWidgetInfoContainer = styled.div<Props>`
  display: grid;
  grid-template-columns: ${({ showMap }) => showMap ? "0.978fr 2fr" : "repeat(auto-fit, minmax(269px, 1fr))"};
  grid-template-rows: ${(props) => calcHeight(
    props?.height, 
    props?.fullscreen, 
    !!props?.displayShops, 
    props?.buildingAreaId, 
    props?.a11yStandard, 
    !!props?.displayedOnListTab,
    props?.isBuildingArea,
    false,
    props?.informationBlockStatusData,
  )};
  gap: 1rem;
  margin-top: 15px;
  @media (min-width: 769px) and (max-width: 942px) {
    height: 100% !important;
    grid-template-columns:  ${({showMap, displayedOnListTab}) => showMap ? "0.6fr 1fr" :displayedOnListTab ? "repeat(auto-fit, minmax(269px, 1fr))" : "repeat(2, 1fr)"};
    grid-template-rows: ${(props) => ((props?.displayedOnListTab && !props?.displayedOnListTabDetailedPage) || props.showMap) ? calcHeight(
      props?.height, 
      props?.fullscreen, 
      !!props?.displayShops, 
      props?.buildingAreaId, 
      props?.a11yStandard, 
      !!props?.displayedOnListTab,
      props?.isBuildingArea,
      true,
      props?.informationBlockStatusData,
    ) : "1fr"};
    & > div:nth-child(1) {
      // max-height: 650px;
      grid-area: 1 / 1 / 2 / 2;
    }
    & > div:nth-child(2) {
      // max-height: 650px;
      grid-area: 1 / 2 / 2 / 3;
    }
    & > div:nth-child(3) {
      grid-area: 2 / 1 / 3 / 3;
    }
    margin-bottom: 50px
  }
  
  @media (max-width: 768px) {
    display: ${({ displayedOnListTab }) => displayedOnListTab ? "block" : "grid"};
    height: 100%;
    grid-template-columns: unset;
    grid-template-rows: ${({ showMap }) => showMap ? "1.2fr 400px" : "1fr"};
  }
`;