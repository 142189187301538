import {LanguagesType} from "../constants/constants";

export const MetricMessage = {
  // Core widget action metrics
  accessed_widget_button: "Accessed widget",
  closed_widget_modal: "Closed Widget Dialog",
  closed_widget_window: "Closed Widget Dialog through cross button",
  verified_by_handiscover_click: "Clicked on Verified by Handiscover",
  changed_language: {
    [LanguagesType.English]: "Changed Widget Language for English",
    [LanguagesType.Swedish]: "Changed Widget Language for Swedish",
    [LanguagesType.Danish]: "Changed Widget Language for Danish",
    [LanguagesType.German]: "Changed Widget Language for German",
    [LanguagesType.Spanish]: "Changed Widget Language for Spanish",
    [LanguagesType.French]: "Changed Widget Language for French",
    [LanguagesType.Norwegian]: "Changed Widget Language for Norwegian",
    [LanguagesType.Portuguese]: "Changed Widget Language for Portuguese",
    [LanguagesType.Ukrainian]: "Changed Widget Language for Ukrainian",
  },
  // Widget navigation related metrics
  accessed_building_area: "Accessed Building Area",
  accessed_property_tab: "Accessed Property",
  accessed_unit_list: "Accessed Unit List",
  accessed_single_unit: "Accessed Single Unit",
  accessed_map_view: "Accessed map view",
  closed_map_view: "Closed map view",
  // Specific features related metrics
  picture_navigation: "Navigated on Pictures",
  clicked_fullscreen_picture: "Clicked on fullscreen picture",
  clicked_book_now: "Clicked on Book now",
  accessibilities_icons_click: "Clicked on Accessibility Icon",
  changed_map_filter: "Changed map filter", // filter as a param: type { type: selectedType }
  selected_map_floor: "Selected map floor", //floor as a param: { floor: selectedFloor }
  map_pin_click: "Clicked on map pin", // type and unit id as params {type: selectedType, unitId: ClickedMapPoiID}
  scroll_percentage: "Scroll Percentage",
  // procentage_viewed: "Procentage Viewed"
  selected_disability_type: "Selected disability type",
  feedback_data: 'Feedback data',
  feedback_shown: "Feedback shown"
};
