import { FC } from "react";
import styled from "styled-components";
import { borderRadius } from "../constants/constants";

type PropsType = {
  fullscreen: boolean
}

const TabLayout = styled.div<PropsType>`
  border-radius: 0 0 ${borderRadius}px 0px;
  padding: 20px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 10px;
  }
  @media (min-width: 769px) and (max-width: 942px) {
    //height: 83vh;
    height: ${({ fullscreen }) => fullscreen ? "auto" : "83vh"};
    max-height: calc(100vh - 102px);
  }
`;
export const TabContentLayout: FC<{ fullscreen: boolean }> = ({ children, fullscreen }) => {
  return <TabLayout fullscreen={fullscreen} id="tab-layout" className="fixed-page-size">{children}</TabLayout>;
};
