import React, {FC, useMemo, SetStateAction, Dispatch, useState, useEffect, useRef} from "react";
import {Property} from "../../../Models/Property";
import {AccessibilityUnit} from "../../../Models/AccessibilityUnits";
import {PropertyAccessibilityImages} from "../../../Models/PropertyAccessibilityImages";
import { useFetchDetailedUnitData } from "../../../hooks/useFetchDetailedUnitData";
import MissingInformation from "./MissingInformation";
import SkeletonItemInfo from "./SkeletonItemInfo";
import HighlightedInfoContainer from "./HighlightedInfoContainer";
import ItemImageContainer from "./ItemImageContainer";
import GeneralItemInfoContainer from "./GeneralItemInfoContainer";
import {METRIC_TRIGGERED_ON} from "../../../constants/constants";
import { ReactImageGalleryItem } from "react-image-gallery";
import { ImageGalleryRenderer } from "../../../components/image-gallery/ImageGalleryRenderer";
import { A11yStandardType } from "../../../Models/utils";
import {Mixpanel} from "../../../Mixpanel";
import {MetricMessage} from "../../../utils/metrics";
import {
  getElementByIdFromIframe,
  getFilteredPropertyImagesByDisabilityType, getTextByCurrentLanguage, scrollToUnitByCurrentImage,
  sortPropertyImages
} from "../../../utils/utils";
import {useStorageData} from "../../../hooks/useStorageData";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";

type PropsType = {
  type: string
  a11yStandard: A11yStandardType
  property: Property
  unitItem: AccessibilityUnit
  // isPropertyList: boolean
  // isUnitList: boolean
  // lastItemRef: any
  // onFetchPropertyItemData: (item: any, openItemTab: boolean) => void
  setShowDetailedInfoPage: Dispatch<SetStateAction<boolean>>
}

const DetailedUnitInfoItem: FC<PropsType> = ({
  type,
  a11yStandard,
  property,
  unitItem,
  setShowDetailedInfoPage
}) => {
  const theme = useTheme()
  const disabilityTypes = useStorageData('disability_types')
  const [currentImageData, setCurrentImageData] = useState({index: 0, accessibility_unit: '', name: ''})
  const [imagesByAccessibilityUnit, setImagesByAccessibilityUnit] = useState<PropertyAccessibilityImages[]>([])
  const [arrayOfPropertyItemAccessibilityUnits, setArrayOfPropertyItemAccessibilityUnits] = useState<AccessibilityUnit[]>([unitItem])
  const accessibilityUnitRefs = useRef<any>([])
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const {
    loading,
    propertyItemAccessibilityUnits,
    itemAccessibilites,
    propertyItemImages, 
    unitItemFloors,
  } = useFetchDetailedUnitData(property, unitItem, a11yStandard);

  // Auto-scrolling for Detailed information list
  useEffect(() => {
    if (isDesktop && accessibilityUnitRefs.current.length > 0) {
      scrollToUnitByCurrentImage(
        accessibilityUnitRefs,
        currentImageData,
        'detailed-information'
      )
    }
  }, [accessibilityUnitRefs.current.length, currentImageData])


  useEffect(() => {
    if (propertyItemAccessibilityUnits.length > 0) {
      const relatedAccessibilityUnits = getRelatedAccessibilityUnits(propertyItemAccessibilityUnits)
      if (relatedAccessibilityUnits.length > 0) {
        setArrayOfPropertyItemAccessibilityUnits(
          arrayOfPropertyItemAccessibilityUnits.concat(relatedAccessibilityUnits)
        )
      }
    }
  }, [propertyItemAccessibilityUnits])

  const getRelatedAccessibilityUnits = (units: AccessibilityUnit[]) => {
    if (units.length > 0) {
      return propertyItemAccessibilityUnits.filter((item) => (
        item.related_to && item.related_to.some((related) => related === unitItem.id)
      ))
    }

    return []
  }

  useEffect(() => {
    if (imagesByAccessibilityUnit.length === 0 && propertyItemImages.length > 0 && unitItem && propertyItemAccessibilityUnits.length > 0) {
      let unitImages = propertyItemImages.filter((img) => img.accessibility_unit === unitItem.id)
      const relatedAccessibilityUnits = getRelatedAccessibilityUnits(propertyItemAccessibilityUnits)
      if (relatedAccessibilityUnits.length > 0) {
        relatedAccessibilityUnits.forEach((item) => {
          const images = propertyItemImages.filter((img) => img.accessibility_unit === item.id)
          if (images.length > 0) {
            unitImages = unitImages.concat(images)
          }
        })
      }
      setImagesByAccessibilityUnit(unitImages)
    }
  }, [propertyItemImages, unitItem, propertyItemAccessibilityUnits])

  useEffect(() => {
    if (!loading) {
      const rootContent = getElementByIdFromIframe('root-content')
      rootContent?.scrollTo(0,0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loading])

  const handlerCurrentImage = (currentIndex: number, triggerMixpanel = true): void => {
    const sortedPropertyImages = sortPropertyImages(imagesByAccessibilityUnit, propertyItemAccessibilityUnits)
    if (sortedPropertyImages.length > 0) {
      let filteredPropertyImages = getFilteredPropertyImagesByDisabilityType(sortedPropertyImages, disabilityTypes, itemAccessibilites)
      if (filteredPropertyImages.length > 0 && filteredPropertyImages[currentIndex]) {
        const description = propertyItemAccessibilityUnits?.find((el) => (
          el.id === filteredPropertyImages[currentIndex].accessibility_unit
        ))
        setCurrentImageData({
          index: currentIndex,
          accessibility_unit: filteredPropertyImages[currentIndex].accessibility_unit,
          name: description ? getTextByCurrentLanguage(description.name) : ''
        })
        if (triggerMixpanel) {
          Mixpanel.track(`${MetricMessage.picture_navigation}`, {
            triggeredOn: METRIC_TRIGGERED_ON.PROPERTY_PAGE_SINGLE_UNIT
          });
        }
      }
    }

    if (imagesByAccessibilityUnit.length > 0) {
      setCurrentImageData({
        index: currentIndex,
        accessibility_unit: imagesByAccessibilityUnit[currentIndex].accessibility_unit,
        name: getTextByCurrentLanguage(unitItem?.name) ? getTextByCurrentLanguage(unitItem?.name) : ''
      })
    }
    if (triggerMixpanel) {
      Mixpanel.track(`${MetricMessage.picture_navigation}`, {
        triggeredOn: METRIC_TRIGGERED_ON.PROPERTY_PAGE_SINGLE_UNIT
      });
    }
  }

  const getImageDescription = (accessibilityUnitId: string, accessibilityUnits: AccessibilityUnit[]) => {
    let name = ''
    if (accessibilityUnitId && accessibilityUnits.length > 0) {
      const findCurrentAccessibilityUnit = accessibilityUnits
        .find((item) => item.id === accessibilityUnitId)
      if (findCurrentAccessibilityUnit) {
        name = getTextByCurrentLanguage(findCurrentAccessibilityUnit.name)
      }
    }
    return name
  }
  
  const itemCarouselImages: ReactImageGalleryItem[] = useMemo(() => {
    if (imagesByAccessibilityUnit && imagesByAccessibilityUnit.length > 0) {
      handlerCurrentImage(0, false)
      const sortedPropertyImages = sortPropertyImages(imagesByAccessibilityUnit, propertyItemAccessibilityUnits)
      const filteredPropertyImages = getFilteredPropertyImagesByDisabilityType(sortedPropertyImages, disabilityTypes, itemAccessibilites)
      if (filteredPropertyImages) {
        return filteredPropertyImages.map<ReactImageGalleryItem>(
          ({ thumbnails, description, accessibility_unit }) => ({
            fullscreen: thumbnails.large,
            original: thumbnails.medium,
            thumbnail: thumbnails.small,
            renderItem: ImageGalleryRenderer(),
            thumbnailClass: "handiscover-thumbnail-class",
            imageDescription: description,
            description: getImageDescription(accessibility_unit, propertyItemAccessibilityUnits),
          })
        );
      }
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesByAccessibilityUnit, propertyItemAccessibilityUnits, disabilityTypes]);

  return (
    <React.Fragment>
      {loading ? (
        <SkeletonItemInfo />
        ) : (
          <>
            {unitItem && itemAccessibilites && itemCarouselImages && propertyItemAccessibilityUnits ? (
              <>
                <HighlightedInfoContainer
                  type={type}
                  property={property}
                  name={getTextByCurrentLanguage(unitItem.name)}
                  propertyItemFloors={unitItemFloors}
                  itemAccessibilites={itemAccessibilites}
                  a11yStandardOrUnitType={unitItem.type}
                  propertyItemAccessibilityUnits={propertyItemAccessibilityUnits}
                  setListPage={setShowDetailedInfoPage}
                  a11yStandard={a11yStandard}
                  currentImageData={currentImageData}
                />            
                <ItemImageContainer
                  itemCarouselImages={itemCarouselImages}
                  onSlide={(currentIndex) => handlerCurrentImage(currentIndex)}
                />
                <GeneralItemInfoContainer
                  arrayOfPropertyItemAccessibilityUnits={arrayOfPropertyItemAccessibilityUnits}
                  itemAccessibilites={itemAccessibilites}
                  locatedOn={{
                    type: type,
                    triggeredOnPage: METRIC_TRIGGERED_ON.PROPERTY_PAGE_SINGLE_UNIT,
                    section: METRIC_TRIGGERED_ON.GENERAL_INFO_TABLE
                  }}
                  refs={accessibilityUnitRefs}
                />
              </>
              ) : (
                <MissingInformation />
              )}
          </>
        )
      }
    </React.Fragment>
  )
}

export default DetailedUnitInfoItem
