import { ReactImageGalleryItem } from "react-image-gallery";
import styled from "styled-components";

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ImageContainer = styled.img`
  height: calc(100% - 32px);
  display: inline;
  @media (min-width: 769px) and (max-width: 942px) {
    height: 480px;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    height: 500px;
    object-fit: cover;
  }
`;

const ImageDescriptionContainer = styled.div`
  background: #EDF5FF;
  color: #121619;
  line-height: 1;
  font-size: 12px;
  padding: 10px 0;
  white-space: normal;
  text-align: left;
  height: 12px;
  max-height: 12px;
  width: 100%;
  @media (max-width: 768px) {
    height: unset;
  }
  @media (min-width: 769px) and (max-width: 942px) {
    height: 14px;
  }
`

const ImageDescription = styled.span`
  padding-left: 15px;
`;

export const ImageGalleryRenderer = () => (item: ReactImageGalleryItem) => {
    return (
      <>
        <ImageWrapper>
          <ImageContainer alt="" aria-hidden={true} src={item.original}/>
          <ImageDescriptionContainer className='image-gallery-custom-description'>
            <ImageDescription>
              {item.description}
            </ImageDescription>
          </ImageDescriptionContainer>
        </ImageWrapper>
      </>
    );
  };
