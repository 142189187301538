import {a11yStandardKeys, LevelSizeMap, A11Y_EXCLUDE_INFO_BLOCK} from "../../../constants/constants";
import {InfoCell, SizeContainer} from "../../../StyledWrappers/Wrappers";
import React, {FC, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {Grid, Skeleton, SvgIcon, Tooltip, useMediaQuery} from "@mui/material";
import {A11yStandardType} from "../../../Models/utils";
import {useTranslation} from "react-i18next";
import {AccessibilityUnitLevel} from "../../../Models/AccessibilityLevel";
import {AccessibilityUnit} from "../../../Models/AccessibilityUnits";
import {Property} from "../../../Models/Property";
import {getAccessibilityUnitLevelNumber} from "../../../utils/utils";
import FloorsSvg from "../../../assets/svg/FloorsSvg";
import HotelRoomSvg from "../../../assets/svg/HotelRoomSvg";
import AccessibleParkingSvg from "../../../assets/svg/AccessibleParkingSvg";
import {Box} from "@mui/system";
import Typography from "@mui/material/Typography";
import AccessibilityToiletSvg from "../../../assets/svg/AccessibilityToiletSvg";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import {useTheme} from "@mui/material/styles";
import AccessibleRoomSvg from "../../../assets/svg/AccessibleRoomSvg";
import {TabsContext} from "../../../components/MainPage";

const StyledGridWrapper = styled(Grid)`
  display: unset;
`;

const Plug = styled.div`
  @media (max-width: 942px) {
    display: none;
  }
`

const StyledGrid = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(169px,1fr));
  ${(props) => props.theme.breakpoints.down("md")} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr 1fr 1fr;
    .MuiBox-root {
      justify-content: center;
    }
  }
  gap: 1rem;
  padding-top: 24px !important;
`;

const StyledDiv = styled.div`
  margin-top: -9px !important;
`;

type DisplayData = {
  order: number,
  title: string,
  value: number | undefined,
  index?: number,
  icon?: any
}

type PropsType = {
  a11yStandard: A11yStandardType
  property: Property
  rooms: AccessibilityUnit[]
  totalShop: number | undefined
  parkingSpaces:  AccessibilityUnitLevel[] | undefined
  toiletRoom: AccessibilityUnitLevel[] | undefined,
  onGetStatusData: (status: boolean) => void,
  units: AccessibilityUnit[],
  onSetTab: (e: any, tab: number) => void
}

const InformationBlock: FC<PropsType> = ({
  a11yStandard,
  property,
  rooms,
  totalShop,
  parkingSpaces,
  toiletRoom,
  onGetStatusData,
  units,
  onSetTab
}) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation()
  const [displayData, setDisplayData] = useState<DisplayData[] | null>(null)
  const [ ,setField] = useContext(TabsContext);

  const floorData: DisplayData = {
    order: 0,
    title: t("hotel_floors"),
    value: property ? property.amount_of_floor_levels ? property.amount_of_floor_levels : 0 : undefined,
    icon: <FloorsSvg stroke={'#636363'} height={30} width={30}/>
  }

  const totalRoomsData: DisplayData = {
    order: 0,
    title: t("hotel_totalRooms"),
    value: property ? property.additional_information.property_rooms ? Number(property.additional_information.property_rooms) : 0 : undefined,
  }

  const allergyRoomsData: DisplayData = {
    order: 0,
    title: t("hotel_allergyRooms"),
    value: property ? property.additional_information.allergy_rooms ? Number(property.additional_information.allergy_rooms) : 0 : undefined,
  }

  const accessibleRoomsData: DisplayData = {
    order: 0,
    title: t("hotel_accessibleRooms"),
    value: rooms.length > 0 ? rooms.filter((i) => i.is_accessible_unit).length : undefined,
    icon: <AccessibleRoomSvg fill={'#636363'} height={25} width={25}/>,
    index: 2,
  }

  const parkingData: DisplayData = {
    order: 0,
    title: t("shopping_mall_accessibleParking"),
    value: parkingSpaces && units ? getAccessibilityUnitLevelNumber(parkingSpaces, units) : undefined,
    icon: <AccessibleParkingSvg fill={'#636363'} height={23} width={23}/>
  }

  const toiletsData: DisplayData = {
    order: 0,
    title: t('amount_of_accessible_toilets'),
    value: toiletRoom && units ? getAccessibilityUnitLevelNumber(toiletRoom, units) : undefined,
    icon: <AccessibilityToiletSvg fill={'#636363'}/>,
    index: 6,
  }

  const inventoriedShops: DisplayData = {
    order: 0,
    title: t("shopping_mall_totalShops"),
    value: totalShop,
    icon: <ShoppingBasketIcon fill={'#636363'}/>,
    index: 1,
  }

  const defaultData: DisplayData[] = [
    {...floorData, order: 1},
    {...totalRoomsData, order: 2},
    {...accessibleRoomsData, order: 3},
    {...allergyRoomsData, order: 4}
  ]

  const shoppingMallData: DisplayData[] = [
    {...floorData, order: 1},
    {...parkingData, order: 2},
    {...toiletsData, order: 3},
    {...inventoriedShops, order: 4}
  ]

  const museumData: DisplayData[] = [
    {...floorData, order: 1},
    {...parkingData, order: 2},
    {...toiletsData, order: 3},
  ]

  const hotelData: DisplayData[] = [
    {...floorData, order: 1},
    {...accessibleRoomsData, order: 2},
    {...parkingData, order: 3}
  ]

  const officeData: DisplayData[] = [
    {...floorData, order: 1},
    {...parkingData, order: 2},
    {...toiletsData, order: 3},
  ]

  const outdoorPlaygroundData: DisplayData[] = [
    {...parkingData, order: 1},
    {order: 2, title: '', value: 0},
    {order: 3, title: '', value: 0},
  ]

  const updateInformation = () => {
    let data: DisplayData[] = defaultData

    if (a11yStandard.key === a11yStandardKeys.shoppingMall) {
      data = shoppingMallData
    }

    if (a11yStandard.key === a11yStandardKeys.museum) {
      data = museumData
    }

    if (a11yStandard.key === a11yStandardKeys.hotel) {
      data = hotelData
    }

    if (a11yStandard.key === a11yStandardKeys.office) {
      data = officeData
    }

    if (a11yStandard.key === a11yStandardKeys.outdoorPlayground) {
      data = outdoorPlaygroundData
    }

    let sortedValues: DisplayData[] = []
    const positiveValues = data.filter((i) => i.value && i.value > 0)
    sortedValues = [...positiveValues]
    const undefinedValues = data.filter((i) => i.value === undefined)
    sortedValues = [...sortedValues, ...undefinedValues]
    const zeroValues = data.filter((i) => i.value === 0)
    sortedValues = [...sortedValues, ...zeroValues]

    const zeroValueIndex = sortedValues.findIndex((item) => item.value === 0);
    if (zeroValueIndex !== -1) {
      const zeroValueItem = sortedValues.splice(zeroValueIndex, 1)[0];
      sortedValues.push(zeroValueItem)
    }

    if (sortedValues.length > 3 && sortedValues[sortedValues.length - 1].value === undefined) {
      sortedValues.pop()
    }
    if (sortedValues.length > 3 && sortedValues[sortedValues.length - 1].value === 0) {
      sortedValues.pop()
    }

    return sortedValues
  }

  useEffect(() => {
    const newData = updateInformation()
    setDisplayData(newData)
  }, [property, a11yStandard, rooms, parkingSpaces, totalShop, toiletRoom]);

  useEffect(() => {
    // If our block takes up space in the widget then we send onGetStatusData(true)
    if (displayData && displayData.length > 0) {
      const havePositiveData = displayData.some(i => {
        if (i.value) {
          return Math.sign(i.value)
        }
        return typeof i.value === 'undefined';
      })
      onGetStatusData(havePositiveData)
    }
  }, [displayData]);

  return (
    <>
      {(Object.values(A11Y_EXCLUDE_INFO_BLOCK).includes(a11yStandard.key)) ? <StyledDiv /> :
        <StyledGridWrapper
          container
          spacing={3}
        >
          <StyledGrid item>
            {displayData ? (displayData.map((item, index) => {
              if (typeof item.value === 'undefined') {
                return (
                  <Skeleton
                    key={index}
                    height={48.39}
                    variant="rectangular"
                    animation="wave"
                    style={{borderRadius: 0}}
                  />
                )
              }

              if (typeof item.value === 'number' && item.value === 0) {
                return <Plug key={index}/>
              }
              return (
                <Tooltip
                  key={index}
                  title={item.title}
                  enterTouchDelay={sm ? 0 : undefined}
                  PopperProps={{sx: { top: '-18px !important' }}}
                  disableHoverListener
                  arrow
                >
                  <InfoCell
                      key={index}
                      display={'flex'}
                      alignItems={'center'}
                      onClick={() => item.index ? onSetTab('', item.index) : null}
                      style={{cursor: item.index ? 'pointer' : 'default'}}
                  >
                      <span className={'number'}>{item.value}</span>
                      <Box className={'label'} display={'flex'} alignItems={'center'}>
                        <Box display={'flex'} justifyContent={'center'}>{item.icon}</Box>
                        {!sm ? (<Box ml={1}><span>{item.title}</span></Box>) : null}
                      </Box>
                  </InfoCell>
                </Tooltip>
              )
            })) : null}
          </StyledGrid>
        </StyledGridWrapper>
      }
    </>
  )
}

export default InformationBlock
