import styled from "styled-components";
import { A11yStandardType } from "../Models/utils";
import { WidgetSettings } from "../Models/WidgetSettings";
import {FC} from "react";
import {CircularProgress} from "@mui/material";
import Icons from "../components/Icons";
import iconImg from "../images/icons/person-icon.svg";
import {Box} from "@mui/system";

export const DialogStylesWrapper = styled.div`
  .MuiListItemText-root > .MuiTypography-root, .MuiCheckbox-root {
    font-size: 16px;
  }

  .MuiMenuItem-root .MuiCheckbox-root .MuiSvgIcon-root {
    height: 21px !important;
    width: 21px !important;
  }

  :root {
    --handiscover-transition-speed: 500ms;
    --handiscover-translate-size-positive: 110%;
    --handiscover-translate-size-negative: -110%;
  }

  body .handiscover-accessibility-widget {
    margin: 0;
    font-family: "Roboto", "Manrope", sans-serif !important;
    /* debug only */
    /* background-color: blue; */
    /* for release */
    background-color: transparent;
    z-index: 14000 !important;
  }

  div {
    font-family: "Roboto", "Manrope", sans-serif !important;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
  }

  .handiscover-accessibility-widget strong {
    font-family: 'Roboto' !important;
  }

  .handicover-widget-entrypoint > button {
    font-family: Helvetica, sans-serif !important;
  }

  .handiscover-accessibility-widget p {
    font-size: inherit;
    font-family: 'Roboto' !important;
    color: black !important;
  }

  .handiscover-accessibility-widget a,
  .handiscover-accessibility-widget a:hover {
    /* font-size: 1rem !important; */
    color: rgb(94, 179, 232) !important;
    font-family: 'Roboto' !important;
  }

  .handiscover-accessibility-widget * {
    box-sizing: unset !important;
  }

  .handiscover-accessibility-widget table {
    border-collapse: collapse !important;
    margin: 0 !important;
    border: none !important;
    width: 100% !important;
  }

  .handiscover-accessibility-widget td, .handiscover-accessibility-widget th {
    margin: 0 !important;
    border: none !important;
    font-family: "Roboto", "Manrope", sans-serif;
  }

  .handiscover-accessibility-widget thead > tr > th {
    padding: 1px 1px 1px 16px !important;
    font-family: 'Roboto' !important;
  }

  //.handiscover-accessibility-widget tbody > tr > th {
  //  padding: 0 !important;
  //}

  .handiscover-accessibility-widget tbody > tr > td {
    padding: 0 !important;
    border: none !important;
  }

  .handiscover-accessibility-widget-path path {
    fill: #a6adaf;
  }

  .handiscover-accessibility-widget-path.selected path {
    fill: black;
  }

  .handiscover-scrollbar-overwrite {
    width: 100%;
    overflow-x: hidden;
  }

  .handiscover-scrollbar-overwrite::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #ffffff;
  }

  .handiscover-scrollbar-overwrite::-webkit-scrollbar {
    width: 8px;
    border-radius: 20px;
    background-color: #f5f5f5;
  }

  .handiscover-scrollbar-overwrite::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #bdbdbd;
  }

  .fixed-page-size {
    overflow-x: hidden;
    overflow-y: visible;
    /*max-height: calc(100vh - 218px);*/
    background-color: #F2F4F8;
  }

  .fixed-page-size::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #F2F4F8;
  }

  .fixed-page-size::-webkit-scrollbar {
    width: 8px;
    border-radius: 20px;
    background-color: #f5f5f5;
  }

  .fixed-page-size::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #bdbdbd;
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    .fixed-page-size {
      overflow-x: hidden;
      overflow-y: visible;
      /*max-height: calc(100vh - 188px);*/
    }
  }

  @media screen and (max-width: 600px) {
    .fixed-page-size {
      overflow-x: hidden;
      overflow-y: visible;
      /* max-height: calc(100vh - 150px); */
    }
  }

  .handiscover-accessibility-widget .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .handiscover-accessibility-widget .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  /* styling overwrites for react-image-gallery */

  .handiscover-accessibility-widget .handiscover-thumbnail-class {
    width: 60px;
    height: 60px;
  }

  .handiscover-accessibility-widget .image-gallery-content {
    border: 1px solid #E3E5E5;
  }
  .handiscover-accessibility-widget .fullscreen {
    border: none !important;
    height: 100% !important;
  }

  .handiscover-accessibility-widget .fullscreen .image-gallery-swipe {
    height: 100% !important;
  }

  .handiscover-accessibility-widget .image-gallery-thumbnails {
    padding: 4px 0;
  }


  .handiscover-accessibility-widget .img-gallery-overflow-hidden .image-gallery-slides > .image-gallery-slide,
  .handiscover-accessibility-widget .img-gallery-overflow-hidden {
    overflow: hidden !important;
  }

  .handiscover-accessibility-widget .image-gallery-thumbnail {
    border-radius: 0px !important;
  }

  .handiscover-accessibility-widget .image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: default;
  }

  .handiscover-accessibility-widget .image-gallery-thumbnail:hover {
    cursor: pointer;
    background-color: transparent !important;
  }

  .handiscover-accessibility-widget .image-gallery-thumbnail:focus {
    background-color: transparent !important;
  }
  
  .handiscover-accessibility-widget .image-gallery-thumbnail .image-gallery-thumbnail-image {
    max-height: 60px;
    object-fit: cover;
  }

  .handiscover-accessibility-widget .image-gallery-slide-wrapper {
    height: calc(100% - 78px);
  }

  .handiscover-accessibility-widget .image-gallery-slide > div {
    display: flex;
    align-items: center;
  }

  .handiscover-accessibility-widget .image-gallery-slides {
    background-color: rgba(25, 118, 210, 0.08);
  }

  .handiscover-accessibility-widget .image-gallery-slide-wrapper .image-gallery-icon:hover {
    background-color: transparent !important;
  }

  .handiscover-accessibility-widget .image-gallery-slide-wrapper button > img {
    height: 21px !important;
    width: 21px !important;
  }

  .handiscover-accessibility-widget .image-gallery,
  .handiscover-accessibility-widget .image-gallery-slides,
  .handiscover-accessibility-widget .image-gallery-slide,
  .handiscover-accessibility-widget .image-gallery-slide > div,
  .handiscover-accessibility-widget .image-gallery-swipe,
  .handiscover-accessibility-widget .image-gallery-content {
    height: 100%;
    background: #FFF;
  }

  .handiscover-accessibility-widget .fullscreen .image-gallery,
  .handiscover-accessibility-widget .fullscreen .image-gallery-slides,
  .handiscover-accessibility-widget .fullscreen .image-gallery-slide,
  .handiscover-accessibility-widget .fullscreen .image-gallery-slide > div,
  .handiscover-accessibility-widget .fullscreen .image-gallery-swipe,
  .handiscover-accessibility-widget .fullscreen .image-gallery-content,
  .handiscover-accessibility-widget .fullscreen .image-gallery-thumbnails-wrapper {
    background: #000000 !important;
    height: 100%;
  }

  .handiscover-accessibility-widget .image-gallery-content.fullscreen img {
    height: 100%;
  }

  .handiscover-accessibility-widget .image-gallery-custom-description {
    font-family: "Roboto", "Manrope", sans-serif;
  }

  .handiscover-accessibility-widget .image-gallery-content.fullscreen .image-gallery-custom-description {
    display: none;
  }

  .handiscover-accessibility-widget .image-gallery-fullscreen-button {
    z-index: 101;
  }

  .handiscover-accessibility-widget .image-gallery-fullscreen-button:focus {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  @media screen and (max-width: 694px) {
    .handiscover-accessibility-widget .image-gallery-content.fullscreen > .image-gallery-slide-wrapper {
      height: calc(100% - 78px);
    }
    .handiscover-accessibility-widget .image-gallery-content.fullscreen > .image-gallery-slide-wrapper div {
      background: black !important;
      display: flex;
      justify-content: center;
    }
    .handiscover-accessibility-widget .image-gallery-content.fullscreen img {
      height: auto;
    }
    .handiscover-accessibility-widget .image-gallery-content.fullscreen .image-gallery-custom-description {
      display: none;
    }
    .handiscover-accessibility-widget .image-gallery .fullscreen,
    .handiscover-accessibility-widget .image-gallery-slides .fullscreen,
    .handiscover-accessibility-widget .image-gallery-slide .fullscreen,
    .handiscover-accessibility-widget .image-gallery-slide .fullscreen > div,
    .handiscover-accessibility-widget .image-gallery-swipe .fullscreen,
    .handiscover-accessibility-widget .image-gallery-content .fullscreen {
      background: black !important;
    }
    .handiscover-accessibility-widget .custom-image-gallery-arrow-controls {
      height: 15px;
      width: 15px;
    }
    .handiscover-accessibility-widget .image-gallery-slide-wrapper button > img {
      height: 15px !important;
      width: 15px !important;
    }
  }

  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }
  
  .thumbnails-false .image-gallery-slide-wrapper {
    height: 100%
  }

`

export const SizeContainerWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  background: #ffffff;
  border: 1px solid rgb(232, 232, 232);
  align-items: center;
  padding: 10px 20px;
  word-break: break-word;
  min-width: 50px;
`;

export const InfoCell = styled(Box)`
  background: #ffffff;
  border: 1px solid rgb(232, 232, 232);
  padding: 10px 20px;
  color: #636363;
  .number {
    font-weight: 700;
    font-size: 22px !important;
    margin-right: 15px;
    line-height: 1.2 !important;
  }
  .label {
    font-weight: 400;
    font-size: 12px !important;
    font-family: 'Roboto',sans-serif;
  }
`

export const ClassificationWrapper = styled.div`
  background: #fbfbfb;
  border: 1px solid #e8e8e8;
  height: auto;
  // min-height: 130px;
  padding: 15px;
  cursor: pointer;
`;

export const SmallDevicesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 10px;
  gap: 20px;
`;

export const PropertyPageTable = styled.table`
  display: contents;
`;

export const PropertyPageTableHeader = styled.th`
  height: 100%;
  min-height: 30px;
  background-color: #edf5ff;
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap:nowrap;
  justify-content:space-between;
  align-items: center;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  text-align: left;
`;

type GeneralInformationContainerTableRowType = {
  padding?: string | undefined
  width?: string | undefined
}

export const GeneralInformationContainerTableRow = styled.tr<GeneralInformationContainerTableRowType>`
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: ${(props) => props.padding ? '0' : '3px'};
  align-items: center;
  padding: ${(props) => props.padding ? props.padding : '0 0 8px 0'};
  width: ${(props) => props.width ? props.width : 'unset'};
`;

export const GeneralInformationContainerTableHeader = styled.td`
  display: flex;
  flex: 1;
  padding-right: 20px;
  text-align: inherit;
  align-items: flex-start;
`;

export const MainContainerAccessibilityInfo = styled.div`
  font-weight: 700 !important;
  font-style: normal !important;
  font-size: 16px !important;
  margin-left: 5px;
  font-family: Roboto !important;
  color: black !important;
`;

const SizeContainerInnerTop = styled.div`
  min-width: 40px !important;
  font-family: "Roboto", "Manrope", sans-serif;
`;

const SizeContainerTopNrText = styled.div`
  font-weight: 700 !important; 
  font-size: 22px !important;
  line-height: 1.2 !important;
`;

const SizeContainerInnerBottom = styled.div`
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 12px !important;
  font-family: 'Roboto' !important;
`;

export const TranslationIcon = styled(Icons.Translation)`
  width:16px;
  height:14px;
  margin:5px;
  padding:5px;
  border-radius:3px;
  background-color: #FFFFFF;
  border-color: #DDE1E6;
  position:relative;
`;

export const Circle = styled.div`
  height: 10px;
  width: 10px;
  margin-right: 6px;
  border-radius: 50%;
  background: #565857;
`

export const Loader = styled(CircularProgress)`
  width: 25px !important;
  height: 25px !important;
  ${(props) => props.theme.breakpoints.down("md")} {
    height: 20px !important;
    width: 20px !important;
  }
`;

export const SizeContainer = (p: { nr: number | string; label: string }) => {
  const { label, nr } = p;

  return (
    <SizeContainerWrapper>
      <SizeContainerInnerTop>
        <SizeContainerTopNrText>
          {nr}
        </SizeContainerTopNrText>
      </SizeContainerInnerTop>

      <SizeContainerInnerBottom>
        {label}
      </SizeContainerInnerBottom>
    </SizeContainerWrapper>
  );
};

export const BtnImgIcon = styled.img`
  width: 31px !important;
  height: 31px !important;
  padding-bottom: 1px !important;
  margin-right: 8px !important;
  ${(props) => props.theme.breakpoints.down("md")} {
    height: 22px !important;
    width: 22px !important;
    margin-right: 3px !important;
  }
`

type TypeAccBtnText = {
  color?: string;
}
export const AccBtnText = styled.span<TypeAccBtnText>`
  text-transform: capitalize !important;
  color: ${({ color }) => color ? `${color} !important;` : '#FFF !important'};
`

type Props = {
  settings: WidgetSettings[] | undefined
}

const AccessibilityButton = styled.button<Props>`
  background-color: ${(props) => (
      props.disabled ? 
          'rgba(18, 22, 25, 0.5)' : 
          props.settings && props.settings[0].background_color === "" ? 
              "#121619" :
              props.settings && props.settings[0].background_color
  )} !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 41px !important;
  margin: 0 !important;
  font-weight: 400 !important;
  padding: 1px 17px !important;
  //box-sizing: border-box;
  border: 1px solid #ffffff !important;
  border-radius: 40px !important;
  color: ${({ settings }) => settings && settings[0].color === "" ? 
    "#ffffff !important" : `${settings && settings[0].color} !important`};
  font-size: ${({ settings }) => settings && settings[0].font_size === "" ? 
    "15px !important" : settings && settings[0].font_size};
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.25px !important;
  cursor: pointer !important;
  float: right !important;
  margin-right: 24px !important;
  margin-bottom:  ${({ settings }) => settings && settings[0].margin === "" ? 
    "15" : settings && settings[0].margin}px !important;
  position: fixed !important;
  bottom: ${({ settings }) => settings && settings[0].positioning_bottom === "" ?
          0 : settings && settings[0].positioning_top} !important;
  right: ${({ settings }) => settings && settings[0].positioning_right === "" ?
          0 : settings && settings[0].positioning_right} !important;
  z-index: 1400000 !important;
  &:focus {
    background-color: #121619 !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
    outline: 3px solid rgb(0 0 0) !important;
    outline-offset: 1px !important;
  }
  &:hover {
    background-color: ${(props) => (
            props.disabled ?
                    'rgba(18, 22, 25, 0.5)' :
                    props.settings && props.settings[0].background_color === "" ?
                            "#121619" :
                            props.settings && props.settings[0].background_color
    )};
    opacity: 0.85;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
  }
  @media (max-width: 768px) {
    left: ${({ settings }) => settings && settings[0].positioning_left === "" ? 
      "" : settings && settings[0].positioning_left} !important;
    top: ${({ settings }) => settings && settings[0].positioning_top === "" ? 
      "" : settings && settings[0].positioning_top} !important;
    right: ${({ settings }) => settings && settings[0].positioning_right === "" ? 
      "" : settings && settings[0].positioning_left} !important;
    bottom: ${({ settings }) => settings && settings[0].positioning_bottom === "" ? 
      "" : settings && settings[0].positioning_top} !important;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    font-size: 14px !important;
    padding: 1px 17px !important;
    height: 37px !important;
    padding: 1px 15px !important;
    letter-spacing: 0.5px;
  }
`;

type PropsType = {
  settings: WidgetSettings[] | undefined
  onAccessibilityClick: () => void,
  loading: boolean
  a11yStandard: A11yStandardType,
  t: (key: string) => string
}

export const CustomAccessibilityButton: FC<PropsType> = ({
  settings,
  onAccessibilityClick,
  loading,
  a11yStandard,
  t
}) => {
  return (
    <AccessibilityButton
      onClick={onAccessibilityClick}
      disabled={loading}
      aria-label={t("accessibility_button_open_ariaLabel")}
      settings={settings}
      tabIndex={1}
    >
      <>
        <BtnImgIcon src={iconImg} />
        <AccBtnText color={settings && settings[0] ? settings[0].color : ''}>
          {t("accessibility_button")}
        </AccBtnText>
      </>
    </AccessibilityButton>
  );
};

