import {FC} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";
import {SvgIcon} from "@mui/material";

const HotelRoomSvg:FC<SvgIconProps> = (p) => {
    return (
        <SvgIcon {...p}>
            <svg viewBox="0 0 93 93" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1884_12)">
                    <path d="M49.7004 24.1484H33.2993C29.0574 24.1484 25.6064 27.5994 25.6064 31.8413V36.27H57.3935V31.8413C57.3935 27.5997 53.9424 24.1484 49.7004 24.1484Z"/>
                    <path d="M68.2316 0.797729H14.7574C9.42986 0.797729 5.0957 5.13205 5.0957 10.4596V36.6597C6.14569 36.4072 7.2396 36.2697 8.36594 36.2697H19.9867V31.841C19.9867 24.5033 25.9564 18.5336 33.2941 18.5336H49.6952C57.033 18.5336 63.0026 24.5033 63.0026 31.841V36.2697H74.6234C75.7498 36.2697 76.844 36.407 77.8938 36.6597V10.4596C77.8935 5.13205 73.5592 0.797729 68.2316 0.797729Z"/>
                    <path d="M74.6216 41.8845H60.1937H22.7921H8.36425C3.74818 41.8844 -0.00708008 45.6398 -0.00708008 50.2559V66.9906H82.9929V50.2559C82.9929 45.6398 79.2377 41.8845 74.6216 41.8845Z"/>
                    <path d="M5.0957 72.6055V79.3949C5.0957 80.9452 6.35254 82.2022 7.90295 82.2022C9.45337 82.2022 10.7102 80.9453 10.7102 79.3949V72.6055H5.0957Z"/>
                    <path d="M72.2913 72.6055V79.3949C72.2913 80.9452 73.5481 82.2022 75.0985 82.2022C76.6489 82.2022 77.9058 80.9453 77.9058 79.3949V72.6055H72.2913Z"/>
                </g>
                    <defs>
                        <clipPath id="clip0_1884_12">
                            <rect width="83" height="83" fill="white"/>
                        </clipPath>
                    </defs>
            </svg>
        </SvgIcon>
    )
}

export default HotelRoomSvg