import { AccessiblityQuery } from "../Models/Accessibility";
import { AccessibilityImagesQuery } from "../Models/AccessibilityImages";
import { AccessibilityUnitLevelQuery } from "../Models/AccessibilityLevel";
import { AccessibilityUnitsQuery } from "../Models/AccessibilityUnits";
import { Property, PropertyQuery } from "../Models/Property";
import { AccessibilityInfoQuery } from "../Models/AccessibilityInfo";
import { PropertyAccessibilityImagesQuery } from "../Models/PropertyAccessibilityImages";
import { PropertyCategoryListQuery } from "../Models/PropertyCategoryList"
import { PropertySubCategoryListQuery } from "../Models/PropertySubCategoryList"

import { A11yStandardType } from "../Models/utils";
import { baseUrl, BasicQuerySearch, fetchAndParse, generateQueryString, headers, qmsUrl } from "./utils"
import {QueryPropertyFreeTextInfo} from "../Models/PropertyFreeTextInfo";
const QUERY_LIMIT = 1999;


export const searchProperties = (query: BasicQuerySearch & {
    building_area?: string;
    a11y_standard?: string;
    related_to?: string;
}) => {
    const queryText = generateQueryString(query);
    const url = new URL(`/properties/${queryText}&published=true`, baseUrl);
    return fetchAndParse<PropertyQuery>(url.toString(), { method: "GET", headers });
}

export const searchPropertiesWithFields = (query: BasicQuerySearch & {
    building_area?: string;
    a11y_standard?: string;
    related_to?: string;
    fields?: string;
}) => {
    const queryText = generateQueryString(query);
    const url = new URL(`/properties/${queryText}&published=true`, baseUrl);
    return fetchAndParse<PropertyQuery>(url.toString(), { method: "GET", headers });
}

export const retrieveProperty = (id: string) => {
    const url = new URL(`/properties/${id}/`, baseUrl);
    return fetchAndParse<Property>(url.toString(), { method: "GET", headers })
}

export const retrieveAccessibilityUnits = (propertyId: string) => {
    const url = new URL(`/accessibility-units/?property__id=${propertyId}&limit=${QUERY_LIMIT}&is_active=true`, baseUrl);
    return fetchAndParse<AccessibilityUnitsQuery>(url.toString(), { method: "GET", headers })
}

export const retrieveAccessibilityUnitsByBuildingAreaId = (buildingAreaId: string, fields: string) => {
    const url = new URL(`/accessibility-units/?property__building_area=${buildingAreaId}&limit=${QUERY_LIMIT}&is_active=true&fields=${fields}`, baseUrl);
    return fetchAndParse<AccessibilityUnitsQuery>(url.toString(), { method: "GET", headers })
}

export const retrieveAccessibilityUnitsWithFields = (propertyId: string, fields: string) => {
    const url = new URL(`/accessibility-units/?property__id=${propertyId}&fields=${fields}&limit=${QUERY_LIMIT}&is_active=true`, baseUrl);
    return fetchAndParse<AccessibilityUnitsQuery>(url.toString(), { method: "GET", headers })
}

export const retrieveAccessibilityUnitsByTypeWithFields = (propertyId: string, type: string, fields: string) => {
    const url = new URL(`/accessibility-units/?property__id=${propertyId}&type=${type}&fields=${fields}&limit=${QUERY_LIMIT}&is_active=true`, baseUrl);
    return fetchAndParse<AccessibilityUnitsQuery>(url.toString(), { method: "GET", headers })
}

export const retrieveRelatedAccessibilityUnitsByUnit = (id: string) => {
    const url = new URL(`/accessibility-units/?related_to=${id}&limit=${QUERY_LIMIT}&is_active=true`, baseUrl);
    return fetchAndParse<AccessibilityUnitsQuery>(url.toString(), { method: "GET", headers })
}

export const retrieveAccessibilityUnitsByBuildingAreaAndType = (building_area: string, type: string) => {
    const url = new URL(`/accessibility-units/?property__building_area=${building_area}&type=${type}&limit=${QUERY_LIMIT}&is_active=true`, baseUrl);
    return fetchAndParse<AccessibilityUnitsQuery>(url.toString(), { method: "GET", headers })
}

export const retrieveAccessibilityUnitsImage = (unitId: string) => {
    const url = new URL(`/accessibility-images/?accessibility_units__id=${unitId}&accessibility_unit__is_active=true`, baseUrl);
    return fetchAndParse<AccessibilityImagesQuery>(url.toString(), { method: "GET", headers });
}

export const retrieveAccessibilityUnitsImageWith1Limit = (unitId: string, ) => {
    const url = new URL(`/accessibility-images/?accessibility_units__id=${unitId}&limit=1&accessibility_unit__is_active=true`, baseUrl);
    return fetchAndParse<AccessibilityImagesQuery>(url.toString(), { method: "GET", headers });
}

export const retrieveAccessibilityUnitsImageByPropertyId = (propertyId: string) => {
    const url = new URL(`/accessibility-images/?accessibility_units__property__id=${propertyId}&accessibility_unit__is_active=true`, baseUrl);
    return fetchAndParse<AccessibilityImagesQuery>(url.toString(), { method: "GET", headers });
}

export const retrieveAccessibilitiyByPropertyId = (propertyId: string, fields:string) => {
    const url = new URL(`/accessibilities/?accessibility_unit__property__id=${propertyId}&limit=${QUERY_LIMIT}&fields=${fields}`, baseUrl);
    return fetchAndParse<AccessiblityQuery>(url.toString(), { method: "GET", headers });
}

export const retrieveAccessibilitiyByUnitId = (unitId: string) => {
    const url = new URL(`/accessibilities/?accessibility_unit__id=${unitId}&limit=${QUERY_LIMIT}`, baseUrl);
    return fetchAndParse<AccessiblityQuery>(url.toString(), { method: "GET", headers });
}

export const retrieveAccessibilitiyByBuildingArea = (buildingAreaId: string, fields: string) => {
    const url = new URL(`/accessibilities/?accessibility_unit__property__building_area=${buildingAreaId}&limit=${QUERY_LIMIT}&is_absent=false&fields=${fields}`, baseUrl);
    return fetchAndParse<AccessiblityQuery>(url.toString(), { method: "GET", headers });
}

export const retrieveAccesibilityUnitsLevelsByPropertyId = (propertyId: string) => {
    const url = new URL(`/accessibility-unit-levels/?accessibility_unit__property__id=${propertyId}&limit=${QUERY_LIMIT}`, baseUrl);
    return fetchAndParse<AccessibilityUnitLevelQuery>(url.toString(), { method: "GET", headers });
}

export const retrieveAccesibilityUnitsLevelsByPropertyIdAndType = (propertyId: string, type: string) => {
    const url = new URL(`/accessibility-unit-levels/?accessibility_unit__property__id=${propertyId}&accessibility_unit__type=${type}&limit=${QUERY_LIMIT}`, baseUrl);
    return fetchAndParse<AccessibilityUnitLevelQuery>(url.toString(), { method: "GET", headers });
}

export const retrieveAccesibilityUnitsLevelsByUnitId = (unitId: string) => {
    const url = new URL(`/accessibility-unit-levels/?accessibility_unit=${unitId}&limit=${QUERY_LIMIT}`, baseUrl);
    return fetchAndParse<AccessibilityUnitLevelQuery>(url.toString(), { method: "GET", headers });
}

export const retrieveQuestionnaireStandards = (key: string) => {
    const url = new URL(`/questionnaires-a11y-standards/?questionnaire__key=${key}&limit=1`, qmsUrl);
    return fetchAndParse<A11yStandardType[]>(url.toString(), { method: "GET" });
}

export const retrievePropertyAccessibilityInfoByPropertyId = (id: string) => {
    const url = new URL(`/property-accessibility-infos/?property__id=${id}&is_displayed=true`, baseUrl);
    return fetchAndParse<AccessibilityInfoQuery>(url.toString(), { method: "GET", headers });
}

export const retrievePropertyFreeTextInfo = (id: string) => {
    const url = new URL(`/property_free_text_infos/?property=${id}&is_displayed=true`, baseUrl);
    return fetchAndParse<QueryPropertyFreeTextInfo>(url.toString(), { method: "GET", headers });
}

export const listAccessbilityImagesByPropertyId = (id: string) => {
    const url = new URL(`/accessibility-images/?accessibility_unit__property__id=${id}&is_display_in_output_service=true&limit=${QUERY_LIMIT}&accessibility_unit__is_active=true`, baseUrl);
    return fetchAndParse<PropertyAccessibilityImagesQuery>(url.toString(), { method: "GET", headers });
}
export const listAccessbilityImagesByBuildingAreaId = (id: string) => {
    const url = new URL(`/accessibility-images/?accessibility_unit__property__building_area__id=${id}&is_display_in_output_service=true&limit=${QUERY_LIMIT}&accessibility_unit__is_active=true`, baseUrl);
    return fetchAndParse<PropertyAccessibilityImagesQuery>(url.toString(), { method: "GET", headers });
}

export const retrievePropertiesByRelatedId = (relatedId: string) => {
    // we are limiting it to 1 since we only need to know how many are they ( from the countObject )
    const url = new URL(`/properties/?related_to=${relatedId}&limit=1`, baseUrl);
    return fetchAndParse<PropertyQuery>(url.toString(), { method: "GET", headers });
}

export const retrieveBuildingAreaGeneralInfo = (buildingAreaId: string) => {
    const url = new URL(`/building-area-general-infos/?building_area=${buildingAreaId}&limit=${QUERY_LIMIT}`, baseUrl);
    return fetchAndParse<AccessibilityInfoQuery>(url.toString(), { method: "GET", headers });
}

export const retrieveBuildingAreaAccessibilityInfos = (buildingAreaId: string) => {
    const url = new URL(`/building-area-accessibility-infos/?building_area=${buildingAreaId}&limit=${QUERY_LIMIT}`, baseUrl);
    return fetchAndParse<AccessibilityInfoQuery>(url.toString(), { method: "GET", headers });
}

export const retrievePropertyAccessibilityByType = (propertyId: string, accessibilityType: string, filter? : string) => {
    const url = new URL(`/accessibility-unit-levels/?accessibility_unit__type=${accessibilityType}&accessibility_unit__property__id=${propertyId}&fields=${filter}`, baseUrl);
    return fetchAndParse<AccessibilityUnitLevelQuery>(url.toString(), { method: "GET", headers })
}

export const retrievePropertyCategoryByKey = (key: string,) => {
    const url = new URL(`/property-categories/?key=${key}&limit=1`, baseUrl);
    return fetchAndParse<PropertyCategoryListQuery>(url.toString(), { method: "GET", headers })
}

export const retrievePropertySubCategoryByKey = (key: string,) => {
    const url = new URL(`/property-sub-categories/?key=${key}&limit=1`, baseUrl);
    return fetchAndParse<PropertySubCategoryListQuery>(url.toString(), { method: "GET", headers })
}
