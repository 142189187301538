export const TRANSLATIONS_FR = {
  "accessibility_button": "Accessibilité",
  "sidebar_Hotel": "Hôtel",
  "sidebar_Rooms": "Pièces",
  "sidebar_verified": "Vérifié par Handiscover",
  "hotel_floors": "Etage(s)",
  "hotel_totalRooms": "Nombre total de chambres",
  "hotel_accessibleRooms": "Chambre PMR",
  "hotel_allergyRooms": "Chambres d'allergie",
  "mainContainer_accessibilityInformation": "Informations sur l'accessibilité",
  "mainContainer_pictures": "Des photos",
  "mainContainer_informationByDisability": "Informations par handicap",
  "mainContainer_GeneralHotelInformation": "Informations générales sur l'hôtel",
  "informationByDisability_mobility": "Mobilité",
  "informationByDisability_hearing": "Audience",
  "informationByDisability_vision": "Vision",
  "informationByDisability_other": "Autre",
  "rooms_header": "Informations sur la chambre",
  "accessibility_room_icons": "Icônes d'accessibilité pour la pièce",
  "roomInformation_generalRoomInformation": "Informations générales sur la chambre",
  "shopping_mall_mallFloors": "Etage(s) de centres commerciaux",
  "shopping_mall_totalShops": "Commerces inventoriés",
  "shopping_mall_accessibleParking": "Parking PMR",
  "shopping_mall_foodCourts": "Aires de restauration",
  "mainContainer_GeneralMallInformation": "Informations générales sur le centre commercial",
  "rooms_noPictures": "Cette salle n'a pas d'images",
  "rooms_noInformation": "Cette salle n'a aucune information",
  "rooms_noIcons": "Aucune icône d'accessibilité",
  "property_noPictures": "Aucune photo trouvée pour cette propriété",
  "disability_noPictures": "Aucune photo pour le handicap sélectionné",
  "accessibilityIcons_notPresent": "Aucune icône d'accessibilité",
  "shopping_mall": "Centre commercial",
  "building_area_buildings": "Bâtiments",
  "building_area_shops": "Commerces inventoriés",
  "building_area_parking_spaces": "Parking PMR",
  "building_area_food_courts": "Aires de restauration",
  "mainContainer_generalInformation": "Informations générales",
  "mainContainer_GeneralOfficeInformation": "Informations générales sur le bureau",
  "mainContainer_GeneralShopInformation": "Informations générales sur la boutique",
  "header_buildingInformation": "Informations sur le bâtiment",
  "a11yStandard_shopping_mall": "Boutique",
  "a11yStandard_shopping_mall_plural": "Magasins",
  "property_floorSignular": "Etage",
  "property_floorPlural": "Etage",
  "property_tabTitle_shops": "Informations sur les magasins",
  "general_invalid_API_CALL_errorMessage": "Un problème est survenu ! Impossible de récupérer les données.",
  "general_missingInfo_forItem": "Il semble que les informations n'aient pas encore été ajoutées",
  "translated_text": "Le titre a été traduit",
  "accessibility_button_open_ariaLabel": "Informations sur l'accessibilité ouverte",
  "mainContainer_mapView": "Vue de la carte",
  "mainContainer_generalAccessibility": "Accessibilité générale",
  "mapView_filterBy": "Filtrer par",
  "mapView_filter_all": "Tous",
  "mapView_filter_entrance": "Entrée",
  "mapView_filter_parking": "Parking",
  "mapView_filter_toilet": "Toilettes",
  "mapView_floor": "Sol",
  "selectDisabilityType_mobility": "Mobilité",
  "selectDisabilityType_hearing": "Audience",
  "selectDisabilityType_allergy": "Allergie",
  "selectDisabilityType_cognitive": "Cognitif",
  "selectDisabilityType_other": "Autre",
  "selectDisabilityType_select": "Sélectionner",
  "selectDisabilityType_disability_type": "type de handicap",
  "selectDisabilityType_select_disability_type": "Sélectionnez le type de handicap",
  "selectDisabilityType_select_what_type": "Filtrer par handicap",
  "selectDisabilityType_visual": "Visuel",
  "go_back": "Retourner",
  "tabTitle_meetingRooms": "Salles de réunions",
  "tabTitle_hospitalityRooms": "Chambres d'accueil",
  "generalAccessibility_noGeneralAccessibilitiesForThisProperty": "Aucune accessibilité générale n'a été trouvée pour cette propriété",
  "generalAccessibility_noGeneralAccessibilitiesForTheseDisabilityFilters": "Aucune accessibilité générale n'a été trouvée pour ces filtres de handicap",
  "informationByDisabilityContainer_noDataIsFound": "Aucune donnée n'est trouvée",
  "informationByDisabilityContainer_weDoNotHaveTheData": "Nous n'avons pas encore les données pour cette propriété",
  "informationByDisabilityContainer_tryAnotherDisabilityFiltering": "Essayez un autre filtrage de handicap",
  "playground_information": "Informations sur l'aire de jeux",
  "total_playgrounds": "Terrains de jeux totaux",
  "inventoried_playgrounds": "Aires de jeux inventoriées",
  "general_accessibility_and_information": "Accessibilité générale et informations",
  "detailed_accessibility": "Accessibilité détaillée",
  "helsingborg_city_has_reviewed": "La ville d'Helsingborg a examiné la disponibilité de 26 de ses 200 terrains de jeux. Vous y trouverez des informations sur le stationnement, la disponibilité des toilettes et diverses aires de jeux.",
  "for_detailed_accessibility_information": "Pour des informations détaillées sur l’accessibilité, veuillez choisir le terrain de jeu concerné.",
  "amount_of_accessible_toilets": "Nombre de toilettes accessibles",
  "mapView_filter_elevator": "Ascenseur",
  "mapView_filter_reception_infodesk": "Réception",
  "mapView_filter_playground": "Cour de récréation",
  "mapView_filter_garden": "Jardin",
  "mapView_filter_shop": "Boutique",
  "mapView_filter_restaurant": "Restaurant",
  "mapView_filter_conference_room": "Salle de conférence",
  "mapView_filter_auditorium": "Salle",
  "jarfalla_municipality_has_reviewed": "La municipalité de Järfälla a examiné l'accessibilité de 55 aires de jeux, dont 2 aires de jeux d'aventure qui favorisent la créativité. Vous y trouverez des informations sur le stationnement, l'accessibilité des toilettes et les différentes aires de jeux.",
  "mapView_filter_vac": "Changement de niveau du sol",
  "languages": "Langue",
  "english": "Anglais",
  "swedish": "Suédois",
  "danish": "Danois",
  "german": "Allemand",
  "spanish": "Espagnol",
  "french": "Français",
  "norwegian": "Norvégien",
  "portuguese": "Portugais",
  "ukrainian": "Ukrainien",
  "accessibility_room": "Salle d'accessibilité",
  "pool": "Piscine",
  "spa": "Spa",
  "bar_restaurant": "Bar/Restaurant",
  "bathrooms": "Salles de bains",
  "shop": "Boutique",
  "exhibition": "Exposition",
  "rate_your_experience": "Pouvez-vous nous aider à nous améliorer🧑‍🦼🦮🦻 ? 🙏",
  "did_you_find_the_accessibility_information_you_needed": "Avez-vous trouvé les informations d’accessibilité dont vous aviez besoin ?",
  "how_satisfied_are_you_with_your_experience": "Êtes-vous satisfait de votre expérience avec notre guide d'accessibilité ?",
  "what_do_you_like_the_most": "Qu'est-ce que vous appréciez le plus?",
  "sorry_to_hear_that": "Déolé de vous décevoir ! Veuillez commenter pourquoi pour soumettre votre note",
  "we_would_love_to_hear_your_suggestions": "Nous aimerions entendre vos suggestions",
  "submit": "Soumettre",
  "book_now": "Réserver",
  "very_bad": "Très mauvais",
  "bad": "Mauvais",
  "neutral": "Neutre",
  "good": "Bien",
  "great": "Super"
}