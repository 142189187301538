import { AccessibilityUnitLevelQuery } from "../Models/AccessibilityLevel";
import { BuildArea, BuildAreaQuery } from "../Models/BuildArea";
import { baseUrl, BasicQuerySearch, fetchAndParse, generateQueryString, headers } from "./utils"

export const listBuildingAreas = (query: BasicQuerySearch) => {
    const queryText = generateQueryString(query);
    const url = new URL(`/building_areas/${queryText}`, baseUrl);
    return fetchAndParse<BuildAreaQuery>(url.toString(), { method: "GET", headers });
}

export const retrieveBuildingAreaById = (id: string) => {
    const url = new URL(`/building_areas/${id}/`, baseUrl);
    return fetchAndParse<BuildArea>(url.toString(), { method: "GET", headers })
}

export const retrieveBuildingAreasAccessibilityByType = (buildingAreaId: string, accessibilityType: string, filter? : string) => {
    const url = new URL(`/accessibility-unit-levels/?accessibility_unit__type=${accessibilityType}&accessibility_unit__property__building_area__id=${buildingAreaId}&fields=${filter}`, baseUrl);
    return fetchAndParse<AccessibilityUnitLevelQuery>(url.toString(), { method: "GET", headers })
} 