import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";
import {useTranslation} from "react-i18next";

const MissingInformation = () => {
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      height={"100%"}
      alignItems="center"
      justifyContent="center"
      mt={3}
    >
      <Typography variant="h6">
        {t("general_missingInfo_forItem")}
      </Typography>
    </Box>
  )
}

export default MissingInformation
