import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const SkeletonItemInfo = () => {
  return (
    <>
      <Box>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={'40px'}
          width={"100%"}
          sx={{mb: 1.5}}
        />
        <Skeleton sx={{mb:1.5}}
                  variant="rectangular"
                  animation="wave"
                  height={175}
                  width={"100%"}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={'40px'}
          width={"100%"}
          sx={{mb: 1.5}}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={150}
          width={"100%"}
        />
      </Box>
      <Box>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={'40px'}
          width={"100%"}
          sx={{mb: 1.5}}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          style={{minHeight: '380px'}}
          height={'89%'}
          width={"100%"}
        />
      </Box>
      <Box>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={'40px'}
          width={"100%"}
          sx={{mb: 1.5}}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          style={{minHeight: '380px'}}
          height={'89%'}
          width={"100%"}
        />
      </Box>
    </>
  )
}

export default SkeletonItemInfo
