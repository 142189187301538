import {Property} from "../../Models/Property";
import {AccessibilityUnit} from "../../Models/AccessibilityUnits";
import {Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {MapContext,} from "../../pages/Property/PropertyWrapper";
import {MapContextBuildingArea} from "../../pages/BuildingArea/BuildingAreaPage";
import FloorPicker from "./components/FloorPicker";
import Icons from "../Icons";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import {GoogleMap} from "./GoogleMap";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import {AccessibilityUnitLevel} from "../../Models/AccessibilityLevel";
import {Mixpanel} from "../../Mixpanel";
import {MetricMessage} from "../../utils/metrics";
import {useTranslation} from "react-i18next";
import {filterSettings, FilterSettingsType} from "./utils/filterSettings";
import {Accessibility} from "../../Models/Accessibility";
import {Tooltip} from "@mui/material";

const Wrapper = styled.div`
  background-color: #FFF;
  border: 1px solid #E3E5E5;
  width: 100%;
  height: 100%;
  position: relative;
`;

const StyledChip = styled(Chip)`
  border-radius: 50%;
  padding: 5px;
  border: 1px solid rgba(25, 118, 210, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 30px;
  & img {
    margin: 0 !important;
    object-fit: none;
  }
  span {
    display: none;
  }
`;

const StyledFilterLabel = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-right: 4px;
  font-size: 14px !important;
  padding-bottom: unset !important;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }
`;

const StyledSpace = styled.div`
  flex-grow: 1;
`;

const StyledCloseIcons = styled(Icons.CloseIcon)`
  width: 16px;
  height: 16px
`

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  min-height: 45px;
  padding-left: 15px;
  padding-right: 15px;
  & {
    color: #000000DE;
  }
`

const RightBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 137px;
`

const StyledAppBar = styled(AppBar)`
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #E3E5E5;
  padding: unset !important;
  height: unset !important;
  z-index: 4 !important;
  @media (max-width: 768px) {
    z-index: 16000000;
  }
`

const CustomTitle = styled.span`
  position: absolute;
  width: 91%;
  text-align: center;
  color: #3885ee !important;
  font-style: normal !important;
  font-size: 14px !important;
  font-family: 'Roboto' !important;
  line-height: 1.3 !important;
  text-transform: uppercase;
  font-weight: 500;
`


const filterByType = (selectedFilters: FilterSettingsType[], pois : AccessibilityUnit[] | undefined) => {
  // We only want to include entrance, parking & toilet for now, filter out rest.
  // Only include types that is active in the filterSettings
  return pois?.filter((poi: AccessibilityUnit) => {
    let filterType;
    if (poi.type === "toilet_room__common" || poi.type === "toilet_room__private") {
      filterType = selectedFilters.find((filter) => filter.type === "toilet")
    } else {
      filterType = selectedFilters.find((filter) => filter.type === poi.type)
    }
    // if filterType isn't active we still want to include it if filterSetting "all" is active
    if ((filterType && filterType.active) || (filterType && !filterType.active && selectedFilters[0].active)) {
      return true
    }
    return false
  })
}

const filterByLevels = (
  selectedLevels: number[],
  accessibleUnits: AccessibilityUnit[] | undefined,
  unitLevels: AccessibilityUnitLevel[] | undefined
) => {
  if (accessibleUnits && unitLevels) {
    return accessibleUnits.filter((unit) => {
      const foundUnitLevels = unitLevels.filter((unitLevel) => unitLevel.accessibility_unit === unit.id)
      if (foundUnitLevels.length > 0) {
        return foundUnitLevels.some((i) => selectedLevels.includes(i.level))
      }
      return false
    })
  }
  return accessibleUnits
}

const getUniqueLevels = (
  unitLevels: AccessibilityUnitLevel[] | undefined,
  filteredPOIs: AccessibilityUnit[] | undefined
) => {
  if (filteredPOIs && unitLevels && unitLevels.length > 0) {
    const foundUnitLevels: number[] = []
    filteredPOIs.forEach((poi) => {
      const foundUnit = unitLevels.find((unit) => unit.accessibility_unit === poi.id)
      if (foundUnit) {
        foundUnitLevels.push(foundUnit.level)
      }
    })
    return [...new Set(foundUnitLevels)].sort((a,b) => a - b)
  }
  return []
}

const disabledFiltersForStandards = [
  'outdoor_playground',
]


const initialSelectedLevel = (array: number[]): number => {
  const filteredArray = array.filter((x) => x > -1)
  if (filteredArray.length > 0) {
    return filteredArray[0]
  }
  return array[0]
}

type PropsType = {
  id: string;
  standard: string;
  mapCenterCoordinates: [number, number];
  unitLevels?: AccessibilityUnitLevel[] | undefined;
  accessibleUnits?: AccessibilityUnit[] | undefined;
  poisFromBuildingArea?: Property[];
  activePoiId: string
  onSetActivePoiId?: Dispatch<SetStateAction<string>> | undefined;
  setClickedMapPoiName?: Dispatch<SetStateAction<string>> | undefined;
  // units: AccessibilityUnitsQuery | undefined
  customTitle?: string
  crossButton?: boolean
  selectedPropertyImageFromBuildingArea?: string
  fullscreen?: boolean
  accessibilities?: Accessibility[]
}

const MapWrapper: FC<PropsType> = ({
  id,
  standard,
  mapCenterCoordinates,
  unitLevels,
  accessibleUnits,
  poisFromBuildingArea,
  activePoiId,
  onSetActivePoiId,
  setClickedMapPoiName,
  customTitle,
  crossButton= true,
  selectedPropertyImageFromBuildingArea,
  fullscreen = false,
  accessibilities
  // units,
}) => {
  const { t } = useTranslation()
  const googleMapRef = useRef<any>()
  const [, setShowMap] = useContext(MapContext);
  const [, setShowMapBuildingArea] = useContext(MapContextBuildingArea);
  const [selectedFilters, setSelectedFilters] = useState<FilterSettingsType[]>([]);
  const [filteredPOIs, setFilteredPOIs] = useState<AccessibilityUnit[] | undefined>(
    disabledFiltersForStandards.includes(standard) ? accessibleUnits : filterByType(selectedFilters, accessibleUnits)
  );
  const [uniqueLevels, setUniqueLevels] = useState<number[]>([])
  const [selectedLevels, setSelectedLevels] = useState<number[]>([])

  useEffect(() => {
    if (accessibleUnits && accessibleUnits.length > 0) {
      const filterByUnits = filterSettings.filter((setting) => {
        const filteredUnits = accessibleUnits.filter((unit) => unit.type.includes(setting.type) && unit.point)
        if (setting.type === 'all') {
          return true
        }
        return filteredUnits && filteredUnits.length > 0;
      })
      setSelectedFilters(filterByUnits)

    }
  }, [accessibleUnits]);

  useEffect(() => {
    if (unitLevels && unitLevels.length > 0 && accessibleUnits && accessibleUnits.length > 0) {
      const uniqLevels = getUniqueLevels(unitLevels, accessibleUnits)
      if (uniqLevels.length > 0) {
        setUniqueLevels(uniqLevels)
        setSelectedLevels(uniqLevels)
      }
    }
  }, [unitLevels, accessibleUnits])

  const closeDialog = (e: React.MouseEvent<HTMLElement>) => {
    Mixpanel.track(`${MetricMessage.closed_map_view}`)
    if (poisFromBuildingArea) {
      setShowMapBuildingArea(false)
    } else {
      setShowMap(false);
    }
  };

  const handleFilter = (filter: FilterSettingsType) => {
    let newFilters: FilterSettingsType[] = selectedFilters

    if (filter.type !== selectedFilters[0].type) {
      newFilters = selectedFilters.map((i, index) => {
        if (index === 0) {
          return {...i, active: false}
        }
        if (i.type === filter.type) {
          return {...i, active: !i.active}
        }
        return i
      })
      if (newFilters.every((i) => !i.active)) {
        newFilters = newFilters.map((i, indexArr) => ({
          ...i,
          active: indexArr === 0
        }))
      }
    }

    if (filter.type === newFilters[0].type || newFilters.slice(1).every((i) => i.active)) {
      newFilters = newFilters.map((i, indexArr) => ({
        ...i,
        active: indexArr === 0
      }))
    }

    Mixpanel.track(
       `${MetricMessage.changed_map_filter}`,
       {
         type: `${filter.type}`,
         selectedFilters: newFilters
           .filter((i) => i.active)
           .map((i) => i.type)
        }
    );

    setSelectedFilters(newFilters)
  }

  const mixpanelTracker = (id: string) => {
    let foundPoiFromBuildingArea: Property | null | undefined = null
    let foundPoiFromProperty: AccessibilityUnit | null | undefined = null

    if (filteredPOIs && filteredPOIs.length > 0) {
      foundPoiFromProperty = filteredPOIs.find((poi) => poi.id === id)
    }
    if (poisFromBuildingArea && poisFromBuildingArea.length > 0) {
      foundPoiFromBuildingArea = poisFromBuildingArea.find((poi) => poi.id === id)
    }

    let mixpanelProps: { type?: string, selectedUnitId?: string, selectedPropertyId?: string } = {}

    if (foundPoiFromProperty) {
      mixpanelProps.type = foundPoiFromProperty.type
      mixpanelProps.selectedUnitId = id
    }
    if (foundPoiFromBuildingArea) {
      mixpanelProps.type = foundPoiFromBuildingArea.top_category
      mixpanelProps.selectedPropertyId = id
    }

    Mixpanel.track(`${MetricMessage.map_pin_click}`, mixpanelProps)
  }

  const handlerActivePoiId = (id: string) => {
    mixpanelTracker(id)
    if (onSetActivePoiId) {
      onSetActivePoiId(id)
    }
  }

  const sendMouseEvent = (typeArg: any, e: any): void => {
    const evt = document.createEvent("MouseEvents");
    evt.initMouseEvent(typeArg, e.bubbles, e.cancelable, e.view,
      e.detail, e.screenX, e.screenY, e.clientX, e.clientY,
      e.ctrlKey, e.altKey, e.shiftKey, e.metaKey,
      e.button, e.relatedTarget);

    document.dispatchEvent(evt);
  }

  const handlerMouseUp = (e: any) => sendMouseEvent('mouseup', e)
  const handlerMouseDown = (e: any) => sendMouseEvent( 'mousedown', e)
  const handlerMouseMove = (e: any) => sendMouseEvent('mousemove', e)

  const handlerTouchStart = (e: any) => sendTouchEvent('touchstart', e)
  const handlerTouchMove = (e: any) => sendTouchEvent('touchmove', e)
  const handlerTouchEnd = (e: any) => sendTouchEvent('touchend', e)

  // This is a function that converts TouchEvents to MouseEvents.
  // In our case, we use this function due to the fact that when clicking on POIs using TouchEvent,
  // we did not work out clicking on them
  const touchToMouseEvent = (event: any) => {
    let touches = event.changedTouches, first = touches[0], type = "";

    switch(event.type) {
      case "touchstart": type = "mousedown"; break;
      case "touchmove":  type = "mousemove"; break;
      case "touchend":   type = "mouseup";   break;
      default:           return;
    }

    let simulatedEvent = document.createEvent("MouseEvent");

    simulatedEvent.initMouseEvent(type, event.bubbles, event.cancelable, event.view, 1,
      first.screenX, first.screenY,
      first.clientX, first.clientY, false,
      false, false, false, 1, null);

    first.target.dispatchEvent(simulatedEvent);

    if (type !== 'mousedown' && event.target.tagName !== 'IMG') {
      event.preventDefault();
    }
    event.stopPropagation();
  }

  const sendTouchEvent = (eventType: any, e: any) => {
    const touchEvent = new TouchEvent(eventType, {
      cancelable: true,
      bubbles: true,
      touches: e.touches,
      targetTouches: e.targetTouches,
      changedTouches: e.changedTouches,
      shiftKey: true,
    });
    if (e.target.tagName === 'IMG') {
      touchToMouseEvent(e)
    } else {
      document.dispatchEvent(touchEvent);
    }
  }

  const addEventListeners = (map: any) => {
    if (map) {
      map.addEventListener('mouseup', handlerMouseUp)
      map.addEventListener('mousedown', handlerMouseDown)
      map.addEventListener('mousemove', handlerMouseMove)
      map.addEventListener("touchstart", handlerTouchStart, true);
      map.addEventListener("touchmove", handlerTouchMove, true);
      map.addEventListener("touchend", handlerTouchEnd, true);
    }
  }

  const removeEventListeners = (map: any) => {
    if (map) {
      map.removeEventListener('mouseup', handlerMouseUp)
      map.removeEventListener('mousedown', handlerMouseDown)
      map.removeEventListener('mousemove', handlerMouseMove)
      map.removeEventListener('touchstart', handlerTouchStart)
      map.removeEventListener('touchend', handlerTouchEnd)
      map.removeEventListener('touchmove', handlerTouchMove)
    }
  }

  useEffect(() => {
    if (googleMapRef.current) {
      addEventListeners(googleMapRef.current)
    }
    return () => removeEventListeners(googleMapRef.current)
  }, [googleMapRef])

  // filters
  useEffect(() => {
    let filteredByType = accessibleUnits
    if (!disabledFiltersForStandards.includes(standard)) {
      filteredByType = filterByType(selectedFilters, accessibleUnits)
    }
    let filteredByLevels = filterByLevels(selectedLevels, filteredByType, unitLevels)
    if (filteredByLevels) {
      filteredByLevels = filteredByLevels.filter((i) => i.related_to.length === 0)
    }
    setFilteredPOIs(filteredByLevels)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, selectedLevels])

  return (
    <Wrapper>
      <StyledAppBar position="absolute" elevation={0}>
        <StyledToolbar>
          {!poisFromBuildingArea && !disabledFiltersForStandards.includes(standard) ?
            <>
              <StyledFilterLabel>{t('mapView_filterBy')}</StyledFilterLabel>
              <Stack direction="row" spacing={1} sx={{ flexGrow: 1, overflowY: 'auto', maxWidth: fullscreen ? '50vw' : 540 }}>
                {selectedFilters.map((filter, i) => (
                  <Tooltip title={t(filter.name)} placement={'top'} arrow>
                    <StyledChip
                      key={i}
                      size="small"
                      variant={filter.active ? "filled": "outlined"}
                      icon={<img height={28} width={28} src={filter.active ? filter.activeIcon : filter.defaultIcon} alt={filter.name} />}
                      clickable={true}
                      color="primary"
                      onClick={() => handleFilter(filter)}
                    />
                  </Tooltip>
                ))}
              </Stack>
            </> : <StyledSpace></StyledSpace>
          }
          {customTitle ? (
            <CustomTitle>
              {customTitle}
            </CustomTitle>
          ) : null}
          <RightBlock>
            {!poisFromBuildingArea && !disabledFiltersForStandards.includes(standard) ? (
              <FloorPicker
                floorData={uniqueLevels}
                selectedFloors={selectedLevels}
                onSetFloors={setSelectedLevels}
              />
            ): null}
            {crossButton ? (
              <IconButton
                size="medium"
                onClick={closeDialog}
                aria-label="Close map"
                color="primary"
              >
                <StyledCloseIcons />
              </IconButton>
            ) : null}
          </RightBlock>
        </StyledToolbar>
      </StyledAppBar>
      {(filteredPOIs || poisFromBuildingArea) ?
        <GoogleMap
          id={id}
          mapCenterCoordinates={mapCenterCoordinates}
          poisFromProperty={filteredPOIs as AccessibilityUnit[]}
          poisFromBuildingArea={poisFromBuildingArea}
          activePoiId={activePoiId}
          onSetActivePoiId={(e) => handlerActivePoiId(e as string)}
          setClickedMapPoiName={setClickedMapPoiName}
          googleMapRef={(e: any) => googleMapRef.current = e}
          standard={standard}
          selectedPropertyImageFromBuildingArea={selectedPropertyImageFromBuildingArea}
          accessibilities={accessibilities}
        /> : <p>No POI data where provided</p>
      }
    </Wrapper>
  );
}

export default MapWrapper;
