import {FC} from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {FormControl as MuiFormControl, OutlinedInput} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {Mixpanel} from "../../../Mixpanel";
import {MetricMessage} from "../../../utils/metrics";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const FormControl = styled(MuiFormControl)`
  label {
    font-size: 16px !important;
  }
  .MuiOutlinedInput-root {
    font-size: 16px !important;
  }
  .MuiSvgIcon-root {
    font-size: 24px !important;
  }
`

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};

type PropsType = {
  floorData: Array<number>;
  selectedFloors: number[]
  onSetFloors: (value: number[]) => void
}

const FloorPicker: FC<PropsType> = ({
  floorData,
  selectedFloors,
  onSetFloors
}) => {
  const { t } = useTranslation()

  const handleChange = (event: SelectChangeEvent<typeof selectedFloors>) => {
    const {target: { value }} = event;
    if (typeof value !== "string") {
        const foundNewValuePosition = value.map((i) => selectedFloors.includes(i) ? i : 'new')
        const indexNewValue = foundNewValuePosition.findIndex((i) => i === 'new')
        Mixpanel.track(
          `${MetricMessage.selected_map_floor}`,
          {
            floor: value[indexNewValue],
            selectedFloors: value
          }
        )
        onSetFloors(value)
      }
  };

  return (
    <FormControl sx={{ m: 1, width: '100%' }} size="small">
      <InputLabel id="floor-multiple-checkbox-label">{t('mapView_floor')}</InputLabel>
      <Select
        labelId="floor-multiple-checkbox-label"
        id="floor-multiple-checkbox"
        multiple
        value={selectedFloors}
        onChange={handleChange}
        input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {floorData.map((floor) => (
          <MenuItem key={floor} value={floor}>
            <Checkbox checked={selectedFloors.indexOf(floor) > -1} />
            <ListItemText primary={floor} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default FloorPicker;
