export const TRANSLATIONS_UK = {
  "accessibility_button": "Доступність",
  "sidebar_Hotel": "Готель",
  "sidebar_Rooms": "Кімнати",
  "sidebar_verified": "Перевірено Handiscover",
  "hotel_floors": "Поверхи",
  "hotel_totalRooms": "Всього номерів",
  "hotel_accessibleRooms": "Доступні номери",
  "hotel_allergyRooms": "Кімнати для алергіків",
  "mainContainer_accessibilityInformation": "Інформація про доступність",
  "mainContainer_pictures": "Зображення",
  "mainContainer_informationByDisability": "Інформація за категоріями інвалідності",
  "mainContainer_GeneralHotelInformation": "Загальна інформація про готель",
  "informationByDisability_mobility": "Мобільність",
  "informationByDisability_hearing": "Слух",
  "informationByDisability_vision": "Бачення",
  "informationByDisability_other": "Інше",
  "rooms_header": "Інформація про кімнату",
  "accessibility_room_icons": "Піктограми доступності для приміщень",
  "roomInformation_generalRoomInformation": "Загальна інформація про номер",
  "shopping_mall_mallFloors": "Поверхи торгових центрів",
  "shopping_mall_totalShops": "Проінвентаризовано магазини",
  "shopping_mall_accessibleParking": "Доступні місця для паркування",
  "shopping_mall_foodCourts": "Фуд-корти",
  "mainContainer_GeneralMallInformation": "Загальна інформація про ТРЦ",
  "rooms_noPictures": "У цій кімнаті немає зображень",
  "rooms_noInformation": "Цей номер не має інформації",
  "rooms_noIcons": "Немає значків доступності",
  "property_noPictures": "Для цього об'єкта не знайдено зображень",
  "disability_noPictures": "Немає зображень для обраної Інвалідності",
  "accessibilityIcons_notPresent": "Немає значків доступності",
  "shopping_mall": "Торговий центр",
  "building_area_buildings": "Будівлі",
  "building_area_shops": "Проінвентаризовано магазини",
  "building_area_parking_spaces": "Доступні місця для паркування",
  "building_area_food_courts": "Фуд-корти",
  "mainContainer_generalInformation": "Загальна інформація",
  "mainContainer_GeneralOfficeInformation": "Загальна інформація про офіс",
  "mainContainer_GeneralShopInformation": "Загальна інформація про магазин",
  "header_buildingInformation": "Інформація про будівлю",
  "a11yStandard_shopping_mall": "Магазин",
  "a11yStandard_shopping_mall_plural": "Магазини",
  "property_floorSignular": "Підлога",
  "property_floorPlural": "Поверхи",
  "property_tabTitle_shops": "Інформація про магазини",
  "general_invalid_API_CALL_errorMessage": "Виникла проблема! Не вдалося отримати дані.",
  "general_missingInfo_forItem": "Схоже, що інформація ще не додана",
  "translated_text": "Назву перекладено",
  "accessibility_button_open_ariaLabel": "Інформація з відкритим доступом",
  "mainContainer_mapView": "Перегляд мапи",
  "mainContainer_generalAccessibility": "Загальна доступність",
  "mapView_filterBy": "Фільтрувати за",
  "mapView_filter_all": "Усе.",
  "mapView_filter_entrance": "Вхід",
  "mapView_filter_parking": "Парковка",
  "mapView_filter_toilet": "Туалет",
  "mapView_floor": "Підлога",
  "selectDisabilityType_mobility": "Мобільність",
  "selectDisabilityType_hearing": "Слух",
  "selectDisabilityType_allergy": "Алергія",
  "selectDisabilityType_cognitive": "Когнітивний",
  "selectDisabilityType_other": "Інше",
  "selectDisabilityType_select": "Виберіть",
  "selectDisabilityType_disability_type": "тип інвалідності",
  "selectDisabilityType_select_disability_type": "Виберіть тип інвалідності",
  "selectDisabilityType_select_what_type": "Фільтр за інвалідністю",
  "selectDisabilityType_visual": "Візуальний",
  "go_back": "Повернись.",
  "tabTitle_meetingRooms": "Кімнати для переговорів",
  "tabTitle_hospitalityRooms": "Кімнати для прийому гостей",
  "generalAccessibility_noGeneralAccessibilitiesForThisProperty": "Для цього об'єкта не знайдено жодного загального доступу",
  "generalAccessibility_noGeneralAccessibilitiesForTheseDisabilityFilters": "Для цих фільтрів для людей з обмеженими можливостями не знайдено жодного загального доступу",
  "informationByDisabilityContainer_noDataIsFound": "Дані не знайдено",
  "informationByDisabilityContainer_weDoNotHaveTheData": "Ми ще не маємо даних по цьому об'єкту нерухомості",
  "informationByDisabilityContainer_tryAnotherDisabilityFiltering": "Спробуйте інший фільтр за інвалідністю",
  "playground_information": "Інформація про дитячий майданчик",
  "total_playgrounds": "Всього дитячих майданчиків",
  "inventoried_playgrounds": "Інвентаризація дитячих майданчиків",
  "general_accessibility_and_information": "Загальна доступність та інформація",
  "detailed_accessibility": "Детальна доступність",
  "helsingborg_city_has_reviewed": "Місто Гельсінгборг проаналізувало доступність 26 з 200 дитячих майданчиків. Там ви знайдете інформацію про паркування, наявність туалетів та різних ігрових зон.",
  "for_detailed_accessibility_information": "Для отримання детальної інформації про доступність, будь ласка, оберіть відповідний дитячий майданчик.",
  "amount_of_accessible_toilets": "Кількість доступних туалетів",
  "mapView_filter_elevator": "Ліфт",
  "mapView_filter_reception_infodesk": "Прийом",
  "mapView_filter_playground": "Дитячий майданчик",
  "mapView_filter_garden": "Сад",
  "mapView_filter_shop": "Магазин",
  "mapView_filter_restaurant": "Ресторан",
  "mapView_filter_conference_room": "Конференц-зал",
  "mapView_filter_auditorium": "Зал для глядачів",
  "jarfalla_municipality_has_reviewed": "Муніципалітет міста Ярфелла проаналізував доступність 55 дитячих майданчиків, у тому числі 2 пригодницьких майданчиків, які сприяють розвитку креативності. Тут ви знайдете інформацію про паркування, доступність туалетів та різних ігрових зон.",
  "mapView_filter_vac": "Зміна рівня підлоги",
  "languages": "Мова",
  "english": "Англійська",
  "swedish": "Шведська",
  "danish": "Данська",
  "german": "Німецька",
  "spanish": "Іспанська",
  "french": "Французька",
  "norwegian": "Норвезька",
  "portuguese": "Португальська",
  "ukrainian": "Українська",
  "accessibility_room": "Кімната доступності",
  "pool": "Басейн",
  "spa": "Спа",
  "bar_restaurant": "Бар/Ресторан",
  "bathrooms": "Туалети",
  "shop": "Магазин",
  "exhibition": "Виставка",
  "rate_your_experience": "Чи можете ви допомогти нам покращити 🧑‍🦼🦮🦻? 🙏",
  "did_you_find_the_accessibility_information_you_needed": "Чи знайшли ви потрібну вам інформацію про доступність?",
  "how_satisfied_are_you_with_your_experience": "Наскільки ви задоволені своїм досвідом роботи з нашим посібником доступності?",
  "what_do_you_like_the_most": "Що вам подобається найбільше?",
  "sorry_to_hear_that": "Шкода це чути! Будь ласка, прокоментуйте чому, щоб відправити свій рейтинг",
  "we_would_love_to_hear_your_suggestions": "Ми з радістю вислухаємо ваші пропозиції",
  "submit": "Надіслати",
  "book_now": "Забронювати зараз",
  "very_bad": "Дуже погано",
  "bad": "Погано",
  "neutral": "Нейтрально",
  "good": "Добре",
  "great": "Чудово"
}