import { IconButton } from "@mui/material";
import React, {forwardRef, useEffect, useRef, useState} from "react";
import ReactImageGallery from "react-image-gallery";
import ImageGallery, { ReactImageGalleryProps } from "react-image-gallery";
import Icons from "../Icons";
import styled from "styled-components";
import { Mixpanel } from "../../Mixpanel";
import { MetricMessage } from "../../utils/metrics";
import {getIframeDocument} from "../../utils/utils";

type TypeWrapper = {
  height: string
}

const Wrapper = styled.div<TypeWrapper>`

`

const IconStyle = `
  position: absolute !important;
  z-index: 15000000;
  top: 50%;
  fill: white;
  transform: translateY(-50%);  
  background-color: rgba(0, 0, 0, 0.4) !important;
  width: 24px !important;
  height: 24px !important;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.9) !important;
  }
  @media (max-width: 694px) {
    width: 15px;
    height: 15px;
  }
`;
const LeftIcon = styled(IconButton)`
  ${IconStyle};
  left:5px;
`;
const RightIcon = styled(IconButton)`
  ${IconStyle};
  right: 5px;
`;


const handleThumbnailClick = (props: ReactImageGalleryProps) => {
  return props.onThumbnailClick;
};

const handlePictureClick = (onClick: React.MouseEventHandler<HTMLElement>) => {
  // Mixpanel.track(`${MetricMessage.picture_navigation}`);
  return onClick;
}

export const CustomImageGallery = forwardRef(
  (
    props: ReactImageGalleryProps,
    ref: React.ForwardedRef<ReactImageGallery>
  ) => {
    const doc = getIframeDocument()
    const [fullscreen, setFullscreen] = useState(false)
    const imageGalleryRef = useRef<any>(null)

    const handleKeydown = (e: any) => {
      if (imageGalleryRef && imageGalleryRef.current) {
        if (e.keyCode === 37) { //left arrow
          imageGalleryRef.current.slideToIndex(imageGalleryRef.current.getCurrentIndex() - 1);
        } else if (e.keyCode === 39) { //right arrow
          imageGalleryRef.current.slideToIndex(imageGalleryRef.current.getCurrentIndex() + 1);
        }
      }
    }

    useEffect(() => {
      if (fullscreen) {
        doc?.addEventListener('keydown', handleKeydown)
      }
      return () => doc?.removeEventListener('keydown', handleKeydown)
    }, [fullscreen])

    return (
      <ImageGallery
        lazyLoad={true}
        additionalClass={`img-gallery-overflow-hidden ${!props.showThumbnails ? 'thumbnails-false' : ''}`}
        ref={imageGalleryRef}
        renderLeftNav={(onClick, disabled) => {
          if (disabled) {
            return null;
          }

          return (
            <LeftIcon
              onClick={handlePictureClick(onClick)}
              disabled={disabled}
            >
              <Icons.CarretLeft />
            </LeftIcon>
          );
        }}
        renderRightNav={(onClick, disabled) => {
          if (disabled) {
            return null;
          }
          return (
            <RightIcon
              onClick={handlePictureClick(onClick)}
              disabled={disabled}
            >
              <Icons.CarretRight />
            </RightIcon>
          );
        }}
        useBrowserFullscreen={false}
        infinite={false}
        showFullscreenButton={true}
        showPlayButton={false}
        showThumbnails={props.showThumbnails}
        onSlide={(currentIndex) => {
          if (props.onSlide) props.onSlide(currentIndex)
        }}
        onThumbnailClick={() => {
          handleThumbnailClick(props)}}
        // {...props}
        onScreenChange={(state) => {
          setFullscreen(state)
          if (state !== fullscreen) {
            Mixpanel.track(`${MetricMessage.clicked_fullscreen_picture}`);
          }
        }}
        items={props.items}
      />
    );
  }
);
