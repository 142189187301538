import React, {FC, useMemo, SetStateAction, Dispatch, useState, useEffect, useRef} from "react";
import {Property} from "../../../Models/Property";
import { useFetchDetailedPropertyData } from "../../../hooks/useFetchDetailedPropertyData";
import MissingInformation from "./MissingInformation";
import SkeletonItemInfo from "./SkeletonItemInfo";
import HighlightedInfoContainer from "./HighlightedInfoContainer";
import ItemImageContainer from "./ItemImageContainer";
import GeneralItemInfoContainer from "./GeneralItemInfoContainer";
import {METRIC_TRIGGERED_ON} from "../../../constants/constants";
import { ReactImageGalleryItem } from "react-image-gallery";
import { ImageGalleryRenderer } from "../../../components/image-gallery/ImageGalleryRenderer";
import { A11yStandardType } from "../../../Models/utils";
import {Mixpanel} from "../../../Mixpanel";
import {MetricMessage} from "../../../utils/metrics";
import {
  getElementByIdFromIframe,
  getFilteredPropertyImagesByDisabilityType, getTextByCurrentLanguage, scrollToUnitByCurrentImage,
  sortPropertyImages
} from "../../../utils/utils";
import {useStorageData} from "../../../hooks/useStorageData";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";

type PropsType = {
  type: string
  a11yStandard: A11yStandardType
  propertyItem: Property | undefined
  setShowDetailedInfoPage: Dispatch<SetStateAction<boolean>>
}

const DetailedPropertyInfoItem: FC<PropsType> = ({
  type,
  a11yStandard,
  propertyItem,
  setShowDetailedInfoPage,
}) => {
  const {
    loading,
    propertyItemAccessibilityUnits,
    itemAccessibilites,
    propertyItemImages,
    propertyItemCategory,
    propertyItemFloors,
  } = useFetchDetailedPropertyData(propertyItem, a11yStandard);

  const theme = useTheme()
  const disabilityTypes = useStorageData('disability_types')
  const [currentImageData, setCurrentImageData] = useState({index: 0, accessibility_unit: '', name: ''})
  const accessibilityUnitRefs = useRef<HTMLDivElement[] | null[]>([])
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  // Auto-scrolling for Detailed information list
  useEffect(() => {
    if (isDesktop && accessibilityUnitRefs.current.length > 0) {
      scrollToUnitByCurrentImage(
        accessibilityUnitRefs,
        currentImageData,
        'detailed-information'
      )
    }
  }, [accessibilityUnitRefs.current.length, currentImageData])

  const handlerCurrentImage = (currentIndex: number, triggerMixpanel = true): void => {
    const sortedPropertyImages = sortPropertyImages(propertyItemImages, propertyItemAccessibilityUnits)
    if (sortedPropertyImages.length > 0) {
      let filteredPropertyImages = getFilteredPropertyImagesByDisabilityType(sortedPropertyImages, disabilityTypes, itemAccessibilites)
      if (filteredPropertyImages.length > 0 && filteredPropertyImages[currentIndex]) {
        const description = propertyItemAccessibilityUnits?.find((el) => (
          el.id === filteredPropertyImages[currentIndex].accessibility_unit
        ))
        setCurrentImageData({
          index: currentIndex,
          accessibility_unit: filteredPropertyImages[currentIndex].accessibility_unit,
          name: description ? getTextByCurrentLanguage(description.name) : ''
        })
        if (triggerMixpanel) {
          Mixpanel.track(`${MetricMessage.picture_navigation}`, {
            triggeredOn: METRIC_TRIGGERED_ON.PROPERTY_PAGE_SINGLE_UNIT
          });
        }
      }
    }
  }

  useEffect(() => {
    if (!loading) {
      const rootContent = getElementByIdFromIframe('root-content')
      rootContent?.scrollTo(0,0)
    }
  },[loading])

  const itemCarouselImages: ReactImageGalleryItem[] = useMemo(() => {
    if (propertyItemImages && propertyItemImages.length > 1) {
      handlerCurrentImage(0, false)
      const sortedPropertyImages = sortPropertyImages(propertyItemImages, propertyItemAccessibilityUnits)
      const filteredPropertyImages = getFilteredPropertyImagesByDisabilityType(sortedPropertyImages, disabilityTypes, itemAccessibilites)
      if (filteredPropertyImages) {
        return filteredPropertyImages.map<ReactImageGalleryItem>(
          ({ thumbnails, description, accessibility_unit }) => {
            const findAccessibilityUnit = propertyItemAccessibilityUnits
              .find((i) => i.id === accessibility_unit)
            return ({
              fullscreen: thumbnails.large,
              original: thumbnails.medium,
              thumbnail: thumbnails.small,
              renderItem: ImageGalleryRenderer(),
              thumbnailClass: "handiscover-thumbnail-class",
              imageDescription: description,
              description: findAccessibilityUnit ? getTextByCurrentLanguage(findAccessibilityUnit.name) : '',
            })
          });
      }
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyItemImages, propertyItemAccessibilityUnits, disabilityTypes]);

  return (
    <React.Fragment>
      {loading ? (
        <SkeletonItemInfo />
        ) : (
          <>
            {propertyItem && itemAccessibilites ? (
              <>
                <HighlightedInfoContainer
                  type={type}
                  property={propertyItem}
                  logo={propertyItem.logo}
                  name={getTextByCurrentLanguage(propertyItem.name)}
                  propertyItemFloors={propertyItemFloors}
                  propertyItemCategory={propertyItemCategory}
                  itemAccessibilites={itemAccessibilites}
                  a11yStandardOrUnitType={a11yStandard.key}
                  propertyItemAccessibilityUnits={propertyItemAccessibilityUnits}
                  setListPage={setShowDetailedInfoPage}
                  a11yStandard={a11yStandard}
                  currentImageData={currentImageData}
                />
                <ItemImageContainer
                  itemCarouselImages={itemCarouselImages}
                  onSlide={(currentIndex) => handlerCurrentImage(currentIndex)}
                />
                <GeneralItemInfoContainer
                  arrayOfPropertyItemAccessibilityUnits={propertyItemAccessibilityUnits}
                  itemAccessibilites={itemAccessibilites}
                  locatedOn={{
                    type: type,
                    triggeredOnPage: METRIC_TRIGGERED_ON.PROPERTY_PAGE_SINGLE_PROPERTY,
                    section: METRIC_TRIGGERED_ON.GENERAL_INFO_TABLE
                  }}
                  refs={accessibilityUnitRefs}
                />
              </>
              ) : (
                <MissingInformation />
              )}
          </>
        )
      }
    </React.Fragment>
  )
}

export default DetailedPropertyInfoItem
