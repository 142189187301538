export const TRANSLATIONS_NO = {
  "accessibility_button": "tilgjengelighet",
  "sidebar_Hotel": "Hotell",
  "sidebar_Rooms": "Rom",
  "sidebar_verified": "Verifisert av Handiscover",
  "hotel_floors": "Gulv",
  "hotel_totalRooms": "Totalt antall rom",
  "hotel_accessibleRooms": "Tilrettelagte rom",
  "hotel_allergyRooms": "Allergirom",
  "mainContainer_accessibilityInformation": "Informasjon om tilgjengelighet",
  "mainContainer_pictures": "Bilder",
  "mainContainer_informationByDisability": "Informasjon etter funksjonshemming",
  "mainContainer_GeneralHotelInformation": "Generell hotellinformasjon",
  "informationByDisability_mobility": "Mobilitet",
  "informationByDisability_hearing": "Hørsel",
  "informationByDisability_vision": "Syn",
  "informationByDisability_other": "Annen",
  "rooms_header": "Rominformasjon",
  "accessibility_room_icons": "Tilgjengelighetsikoner for rom",
  "roomInformation_generalRoomInformation": "Generell rominformasjon",
  "shopping_mall_mallFloors": "Mall Gulv",
  "shopping_mall_totalShops": "Lagerførte butikker",
  "shopping_mall_accessibleParking": "Tilgjengelige parkeringsplasser",
  "shopping_mall_foodCourts": "Food Courts",
  "mainContainer_GeneralMallInformation": "Generell kjøpesenterinformasjon",
  "rooms_noPictures": "Dette rommet har ingen bilder",
  "rooms_noInformation": "Dette rommet har ingen informasjon",
  "rooms_noIcons": "Ingen tilgjengelighetsikoner",
  "property_noPictures": "Ingen bilder funnet for denne eiendommen",
  "disability_noPictures": "Ingen bilder for den valgte funksjonshemmingen",
  "accessibilityIcons_notPresent": "Ingen tilgjengelighetsikoner",
  "shopping_mall": "kjøpesenter",
  "building_area_buildings": "Bygninger",
  "building_area_shops": "Lagerførte butikker",
  "building_area_parking_spaces": "Tilgjengelige parkeringsplasser",
  "building_area_food_courts": "Food Courts",
  "mainContainer_generalInformation": "Generell informasjon",
  "mainContainer_GeneralOfficeInformation": "Generell kontorinformasjon",
  "mainContainer_GeneralShopInformation": "Generell butikkinformasjon",
  "header_buildingInformation": "Bygningsinformasjon",
  "a11yStandard_shopping_mall": "Butikk",
  "a11yStandard_shopping_mall_plural": "Butikker",
  "property_floorSignular": "Gulv",
  "property_floorPlural": "Gulv",
  "property_tabTitle_shops": "Butikker informasjon",
  "general_invalid_API_CALL_errorMessage": "Det har oppstått et problem! Kunne ikke hente data.",
  "general_missingInfo_forItem": "Det ser ut til at informasjonen ikke er lagt til ennå",
  "translated_text": "Tittelen er oversatt",
  "accessibility_button_open_ariaLabel": "Åpne tilgjengelighetsinformasjon",
  "mainContainer_mapView": "Kartvisning",
  "mainContainer_generalAccessibility": "Generell tilgjengelighet",
  "mapView_filterBy": "Filtrer etter",
  "mapView_filter_all": "Alle",
  "mapView_filter_entrance": "Inngang",
  "mapView_filter_parking": "Parkering",
  "mapView_filter_toilet": "Toalett",
  "mapView_floor": "Gulv",
  "selectDisabilityType_mobility": "Mobilitet",
  "selectDisabilityType_hearing": "Hørsel",
  "selectDisabilityType_allergy": "Allergi",
  "selectDisabilityType_cognitive": "Kognitiv",
  "selectDisabilityType_other": "Annen",
  "selectDisabilityType_select": "Plukke ut",
  "selectDisabilityType_disability_type": "funksjonshemming type",
  "selectDisabilityType_select_disability_type": "Velg funksjonshemmingstype",
  "selectDisabilityType_select_what_type": "Filtrer etter funksjonshemming",
  "selectDisabilityType_visual": "Visuell",
  "go_back": "Gå tilbake",
  "tabTitle_meetingRooms": "Møterom",
  "tabTitle_hospitalityRooms": "Gjestfrihetsrom",
  "generalAccessibility_noGeneralAccessibilitiesForThisProperty": "Ingen generelle tilgjengeligheter ble funnet for denne eiendommen",
  "generalAccessibility_noGeneralAccessibilitiesForTheseDisabilityFilters": "Ingen generelle tilgjengeligheter ble funnet for disse funksjonshemmingsfiltrene",
  "informationByDisabilityContainer_noDataIsFound": "Ingen data ble funnet",
  "informationByDisabilityContainer_weDoNotHaveTheData": "Vi har ikke dataene for denne eiendommen ennå",
  "informationByDisabilityContainer_tryAnotherDisabilityFiltering": "Prøv en annen funksjonshemmingsfiltrering",
  "playground_information": "Informasjon om lekeplassen",
  "total_playgrounds": "Totalt lekeplasser",
  "inventoried_playgrounds": "Inventarerte lekeplasser",
  "general_accessibility_and_information": "Generell tilgjengelighet og informasjon",
  "detailed_accessibility": "Detaljert tilgjengelighet",
  "helsingborg_city_has_reviewed": "Helsingborg by har vurdert tilgjengeligheten til 26 av sine 200 lekeplasser. Der får du informasjon om parkering, tilgjengelighet på toaletter og ulike lekeområder.",
  "for_detailed_accessibility_information": "For detaljert tilgjengelighetsinformasjon, velg respektive lekeplass.",
  "amount_of_accessible_toilets": "Antall tilgjengelige toaletter",
  "mapView_filter_elevator": "Heis",
  "mapView_filter_reception_infodesk": "Resepsjon",
  "mapView_filter_playground": "Lekeplass",
  "mapView_filter_garden": "Hage",
  "mapView_filter_shop": "Butikk",
  "mapView_filter_restaurant": "Restaurant",
  "mapView_filter_conference_room": "Konferanserom",
  "mapView_filter_auditorium": "Auditorium",
  "jarfalla_municipality_has_reviewed": "Järfälla kommune har gjennomgått tilgjengeligheten til 55 lekeplasser, inkludert 2 opplevelseslekeplasser som fremmer kreativitet. Der får du informasjon om parkering, tilgjengelighet til toaletter og de ulike lekeplassene.",
  "mapView_filter_vac": "Endring av gulvnivå",
  "languages": "Språk",
  "english": "Engelsk",
  "swedish": "Svensk",
  "danish": "Dansk",
  "german": "Tysk",
  "spanish": "Spansk",
  "french": "Fransk",
  "norwegian": "Norsk",
  "portuguese": "Portugisisk",
  "ukrainian": "Ukrainsk",
  "accessibility_room": "Tilgjengelighetsrom",
  "pool": "Basseng",
  "spa": "Spa",
  "bar_restaurant": "Bar/Restaurant",
  "bathrooms": "Baderom",
  "shop": "Butikk",
  "exhibition": "Utstilling",
  "rate_your_experience": "Kan du hjelpe oss med å bli bedre🧑‍🦼🦮🦻? 🙏",
  "did_you_find_the_accessibility_information_you_needed": "Fant du tilgjengelighetsinformasjonen du trengte?",
  "how_satisfied_are_you_with_your_experience": "Hvor fornøyd er du med opplevelsen din av tilgjengelighetswidgeten vår?",
  "what_do_you_like_the_most": "Hva liker du mest?",
  "sorry_to_hear_that": "Vennligst kommenter hvorfor, for å sende inn vurderingen din",
  "we_would_love_to_hear_your_suggestions": "Vi vil gjerne høre dine forslag",
  "submit": "Sende inn",
  "book_now": "Bestill nå",
  "very_bad": "Veldig dårlig",
  "bad": "Dårlig",
  "neutral": "Nøytral",
  "good": "Flink",
  "great": "Flott"
}