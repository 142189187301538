import styled from "styled-components";
import React, {FC, useEffect, useRef, useState} from "react";
import {Accessibility} from "../../../Models/Accessibility";
import {getPoiIcons} from "../utils/poiIcons";
import RevealElements from "./RevealElements";
import {Tooltip} from "@mui/material";

type PropsTypeUnion = {
  backgroundColor: string;
}

const Union = styled.div<PropsTypeUnion>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 6px;
  height: 23px;
  background: ${(props) => props.backgroundColor};
  padding: 2px 5px;
  border: 2px solid #ffffff;
  z-index: 100;
  ::before {
    content: "";
    position: absolute;
    bottom: -12px;
    left: calc(50% - 6px);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 11px solid #ffffff;
  }
  ::after {
    content: "";
    position: absolute;
    bottom: -9px;
    left: calc(50% - 5px);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid ${(props) => props.backgroundColor};
  }
`

type PropsType = {
  selected: boolean
  accessibilities: Accessibility[] | undefined
  standard: string
  data: any
}

const POI:FC<PropsType> = ({
  selected,
  data,
  accessibilities,
  standard
}) => {
  const poiIcons = getPoiIcons(standard, data, accessibilities)
  const unionRef = useRef<HTMLDivElement | null>(null);
  const [openCircularMenu, setOpenCircularMenu] = useState<boolean>(false)
  const [delayHandler, setDelayHandler] = useState<any>(null)
  const [openTooltip, setOpenTooltip] = useState(false)

  const handleOnMouseEnter = (e: React.SyntheticEvent) => {
    setDelayHandler(setTimeout(() => {
      setOpenCircularMenu(true)
      setOpenTooltip(true)
    }, 500))
  }
  const handleMouseLeave = (e: React.SyntheticEvent) => {
    clearTimeout(delayHandler)
    setOpenCircularMenu(false)
    setOpenTooltip(false)
  }

  useEffect(() => {
    if (selected) {
      setOpenTooltip(false);
    }
  }, [selected]);

  return (
    <>
      <Tooltip
        open={openTooltip}
        title={poiIcons.length === 1 ? poiIcons[0].title : ''}
        enterDelay={500}
        enterNextDelay={500}
        placement={'top'}
        arrow={true}
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -5],
              },
            },
          ],
        }}
      >
        <Union
          ref={unionRef}
          backgroundColor={selected ? '#76232f' : '#2F80ED'}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={poiIcons[0].src}
            height={18}
            width={18}
            style={{objectFit: 'contain'}}
            alt={poiIcons[0].title}
          />
        </Union>
      </Tooltip>
      <RevealElements
        show={openCircularMenu || selected}
        items={poiIcons.slice(1)}
        selected={selected}
        radius={40}
        startPosition={-120}
      />
    </>
  )
}

export default POI