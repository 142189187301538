import {Box} from "@mui/system";
import {Button as MuiButton, Dialog as MuiDialog, TextField} from "@mui/material";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import {FC, useEffect, useState} from "react";
import Icons from "../Icons";
import {Mixpanel} from "../../Mixpanel";
import {MetricMessage} from "../../utils/metrics";
import {useTranslation} from "react-i18next";
import {useStorageData} from "../../hooks/useStorageData";
import Chip from "@mui/material/Chip";

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    box-shadow: 0 12px 24px rgba(37,42,49,0.24), 0 8px 60px rgba(37,42,49,0.32) !important;
    margin: 10px
  }
  z-index: 15000001;
  .MuiBackdrop-root {
    background: rgb(0 0 0 / 18%);
  }
  @media (max-width: 768px) {
    .MuiPaper-root {
      box-shadow: 0 12px 24px rgba(37,42,49,0.24), 0 8px 60px rgba(37,42,49,0.32) !important;
      margin: 0
    } 
  }
`

const Modal = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 25px;
  border-radius: 4px;
  position: relative;
`

const CloseButton = styled(MuiButton)`
  position: absolute;
  top: 5px;
  right: 5px;
  min-width: 16px;
`

const StyledCloseIcons = styled(Icons.CloseIcon)`
  width: 16px;
  height: 16px;
`

const RateButton = styled(MuiButton)`
  height: 50px;
  min-width: unset;
  width: 100%;
  border-radius: 4px;
  color: #2b2b2c;
  font-size: 15px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  text-transform: unset;
  :hover {
    background: rgba(205, 207, 211, 0.42);
  }

  :not(:last-child) {
    margin-right: 10px;
  }

  &.selected-rate {
    background: rgba(25, 118, 210, 0.08);
    color: #1976d2;
    border-color: #1976d25c
  }

  &.error-selected-rate {
    background: rgba(210, 25, 25, 0.08);
    color: #d21919;
    border-color: rgba(210, 25, 25, 0.36)
  }
`

const VALUES = [5, 4, 3, 2, 1]
const KEYS = ['great', 'good', 'neutral', 'bad', 'very_bad']
const TIMEOUT_TIME = 40000

type PropsType = {
  open: boolean
  onClose: () => void
}

const GOOD_SUGGESTIONS = [
  'Accessibility', 'Photos', 'Information', 'Map', 'Interface', 'I found what I need',
]
const BAD_SUGGESTIONS = [
    'Unclearly', 'Not much data', 'Bad interface', 'Bad photos', 'Incorrect point on map', 'Found a bug', 'I didn\'t figure out the widget'
]

type SuggestionChipType = {
  index: number
  label: string
  onClick: (index: number) => void
  selectedChip: number[],
  color?: "default" | "primary" | "error" | "secondary" | "info" | "success" | "warning" | undefined
}

const SuggestionChip: FC<SuggestionChipType> = ({
  index,
  label,
  onClick,
  selectedChip,
  color = 'primary'
}) => {
  const colors = { background: 'rgba(25,118,210,0.08)', color: '#1976d2' }
  if (color === 'error') {
    colors.background = 'rgba(210,25,25,0.08)'
    colors.color = '#d32f2f'
  }

  return (
      <Chip
          style={{
            marginRight: 8,
            marginBottom: 8,
            background: selectedChip.includes(index) ? colors.background : 'transparent',
            color: selectedChip.includes(index) ? colors.color : '#1f1f21',
            borderRadius: 4
          }}
          variant={'outlined'}
          color={selectedChip.includes(index) ? color : 'default'}

          onClick={() => onClick(index)}
          label={label}
      />
  )
}

const FeedbackModal: FC<PropsType> = ({open, onClose}) => {
  const { t } = useTranslation()
  const [selectedChip, setSelectedChip] = useState<number[]>([])
  const [accessibilityInformationFound, setAccessibilityInformationFound] = useState<number | null>(null)
  const [accessibilityWidgetSatisfaction, setAccessibilityWidgetSatisfaction] = useState<number | null>(null)
  const [inputError, setInputError] = useState(false)
  const [suggestions, setSuggestions] = useState('')
  // const feedbackShown = useStorageData('feedbackShown')
  const informationFound = useStorageData('informationFound')
  const widgetSatisfaction = useStorageData('widgetSatisfaction')

  useEffect(() => {
    if (selectedChip.length > 0) {
      setSelectedChip([])
    }
  }, [accessibilityInformationFound, accessibilityWidgetSatisfaction]);

  // useEffect(() => {
  //   if (!open && !feedbackShown) {
  //     let feedbackTimeout = setTimeout(() => {
  //       Mixpanel.track(`${MetricMessage.feedback_shown}`)
  //       localStorage.setItem("feedbackShown", "true");
  //       window.dispatchEvent(new Event('storage'))
  //       setFeedbackOpen(true)
  //     }, TIMEOUT_TIME)
  //     return () => {
  //       clearTimeout(feedbackTimeout);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (suggestions.trim().length > 3) {
      setInputError(false)
    }
  }, [suggestions]);


  const handleRateAnswer = (rate: number, field: string) => {
    if (field === 'informationFound') {
      setAccessibilityInformationFound(rate)
      if (!informationFound) {
        localStorage.setItem("informationFound", "true");
        window.dispatchEvent(new Event('storage'))
        if (rate > 2) {
          Mixpanel.track(
              `${MetricMessage.feedback_data}`,
              { accessibility_information_found: rate }
          );
        }
      }
    }
    if (field === 'widgetSatisfaction') {
      setAccessibilityWidgetSatisfaction(rate)
      if (!widgetSatisfaction) {
        localStorage.setItem("widgetSatisfaction", "true");
        window.dispatchEvent(new Event('storage'))
        if (rate > 2) {
          Mixpanel.track(
              `${MetricMessage.feedback_data}`,
              { accessibility_widget_satisfaction: rate }
          );
        }
      }
    }
  }

  const getTextByValue = () => {
    if (
      accessibilityWidgetSatisfaction && accessibilityWidgetSatisfaction > 2 &&
      accessibilityInformationFound && accessibilityInformationFound > 2
    ) {
      return t('what_do_you_like_the_most')
    }
    return t('sorry_to_hear_that')
  }

  const getChips = () => {
    if (accessibilityWidgetSatisfaction && accessibilityInformationFound) {
      const handleClick = (index: number) => {
        if (selectedChip.length > 0 && selectedChip.includes(index)) {
          setSelectedChip(selectedChip.filter((i) => i !== index))
        } else {
          setSelectedChip([...selectedChip, index])
        }
      }
      if (accessibilityWidgetSatisfaction > 3 && accessibilityInformationFound > 3) {
        return GOOD_SUGGESTIONS.map((i, index) => (
          <SuggestionChip
            label={i}
            onClick={handleClick}
            index={index}
            selectedChip={selectedChip}
          />
        ))
      } else {
        return BAD_SUGGESTIONS.map((i, index) => (
          <SuggestionChip
            label={i}
            onClick={handleClick}
            index={index}
            selectedChip={selectedChip}
            color={'error'}
          />
        ))
      }
    }
    return null
  }

  const handleSubmit = () => {
    if (
        accessibilityWidgetSatisfaction && accessibilityWidgetSatisfaction > 2 &&
        accessibilityInformationFound && accessibilityInformationFound > 2
    ) {
      Mixpanel.track(`${MetricMessage.feedback_data}`, { suggestions: suggestions });
      onClose()
    } else {
      if (suggestions.trim().length > 3) {
        Mixpanel.track(`${MetricMessage.feedback_data}`, {
          accessibility_information_found: accessibilityInformationFound,
          accessibility_widget_satisfaction: accessibilityWidgetSatisfaction,
          suggestions: suggestions
        });
        onClose()
      } else {
        setInputError(true)
      }
    }
  }

  const getButtonClassName = (item: number, selectedItem: number | null) => {
    if (item === selectedItem) {
      return selectedItem > 2 ? 'selected-rate' : 'error-selected-rate'
    }
    return ''
  }

  return (
    <Dialog
      open={open}
      disablePortal
    >
      <Modal>
        <CloseButton onClick={() => onClose()}><StyledCloseIcons/></CloseButton>
        <Typography variant={'h6'} textAlign={'center'}>{t('rate_your_experience')}</Typography>
        <Box mt={2}>
          <Typography>{t('did_you_find_the_accessibility_information_you_needed')}</Typography>
        </Box>
        <Box mt={1} display={'flex'}>
          {VALUES.map((item, index) => (
            <RateButton
              key={index}
              className={getButtonClassName(item, accessibilityInformationFound)}
              onClick={() => handleRateAnswer(item, 'informationFound')}
            >
              {t(`${KEYS[index]}`)}
            </RateButton>
          ))}
        </Box>
        <Box mt={3}>
          <Typography>{t('how_satisfied_are_you_with_your_experience')}</Typography>
        </Box>
        <Box mt={1} display={'flex'}>
          {VALUES.map((item, index) => (
            <RateButton
              key={index}
              className={getButtonClassName(item, accessibilityWidgetSatisfaction)}
              onClick={() => handleRateAnswer(item, 'widgetSatisfaction')}
            >
              {t(`${KEYS[index]}`)}
            </RateButton>
          ))}
        </Box>
        {accessibilityWidgetSatisfaction && accessibilityInformationFound ? (
          <>
            <Box mt={3}>
              <Typography>{getTextByValue()}</Typography>
            </Box>
            {/*<Box mt={1}>*/}
            {/*  {getChips()}*/}
            {/*</Box>*/}
            <Box mt={1}>
              <TextField
                value={suggestions}
                onChange={(e) => setSuggestions(e.target.value)}
                placeholder={t('we_would_love_to_hear_your_suggestions')}
                error={inputError}
                fullWidth
              />
            </Box>
            <Box mt={2} display={'flex'} justifyContent={'right'}>
              <MuiButton
                  variant={'contained'}
                  onClick={handleSubmit}
              >
                {t('submit')}
              </MuiButton>
            </Box>
          </>
        ) : null}
      </Modal>
    </Dialog>
  )
}

export default FeedbackModal