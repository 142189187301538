import React, {FC} from "react";
import {A11yStandardType} from "../Models/utils";
import styled, {css} from "styled-components";
import {CustomAccessibilityButton, Loader, BtnImgIcon, AccBtnText} from "../StyledWrappers/Wrappers";
import iconImg from "../images/icons/person-icon.svg";
import {WidgetSettings} from "../Models/WidgetSettings";

const AccessibilityButton = styled.button`
  ${(props) =>
    props.disabled
      ? css`
        display: none;
        background-color: rgba(18, 22, 25, 0.5) !important;
      `
      : css`
        background-color: #121619 !important;
      `}
  font-family: Helvetica,sans-serif!important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 41px !important;
  margin: 0 !important;
  font-weight: 400 !important;
  padding: 1px 17px !important;
  // box-sizing: border-box;
  border: 1px solid #ffffff !important;
  border-radius: 40px !important;
  color: #ffffff !important;
  font-size: 15px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.25px !important;
  cursor: pointer !important;
  float: right !important;
  margin-right: 24px !important;
  margin-bottom: 15px !important;
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  z-index: 1400000 !important;
  &:focus {
    background-color: #121619 !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
    outline: 3px solid rgb(0 0 0) !important;
    outline-offset: 1px !important;
  }
  &:hover {
    background-color: #121619 !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    font-size: 14px !important;
    padding: 1px 17px !important;
    height: 37px !important;
    padding: 1px 15px !important;
    letter-spacing: 0.5px;
  }
`;

type PropsType = {
  companyIdAPIKey: string
  a11yStandard: A11yStandardType | undefined
  settings: WidgetSettings[] | undefined
  loadingState: boolean
  loading: boolean
  t: (key: string) => string
  onAccessibilityClick: () => void
}

const OpenWidgetButton: FC<PropsType> = ({
    companyIdAPIKey,
    a11yStandard,
    settings,
    loadingState,
    loading,
    t,
    onAccessibilityClick,
  }) => {

    const isHaveSettings = settings && settings[0];

    const isCompanyEmporia =
      companyIdAPIKey === "03MYUEH0.r6NomgA7qiBTU774rq6aKghbHbmqq9Z0" ||
      companyIdAPIKey === "YfBkJ1oX.6U9t8MQ1ZB33iVCYQUji96HJYeWFGhRW"

    const getAccessibilityButtonText = () => (
      `${t("accessibility_button")}`
    )

    const getButtonWidget = () => {
      // If it is Emporia or ICA company id we need to render button with loading state
      if (isHaveSettings && a11yStandard && !isCompanyEmporia) {
        return (
          <CustomAccessibilityButton
            settings={settings}
            onAccessibilityClick={onAccessibilityClick}
            loading={loading}
            a11yStandard={a11yStandard}
            t={t}
          />
        )
      } else if (!isHaveSettings || isCompanyEmporia) {
        return (
          <AccessibilityButton
            onClick={onAccessibilityClick}
            aria-label={t("accessibility_button_open_ariaLabel")}
            disabled={loadingState && isCompanyEmporia}
            tabIndex={1}
          >
            {(loadingState && isCompanyEmporia) ? (
              <Loader color="inherit" />
            ) : (
              <>
                <BtnImgIcon src={iconImg} />
                <AccBtnText>
                  {getAccessibilityButtonText()}
                </AccBtnText>
              </>
            )}
            </AccessibilityButton>
        )
      } else {
        return (
          <></>
        )
      }
    }

    return getButtonWidget()
}

export default OpenWidgetButton
