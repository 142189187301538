import mixpanel from "mixpanel-browser";
import ips from "./ip-blacklist.json";

let env_check = process.env.NODE_ENV;

const path = window.location.href.split('/');
const isDevEnv = path.includes("demo" || "localhost" || "amswidget-accessibility-dev.handiscover.com");
if (env_check === "development" || isDevEnv) {
  mixpanel.init("4d695739007ee9deb43c37c489ba96d4", { ignore_dnt: true });
  // Set a default test user for less charging on this dev instance
  mixpanel.identify("mixpanelTestUser@handiscover.com");
} else {
  mixpanel.init("419371d9c7988ebf9fc1cb5c0679b0ca", { ignore_dnt: true });
}

async function getIP() {
  const response = await fetch("https://api.ipify.org/?format=json");
  const client = await response.json();
  return client.ip;
}

getIP().then((result) => {
  const exists = !!ips.blacklist.includes(result);
  if (exists) {
    localStorage.setItem("ipExist", "true");
  } else {
    localStorage.setItem("ipExist", "false");
  }
});

function getActions() {
  let enclosedIDs = {
    companyId: null,
    propertyId: null,
    buildingAreaId: null,
  };
  const fullscreen = localStorage.getItem('fullscreen')
  const isInIframe = window.self !== window.top
  return {
    track: (name, props) => {
      const cookieConsent = localStorage.getItem('cookie-consent')
      if (JSON.parse(cookieConsent) || !JSON.parse(fullscreen) || isInIframe) {
        mixpanel.track(name, { ...props, ...enclosedIDs });
        if (localStorage.getItem("ipExist") === "true") {
          mixpanel.register({ $ignore: "true" });
        }
      }
    },
    setGroup: ({ companyId, propertyId = null, buildingAreaId = null }) => {
      const cookieConsent = localStorage.getItem('cookie-consent')
      enclosedIDs = { companyId, propertyId, buildingAreaId };
      if (JSON.parse(cookieConsent) || !JSON.parse(fullscreen) || isInIframe) {
        mixpanel.set_group("company_id", companyId);
        mixpanel.register({
          companyId,
          propertyId,
          buildingAreaId,
        });
      }
    },
    };
  }

let actions = getActions();

if (process.env.NODE_ENV === "development") {
  console.log("environment check: ", env_check);
}

export let Mixpanel = actions;
