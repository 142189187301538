import React, {
  useState,
  useEffect,
  useMemo,
  useContext, useRef
} from "react";
import { ReactImageGalleryItem } from "react-image-gallery";
import { Property } from "../../Models/Property";
import { AccessibilityUnit } from "../../Models/AccessibilityUnits";
import { PropertyAccessibilityImages } from "../../Models/PropertyAccessibilityImages";
import { ImageGalleryRenderer } from "../../components/image-gallery/ImageGalleryRenderer";
import { A11yStandardType } from "../../Models/utils";
import {a11yStandardKeys, METRIC_TRIGGERED_ON, unitTypeKeys} from "../../constants/constants";
import { AccessibilityWidgetInfoContainer } from "../../components/AccessibilityWidgetInfoContainer";
// import { GetClassification } from "../../components/GetClassificationIcon";
// import { useTranslation } from "react-i18next";
// import "../../translations/i18n";
import { TabsContext } from "../../components/MainPage";
// import { i18n } from "../../translations/i18n";
import { Mixpanel } from "../../Mixpanel";
import { MetricMessage } from "../../utils/metrics";
// import loadable from "@loadable/component";
import styled from "styled-components";
import { useFetchInfoFromProperty } from "../../hooks/useFetchInfoFromProperty";
// import { useTranslator } from "aws-translator/dist/useTranslator";
import ImageContainer from "./components/ImageContainer";
import GeneralRoomInformationContainer from "./components/GeneralRoomInformationContainer";
import InformationBlock from "./components/InformationBlock";
import ElementOfTable from "./components/ElementOfTable";
import TabContentProperties from "./components/TabContentProperties";
import TabContentUnits from "./components/TabContentUnits";
import TabsBlock from "./components/TabsBlock";
// import {getCurrentServer} from "../../services/utils";
// import {useEffectAsync} from "../../hooks/useEffectAsync";
import {MapContext} from "./PropertyWrapper";
import MapWrapper from "../../components/map/MapWrapper";
import {useStorageData} from "../../hooks/useStorageData";
import useWindowDimensions from "../../hooks/useWindowDementions";
// const InformationByDisability = loadable(
//   () => import("../../components/InformationByDisabilityContainer"),
//   {
//     fallback: <div>Loading ...</div>,
//   }
// );
import InformationByDisability from "../../components/InformationByDisabilityContainer";
import {Button, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {
  getElementByIdFromIframe,
  getFilteredPropertyImagesByDisabilityType, getTextByCurrentLanguage, scrollToUnitByCurrentImage,
  sortPropertyImages
} from "../../utils/utils";
import {useTranslation} from "react-i18next";
import MainAccessibility from "../../components/MainAccessibility";
import informationBlock from "./components/InformationBlock";
import {AccessibilityImages} from "../../Models/AccessibilityImages";
import {Box} from "@mui/system";

const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return (
    <>{value === index && (<>{children}</>)}</>
  );
}

type CachedStates = {
  tabValue: number,
  field: number,
}

// Saved/cached states for language change
let cachedStates: CachedStates = {
  tabValue: 0,
  field: 0,
}

export const PropertyPage = (p: {
  property: Property;
  a11yStandard: A11yStandardType;
  displayShops: boolean;
  companyIdAPIKey: string;
  buildingAreaId: string | null;
  fullscreen: boolean
}) => {

  const { property, a11yStandard, displayShops, buildingAreaId, fullscreen } = p;
  const [value, setValue] = useState(cachedStates.tabValue);
  const [field] = useContext(TabsContext);
  const theme = useTheme()
  const [accessibilityTable, setAccessibilityTable] = useState<JSX.Element[]>();
  const [activePoiId, setActivePoiId] = useState("");
  const [clickedMapPoiName, setClickedMapPoiName] = useState("");
  const [showMap] = useContext(MapContext);
  const [currentImageData, setCurrentImageData] = useState({index: 0, accessibility_unit: '', name: ''})
  const disabilityTypes = useStorageData('disability_types')
  const { height } = useWindowDimensions();
  const generalInformationRefs = useRef<HTMLDivElement[] | null[]>([])
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation()
  const [informationBlockStatusData, setInformationBlockStatusData] = useState<boolean>(false)

  useEffect(() => {
    // reset all cachedStates and regular states when user visits new property
    if (field !== cachedStates.field) {
      cachedStates.tabValue = 0;
      cachedStates.field = field;
      setValue(0);
    }
  }, [field])

  
  const displayUnitRooms = (rooms: AccessibilityUnit[]) =>
  !!(rooms && rooms.length > 0) && buildingAreaId === null
  
  const {
    parkingSpaces,
    toiletRoom,
    totalShop,
    accessibilities,
    hospitalityRooms,
    meetingRooms,
    exhibitionRooms,
    museumShopRooms,
    toiletCommonRooms,
    propertyImages,
    propertyAccessibilityInfo,
    propertyFreeTextInfo,
    accessibleUnits,
    unitLevels,
    questions,
  } = useFetchInfoFromProperty(property, property.id, a11yStandard);

  const handleChange = (event: any, newValue: number) => {
    if (newValue === 0) {
      Mixpanel.track(`${MetricMessage.accessed_property_tab}`);
    }
    if (newValue === 1) {
      Mixpanel.track(`${MetricMessage.accessed_unit_list}`, {
        a11yStandard: a11yStandardKeys.shop,
        unitType: null
      });
    }
    if (newValue === 2) {
      Mixpanel.track(`${MetricMessage.accessed_unit_list}`, {
        a11yStandard: null,
        unitType: unitTypeKeys.hotelRoomType
      });
    }
    if (newValue === 3) {
      Mixpanel.track(`${MetricMessage.accessed_unit_list}`, {
        a11yStandard: null,
        unitType: unitTypeKeys.meetingRoom
      });
    }
    cachedStates.tabValue = newValue;
    setValue(newValue);
  };

  useEffect(() => {
    if (propertyImages && propertyImages.length > 0) {
      handlerCurrentImage(0, "")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyImages, value])

  useEffect(() => {
    if (accessibleUnits && accessibleUnits.length > 0) {
      const table = accessibleUnits.map((el, index) => (
        <ElementOfTable
          id={el.id}
          name={getTextByCurrentLanguage(el.name)}
          title={el.title}
          key={el.id}
          onSetRef={el => generalInformationRefs.current[index] = el}
          accessibilities={accessibilities}
        />
      ));
      setAccessibilityTable(table);
    }
  }, [accessibleUnits, accessibilities]);

  const carouselImages: ReactImageGalleryItem[] = useMemo(() => {
    if (showMap && activePoiId && clickedMapPoiName) {
      let poiImages: PropertyAccessibilityImages[] = [];
      if (propertyImages.length > 0) {
        const sortedPropertyImages = sortPropertyImages(propertyImages, accessibleUnits)
        let filteredPropertyImages = getFilteredPropertyImagesByDisabilityType(sortedPropertyImages, disabilityTypes, accessibilities)
        const filteredByAccessibilityUnit = filteredPropertyImages
          .filter((i) => i.accessibility_unit === activePoiId)
        if (filteredByAccessibilityUnit.length > 0) {
          poiImages = filteredByAccessibilityUnit
        }
      }

      return poiImages.map<ReactImageGalleryItem>(
        ({ thumbnails, description, accessibility_unit }) => ({
          fullscreen: thumbnails.large,
          original: thumbnails.medium,
          thumbnail: thumbnails.small,
          renderItem: ImageGalleryRenderer(),
          thumbnailClass: "handiscover-thumbnail-class",
          imageDescription: description,
          description: clickedMapPoiName
        })
      );
    } else if (propertyImages && accessibleUnits) {
      const sortedPropertyImages = sortPropertyImages(propertyImages, accessibleUnits)
      let filteredPropertyImages = getFilteredPropertyImagesByDisabilityType(sortedPropertyImages, disabilityTypes, accessibilities)
      const foundGeneralImage = filteredPropertyImages.find((i) => i.id === property.id)
      if (property.general_image && !foundGeneralImage) {
        filteredPropertyImages.unshift({
          id: property.id,
          thumbnails: {
            small: property.general_image,
            medium: property.general_image,
            large: property.general_image
          },
          image: property.general_image,
          is_display_in_output_service: true,
          is_general_image: false,
          accessibility_unit: '',
          accessibility: '',
          description: property.general_image_description,
        })
      }
      return filteredPropertyImages.map<ReactImageGalleryItem>(
        ({ thumbnails, description, accessibility_unit }) => ({
          fullscreen: thumbnails.large,
          original: thumbnails.medium,
          thumbnail: thumbnails.small,
          renderItem: ImageGalleryRenderer(),
          thumbnailClass: "handiscover-thumbnail-class",
          imageDescription: description,
          description: getTextByCurrentLanguage(accessibleUnits?.find((el) => el.id ===  accessibility_unit)?.name),
        })
      );
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyImages, showMap, activePoiId, disabilityTypes, accessibilities, accessibleUnits]);

  useEffect(() => {
    if (activePoiId.length > 0) {
      if (showMap) {
        handlerCurrentImage(0, METRIC_TRIGGERED_ON.PROPERTY_PAGE_MAP_VIEW)
      } else {
        setActivePoiId('')
        handlerCurrentImage(0, '')
      }
    }
  }, [showMap, activePoiId])

  useEffect(() => {
    if (isDesktop && generalInformationRefs.current.length > 0) {
      scrollToUnitByCurrentImage(
        generalInformationRefs,
        currentImageData,
        'detailed-information'
      )
    }
  }, [generalInformationRefs.current.length, currentImageData, accessibilityTable])

  const handlerCurrentImage = (currentIndex: number, navigatedOn: string) => {
    if (property.general_image && !showMap) {
      currentIndex = currentIndex - 1
    }
    const sortedPropertyImages = sortPropertyImages(propertyImages, accessibleUnits)
    if (sortedPropertyImages.length > 0) {
      let filteredPropertyImages = getFilteredPropertyImagesByDisabilityType(sortedPropertyImages, disabilityTypes, accessibilities)
      if (activePoiId) {
        filteredPropertyImages = filteredPropertyImages
          .filter((i) => i.accessibility_unit === activePoiId)
      }

      if (filteredPropertyImages.length > 0 && filteredPropertyImages[currentIndex]) {
        const description = accessibleUnits?.find((el) => (
          el.id === filteredPropertyImages[currentIndex].accessibility_unit
        ))
        setCurrentImageData({
          index: currentIndex,
          accessibility_unit: filteredPropertyImages[currentIndex].accessibility_unit,
          name: description ? getTextByCurrentLanguage(description.name) : ''
        })
      }
      if (navigatedOn !== "") {
        Mixpanel.track(`${MetricMessage.picture_navigation}`, {
          triggeredOn: navigatedOn
        });
      }
    }
  }

  const getBookButton = (property: Property, isDesktop: boolean, position: number) => {
    const { additional_information } = property
    if (additional_information && additional_information.link && value === 0) {
      const handleClick = () => {
        Mixpanel.track(`${MetricMessage.clicked_book_now}`);
        window.open(additional_information.link, '_blank');
      };

      if (position === 1) {
        return !isDesktop ? (
          <Button
            variant={'contained'}
            size={'small'}
            style={{marginBottom: 10, marginTop: 15, height: 30}}
            onClick={handleClick}

          >
            {t('book_now')}
          </Button>
        ) : null
      } else if (position === 2) {
        return isDesktop ? (
          <Button
            variant={'contained'}
            style={{maxHeight: 27, minWidth: 120}}
            onClick={handleClick}
          >
            {t('book_now')}
          </Button>
        ) : null
      }
    }
    return null
  }

  return (
    <RootDiv>
      <InformationBlock
        a11yStandard={a11yStandard}
        property={property}
        rooms={hospitalityRooms}
        totalShop={totalShop}
        toiletRoom={toiletRoom}
        parkingSpaces={parkingSpaces}
        units={accessibleUnits}
        onGetStatusData={(status) => setInformationBlockStatusData(status)}
        onSetTab={handleChange}
      />
      {getBookButton(property, isDesktop, 1)}
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        {(displayShops ||
          displayUnitRooms(hospitalityRooms) ||
          displayUnitRooms(meetingRooms) ||
          displayUnitRooms(exhibitionRooms) ||
          displayUnitRooms(museumShopRooms) ||
          displayUnitRooms(toiletCommonRooms)
        ) ?
          <TabsBlock
            value={value}
            handleChange={handleChange}
            displayShops={displayShops}
            displayHospitalityRooms={displayUnitRooms(hospitalityRooms)}
            displayMeetingRooms={displayUnitRooms(meetingRooms)}
            displayExhibitionRooms={displayUnitRooms(exhibitionRooms)}
            displayMuseumShopRooms={displayUnitRooms(museumShopRooms)}
            displayToiletCommonRooms={displayUnitRooms(toiletCommonRooms)}
            a11yStandard={a11yStandard}
          /> : null
        }
        {getBookButton(property, isDesktop, 2)}
      </Box>
      <TabPanel value={value} index={0}>
        {/* Building info tab */}
        <AccessibilityWidgetInfoContainer
          height={height}
          fullscreen={fullscreen}
          displayShops={(displayShops ||
            displayUnitRooms(hospitalityRooms) ||
            displayUnitRooms(meetingRooms) ||
            displayUnitRooms(exhibitionRooms) ||
            displayUnitRooms(museumShopRooms) ||
            displayUnitRooms(toiletCommonRooms)
          )}
          buildingAreaId={buildingAreaId}
          showMap={showMap}
          a11yStandard={a11yStandard}
          isBuildingArea={false}
          informationBlockStatusData={informationBlockStatusData}
        >
          {showMap ? null :
            <ImageContainer
              carouselImages={carouselImages}
              onSlide={(currentIndex) => handlerCurrentImage(
                currentIndex,
                METRIC_TRIGGERED_ON.PROPERTY_PAGE_BUILDING_INFO
              )}
            />
          }
          {(showMap && activePoiId) ? (
            <ImageContainer
              showCloseButton={true}
              onClose={() => setActivePoiId('')}
              propertyImages={propertyImages}
              carouselImages={carouselImages}
              onSlide={(currentIndex) => handlerCurrentImage(
                currentIndex,
                METRIC_TRIGGERED_ON.PROPERTY_PAGE_MAP_VIEW
              )}
              children={
                (carouselImages.length > 0) ? (
                  <MainAccessibility
                    title={''}
                    fullHeight={false}
                    currentImageData={currentImageData}
                    accessibilities={accessibilities}
                    a11yStandard={a11yStandard}
                    propertyItemAccessibilityUnits={accessibleUnits}
                    disabilityTypes={disabilityTypes}
                    marginBottom={false}
                    borderBottom={false}
                    fullscreen={fullscreen}
                    showOnlySelected={true}
                  />
                ) : (<React.Fragment/>)
              }
            />
            )
            :
            <InformationWrapper>
              <MainAccessibility
                title={t('general_accessibility_and_information')}
                currentImageData={currentImageData}
                accessibilities={accessibilities}
                a11yStandard={a11yStandard}
                propertyItemAccessibilityUnits={accessibleUnits}
                disabilityTypes={disabilityTypes}
              />
              <InformationByDisability
                questions={questions}
                accessibilityInfo={propertyAccessibilityInfo}
                freeTextInfo={propertyFreeTextInfo}
              />
            </InformationWrapper>
          }
          {(showMap && property.point && property.point.coordinates) ?
            <MapWrapper
              id={property.id}
              fullscreen={fullscreen}
              standard={property.a11y_standard}
              mapCenterCoordinates={property.point.coordinates}
              unitLevels={unitLevels}
              accessibleUnits={accessibleUnits}
              activePoiId={activePoiId}
              onSetActivePoiId={setActivePoiId}
              setClickedMapPoiName={setClickedMapPoiName}
              accessibilities={accessibilities}
            />
            :
            <GeneralRoomInformationContainer
              a11yStandard={a11yStandard}
              accessibilityTable={accessibilityTable}
              locatedOn={{
                triggeredOnPage: METRIC_TRIGGERED_ON.PROPERTY_PAGE_BUILDING_INFO,
                section: METRIC_TRIGGERED_ON.GENERAL_INFO_TABLE
              }}
            />
          }
        </AccessibilityWidgetInfoContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* Shops properties tab */}
        <TabContentProperties
          a11yStandard={a11yStandard}
          property={property}
          fullscreen={fullscreen}
          displayShops={displayShops}
          buildingAreaId={buildingAreaId}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* Hospitality units tab */}
          <TabContentUnits
            type={hospitalityRooms[0]?.type}
            a11yStandard={a11yStandard}
            property={property}
            units={hospitalityRooms}
            propertyImages={propertyImages}
            fullscreen={fullscreen}
            displayShops={displayUnitRooms(hospitalityRooms)}
            buildingAreaId={buildingAreaId}
          />
      </TabPanel>
      <TabPanel value={value} index={3}>
        {/* Meeting units tab */}
          <TabContentUnits
            type={meetingRooms[0]?.type}
            a11yStandard={a11yStandard}
            property={property}
            units={meetingRooms}
            propertyImages={propertyImages}
            fullscreen={fullscreen}
            displayShops={displayUnitRooms(meetingRooms)}
            buildingAreaId={buildingAreaId}
          />
      </TabPanel>
      <TabPanel value={value} index={4}>
        {/* Exhibition units tab */}
          <TabContentUnits
            type={exhibitionRooms[0]?.type}
            a11yStandard={a11yStandard}
            property={property}
            units={exhibitionRooms}
            propertyImages={propertyImages}
            fullscreen={fullscreen}
            displayShops={displayUnitRooms(exhibitionRooms)}
            buildingAreaId={buildingAreaId}
          />
      </TabPanel>
      <TabPanel value={value} index={5}>
        {/* Museum shop units tab */}
          <TabContentUnits
            type={museumShopRooms[0]?.type}
            a11yStandard={a11yStandard}
            property={property}
            units={museumShopRooms}
            propertyImages={propertyImages}
            fullscreen={fullscreen}
            displayShops={displayUnitRooms(museumShopRooms)}
            buildingAreaId={buildingAreaId}
          />
      </TabPanel>
      <TabPanel value={value} index={6}>
        {/* Bathrooms units tab */}
          <TabContentUnits
            type={toiletCommonRooms[0]?.type}
            a11yStandard={a11yStandard}
            property={property}
            units={toiletCommonRooms}
            propertyImages={propertyImages}
            fullscreen={fullscreen}
            displayShops={displayUnitRooms(toiletCommonRooms)}
            buildingAreaId={buildingAreaId}
          />
      </TabPanel>
    </RootDiv>
  );
};