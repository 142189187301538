export const TRANSLATIONS_SV = {
  "accessibility_button": "Tillgänglighet",
  "sidebar_Hotel": "Hotell",
  "sidebar_Rooms": "Rum",
  "sidebar_verified": "Verifierad av Handiscover",
  "hotel_floors": "Våningsplan",
  "hotel_totalRooms": "Antal rum",
  "hotel_accessibleRooms": "Handikapp Rum",
  "hotel_allergyRooms": "Allergirum",
  "mainContainer_accessibilityInformation": "Tillgänglighetsinformation",
  "mainContainer_pictures": "Bilder",
  "mainContainer_informationByDisability": "Information för besökare",
  "mainContainer_GeneralHotelInformation": "Allmän information om hotellet",
  "informationByDisability_mobility": "Rörlighet",
  "informationByDisability_hearing": "Hörsel",
  "informationByDisability_vision": "Syn",
  "informationByDisability_other": "Annat",
  "rooms_header": "Information om rum",
  "accessibility_room_icons": "Tillgänglighetsikoner för rum",
  "roomInformation_generalRoomInformation": "Allmän information om rum",
  "shopping_mall_mallFloors": "Våningsplan i köpcentret",
  "shopping_mall_totalShops": "Inventerade butiker",
  "shopping_mall_accessibleParking": "Handikapp Parkering",
  "shopping_mall_foodCourts": "Food-court",
  "mainContainer_GeneralMallInformation": "Allmän information om köpcentret",
  "rooms_noPictures": "Detta rum har inga bilder",
  "rooms_noInformation": "Detta rum har ingen information",
  "rooms_noIcons": "Inga tillgänglighetsikoner",
  "property_noPictures": "Inga bilder hittades för denna anläggning",
  "disability_noPictures": "Inga bilder för den valda funktionsnedsättningen",
  "accessibilityIcons_notPresent": "Inga tillgänglighetsikoner",
  "shopping_mall": "Köpcenter",
  "building_area_buildings": "Byggnader",
  "building_area_shops": "Inventerade butiker",
  "building_area_parking_spaces": "Handikapp Parkering",
  "building_area_food_courts": "Mattorg",
  "mainContainer_generalInformation": "Allmän information",
  "mainContainer_GeneralOfficeInformation": "Allmän kontorsinformation",
  "mainContainer_GeneralShopInformation": "Allmän butiksinformation",
  "header_buildingInformation": "Byggnadsinformation",
  "a11yStandard_shopping_mall": "Butik",
  "a11yStandard_shopping_mall_plural": "Butiker",
  "property_floorSignular": "Våning",
  "property_floorPlural": "Våningar",
  "property_tabTitle_shops": "Butiksinformation",
  "general_invalid_API_CALL_errorMessage": "Ett problem uppstod! Det gick inte att hämta data.",
  "general_missingInfo_forItem": "Det verkar som om informationen inte lagts till ännu",
  "translated_text": "Titeln har blivit översatt",
  "accessibility_button_open_ariaLabel": "Öppna tillgänglighetsinformation",
  "mainContainer_mapView": "Kartvy",
  "mainContainer_generalAccessibility": "Allmän tillgänglighet",
  "mapView_filterBy": "Filtrera efter",
  "mapView_filter_all": "Alla",
  "mapView_filter_entrance": "Ingång",
  "mapView_filter_parking": "Parkering",
  "mapView_filter_toilet": "Toalett",
  "mapView_floor": "Våning",
  "selectDisabilityType_mobility": "Rörlighet",
  "selectDisabilityType_hearing": "Hörsel",
  "selectDisabilityType_allergy": "Allergi",
  "selectDisabilityType_cognitive": "Kognitiv",
  "selectDisabilityType_other": "Annat",
  "selectDisabilityType_select": "Välj",
  "selectDisabilityType_disability_type": "typ av funktionsnedsättning ",
  "selectDisabilityType_select_disability_type": "Välj typ av funktionsnedsättning ",
  "selectDisabilityType_select_what_type": "Filtrera efter funktionsnedsättning",
  "selectDisabilityType_visual": "Syn",
  "go_back": "Gå tillbaka",
  "tabTitle_meetingRooms": "Mötesrum",
  "tabTitle_hospitalityRooms": "Hotellrum",
  "generalAccessibility_noGeneralAccessibilitiesForThisProperty": "Inga Allmänna tillgängligheter hittades för den här anläggningen",
  "generalAccessibility_noGeneralAccessibilitiesForTheseDisabilityFilters": "Inga Allmänna tillgängligheter hittades för dessa funktionsnedsättning filters",
  "informationByDisabilityContainer_noDataIsFound": "Inga data hittades",
  "informationByDisabilityContainer_weDoNotHaveTheData": "Vi har inte data för denna fastighet ännu",
  "informationByDisabilityContainer_tryAnotherDisabilityFiltering": "Prova en annan funktionsnedsättningsfiltrering",
  "playground_information": "Lekplatsinformation",
  "total_playgrounds": "Totala lekplatser",
  "inventoried_playgrounds": "Inventerade lekplatser",
  "general_accessibility_and_information": "Allmän tillgänglighet och information",
  "detailed_accessibility": "Detaljerad tillgänglighet",
  "helsingborg_city_has_reviewed": "Helsingborg Stad har granskat tillgängligheten av 26 av sina 200 lekplatser. Där får du information om parkering, tillgänglighet av toaletter och olika lekar.",
  "for_detailed_accessibility_information": "För detaljerad tillgänglighetsinformation, välj respektiv lekplats.",
  "amount_of_accessible_toilets": "Antal tillgängliga toaletter",
  "mapView_filter_elevator": "Hiss",
  "mapView_filter_reception_infodesk": "Reception",
  "mapView_filter_playground": "Lekplats",
  "mapView_filter_garden": "Trädgård",
  "mapView_filter_shop": "Affär",
  "mapView_filter_restaurant": "Restaurang",
  "mapView_filter_conference_room": "Konferensrum",
  "mapView_filter_auditorium": "Hörsal",
  "jarfalla_municipality_has_reviewed": "Järfälla kommun Stad har granskat tillgängligheten av 55 lekplatser, varav 2 ut-o-lek lekplatser som främjar kreativitet. Där får du information om parkering, tillgänglighet av toaletter och olika lekar.",
  "mapView_filter_vac": "Våningsplan byte",
  "languages": "Språk",
  "english": "Engelska",
  "swedish": "Svenska",
  "danish": "Danska",
  "german": "Tyska",
  "spanish": "Spanska",
  "french": "Franska",
  "norwegian": "Norska",
  "portuguese": "Portugisiska",
  "ukrainian": "Ukrainska",
  "accessibility_room": "Rum för tillgänglighet",
  "pool": "Pool",
  "spa": "Spa",
  "bar_restaurant": "Bar/Restaurang",
  "bathrooms": "Badrum",
  "shop": "Butik",
  "exhibition": "Utställning",
  "rate_your_experience": "Kan du hjälpa oss bli bättre🧑‍🦼🦮🦻? 🙏",
  "did_you_find_the_accessibility_information_you_needed": "Hittade du den information om tillgänglighet som du behövde?",
  "how_satisfied_are_you_with_your_experience": "Hur nöjd är du med din upplevelse av vår tillgänglighetsguide?",
  "what_do_you_like_the_most": "Vad tycker du mest om?",
  "sorry_to_hear_that": "Vänligen kommentera varför, för att skicka ditt betyg",
  "we_would_love_to_hear_your_suggestions": "Vi vill gärna höra dina förslag",
  "submit": "Skicka in",
  "book_now": "Boka nu",
  "very_bad": "Väldigt dåligt",
  "bad": "Dålig",
  "neutral": "Neutral",
  "good": "Bra",
  "great": "Bra",
}