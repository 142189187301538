import {Card, CardActionArea, CardContent, CardMedia, Grid} from "@mui/material";
import React, {FC} from "react";
import styled from "styled-components";
import {SizeContainerWrapper} from "../../../StyledWrappers/Wrappers";
import Typography from "@mui/material/Typography";

const StyledWrapper = styled(SizeContainerWrapper)`
  padding: 0;
  margin: 10px;
`;

const ListItemCard = styled(Card)`
  width: 100%;
  height: 100%;
`;

const StyledCardActionArea = styled(CardActionArea)`
  font-size: 14px !important;
  & img {
    height: 120px !important;
    object-fit: contain !important;
  }
`;

const ListItemBoxName = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  display: inline;
`;

type PropsType = {
  id: string
  logo?: string,
  name: string,
  lastItemRef: ((node: HTMLButtonElement) => void) | null
  onFetchPropertyItemData: (id: string) => void
  lastItem: boolean
}

const PropertyItem: FC<PropsType> = ({
  id,
  logo,
  name,
  lastItemRef,
  onFetchPropertyItemData,
  lastItem
}) => {
  return (
    //sm={6} md={4} lg={3}
    <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
      <StyledWrapper
        onClick={() => onFetchPropertyItemData(id)}
      >
        <ListItemCard elevation={0}>
          {lastItem ?
            <StyledCardActionArea ref={lastItemRef}>
              {(logo && logo.length > 1) ?
                <CardMedia
                  sx={{objectFit: "contain"}}
                  component="img"
                  height="120"
                  image={logo}
                  alt={name}
                /> : null
              }
              <CardContent>
                <ListItemBoxName>{name}</ListItemBoxName>
              </CardContent>
            </StyledCardActionArea>
            :
            <StyledCardActionArea>
              {(logo && logo.length > 1) ?
                <CardMedia
                  sx={{objectFit: "contain"}}
                  component="img"
                  height="120"
                  image={logo}
                  alt={name}
                /> : null
              }
              <CardContent>
                <ListItemBoxName>{name}</ListItemBoxName>
              </CardContent>
            </StyledCardActionArea>
          }
        </ListItemCard>
      </StyledWrapper>
    </Grid>
  )

}

export default PropertyItem
