import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";
import {FC} from "react";
import {SvgIcon} from "@mui/material";

const AccessibleParkingSvg: FC<SvgIconProps> = (p) => (
    <SvgIcon {...p}>
        <svg {...p} viewBox="0 0 81 84">
            <path d="M0.553203 62V0.0799975H26.6972C27.2992 0.0799975 28.1019 0.108664 29.1052 0.165996C30.1372 0.194664 31.0545 0.280665 31.8572 0.423998C35.5552 0.997331 38.5795 2.21566 40.9302 4.07899C43.3095 5.94233 45.0582 8.293 46.1762 11.131C47.2942 13.9403 47.8532 17.0793 47.8532 20.548C47.8532 24.0167 47.2799 27.17 46.1332 30.008C45.0152 32.8173 43.2665 35.1537 40.8872 37.017C38.5365 38.8803 35.5265 40.0987 31.8572 40.672C31.0545 40.7867 30.1372 40.8727 29.1052 40.93C28.0732 40.9873 27.2705 41.016 26.6972 41.016H12.2492V62H0.553203ZM12.2492 30.094H26.1812C26.7832 30.094 27.4425 30.0653 28.1592 30.008C28.8759 29.9507 29.5352 29.836 30.1372 29.664C31.7139 29.234 32.9322 28.5173 33.7922 27.514C34.6522 26.482 35.2399 25.3497 35.5552 24.117C35.8992 22.8557 36.0712 21.666 36.0712 20.548C36.0712 19.43 35.8992 18.2547 35.5552 17.022C35.2399 15.7607 34.6522 14.6283 33.7922 13.625C32.9322 12.593 31.7139 11.862 30.1372 11.432C29.5352 11.26 28.8759 11.1453 28.1592 11.088C27.4425 11.0307 26.7832 11.002 26.1812 11.002H12.2492V30.094Z"/>
            <path d="M47.8499 76.4113C41.285 73.4658 38.333 65.7108 41.2784 59.1449C42.5916 56.218 44.8631 54.0097 47.5569 52.7216L47.6921 56.3552C46.2242 57.3449 45.0026 58.7399 44.2272 60.468C42.0113 65.4082 44.2327 71.2448 49.1718 73.4616C54.1133 75.6786 59.9496 73.4574 62.1668 68.5158C62.2211 68.3939 62.2725 68.2707 62.3213 68.1477L64.2683 71.4222C60.8676 76.8591 53.8606 79.1075 47.8499 76.4113Z"/>
            <path d="M66.975 73.232L60.3135 61.5916H49.2993L48.8256 46.1044C47.8085 45.2638 47.4275 43.8145 47.9935 42.5536C48.6708 41.0433 50.4544 40.3639 51.9659 41.0422C53.4762 41.7195 54.1555 43.5042 53.4782 45.0145C53.1319 45.7865 52.4949 46.3406 51.7568 46.6087L51.7917 46.6217L52.3805 52.6814H59.7999C62.9055 52.6814 62.9055 55.5963 60.2376 55.5963C58.1988 55.5963 52.8315 55.604 52.8315 55.604L53.1985 58.581L62.2873 58.6061L68.1516 69.0759C68.1516 69.0759 69.7649 68.2597 69.7799 68.2417C72.384 66.8932 73.9782 69.5122 71.1503 71.0306C68.3237 72.5483 66.975 73.232 66.975 73.232Z"/>
        </svg>
    </SvgIcon>
)

export default AccessibleParkingSvg