import {FC} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";
import {SvgIcon} from "@mui/material";

const MeetingRoomSvg: FC<SvgIconProps> = (p) => {
    return (
        <SvgIcon {...p}>
            <svg viewBox="0 0 100 101" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1884_72" maskUnits="userSpaceOnUse" x="0" y="0" width="97" height="97">
                    <path d="M97 0H0V97H97V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_1884_72)">
                    <path d="M48.5 19.4C53.8569 19.4 58.2 15.0572 58.2 9.7C58.2 4.34286 53.8569 0 48.5 0C43.1427 0 38.7998 4.34286 38.7998 9.7C38.7998 15.0572 43.1427 19.4 48.5 19.4Z"/>
                    <path d="M9.7 29.1C15.0572 29.1 19.4 24.7571 19.4 19.4C19.4 14.0428 15.0572 9.69995 9.7 9.69995C4.34286 9.69995 0 14.0428 0 19.4C0 24.7571 4.34286 29.1 9.7 29.1Z"/>
                    <path d="M53.3504 24.25H43.65C38.3152 24.25 33.9502 28.615 33.9502 33.95V43.65H63.05V33.95C63.05 28.615 58.6854 24.25 53.3504 24.25Z"/>
                    <path d="M29.1 63.05H19.4V43.65C19.4 38.315 15.035 33.95 9.7 33.95C4.365 33.95 0 38.315 0 43.65V67.9C0 76.145 6.305 82.45 14.55 82.45H24.25V97H43.65V77.6C43.65 69.355 37.345 63.05 29.1 63.05Z"/>
                    <path d="M87.3 29.1C92.6573 29.1 97 24.7571 97 19.4C97 14.0428 92.6573 9.69995 87.3 9.69995C81.9431 9.69995 77.6 14.0428 77.6 19.4C77.6 24.7571 81.9431 29.1 87.3 29.1Z"/>
                    <path d="M87.3 33.95C81.965 33.95 77.6 38.315 77.6 43.65V63.05H67.9C59.655 63.05 53.35 69.355 53.35 77.6V97H72.75V82.45H82.45C90.695 82.45 97 76.145 97 67.9V43.65C97 38.315 92.635 33.95 87.3 33.95Z"/>
                    <path d="M72.75 48.5H24.25V58.2H72.75V48.5Z"/>
                </g>
            </svg>
        </SvgIcon>
    )
}

export default MeetingRoomSvg