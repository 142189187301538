// Uncomment and add your PROD Company API KEY to visualize Prod properties

import {domains} from "../constants/constants";

export const baseUrl =
   "https://ha11y-api.handiscover.com/"
 // "https://ams-api-dev.handiscover.com/"
// process.env.REACT_APP_ENDPOINT_URL
export const headers: Record<string, string> = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    // PROD KEY
    Authorization: "Token [COMPANY API KEY]",
};

export const getCurrentServer = () => {
    switch (window.location.origin) {
        case domains.live:
            return {
                server: 'live',
                token: ''
            }
        case domains.dev:
            return {
                server: 'dev',
                token: 'Token c88b5865f4303a5898bb8d1b2884b78dc87bef16'
            }
        default:
            return {
                server: 'dev',
                token: 'Token c88b5865f4303a5898bb8d1b2884b78dc87bef16'
            }
    }
}

export const qmsUrl = "https://qms.handiscover.com/";

export async function fetchAndParse<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    const response = await fetch(input, init)
    if (response.status !== 200) {
        let json: string | null = null;
        try {
            json = await response.json();
        } catch (e) {

        }
        // eslint-disable-next-line no-throw-literal
        throw {
            status: response.status,
            statusText: response.statusText,
            response,
            json,
        };
    }
    const data = await response.json();
    return data as T;
}

export type BasicQuerySearch = {
    limit?: number;
    offset?: number;
    /**
     * Search can be either include `name` field or `id`
     */
    search?: string;
    ordering?: "name" | "created_date" | "id",
}


export function generateQueryString<T extends BasicQuerySearch>(obj: T) {
    const queryString = Object.entries(obj).map(([k, v]) => `${k}=${v}`).join("&");
    return queryString ? `?${queryString}` : ''
}
