import React, {FC} from "react";
import styled from "styled-components";
import Icons from "./Icons";
import {useTranslation} from "react-i18next";

type WrapperType = {
  margin: string;
  padding: string;
}

const Wrapper = styled.div<WrapperType>`
  border: 1px solid #E3E5E5;
  background: #FFFFFF;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  span {
    font-style: normal !important;
    font-size: 15px !important;
    font-family: 'Roboto' !important;
  }
`

const GeoPinBlue = styled(Icons.GeoPinBlue)`
  height: 25px;
  width: 25px;
`

const BottomBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  span {
    color: rgb(5 141 216) !important;
  }
  img {
    margin-right: 13px;
    filter: invert(48%) sepia(51%) saturate(3207%) hue-rotate(172deg) brightness(84%) contrast(102%);
  }
`

type PropsType = {
  margin?: string;
  padding?: string;
  id: string;
}

const InformationMapBlock: FC<PropsType> = ({
  id,
  margin = '0 0 10px 0',
  padding = '15px 15px'
}) => {
  const { t } = useTranslation()

  const getText = (id: string) => {
    switch (id) {
      case '8f718be6-a4cf-428c-b2b9-3c5046b132c5':
        return t('helsingborg_city_has_reviewed')
      case '78e4e95a-c360-44c4-9e1b-1cad77f95f67':
        return t('jarfalla_municipality_has_reviewed')
      default:
        return t('helsingborg_city_has_reviewed')
    }
  }

  return (
    <Wrapper
      margin={margin}
      padding={padding}
    >
      <span>
        {getText(id)}
      </span>
      <BottomBlock>
        <GeoPinBlue />
        <span>
          {t('for_detailed_accessibility_information')}
        </span>
      </BottomBlock>
    </Wrapper>
  )
}

export default InformationMapBlock