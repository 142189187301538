import Tabs from "@mui/material/Tabs";
import {a11yStandardKeys} from "../../../constants/constants";
import React, {FC} from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import {A11yStandardType} from "../../../Models/utils";
import {useTranslation} from "react-i18next";
import HomeIcon from '@mui/icons-material/Home';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import WcIcon from '@mui/icons-material/Wc';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import Icons from '../../../../src/components/Icons';
import AccessibilityToiletSvg from "../../../assets/svg/AccessibilityToiletSvg";
import {SvgIcon} from "@mui/material";
import ExhibitionSvg from "../../../assets/svg/ExhibitionSvg";
import MeetingRoomSvg from "../../../assets/svg/MeetingRoomSvg";
import HotelRoomSvg from "../../../assets/svg/HotelRoomSvg";

const StyledBox = styled(Box)`
  display: grid;
  margin-top: 5px;
`;

const StyledTab = styled(Tab)`
  font-size: 14px !important;
  min-height: 25px;
  min-width: 40px;
  color: #000000DE !important;
  display: flex;
  flex-direction: row;
  gap: 8px;
  &:hover {
    color: #000000DE !important;
    background-color: transparent !important;
  }
  &.Mui-selected {
    color: #1976d2 !important;
  }
  .MuiTab-iconWrapper {
    margin: 0 !important;
  }
  @media (max-width: 768px) {
    .label {
      display: none;
    }
  }
`;

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

type PropsType = {
  value: number
  handleChange: (event:  React.SyntheticEvent, newValue: number) => void
  displayShops: boolean
  displayHospitalityRooms: boolean
  displayMeetingRooms: boolean
  displayExhibitionRooms: boolean
  displayMuseumShopRooms: boolean
  displayToiletCommonRooms: boolean
  a11yStandard: A11yStandardType,

}

const TabsBlock: FC<PropsType> = ({
  value,
  handleChange,
  displayShops,
  a11yStandard,
  displayHospitalityRooms,
  displayExhibitionRooms,
  displayMuseumShopRooms,
  displayToiletCommonRooms,
  displayMeetingRooms
}) => {
  const { t } = useTranslation()

  return (
    <StyledBox sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
      <Tabs
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="tabs"
        textColor="inherit"
        allowScrollButtonsMobile
        // TabIndicatorProps={{style: {backgroundColor: 'black'}}}
      >
        <StyledTab
          value={0}
          label={<span className='label'>{t("header_buildingInformation")}</span>}
          icon={<HomeIcon/>}
          tabIndex={(value === 0) ? -1 : 0}
          {...a11yProps(0)}
        />
        {(a11yStandard.key === a11yStandardKeys.shoppingMall && displayShops) ?
          <StyledTab
            value={1}
            label={<span className='label'>{t("property_tabTitle_shops")}</span>}
            icon={<ShoppingBasketIcon/>}
            tabIndex={(value === 1) ? -1 : 0}
            {...a11yProps(1)}
          /> : null
        }
        {(displayHospitalityRooms) ?
          <StyledTab
            value={2}
            icon={<HotelRoomSvg/>}
            label={<span className='label'>{t("tabTitle_hospitalityRooms")}</span>}
            tabIndex={(value === 2) ? -1 : 0}
            {...a11yProps(2)}
          /> : null
        }
        {(displayMeetingRooms) ?
          <StyledTab
            value={3}
            icon={<MeetingRoomSvg/>}
            label={<span className='label'>{t("tabTitle_meetingRooms")}</span>}
            tabIndex={(value === 3) ? -1 : 0}
            {...a11yProps(3)}
          /> : null
        }
        {(displayExhibitionRooms) ?
          <StyledTab
            value={4}
            icon={<ExhibitionSvg/>}
            label={<span className='label'>{t('exhibition')}</span>}
            tabIndex={(value === 4) ? -1 : 0}
            {...a11yProps(4)}
          /> : null
        }
        {(displayMuseumShopRooms) ?
          <StyledTab
            value={5}
            icon={<ShoppingBasketIcon/>}
            label={<span className='label'>{t('shop')}</span>}
            tabIndex={(value === 5) ? -1 : 0}
            {...a11yProps(5)}
          /> : null
        }
        {(displayToiletCommonRooms) ?
          <StyledTab
            value={6}
            icon={<AccessibilityToiletSvg/>}
            label={<span className='label'>{t('bathrooms')}</span>}
            tabIndex={(value === 6) ? -1 : 0}
            {...a11yProps(6)}
          /> : null
        }
      </Tabs>
    </StyledBox>
  )
}

export default TabsBlock
