import {Typography} from "@mui/material";
import React, {forwardRef, useContext, useEffect, useRef} from "react";
import styled from "styled-components";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import {MapContext} from "../pages/Property/PropertyWrapper";
import {useTranslation} from "react-i18next";
import {MapContextBuildingArea} from "../pages/BuildingArea/BuildingAreaPage";
import {Mixpanel} from "../Mixpanel";
import {MetricMessage} from "../utils/metrics";
import {IconButton as MuiIconButton} from "@mui/material";
import Icons from "./Icons";
import {isIFrame} from "../utils/utils";
// TODO: rename the name if this file because it's not only for hotels

type FixedHotelContainerProps = {
  padding?: string
}

const FixedHotelContainer = styled.div<FixedHotelContainerProps>`
  border: 1px solid #E3E5E5;
  background: #ffffff;
  padding: ${(props) => props.padding ? props.padding : '10px 15px 0 15px'};
  min-height: 0;
  flex-grow: 1;
  overflow: auto;
`

const IconButton = styled(MuiIconButton)`
  margin-right: 5px;
`

const StyledCloseIcons = styled(Icons.CloseIcon)`
  width: 16px;
  height: 16px;
`

type StyledTextType = {
  position: string | undefined
}

const StyledText = styled(Typography)<StyledTextType>`
  background: #FFFFFF;
  padding: 0 10px;
  top: 0;
  position: ${(props) => props.position ?? 'unset'};
  &:first-letter {
    text-transform: capitalize !important;
  }
`

const TitleBlock = styled.div`
  font-size: 16px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #E3E5E5;
  margin-bottom: 8px;
`


const StyledToggleButton = styled(ToggleButton)`
  flex: 1;
  color: rgba(0, 0, 0, 0.54) !important;
  line-height: 1.3;
  font-size: 14px !important;

  &[type=button], [type=reset], button {
    border: 1px solid #E3E5E5 !important;
  }

  & .MuiToggleButton-root {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  &:hover, &:focus {
    color: rgba(0, 0, 0, 0.54) !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
  }
  &.Mui-selected {
    color: #1976d2 !important;
    &:hover {
      color: #1976d2 !important;
      border-color: rgba(0, 0, 0, 0.12) !important;
      background-color: rgba(25, 118, 210, 0.12) !important;
    }
  }
  &:not(:first-of-type) {
    border-left: 1px solid transparent !important;
  }

`

type HotelInfoWrapperProps = {
  width?: string | undefined
}

const HotelInfoWrapper = styled.div<HotelInfoWrapperProps>`
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: ${({width}) => width ? width : '100%'}
`;

type Props = {
  label?: string;
  labelPosition?: "sticky";
  children: React.ReactNode;
  id?: string;
  styles?: React.CSSProperties;
  dontDisplayHeader?: boolean;
  showMapToggle?: boolean;
  showMapToggleBuildingArea?: boolean;
  padding?: string | undefined;
  width?: string | undefined;
  locatedOn?: {
    type?: string,
    triggeredOnPage: string,
    section: string
  }
  showCloseButton?: boolean
  onClose?: () => void
};
export const HotelInfoContainer = forwardRef<HTMLDivElement | null, Props>(({
    children,
    label,
    labelPosition,
    id,
    styles,
    dontDisplayHeader,
    showMapToggle,
    showMapToggleBuildingArea,
    padding,
    width,
    locatedOn,
    showCloseButton,
    onClose
}, ref) => {

    const [showMap, setShowMap] = useContext(MapContext);
    const [showMapBuildingArea, setShowMapBuildingArea] = useContext(MapContextBuildingArea);
    const scrollContainer = useRef<HTMLDivElement>(null);
    const isScrolledTo = useRef({10: false, 50: false, 100:false})
    const { t } = useTranslation();
    const handleToggle = (
      event: React.MouseEvent<HTMLElement>,
      newAlignment: string,
    ) => {
      if (showMapToggleBuildingArea) {
        if (newAlignment === "map-view") {
          setShowMapBuildingArea(true);
        } else if (newAlignment === "default-view") {
          setShowMapBuildingArea(false);
        }
      } else if (showMapToggle) {
        if (newAlignment === "map-view") {
          setShowMap(true);
        } else if (newAlignment === "default-view") {
          setShowMap(false);
        }
      }
    };

    const sendScrollPercentMetric = (percent: number) => {
      if (locatedOn) {
        Mixpanel.track(`${MetricMessage.scroll_percentage}`, {
          type: locatedOn.type,
          percent: `${percent}%`,
          triggeredOnPage: locatedOn.triggeredOnPage,
          section: locatedOn.section
        });
      }
    }

    const touchMoveListener = () => {
      if (scrollContainer && scrollContainer.current) {
        const boundingClientRect = scrollContainer.current.getBoundingClientRect()
        const generalAccessibilityHeight = scrollContainer.current.offsetHeight
        const clientHeight = window.innerHeight
        const { y } = boundingClientRect
        if (Math.sign(y - clientHeight) === -1) {
          const currentPosition = Math.abs(y - clientHeight)
          if (currentPosition >= generalAccessibilityHeight / 100 * 10 && !isScrolledTo.current[10]) {
            isScrolledTo.current[10] = true
            sendScrollPercentMetric(10)
          } else if (currentPosition >= generalAccessibilityHeight / 100 * 50 && !isScrolledTo.current[50]) {
            isScrolledTo.current[50] = true
            sendScrollPercentMetric(50)
          } else if (currentPosition >= generalAccessibilityHeight / 100 * 100 && !isScrolledTo.current[100]) {
            isScrolledTo.current[100] = true
            sendScrollPercentMetric(100)
          }
        }
      }
    }

    const scrollListener = (e: Event & { target: HTMLButtonElement }) => {
      let scrollTop = e.target.scrollTop;
      let scrollHeight = e.target.scrollHeight;
      let offsetHeight = e.target.offsetHeight;
      let contentHeight = scrollHeight - offsetHeight;
      if (contentHeight <= scrollTop && !isScrolledTo.current[100]) {
        isScrolledTo.current[100] = true
        sendScrollPercentMetric(100)
      }
      if ((contentHeight / 2) <= scrollTop && !isScrolledTo.current[50]) {
        isScrolledTo.current[50] = true
        sendScrollPercentMetric(50)
      }
      if ((contentHeight * 0.1) <= scrollTop && !isScrolledTo.current[10]) {
        isScrolledTo.current[10] = true
        sendScrollPercentMetric(10)
      }
    }

    useEffect(() => {
      if (locatedOn) {
        const iframe = document.getElementById('handiscover-iframe')
        if (isIFrame(iframe) && iframe.contentWindow && scrollContainer && scrollContainer.current) {
          const element = iframe.contentWindow.document.getElementById('tab-layout')
          element?.addEventListener("touchmove", touchMoveListener, false);
          scrollContainer.current.addEventListener(
            "scroll",
            (e) => scrollListener(e as Event & { target: HTMLButtonElement }),
            false
          );
          return () => {
            element?.removeEventListener("touchmove", touchMoveListener, false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            scrollContainer.current?.removeEventListener(
              "scroll",
              (e) => scrollListener(e as Event & { target: HTMLButtonElement }),
              false
            );
          };
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollContainer]);

    return (
      <HotelInfoWrapper
        id={id}
        ref={ref}
        className="handiscover-scrollbar-overwrite"
        width={width}
        style={{
          ...styles
        }}
      >
        {(dontDisplayHeader || showMapToggle || showMapToggleBuildingArea) ? null :
          <TitleBlock>
            {label ?
              <StyledText position={labelPosition}>
                {label}
              </StyledText> : null
            }
            {showCloseButton ? (
              <IconButton
                size="medium"
                onClick={onClose}
                aria-label="Close map"
                color="primary"
              >
                <StyledCloseIcons />
              </IconButton>
            ) : null}
          </TitleBlock>
        }
        {(showMapToggle || showMapToggleBuildingArea)?
          <ToggleButtonGroup
            color="primary"
            value={showMap ? "map-view" : "default-view"}
            exclusive
            onChange={handleToggle}
            aria-label="Platform"
            style={{marginBottom: '10px', background: '#FFF'}}
          >
            <StyledToggleButton
              tabIndex={(showMap || showMapBuildingArea) ? 0 : -1}
              value="default-view"
              aria-label={t("mainContainer_generalInformation")}
            >
              {t('detailed_accessibility')}
            </StyledToggleButton>
            <StyledToggleButton
              onClick={() => Mixpanel.track(`${MetricMessage.accessed_map_view}`)}
              tabIndex={(showMap || showMapBuildingArea) ? -1 : 0}
              value="map-view"
              aria-label="Map View"
            >
              {t('mainContainer_mapView')}
            </StyledToggleButton>
          </ToggleButtonGroup> : null
        }
        {labelPosition ? (
          <FixedHotelContainer ref={scrollContainer} padding={padding}>{children}</FixedHotelContainer>
        ) : (
          <>{children}</>
        )}
      </HotelInfoWrapper>
    );
  }
);
