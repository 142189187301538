export const TRANSLATIONS_ES = {
  "accessibility_button": "Accesibilidad",
  "sidebar_Hotel": "Hotel",
  "sidebar_Rooms": "Habitaciones",
  "sidebar_verified": "Verificado por Handiscover",
  "hotel_floors": "Piso",
  "hotel_totalRooms": "Habitaciones totales",
  "hotel_accessibleRooms": "Habitaciones accesibles",
  "hotel_allergyRooms": "Salas de alergias",
  "mainContainer_accessibilityInformation": "Información de accesibilidad",
  "mainContainer_pictures": "Fotos",
  "mainContainer_informationByDisability": "Información por discapacidad",
  "mainContainer_GeneralHotelInformation": "Información general del hotel",
  "informationByDisability_mobility": "Movilidad",
  "informationByDisability_hearing": "Audiencia",
  "informationByDisability_vision": "Visión",
  "informationByDisability_other": "Otro",
  "rooms_header": "Información de la habitación",
  "accessibility_room_icons": "Iconos de accesibilidad para la habitación",
  "roomInformation_generalRoomInformation": "Información general de la habitación",
  "shopping_mall_mallFloors": "Pisos del centro comercial",
  "shopping_mall_totalShops": "Tiendas inventariadas",
  "shopping_mall_accessibleParking": "Aparcamiento accesible",
  "shopping_mall_foodCourts": "Lugares de comida",
  "mainContainer_GeneralMallInformation": "Información general del centro comercial",
  "rooms_noPictures": "Esta habitación no tiene imágenes.",
  "rooms_noInformation": "Esta habitación no tiene información.",
  "rooms_noIcons": "Sin iconos de accesibilidad",
  "property_noPictures": "No se encontraron imágenes para esta propiedad.",
  "disability_noPictures": "No hay fotos para la Discapacidad seleccionada",
  "accessibilityIcons_notPresent": "Sin iconos de accesibilidad",
  "shopping_mall": "Centro comercial",
  "building_area_buildings": "Edificios",
  "building_area_shops": "Tiendas inventariadas",
  "building_area_parking_spaces": "Aparcamiento accesible",
  "building_area_food_courts": "Lugares de comida",
  "mainContainer_generalInformation": "Información general",
  "mainContainer_GeneralOfficeInformation": "Información general de la oficina",
  "mainContainer_GeneralShopInformation": "Información general de la tienda",
  "header_buildingInformation": "Información de construcción",
  "a11yStandard_shopping_mall": "Comercio",
  "a11yStandard_shopping_mall_plural": "Tiendas",
  "property_floorSignular": "Piso",
  "property_floorPlural": "Piso",
  "property_tabTitle_shops": "Información de tiendas",
  "general_invalid_API_CALL_errorMessage": "¡Ocurrió un problema! No se pudieron recuperar los datos.",
  "general_missingInfo_forItem": "Parece que la información aún no se ha agregado.",
  "translated_text": "El título ha sido traducido.",
  "accessibility_button_open_ariaLabel": "Abrir información de accesibilidad",
  "mainContainer_mapView": "Vista del mapa",
  "mainContainer_generalAccessibility": "Accesibilidad general",
  "mapView_filterBy": "Filtrado por",
  "mapView_filter_all": "Todo",
  "mapView_filter_entrance": "Entrada",
  "mapView_filter_parking": "Estacionamiento",
  "mapView_filter_toilet": "Baño",
  "mapView_floor": "Piso",
  "selectDisabilityType_mobility": "Movilidad",
  "selectDisabilityType_hearing": "Audiencia",
  "selectDisabilityType_allergy": "Alergia",
  "selectDisabilityType_cognitive": "Cognitivo",
  "selectDisabilityType_other": "Otro",
  "selectDisabilityType_select": "Seleccionar",
  "selectDisabilityType_disability_type": "tipo de discapacidad",
  "selectDisabilityType_select_disability_type": "Seleccione el tipo de discapacidad",
  "selectDisabilityType_select_what_type": "Filtrar por discapacidad",
  "selectDisabilityType_visual": "Visual",
  "go_back": "Regresa",
  "tabTitle_meetingRooms": "Salas de reuniones",
  "tabTitle_hospitalityRooms": "Habitaciones de hostelería",
  "generalAccessibility_noGeneralAccessibilitiesForThisProperty": "No se encontraron accesibilidades generales para esta propiedad",
  "generalAccessibility_noGeneralAccessibilitiesForTheseDisabilityFilters": "No se encontraron accesibilidades generales para estos filtros de discapacidad",
  "informationByDisabilityContainer_noDataIsFound": "No se encuentran datos",
  "informationByDisabilityContainer_weDoNotHaveTheData": "Aún no tenemos los datos de esta propiedad",
  "informationByDisabilityContainer_tryAnotherDisabilityFiltering": "Pruebe con otro filtro de discapacidad",
  "playground_information": "Información del parque infantil",
  "total_playgrounds": "Total de parques infantiles",
  "inventoried_playgrounds": "Parques infantiles inventariados",
  "general_accessibility_and_information": "Accesibilidad general e información",
  "detailed_accessibility": "Accesibilidad detallada",
  "helsingborg_city_has_reviewed": "La ciudad de Helsingborg ha revisado la disponibilidad de 26 de sus 200 parques infantiles. Allí obtendrás información sobre aparcamiento, disponibilidad de aseos y diversas zonas de juego.",
  "for_detailed_accessibility_information": "Para obtener información detallada sobre accesibilidad, elija el área de juegos respectiva.",
  "amount_of_accessible_toilets": "Cantidad de baños accesibles",
  "mapView_filter_elevator": "Ascensor",
  "mapView_filter_reception_infodesk": "Recepción",
  "mapView_filter_playground": "Patio de juegos",
  "mapView_filter_garden": "Jardín",
  "mapView_filter_shop": "Comercio",
  "mapView_filter_restaurant": "Restaurante",
  "mapView_filter_conference_room": "Sala de conferencias",
  "mapView_filter_auditorium": "Sala",
  "jarfalla_municipality_has_reviewed": "El Ayuntamiento de Järfälla ha revisado la accesibilidad de 55 parques infantiles, incluidos 2 parques de aventuras que fomentan la creatividad. Allí encontrará información sobre el aparcamiento, la accesibilidad de los aseos y las distintas zonas de juego.",
  "mapView_filter_vac": "Cambio del nivel del suelo",
  "languages": "Idioma",
  "english": "Inglés",
  "swedish": "Sueco",
  "danish": "Danés",
  "german": "Alemán",
  "spanish": "Español",
  "french": "Francés",
  "norwegian": "Noruego",
  "portuguese": "Portugués",
  "ukrainian": "Ucraniano",
  "accessibility_room": "Sala de accesibilidad",
  "pool": "Piscina",
  "spa": "Spa",
  "bar_restaurant": "Bar/Restaurante",
  "bathrooms": "Baños",
  "shop": "Tienda",
  "exhibition": "Exposición",
  "rate_your_experience": "¿Puedes ayudarnos a mejorar🧑‍🦼🦮🦻? 🙏",
  "did_you_find_the_accessibility_information_you_needed": "¿Ha encontrado la información sobre accesibilidad que necesitaba?",
  "how_satisfied_are_you_with_your_experience": "¿Qué tan satisfecho está con su experiencia con nuestra guía de accesibilidad?",
  "what_do_you_like_the_most": "¿Qué es lo que más disfrutas?",
  "sorry_to_hear_that": "Por favor comente por qué, para enviar su calificación",
  "we_would_love_to_hear_your_suggestions": "Nos gustaría escuchar tus sugerencias",
  "submit": "Enviar",
  "book_now": "Reserve ahora",
  "very_bad": "Muy mal",
  "bad": "Malo",
  "neutral": "Neutral",
  "good": "Bien",
  "great": "Excelente"
}