import {useContext, useEffect, useState, useCallback, useRef, MutableRefObject} from "react";
import { refreshContext } from "../contexts/refreshContext";
import { searchPropertiesWithFields } from "../services/property.service";
import { Property } from "../Models/Property";
import { a11yStandardKeys } from "../constants/constants";

export const useFetchProperties = (propertyId: string) => {

  const [loading, setLoading] = useState(true);

  const [refresh] = useContext(refreshContext);

  const [propertyItems, setPropertyItems] = useState<Property[]>([]);
  const [itemsQueryOffset, setItemsQueryOffset] = useState(0);
  const [noItemsreceived, setNoItemsreceived] = useState(false);
  const [infiniteScrollLoading, setInfiniteScrollLoading] = useState(false);
  const [hasMore, setHaseMore] = useState(false);
  const [errorFetchingItems, setErrorFetchingItems] = useState(false);

  const observer: MutableRefObject<IntersectionObserver | []> = useRef([]);
  const lastItemRef = useCallback(
    (node: HTMLButtonElement) => {
      if (observer.current) {
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore && observer) {
            setItemsQueryOffset((prevState) => prevState + 32);
            if ("unobserve" in observer.current) {
              observer.current.unobserve(entries[0].target);
            }
          }
        });
      }
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore]
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(propertyItems.length === 0 ? true : false);
      setInfiniteScrollLoading(true);
      const properties = (
        await searchPropertiesWithFields({
          a11y_standard: a11yStandardKeys.shop, // Only needs and will only work for shops for now because of this.
          related_to: propertyId,
          ordering: "name",
          limit: 32,
          offset: itemsQueryOffset,
          fields: "id,name,logo,amount_of_floor_levels,sub_category,category"
        })
      );      
      if (properties.results.length === 0 && propertyItems.length === 0) {
        console.error("display_shops array is empty");
        setNoItemsreceived(true);
        setInfiniteScrollLoading(false);
      } else {
        setPropertyItems((prevState) => {
          return [...prevState, ...properties.results];
        })
        if (properties.count > propertyItems.length) {
          setHaseMore(true);
        } else {
          setHaseMore(false);
        }
        setNoItemsreceived(false);
        setInfiniteScrollLoading(false);
      }
      setLoading(false);
    };

    fetchData().catch((err) => {
      console.error(err);
        setErrorFetchingItems(true);
        setLoading(false);
        setInfiniteScrollLoading(false);
    });

    return () => {
      //setPropertyItems([]);
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, propertyId, itemsQueryOffset])

  return {
    propertyItems,
    loading,
    noItemsreceived,
    infiniteScrollLoading,
    errorFetchingItems,
    lastItemRef,
  };
};
