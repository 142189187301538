import {Card, CardActionArea, CardContent, CardMedia, Grid} from "@mui/material";
import React, {FC} from "react";
import styled from "styled-components";
import {SizeContainerWrapper} from "../../../StyledWrappers/Wrappers";
import Typography from "@mui/material/Typography";

const StyledWrapper = styled(SizeContainerWrapper)`
  padding: 0;
  margin: 10px;
`;

const ListItemCard = styled(Card)`
  width: 100%;
  height: 100%;
`;

const StyledCardActionArea = styled(CardActionArea)`
  font-size: 14px !important;
  & img {
    height: 150px !important;
    object-fit: cover !important;
  }
`;

const ListItemBoxName = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  display: inline;
`;

type PropsType = {
  id: string
  image?: string,
  name: string,
  onFetchUnitItemData: (id: string) => void
}

const UnitItem: FC<PropsType> = ({
  id,
  image,
  name,
  onFetchUnitItemData,
}) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
      <StyledWrapper
        onClick={() => onFetchUnitItemData(id)}
      >
        <ListItemCard elevation={0}>
          <StyledCardActionArea>
            {(image && image.length > 1) ?
              <CardMedia
                sx={{objectFit: "cover"}}
                component="img"
                height="150"
                image={image}
                alt={name}
              /> : null
            }
            <CardContent>
              <ListItemBoxName>{name}</ListItemBoxName>
            </CardContent>
          </StyledCardActionArea>
        </ListItemCard>
      </StyledWrapper>
    </Grid>
  )
}

export default UnitItem
