import {FC} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";
import {SvgIcon} from "@mui/material";

const ExhibitionSvg: FC<SvgIconProps> = (p) => (
    <SvgIcon {...p}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 540">
            <rect x="55" y="368" width="29" height="115"/>
            <path d="M84,333.128a14.5,14.5,0,1,0-29,0V354H84Z"/>
            <rect x="428" y="368" width="29" height="115"/>
            <path d="M442.5,318.379A14.554,14.554,0,0,0,428,333.128V354h29V333.128A14.554,14.554,0,0,0,442.5,318.379Z"/>
            <path
                d="M414,360c0,20-15.821,39.336-44.548,54.221C339.266,429.861,298.974,438.46,256,438.46s-83.266-8.6-113.454-24.244C113.819,399.332,98,380,98,360H84c0,26,18.5,49.236,52.107,66.646,32.142,16.653,74.721,25.805,119.9,25.805S343.753,443.3,375.9,426.646C409.5,409.236,428,386,428,360Z"/>
            <path
                d="M99,199.624l71.08-46.978a44.006,44.006,0,0,1,52.622,2.29l50.479,42.136a29.64,29.64,0,0,0,33.444,2.978L413,142.067V59H99ZM287.522,75.229c20.759,0,37.647,16.284,37.647,36.3s-16.888,36.3-37.647,36.3-37.647-16.283-37.647-36.3S266.763,75.229,287.522,75.229Z"/>
            <path
                d="M287.522,133.826c13.039,0,23.647-10,23.647-22.3s-10.608-22.3-23.647-22.3-23.647,10-23.647,22.3S274.483,133.826,287.522,133.826Z"/>
            <path
                d="M264.249,207.852l-50.48-42.137a29.927,29.927,0,0,0-35.807-1.5l-.111.075L99,216.405V242H413V158.011l-99.614,54.3A43.553,43.553,0,0,1,264.249,207.852Z"/>
            <path d="M443,29H69V272H443ZM427,249a7,7,0,0,1-7,7H92a7,7,0,0,1-7-7V52a7,7,0,0,1,7-7H420a7,7,0,0,1,7,7Z"/>
        </svg>
    </SvgIcon>
)

export default ExhibitionSvg