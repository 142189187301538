import { createContext, FC, useState } from "react";

export const refreshContext = createContext<[boolean, React.Dispatch<React.SetStateAction<boolean>>]>([false, () => { }])

// this context is used for force re-render ... it's not great but it gets the job done
export const RefreshContextProvider: FC = ({ children }) => {
    const refreshState = useState(false);
    return <refreshContext.Provider value={refreshState}>
        {children}
    </refreshContext.Provider>
}