import ImageGallery, {ReactImageGalleryItem, ReactImageGalleryProps} from "react-image-gallery"
import clsx from "clsx";
import {
  CSSProperties,
  FocusEventHandler,
  MouseEventHandler,
  ReactNode,
  TouchEventHandler
} from "react";
declare module "react-image-gallery" {
  interface ReactImageGalleryItem {
    // i'll leave it required to know that it's something that we've implemented
    imageDescription: string;
    id?: string;
  }
}

type ImageGalleryTypes = {
  state: {
    currentIndex: number
  },
  getAlignmentClassName: (index: number) => string;
  renderItem: (item: ReactImageGalleryItem) => ReactNode;
  renderThumbInner: (item: ReactImageGalleryItem) => ReactNode;
  lazyLoaded: boolean[]
  getSlideStyle: (index: number) => CSSProperties | undefined;
  handleSlideKeyUp: (event: React.KeyboardEvent<HTMLDivElement>) => void
  handleRenderItem: (item: ReactImageGalleryItem) => ReactNode;
  onThumbnailMouseLeave: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleThumbnailMouseOver: (event: React.MouseEvent<HTMLButtonElement> |  React.FocusEvent<HTMLButtonElement>, index: number) => void;
  handleThumbnailKeyUp: (event: React.KeyboardEvent<HTMLButtonElement>, index: number) => void;
  onThumbnailClick: (event: React.MouseEvent<HTMLButtonElement>, index: number) => void;
  slideToIndex: (index: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent> & {target: HTMLButtonElement}) => void
}

Object.defineProperty(ImageGallery.prototype, "getSlideItems", {
  value(this: ImageGalleryTypes & {
    props: ReactImageGalleryProps
  }) {
    // copied from https://github.com/xiaolin/react-image-gallery/blob/master/src/ImageGallery.js , line 468
    // modified the aria labels
    const { currentIndex } = this.state;
    const {
      items,
      slideOnThumbnailOver,
      onClick,
      lazyLoad,
      onTouchMove,
      onTouchEnd,
      onTouchStart,
      onMouseOver,
      onMouseLeave,
      renderItem,
      renderThumbInner,
      showThumbnails,
      showBullets,
    } = this.props;

    const slides: ReactNode[] = [];
    const thumbnails: ReactNode[] = [];
    const bullets: ReactNode[] = [];

    items.forEach((item, index) => {
      const alignment: string = this.getAlignmentClassName(index);
      const originalClass: string = item.originalClass ? ` ${item.originalClass}` : '';
      const thumbnailClass: string = item.thumbnailClass ? ` ${item.thumbnailClass}` : '';
      const handleRenderItem = item.renderItem || renderItem || this.renderItem;
      const handleRenderThumbInner = item.renderThumbInner
        || renderThumbInner || this.renderThumbInner;

      const showItem = !lazyLoad || alignment || this.lazyLoaded[index];
      if (showItem && lazyLoad && !this.lazyLoaded[index]) {
        this.lazyLoaded[index] = true;
      }

      const slideStyle = this.getSlideStyle(index);

      const slide = (
        <div
          aria-label={`${item.imageDescription} ( Slide ${index + 1} ) `}
          key={`slide-${index}`}
          tabIndex={-1}
          className={`image-gallery-slide ${alignment} ${originalClass}`}
          style={slideStyle}
          onClick={onClick as (MouseEventHandler<HTMLDivElement> | undefined)}
          onKeyUp={this.handleSlideKeyUp}
          onTouchMove={onTouchMove as (TouchEventHandler<HTMLDivElement> | undefined)}
          onTouchEnd={onTouchEnd as (TouchEventHandler<HTMLDivElement> | undefined)}
          onTouchStart={onTouchStart as (TouchEventHandler<HTMLDivElement> | undefined)}
          onMouseOver={onMouseOver as (MouseEventHandler<HTMLDivElement> | undefined)}
          onFocus={onMouseOver as (FocusEventHandler<HTMLDivElement> | undefined)}
          onMouseLeave={onMouseLeave as (MouseEventHandler<HTMLDivElement> | undefined)}
          role="button"
        >
          {showItem ? handleRenderItem(item) : <div style={{ height: '100%' }} />}
        </div>
      );

      slides.push(slide);

      // Don't add thumbnails if there is none
      if (showThumbnails && item.thumbnail) {
        const igThumbnailClass = clsx(
          'image-gallery-thumbnail',
          thumbnailClass,
          { active: currentIndex === index },
        );
        thumbnails.push(
          <button
            key={`thumbnail-${index}`}
            type="button"
            tabIndex={0}
            aria-pressed={currentIndex === index ? 'true' : 'false'}
            aria-label={`${item.imageDescription} ( Slide ${index + 1} ) `}
            className={igThumbnailClass}
            onMouseLeave={event => slideOnThumbnailOver ? this.onThumbnailMouseLeave(event) : null}
            onMouseOver={event => this.handleThumbnailMouseOver(event, index)}
            onFocus={event => this.handleThumbnailMouseOver(event, index)}
            onKeyUp={event => this.handleThumbnailKeyUp(event, index)}
            onClick={event => this.onThumbnailClick(event, index)}
          >
            {handleRenderThumbInner(item)}
          </button>,
        );
      }

      if (showBullets) {
        // generate bullet elements and store them in array
        const bulletOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> & {target: HTMLButtonElement}) => {
          if (item.bulletOnClick) {
            item.bulletOnClick({ item, itemIndex: index, currentIndex });
          }
          // blur element to remove outline caused by focus
          event.target.blur();
          return this.slideToIndex.call(this, index, event);
        };
        const igBulletClass = clsx(
          'image-gallery-bullet',
          item.bulletClass,
          { active: currentIndex === index },
        );
        bullets.push(
          <button
            type="button"
            key={`bullet-${index}`}
            className={igBulletClass}
            onClick={bulletOnClick}
            aria-pressed={currentIndex === index ? 'true' : 'false'}
            aria-label={`${item.imageDescription} ( Slide ${index + 1} ) `}
          />,
        );
      }
    });

    return {
      slides,
      thumbnails,
      bullets,
    };
  },
})