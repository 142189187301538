import React, {FC, useContext} from "react";
import styled from "styled-components";
import { i18n } from "../../../translations/i18n";
import {LanguagesType} from "../../../constants/constants";
import {useEffect, useState} from "react";
import Icons from "../../Icons";
import {IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import { getSupportedLangFromCompany } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import {refreshContext} from "../../../contexts/refreshContext";
import {MetricMessage} from "../../../utils/metrics";
import {Mixpanel} from "../../../Mixpanel";

const IconWrapper = styled.div`
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledIconButton = styled(IconButton)`
  max-height: 37px;
  &:focus {
    background-color: transparent !important;
    border-color: transparent !important;
  }
`

const EnFlagIcon = styled(Icons.EnFlag)`
  border-radius: 50%;
  object-fit: cover;
  width: 30px;
  height: 30px;  
`;

const SvFlagIcon = styled(Icons.SweFlag)`
  border-radius: 50%;
  object-fit: cover;
  width: 30px;
  height: 30px;
`;

const DaFlagIcon = styled(Icons.DanFlag)`
  border-radius: 50%;
  object-fit: cover;
  width: 30px;
  height: 30px;
`;

const DeFlagIcon = styled(Icons.GerFlag)`
  border-radius: 50%;
  object-fit: cover;
  width: 30px;
  height: 30px;
`;

const EsFlagIcon = styled(Icons.SpaFlag)`
  border-radius: 50%;
  object-fit: cover;
  width: 30px;
  height: 30px;
`;

const FrFlagIcon = styled(Icons.FreFlag)`
  border-radius: 50%;
  object-fit: cover;
  width: 30px;
  height: 30px;
`;

const NoFlagIcon = styled(Icons.NorFlag)`
  border-radius: 50%;
  object-fit: cover;
  width: 30px;
  height: 30px;
`;

const PtFlagIcon = styled(Icons.PorFlag)`
  border-radius: 50%;
  object-fit: cover;
  width: 30px;
  height: 30px;
`;

const UaFlagIcon = styled(Icons.UkrFlag)`
  border-radius: 50%;
  object-fit: cover;
  width: 30px;
  height: 30px;
`;

const menuStyle = {
  list: {
    style: {
//       paddingTop: 3,
//       paddingBottom: 3,
//       backgroundColor: '#000000',
//       color: '#E0E0E0',
//       borderRadius: 8,
//       fontSize: 20
    }
  },
  paper: {
    style: {
  //     transform: 'translateX(5%) translateY(35%)'
    }
  }
}

const Text = styled.span`
  font-size: 16px !important;
`

const langKeys: { [key: string]: string } = {
  en: "english",
  sv: "swedish",
  da: "danish",
  de: "german",
  es: "spanish",
  fr: "french",
  no: "norwegian",
  pt: "portuguese",
  uk: "ukrainian",
}

const toSupportIfNoLangGiven = ['en', 'da', 'sv'];

type NameLanguage = { [key: string]: string }

const LanguagesDropdown: FC<{
  supportedLanguages: string[];
}> = ({ supportedLanguages }) => {
  const { t } = useTranslation()
  const [language, setLanguage] = useState<string>(getSupportedLangFromCompany(supportedLanguages, i18n.language));
  const [anchorMenu, setAnchorMenu] = React.useState<EventTarget & Element | null>(null);
  const [nameLanguage, setNameLanguage] = useState<NameLanguage>(langKeys);
  const [, setRefresh] = useContext(refreshContext);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleChange = (lang: LanguagesType) => {
    if (MetricMessage.changed_language[lang]) {
      Mixpanel.track(`${MetricMessage.changed_language[lang]}`);
    } else {
      Mixpanel.track(`${MetricMessage.changed_language[LanguagesType.English]}`)
    }
    i18n.changeLanguage(lang, (err) => {
        setRefresh((refresh) => !refresh);
      if (err) return console.log('something went wrong loading language', err);
    });
    setLanguage(lang)
    setAnchorMenu(null);
  };


  useEffect(() => {
    if (supportedLanguages && supportedLanguages.length > 0) {
      const newLangState: NameLanguage = {};
      supportedLanguages.forEach((langCode: string) => {
        newLangState[langCode] = langKeys[langCode];
      })
      setNameLanguage(newLangState);
    } else if (supportedLanguages && supportedLanguages.length === 0) {
      const langsToSupport = toSupportIfNoLangGiven;
      const newLangState: NameLanguage = {};
      langsToSupport.forEach((langCode: string) => {
        newLangState[langCode] = langKeys[langCode];
      })
      setNameLanguage(newLangState);
    }
  }, [supportedLanguages]);


  const FLAGS: {[key: string]: JSX.Element} = {
    [LanguagesType.English]: <EnFlagIcon alt={`${t('english')} flag icon`}/>,
    [LanguagesType.Swedish]: <SvFlagIcon alt={`${t('swedish')} flag icon`}/>,
    [LanguagesType.Danish]: <DaFlagIcon alt={`${t('danish')} flag icon`} />,
    [LanguagesType.German]: <DeFlagIcon alt={`${t('german')} flag icon`} />,
    [LanguagesType.Spanish]: <EsFlagIcon alt={`${t('spanish')} flag icon`} />,
    [LanguagesType.French]: <FrFlagIcon alt={`${t('french')} flag icon`} />,
    [LanguagesType.Norwegian]:<NoFlagIcon alt={`${t('norwegian')} flag icon`} />,
    [LanguagesType.Portuguese]:<PtFlagIcon alt={`${t('portuguese')} flag icon`} />,
    [LanguagesType.Ukrainian]:<UaFlagIcon alt={`${t('ukraine')} flag icon`} />,
  }

  const getFlag = () => {
    return FLAGS[language] || FLAGS[LanguagesType.English]
  }

  const getMenuList = () => {
    return Object.keys(nameLanguage).sort().map((name, index) => {
      return (
        <MenuItem
          key={index}
          onClick={() => handleChange(name as LanguagesType)}
          selected={language === name}
          disabled={language === name}
        >
          <Text>{t(nameLanguage[name])}</Text>
        </MenuItem>
      )
    })
  }

  return (
    <React.Fragment>
      {Object.keys(nameLanguage) && Object.keys(nameLanguage).length > 1 ?
        <>
          <IconWrapper>
            <Tooltip title={t('languages')} PopperProps={{sx: { top: '-10px !important' }}}>
              <StyledIconButton
                aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                aria-haspopup="true"
                onClick={toggleMenu}
                color="inherit"
              >
                {getFlag()}
              </StyledIconButton>
            </Tooltip>
          </IconWrapper>
          <Menu
            disablePortal
            id="menu-appbar"
            anchorEl={anchorMenu}
            open={Boolean(anchorMenu)}
            onClose={closeMenu}
            PaperProps={menuStyle.paper}
            MenuListProps={menuStyle.list}
          >
            {getMenuList()}
          </Menu>
        </> : null
      }
    </React.Fragment>
  );
}

export default LanguagesDropdown;
