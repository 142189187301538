import styled from "styled-components";
import {Skeleton as MuiSkeleton} from "@mui/material";

type SkeletonProps = {
  mt?: number
}

const Skeleton = styled(MuiSkeleton)<SkeletonProps>`
  transform: none;
  border-radius: unset;
  margin-top: ${({mt}) => mt ? `${mt}px` : 'unset'}
`

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const SkeletonList = () => {
  return (
    <Wrapper>
      <Skeleton variant="text" animation="wave" height={'30%'}/>
      <Skeleton variant="rectangular" animation="wave" height={'100%'} mt={10}/>
      <Skeleton variant="text" animation="wave" height={'30%'} mt={10}/>
      <Skeleton variant="rectangular" animation="wave" height={'100%'} mt={10}/>
      <Skeleton variant="text" animation="wave" height={'30%'} mt={10}/>
      <Skeleton variant="rectangular" animation="wave" height={'100%'} mt={10}/>
    </Wrapper>
  )
}

export default SkeletonList
