import {FC} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";

const FloorsSvg: FC<SvgIconProps> = (p) => (
    <svg {...p} viewBox="-20 0 135 94" fill="none">
        <rect width="60" height="60" rx="4" transform="matrix(0.866025 -0.5 0.866025 0.5 0 60)"/>
        <rect width="60" height="60" rx="4" transform="matrix(0.866025 -0.5 0.866025 0.5 0 60)" fill="white"/>
        <rect width="60" height="60" rx="4" transform="matrix(0.866025 -0.5 0.866025 0.5 0 60)" stroke={p.stroke} strokeWidth="12"/>
        <rect width="60" height="60" rx="4" transform="matrix(0.866025 -0.5 0.866025 0.5 0 45)"/>
        <rect width="60" height="60" rx="4" transform="matrix(0.866025 -0.5 0.866025 0.5 0 45)" fill="white"/>
        <rect width="60" height="60" rx="4" transform="matrix(0.866025 -0.5 0.866025 0.5 0 45)" stroke={p.stroke} strokeWidth="12"/>
        <rect width="60" height="60" rx="4" transform="matrix(0.866025 -0.5 0.866025 0.5 0 30)"/>
        <rect width="60" height="60" rx="4" transform="matrix(0.866025 -0.5 0.866025 0.5 0 30)" fill="white"/>
        <rect width="60" height="60" rx="4" transform="matrix(0.866025 -0.5 0.866025 0.5 0 30)" stroke={p.stroke} strokeWidth="12"/>
    </svg>
)

export default FloorsSvg