import React, {createContext, FC, useCallback, useContext, useEffect, useState,} from "react";
import "./overrides/react-image-gallery-thumbails";
import {PageLayout} from "./components/MainPage";
import {TabPanel} from "./components/TabPanel";
import {LoadingComponent} from "./components/LoadingComponent";
import {Dialog, Theme, useMediaQuery} from "@mui/material";
import {headers} from "./services/utils";
import {useTranslation} from "react-i18next";
import {Mixpanel} from "./Mixpanel";
import {MetricMessage} from "./utils/metrics";
import {useFetchData} from "./hooks/useFetchData";
import PropertyWrapper from "./pages/Property/PropertyWrapper";
import {BuildingAreaPage, MapContextProviderBuildingArea} from "./pages/BuildingArea/BuildingAreaPage";
import {refreshContext} from "./contexts/refreshContext";
import {facilityType} from "./constants/constants";
import OpenWidgetButton from "./components/OpenWidgetButton";
import { WidgetSettings } from "./Models/WidgetSettings";
import {DialogStylesWrapper} from "./StyledWrappers/Wrappers";
import IFramer from "./IFramer";
import CookieBanner from "./components/CookieBanner";
import {useStorageData} from "./hooks/useStorageData";
import {getAccessibilityUnitLevelNumber, getTextByCurrentLanguage} from "./utils/utils";
import FeedbackModal from "./components/feedback-modal/FeedbackModal";
import {feedbackOpenContext} from "./contexts/feedbackContext";

export const dialogOpenContext = createContext<
  [boolean, React.Dispatch<boolean>]
>([false, () => null!]);
export const DialogOpenContextProvider: FC = ({ children }) => {
  const dialogState = useState(false);
  return (
    <dialogOpenContext.Provider value={dialogState}>
      {children}
    </dialogOpenContext.Provider>
  );
};

function App(props: {
  openDefault: boolean
  companyIdAPIKey: string | null,
  widgetAccessibilityButtonSettings: WidgetSettings[] | undefined,
  buildingAreaId: string | null,
  propertyId: string | null
}) {
  const { companyIdAPIKey, widgetAccessibilityButtonSettings, buildingAreaId, propertyId } = props;
  const [addedMetricsGroup, setAddedMetricsGroup] = useState(false);
  const [buttonLoaded, setButtonLoaded] = useState<boolean>(false);
  const id = buildingAreaId ? buildingAreaId : propertyId;
  const type = buildingAreaId ? facilityType.buildingArea : facilityType.property;
  const { t, i18n } = useTranslation();
  const [dialogOpen, setDialogOpen] = useContext(dialogOpenContext);
  const [feedbackOpen, setFeedbackOpen] = useContext(feedbackOpenContext);
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [, setRefresh] = useContext(refreshContext);
  const cookieConsent = useStorageData('cookie-consent')
  const isInIframe = window.self !== window.top
  const feedbackShown = useStorageData('feedbackShown')
  const [feedbackTimeout, setFeedbackTimeout] = useState(false)

  // accept building area id and property id
  const {
    properties,
    a11yStandard,
    company,
    loading,
    selectedBuildingArea,
    shopsCount,
    parkingSpaces,
    publicToilets,
    toilets,
    diningArea,
    generalAccessibilityInfo,
    buildingAreaAccessibilityInfo,
    published,
    accessibilityUnits,
    accessibilities,
    questions
  } = useFetchData(id, type);

  useEffect(() => {
    if (company) {
      if (i18n.language && company.supported_languages.includes(i18n.language)) {
        headers["Accept-Language"] = i18n.language
      } else {
        i18n.changeLanguage(company.supported_languages[0], (err) => {
          if (err) return console.log('something went wrong loading language', err);
        });
      }
    }
  }, [i18n.language, company]);

  useEffect(() => {
    // adding mixpanel group in different cases
    const inFullscreenMode =
      JSON.parse(cookieConsent) && props.openDefault && company ||
      props.openDefault && company && isInIframe
    const isNotFullscreenMode = !props.openDefault && company
    if (inFullscreenMode || isNotFullscreenMode) {
      setMixpanelGroup()
    }
  }, [company, cookieConsent, props.openDefault])

  const setMixpanelGroup = () => {
    if (company && Mixpanel.setGroup && !addedMetricsGroup) {
      const group: {
        companyId: string,
        propertyId?: string | null,
        buildingAreaId?: string | null
      } = {
        companyId: company.id,
      }
      group.propertyId = type === facilityType.property ? id : null
      group.buildingAreaId = type === facilityType.buildingArea ? id : null

      setAddedMetricsGroup(true);
      Mixpanel.setGroup(group);
      if (!buttonLoaded) {
        setButtonLoaded(true);
      }
    }
  }

  useEffect(() => {
    if (props.openDefault) {
      setDialogOpen(true);
    }
    localStorage.setItem('fullscreen', String(props.openDefault))
  }, []);

  useEffect(() => {
    // quickfix for funnys adventure widget
    if (dialogOpen) {
      document.body.style.overflow = "hidden"
      let footers = document.getElementsByTagName('footer');
      if (footers.length > 0) {
        footers[0].style.zIndex = '9999'
      }
    } else {
      document.body.style.overflow = "auto"
    }
  }, [dialogOpen])

  useEffect(() => {
    const isNotWrappedInIframe = props.openDefault && company && dialogOpen && JSON.parse(cookieConsent)
    const isWrappedInIframe = props.openDefault && company && dialogOpen && isInIframe
    if (isNotWrappedInIframe || isWrappedInIframe) {
      Mixpanel.track(`${MetricMessage.accessed_widget_button}`);
    }
  }, [dialogOpen, props.openDefault, company, cookieConsent]);

  useEffect(() => {
    if (!feedbackShown) {
      let feedbackTimeoutLink = setTimeout(() => {
        setFeedbackTimeout(true)
      }, 40000)
      return () => {
        clearTimeout(feedbackTimeoutLink);
      }
    }
  }, []);

  const hasData = properties ? properties.length > 0 && !!a11yStandard : false;

  let order: string[] = [];

  properties
    ? properties.map((property) => {
        order.push(property.id);
        return order;
      })
    : order.push("");

  const loadingState = properties ? (properties.length <= 0) : true;

  function renderHeaders(): string[] {
    const propertiesNames: string[] = [];
    if (selectedBuildingArea) {
      propertiesNames.push(selectedBuildingArea.name);
    }

    properties
      ? properties.map((property) => {
          propertiesNames.push(getTextByCurrentLanguage(property.name));
          return propertiesNames;
        })
      : propertiesNames.push("");

    return propertiesNames.map((p) => `${p}`);
  }

  const onAccessibilityClick = () => {
    if (company) {
      Mixpanel.track(`${MetricMessage.accessed_widget_button}`);
    }
    return setDialogOpen(!dialogOpen);
  };

  const isDisplayShops = () => (
    !!(widgetAccessibilityButtonSettings
      && widgetAccessibilityButtonSettings[0]
      && widgetAccessibilityButtonSettings[0].is_display_shops)
  )


  // Check if document in iframe
  if (window.self !== window.top) {
    const metaViewport = document.querySelector('meta[name="viewport"]');
    metaViewport?.setAttribute('content', 'width=device-width, initial-scale=0.9');
  }

  const handleCloseFeedback = () => {
    Mixpanel.track(`${MetricMessage.closed_widget_window}`);
    if (props.openDefault) {
      window.close()
    } else {
      setDialogOpen(false);
    }
    setFeedbackOpen(false)
  }

  const handleDialogClose = (e: any) => {
    if (feedbackShown || !feedbackTimeout) {
      if (company) {
        Mixpanel.track(`${MetricMessage.closed_widget_modal} `);
      }
      setDialogOpen(false);
    } else if (feedbackTimeout) {
      Mixpanel.track(`${MetricMessage.feedback_shown}`)
      localStorage.setItem("feedbackShown", "true");
      window.dispatchEvent(new Event('storage'))
      e.preventDefault()
      e.stopPropagation()
      setFeedbackOpen(true)
    }
  }

  return (
    <>
      {companyIdAPIKey ?
        <div className="handicover-widget-entrypoint">
          {!props.openDefault ? (
            <OpenWidgetButton
              companyIdAPIKey={companyIdAPIKey}
              a11yStandard={a11yStandard}
              settings={widgetAccessibilityButtonSettings}
              loadingState={loadingState}
              loading={loading}
              t={t}
              onAccessibilityClick={onAccessibilityClick}
            />
          ) : null
          }
          <IFramer
            component={
              <DialogStylesWrapper>
                <Dialog
                  disablePortal
                  open={dialogOpen}
                  fullScreen={props.openDefault ? true : sm}
                  onClose={handleDialogClose}
                  className="handiscover-accessibility-widget"
                  fullWidth
                  maxWidth="lg"
                >
                  {properties && a11yStandard && company ? (
                    <PageLayout
                      fullScreen={props.openDefault}
                      loading={loading}
                      properties={properties}
                      currentStandard={a11yStandard}
                      headerTextMap={renderHeaders()}
                      companyLogo={company?.logo}
                      propertyName={company?.name ?? ""}
                      buildingArea={selectedBuildingArea}
                      companyName={company?.name}
                      supportedLanguages={company?.supported_languages}
                    >
                      {loading || !a11yStandard ? (
                        <LoadingComponent />
                      ) : hasData ? (
                        <React.Fragment>
                          {selectedBuildingArea && buildingAreaId ? (
                            <TabPanel key={selectedBuildingArea.id} value={0} fullscreen={props.openDefault}>
                              <MapContextProviderBuildingArea>
                                <BuildingAreaPage
                                  buildingArea={selectedBuildingArea}
                                  a11yStandard={a11yStandard}
                                  propertiesCount={properties.length}
                                  shopsCount={shopsCount}
                                  parkingSpaces={getAccessibilityUnitLevelNumber(parkingSpaces, accessibilityUnits)}
                                  publicToilets={getAccessibilityUnitLevelNumber(publicToilets, accessibilityUnits)}
                                  toilets={getAccessibilityUnitLevelNumber(toilets, accessibilityUnits)}
                                  foodCourtsCount={diningArea}
                                  generalAccessibilityInfo={generalAccessibilityInfo}
                                  properties={properties}
                                  buildingAreaAccessibilityInfo={buildingAreaAccessibilityInfo}
                                  buildingAreaId={buildingAreaId}
                                  fullscreen={props.openDefault}
                                  accessibilityUnits={accessibilityUnits}
                                  accessibilites={accessibilities}
                                  questions={questions}
                                />
                              </MapContextProviderBuildingArea>
                            </TabPanel>
                          ) : null}
                          {loading ? (
                            <LoadingComponent />
                          ) : properties && selectedBuildingArea ? (
                            properties.map((property, i) => {
                              return (
                                // if building area is not rendered is 0 else, is 1 because buildingarea page is the first one.
                                <TabPanel
                                  key={property.id}
                                  value={selectedBuildingArea ? i + 1 : 0}
                                  fullscreen={props.openDefault}
                                >
                                  <PropertyWrapper
                                    property={property}
                                    a11yStandard={a11yStandard}
                                    displayShops={isDisplayShops()} // change name to displayPropertyTabs
                                    companyIdAPIKey={companyIdAPIKey}
                                    buildingAreaId={buildingAreaId}
                                    fullscreen={props.openDefault}
                                  />
                                </TabPanel>
                              );
                            })
                          ) : loading ? (
                            <LoadingComponent />
                          ) : (
                            properties.map((property, i) => {
                              return (
                                <TabPanel
                                  key={property.id}
                                  value={selectedBuildingArea ? i + 1 : 0}
                                  fullscreen={props.openDefault}
                                >
                                  <PropertyWrapper
                                    property={property}
                                    a11yStandard={a11yStandard}
                                    displayShops={isDisplayShops()}
                                    companyIdAPIKey={companyIdAPIKey}
                                    buildingAreaId={buildingAreaId}
                                    fullscreen={props.openDefault}
                                  />
                                </TabPanel>
                              );
                            })
                          )}
                        </React.Fragment>
                      ) : null}
                      <FeedbackModal
                        onClose={handleCloseFeedback}
                        open={feedbackOpen}
                      />
                    </PageLayout>
                  ) : (published) ? (
                    <LoadingComponent />
                  ) : null}
                </Dialog>
                <CookieBanner display={props.openDefault && !isInIframe}/>
              </DialogStylesWrapper>
            }
            dialogOpen={dialogOpen ? 1 : 0}
            name={'Handiscover Dialog'}
          />
        </div> : <></>
      }
    </>
  );
}

export default App;
