import {Accessibility} from "../../../Models/Accessibility";
const S3 = process.env.REACT_APP_AWS_S3_BUCKET;
const URL = `${S3}/ams-widget-map-icons`

enum NAME_ICONS {
  SHOP = 'shop.png',
  PARKING = 'parking.png',
  ENTRANCE = 'entrance.png',
  TOILET = 'toilet.png',
  OUTSIDE_AND_NEAR_PLAYGROUND = 'outside_and_near_playground.png',
  PLAYGROUND = 'playground.png',
  WC = 'wc.png',
  BALANCE_PLAY = 'balance_play.png',
  CLIMBING_STRUCTURES ='climbing_structures.png',
  PLAY_HOUSE = 'play_house.png',
  PLAY_FRAME = 'play_frame.png',
  PLAY_SCULPTURE = 'play_sculpture.png',
  SWINGS = 'swings.png',
  PLAYGROUND_SLIDE = 'playground_slide.png',
  ROLE_PLAY_EQUIPMENT = 'role_play_equipment.png',
  SPRING_RIDERS = 'spring_riders.png',
  BALANCE_BEAMS = 'balance_beams.png',
  CABLE_CARS = 'cable_cars.png',
  PLAY_TABLE = 'play_table.png',
  PLAYGROUND_SPINNERS = 'playground_spinners.png',
  SAND_DIGGERS = 'sand_diggers.png',
  SWING_PLAY = 'swing_play.png',
  TRAMPOLINES = 'trampolines.png',
  LIFT = 'lift.png',
  RECEPTION_INFODESK = 'reception_infodesk.png',
  GARDEN = 'garden.png',
  DINING_AREA = 'dining_area.png',
  MEETING_ROOM = 'meeting_room.png',
  AUDITORIUM = 'auditorium.png',
  SPA = 'spa.png',
  POOL = 'pool.png',
  STAIRS = 'stairs.png',
  VERTICAL_CIRCULATION_TYPE = "vertical_circulation_type.png",
  ESCALATOR = 'escalator.png',
  RAMP = 'ramp.png',
  HOTEL_ROOM_TYPE = 'hotel_room_type.png',
}

interface IconType {
  [key: string]: any;
}

const ListOfIcons: IconType = {
  shop: `${URL}/${NAME_ICONS.SHOP}`,
  parking: `${URL}/${NAME_ICONS.PARKING}`,
  entrance: `${URL}/${NAME_ICONS.ENTRANCE}`,
  toilet_room__common: `${URL}/${NAME_ICONS.TOILET}`,
  toilet_room__private: `${URL}/${NAME_ICONS.TOILET}`,
  garden: `${URL}/${NAME_ICONS.GARDEN}`,
  museum_shop: `${URL}/${NAME_ICONS.SHOP}`,
  dining_area: `${URL}/${NAME_ICONS.DINING_AREA}`,
  restaurant: `${URL}/${NAME_ICONS.DINING_AREA}`,
  meeting_room: `${URL}/${NAME_ICONS.MEETING_ROOM}`,
  auditorium: `${URL}/${NAME_ICONS.AUDITORIUM}`,
  lift: `${URL}/${NAME_ICONS.LIFT}`,
  reception_infodesk: `${URL}/${NAME_ICONS.RECEPTION_INFODESK}`,
  spa: `${URL}/${NAME_ICONS.SPA}`,
  pool: `${URL}/${NAME_ICONS.POOL}`,
  playground: `${URL}/${NAME_ICONS.PLAYGROUND}`,
  outdoor_playground_and_park: `${URL}/${NAME_ICONS.PLAYGROUND}`,
  outside_and_near_playground: `${URL}/${NAME_ICONS.OUTSIDE_AND_NEAR_PLAYGROUND}`,
  public_toilet: `${URL}/${NAME_ICONS.WC}`,
  spring_riders: `${URL}/${NAME_ICONS.SPRING_RIDERS}`,
  playground_spinners: `${URL}/${NAME_ICONS.PLAYGROUND_SPINNERS}`,
  balance_beams: `${URL}/${NAME_ICONS.BALANCE_BEAMS}`,
  cable_cars: `${URL}/${NAME_ICONS.CABLE_CARS}`,
  trampolines: `${URL}/${NAME_ICONS.TRAMPOLINES}`,
  sand_diggers: `${URL}/${NAME_ICONS.SAND_DIGGERS}`,
  play_sculpture: `${URL}/${NAME_ICONS.PLAY_SCULPTURE}`,
  role_play_equipment: `${URL}/${NAME_ICONS.ROLE_PLAY_EQUIPMENT}`,
  play_frame: `${URL}/${NAME_ICONS.PLAY_FRAME}`,
  climbing_structures: `${URL}/${NAME_ICONS.CLIMBING_STRUCTURES}`,
  seesaw: `${URL}/${NAME_ICONS.SWING_PLAY}`,
  balance_play: `${URL}/${NAME_ICONS.BALANCE_PLAY}`,
  swings: `${URL}/${NAME_ICONS.SWINGS}`,
  play_table: `${URL}/${NAME_ICONS.PLAY_TABLE}`,
  play_house: `${URL}/${NAME_ICONS.PLAY_HOUSE}`,
  playground_slide: `${URL}/${NAME_ICONS.PLAYGROUND_SLIDE}`,
  swing_play: `${URL}/${NAME_ICONS.SWING_PLAY}`,
  play_area: `${URL}/${NAME_ICONS.PLAYGROUND}`,
  floor_level_change: `${URL}/${NAME_ICONS.VERTICAL_CIRCULATION_TYPE}`,
  stairs: `${URL}/${NAME_ICONS.STAIRS}`,
  escalator: `${URL}/${NAME_ICONS.ESCALATOR}`,
  ramp: `${URL}/${NAME_ICONS.RAMP}`,
  hotel_room_type: `${URL}/${NAME_ICONS.HOTEL_ROOM_TYPE}`,
}

export const getPoiIcons = (standard: string, data: any, accessibilities?: Accessibility[]) => {
  let icons: any[] = []
  if (ListOfIcons[data.poiType]) {
    icons.push({src: ListOfIcons[data.poiType], title: data.name})
  }

  if (data.fromBuildingArea) {
    icons = [{src: ListOfIcons['shop'], title: data.name}]
  }

  if (standard === 'outdoor_playground' && icons.length === 0) {
    icons = [{src: ListOfIcons['outdoor_playground_and_park'], title: data.name}]
  }

  if (data.fromBuildingArea && data.poiType === 'outdoor_playground_and_park') {
    icons = [{src: ListOfIcons[data.poiType], title: data.name}]
  }


  if (data.poiType === 'floor_level_change' && accessibilities) {
    const filteredAccessibilities = accessibilities.filter((accessibility) => (
      accessibility.accessibility_unit === data.poiId && accessibility.attribute.includes('vertical_access_type')
    ))

    if (filteredAccessibilities.length > 0) {
      for (const accessibility of filteredAccessibilities) {
        const splitAttribute = accessibility.attribute.split('__');
        const name = splitAttribute[splitAttribute.length - 1];
          if (filteredAccessibilities.every((i) => i.attribute.includes(name))) {
            icons = [{src: ListOfIcons[name], title: accessibility.attribute_title}]
            return icons
          }
      }

      filteredAccessibilities.forEach(accessibility => {
        const splitAttribute = accessibility.attribute.split('__');
        const name = splitAttribute[splitAttribute.length - 1];
        if (ListOfIcons[name]) {
          icons.push({src: ListOfIcons[name], title: accessibility.attribute_title})
        }
      });
    }
  }

  return icons
}
