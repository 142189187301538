import filterIconAll from "../icons/filter_icon_all.svg";
import filterIconAllBlue from "../icons/filter_icon_all_blue.svg";
import filterIconEntrance from "../icons/filter_icon_entrance.svg";
import filterIconEntranceBlue from "../icons/filter_icon_entrance_blue.svg";
import filterIconParking from "../icons/filter_icon_parking.svg";
import filterIconParkingBlue from "../icons/filter_icon_parking_blue.svg";
import filterIconToilet from "../icons/filter_icon_toilet.svg";
import filterIconToiletBlue from "../icons/filter_icon_toilet_blue.svg";
import filterIconElevator from "../icons/filter_icon_elevator.svg";
import filterIconElevatorBlue from "../icons/filter_icon_elevator_blue.svg";
import filterIconReception from "../icons/filter_icon_reception.svg";
import filterIconReceptionBlue from "../icons/filter_icon_reception_blue.svg";
import filterIconPlayground from "../icons/filter_icon_playground.svg";
import filterIconPlaygroundBlue from "../icons/filter_icon_playground_blue.svg";
import filterIconAuditorium from "../icons/filter_icon_auditorium.svg";
import filterIconAuditoriumBlue from "../icons/filter_icon_auditorium_blue.svg";
import filterIconGarden from "../icons/filter_icon_garden.svg";
import filterIconGardenBlue from "../icons/filter_icon_garden_blue.svg";
import filterIconDiningArea from "../icons/filter_icon_dining_area.svg";
import filterIconDiningAreaBlue from "../icons/filter_icon_dining_area_blue.svg";
import filterIconMeetingRoom from "../icons/filter_icon_meeting_room.svg";
import filterIconMeetingRoomBlue from "../icons/filter_icon_meeting_room_blue.svg";
import filterIconShop from "../icons/filter_icon_shop.svg";
import filterIconShopBlue from "../icons/filter_icon_shop_blue.svg";
import filterIconPool from "../icons/filter_icon_pool.svg";
import filterIconPoolBlue from "../icons/filter_icon_pool_blue.svg";
import filterIconSpa from "../icons/filter_icon_spa.svg";
import filterIconSpaBlue from "../icons/filter_icon_spa_blue.svg";
import filterIconVac from "../icons/filter_icon_vac.svg";
import filterIconVacBlue from "../icons/filter_icon_vac_blue.svg";
import filterIconAccessibilityRoom from "../icons/filter_icon_accessibility_room.svg";
import filterIconAccessibilityRoomBlue from "../icons/filter_icon_accessibility_room_blue.svg";

export type FilterSettingsType = {
  type: string,
  name: string,
  active: boolean,
  defaultIcon: string,
  activeIcon: string
}

// exporta denna
export const filterSettings: FilterSettingsType[] = [
  {
    type: "all",
    name: "mapView_filter_all",
    active: true,
    defaultIcon: filterIconAllBlue,
    activeIcon: filterIconAll
  },
  {
    type: "entrance",
    name: "mapView_filter_entrance",
    active: false,
    defaultIcon: filterIconEntranceBlue,
    activeIcon: filterIconEntrance
  },
  {
    type: "parking",
    name: "mapView_filter_parking",
    active: false,
    defaultIcon: filterIconParkingBlue,
    activeIcon: filterIconParking
  },
  {
    type: "toilet",
    name: "mapView_filter_toilet",
    active: false,
    defaultIcon: filterIconToiletBlue,
    activeIcon: filterIconToilet
  },
  {
    type: "elevator",
    name: "mapView_filter_elevator",
    active: false,
    defaultIcon: filterIconElevatorBlue,
    activeIcon: filterIconElevator
  },
  {
    type: "reception_infodesk",
    name: "mapView_filter_reception_infodesk",
    active: false,
    defaultIcon: filterIconReceptionBlue,
    activeIcon: filterIconReception
  },
  {
    type: "play_area",
    name: "mapView_filter_playground",
    active: false,
    defaultIcon: filterIconPlaygroundBlue,
    activeIcon: filterIconPlayground
  },
  {
    type: "garden",
    name: "mapView_filter_garden",
    active: false,
    defaultIcon: filterIconGardenBlue,
    activeIcon: filterIconGarden
  },
  {
    type: "museum_shop",
    name: "mapView_filter_shop",
    active: false,
    defaultIcon: filterIconShopBlue,
    activeIcon: filterIconShop
  },
  {
    type: "dining_area",
    name: "mapView_filter_restaurant",
    active: false,
    defaultIcon: filterIconDiningAreaBlue,
    activeIcon: filterIconDiningArea
  },
  {
    type: "restaurant",
    name: "bar_restaurant",
    active: false,
    defaultIcon: filterIconDiningAreaBlue,
    activeIcon: filterIconDiningArea
  },
  {
    type: "meeting_room",
    name: "mapView_filter_conference_room",
    active: false,
    defaultIcon: filterIconMeetingRoomBlue,
    activeIcon: filterIconMeetingRoom
  },
  {
    type: "auditorium",
    name: "mapView_filter_auditorium",
    active: false,
    defaultIcon: filterIconAuditoriumBlue,
    activeIcon: filterIconAuditorium
  },
  {
    type: "spa",
    name: "spa",
    active: false,
    defaultIcon: filterIconSpaBlue,
    activeIcon: filterIconSpa
  },
  {
    type: "pool",
    name: "pool",
    active: false,
    defaultIcon: filterIconPoolBlue,
    activeIcon: filterIconPool
  },
  {
    type: "floor_level_change",
    name: "mapView_filter_vac",
    active: false,
    defaultIcon: filterIconVacBlue,
    activeIcon: filterIconVac
  },
  {
    type: "hotel_room_type",
    name: "accessibility_room",
    active: false,
    defaultIcon: filterIconAccessibilityRoomBlue,
    activeIcon: filterIconAccessibilityRoom
  }
]