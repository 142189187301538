import React, { FC, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { WidgetSettings } from "./Models/WidgetSettings";
import { WidgetCredentials } from "./Models/utils";
import { headers } from "./services/utils";
import {getAreaId, fetchWidgetSettings, isURLPage, getCompanyName} from "./utils/utils";
import Alert from "@mui/material/Alert";
import {helsingborgPropertiesIds} from "./constants/constants";
import Providers from "./providers";

const ids: { [key: string]: string } = {
  'emporia': '03MYUEH0.r6NomgA7qiBTU774rq6aKghbHbmqq9Z0',
  'ica-simrishamn': "YfBkJ1oX.6U9t8MQ1ZB33iVCYQUji96HJYeWFGhRW",
  'nackaforum': "ZbYfzljo.0GyAfuS3R5lw2GlwrTWrqdH2mhJangGK",
  'etage-shopping': "0NxF7MNt.OUP5KtxUh6AwK4OPgL2gvgh8ApU4PbDr",
  'helsingborg-lekplatser': "uGJDrzVE.iggXh1MHksKyjH0AK3UMCWFBDQqIluFe",
  'gallerian-sthlm': "2bipiFPr.skeOlGGhYIRzpGvTGGW5w6Ao4FBCvzyA",
  'mood-sthlm': "AlsEfW77.RumwsS8OvdnemPWGKr7cI3tZq5YauzHT",
  'faltoversten-sthlm': "v8sR8KAr.VnPInVwdQ7Rnhd1MU348c5kAS5HoCdGI",
  'vala': 'irwLhzHo.lMLU09DkxvdlnSfh9SobrX4YNxkXvi10',
  'mvg': 'RZCfnufD.sc0oBsEBraDZNeXLVxjNBlFz69s4lHNh',
  'funnys-adventure': 'K3bNyqk6.GTV5HbtHjsQtCXfaXNGtXH6HiQZe7myX',
  'jarfalla-kommun': '1oqUPSal.ALVNieYjysRxM83tbz6qBxJduJdlrvkz',
}

const propertyIds: { [key: string]: string } = {
  'emporia': 'd79f013f-355e-49b8-b2b2-7fc257686036',
  'ica-simrishamn': '8b5fa4e3-8ae7-4923-b2d4-7e66d1f3cecb',
  'nackaforum': '92c64452-b581-4453-a9b8-29203ed79d92',
  'etage-shopping': "85514832-67f2-40de-a087-ec3157d4f181",
  'gallerian-sthlm': "70a218b7-7c91-441a-bfd5-aba0fb976241",
  'mood-sthlm': "f3258c3a-c83c-46d9-b850-f3c54ae8335f",
  'faltoversten-sthlm': "140af766-432b-46f6-8872-42227a249039",
  'vala': '7ae4fe80-ac1c-4d61-a9c8-2fcc05690c46',
  'mvg': 'd797ee0b-16fb-4cac-8cb1-2e2fc9d91c9e',
  'funnys-adventure': 'cf2bc058-5265-4100-882e-cd07991dfc7f',
}

const buildingAreaIds: { [key: string]: string } = {
  'helsingborg-lekplatser': "8f718be6-a4cf-428c-b2b9-3c5046b132c5",
  'jarfalla-kommun': '78e4e95a-c360-44c4-9e1b-1cad77f95f67',
}

// Getting credentials and companyId API key
const widgetRoot: NodeListOf<HTMLElement> = document.querySelectorAll('#handiscover-accessibility-widget');
let companyIdAPIKey: string | null = widgetRoot[0].getAttribute("companyId");
let credentials: HTMLElement = widgetRoot[0];
if (!companyIdAPIKey && widgetRoot.length > 1) {
  companyIdAPIKey = widgetRoot[1].getAttribute("companyId");
  credentials = widgetRoot[1];
  if (!companyIdAPIKey && !credentials) {
    console.error("[Accessibility widget] Couldn't get credentials");
    console.error("[Accessibility widget] Something went wrong when inserting the widget, contact our support if you need help.");
  }
}

headers.Authorization = `Api-Key ${companyIdAPIKey}`;
// headers.Authorization = `Token c88b5865f4303a5898bb8d1b2884b78dc87bef16`; // for dev env.

const Globals: FC = () => {
  const [widgetSettings, setWidgetSettings] = useState<WidgetSettings[] | undefined>(undefined);
  const [receivedSettings, setReceivedSettings] = useState<boolean>(false);
  const [widgetCredentials, setWidgetCredentials] = useState<WidgetCredentials>({
    buildingAreaID: credentials.getAttribute("buildingAreaId"),
    propertyID: credentials.getAttribute("propertyId")
  });
  const [isOpenDefault, setIsOpenDefault] = useState<boolean>(false);
  const [companyID, setCompanyID] = useState<string | null>(companyIdAPIKey);
  const {buildingAreaId, propertyId} = getAreaId(widgetCredentials);

  useEffect(() => {
    if (isURLPage()) {
      setIsOpenDefault(true);
    }

    const companyName: string | undefined = getCompanyName()

    const url = new URL(window.location.href);
    const id = url.pathname.split("/").pop();
    const helsingborgId = helsingborgPropertiesIds.find((i) => i === id)
    if (helsingborgId) {
      setCompanyID(ids['helsingborg-lekplatser'])
      setWidgetCredentials({
        buildingAreaID: '',
        propertyID: helsingborgId
      })
      headers.Authorization = `Api-Key ${ids['helsingborg-lekplatser']}`;
      setIsOpenDefault(true)
    }

    if (companyName && Object.keys(ids).includes(companyName)) { // if Emporia or Ica links
      setCompanyID(ids[companyName])

      setWidgetCredentials({
        buildingAreaID: buildingAreaIds[companyName] ? buildingAreaIds[companyName] : '',
        propertyID: propertyIds[companyName] ? propertyIds[companyName] : ''
      })
      setIsOpenDefault(true)
      headers.Authorization = `Api-Key ${ids[companyName]}`;
      setIsOpenDefault(true);
    }
  }, [])

  useEffect(() => {
    if (companyID) {
      (async () => {
        const settings = await fetchWidgetSettings(buildingAreaId, propertyId);
        if (settings) {
          setWidgetSettings(settings);
        }
        setReceivedSettings(true);
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyID])

  return (
    <Providers>
      {receivedSettings && (buildingAreaId || propertyId) && companyID ?
          <App
              openDefault={isOpenDefault}
              companyIdAPIKey={companyID}
              widgetAccessibilityButtonSettings={widgetSettings}
              buildingAreaId={buildingAreaId}
              propertyId={propertyId}
          />
          : (!buildingAreaId && !propertyId) ?
              <Alert severity="error"><strong>Error! Add correct link.</strong></Alert> : null
      }
    </Providers>
  );
}

ReactDOM.render(
    <Globals />,
    document.getElementById('handiscover-accessibility-widget')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
