import {SvgIcon} from "@mui/material";
import {FC} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";

const AccessibilityToiletSvg: FC<SvgIconProps> = (p) => (
    <SvgIcon {...p}>
        <svg viewBox="0 0 62 97" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.85363 27.44L0 0.559999H5.3226L10.3474 19.04L15.3723 0.597332L20.6949 0.559999L25.7197 19.04L30.7445 0.559999H36.0671L28.2135 27.44H23.2259L18.0336 9.296L12.8412 27.44H7.85363Z"/>
            <path
                d="M49.8287 28C47.1488 28 44.8349 27.4151 42.887 26.2453C40.9515 25.0756 39.4565 23.4391 38.4019 21.336C37.3597 19.2329 36.8386 16.7876 36.8386 14C36.8386 11.2124 37.3597 8.76711 38.4019 6.664C39.4565 4.56089 40.9515 2.92444 42.887 1.75467C44.8349 0.584889 47.1488 0 49.8287 0C52.9057 0 55.4863 0.765333 57.5707 2.296C59.6675 3.82667 61.1439 5.89867 62 8.512L56.9007 9.93067C56.4044 8.30044 55.567 7.03733 54.3883 6.14133C53.2096 5.23289 51.6898 4.77867 49.8287 4.77867C48.129 4.77867 46.7084 5.15822 45.5669 5.91733C44.4379 6.67645 43.588 7.74667 43.0173 9.128C42.4466 10.5093 42.1612 12.1333 42.1612 14C42.1612 15.8667 42.4466 17.4907 43.0173 18.872C43.588 20.2533 44.4379 21.3236 45.5669 22.0827C46.7084 22.8418 48.129 23.2213 49.8287 23.2213C51.6898 23.2213 53.2096 22.7671 54.3883 21.8587C55.567 20.9502 56.4044 19.6871 56.9007 18.0693L62 19.488C61.1439 22.1013 59.6675 24.1733 57.5707 25.704C55.4863 27.2347 52.9057 28 49.8287 28Z"/>
            <g clipPath="url(#clip0_223_281)">
                <path
                    d="M22.4265 34.1034C21.1471 34.3298 20.2598 34.7629 19.4167 35.5701C16.6716 38.2033 17.3627 42.7413 20.7794 44.4738C22.5441 45.3646 24.8726 45.1481 26.451 43.9471C27.8039 42.9135 28.5686 41.4566 28.6324 39.7684C28.6814 38.5281 28.3971 37.4699 27.7402 36.4856C26.8824 35.196 25.5588 34.3642 23.9216 34.0984C23.2206 33.9803 23.0931 33.9803 22.4265 34.1034Z"/>
                <path
                    d="M22.4657 46.2554C20.5539 46.659 18.9706 48.1996 18.4755 50.1339C18.299 50.8032 18.2794 51.4628 18.3725 53.0476C18.4853 54.9278 18.5392 55.8777 18.598 56.9359C18.6275 57.4379 18.6716 58.2107 18.6961 58.6586C18.7255 59.1064 18.7892 60.2582 18.8431 61.2179C19.1471 66.5237 19.2892 68.8518 19.3382 69.1175C19.4461 69.7672 19.8333 70.6975 20.2108 71.2093C21.1029 72.4398 22.2304 73.1387 23.8578 73.4832C24.0343 73.5226 26.9461 73.5472 33.4412 73.5669L42.7696 73.5965L46.4559 80.0195C50.1226 86.3982 50.1471 86.4475 50.5392 86.8412C50.8529 87.1611 51.0147 87.2842 51.348 87.4417C51.9363 87.7272 52.3235 87.8108 52.9853 87.7862C53.3971 87.7665 53.6078 87.7321 53.8775 87.6386C54.598 87.3826 55.2353 86.8314 55.5833 86.162C55.7745 85.7978 56 84.9906 56 84.6707C56 84.4295 55.799 83.6026 55.6667 83.3073C55.5784 83.1055 46.6226 67.5425 46.1569 66.7797C46.0294 66.5779 45.8284 66.3022 45.7059 66.1693C45.3971 65.8347 44.8382 65.4754 44.3627 65.3179L43.9657 65.18L36.8088 65.1554L29.652 65.1308L29.6176 64.3433C29.6029 63.9102 29.5784 62.9554 29.5686 62.2269L29.5539 60.898L34.799 60.8734C40.0392 60.8488 40.0441 60.8488 40.3235 60.7405C40.9951 60.4846 41.4706 60.0662 41.7598 59.4805C42.4118 58.1565 41.8137 56.6652 40.3971 56.0844C40.1422 55.9761 40.1275 55.9761 34.7157 55.9515C31.7353 55.9368 29.2745 55.9072 29.2549 55.8875C29.2304 55.8679 29.1324 54.7604 29.0343 53.4266C28.9314 52.0928 28.8235 50.8377 28.7941 50.6359C28.4559 48.485 26.7598 46.6886 24.6569 46.2505C24.0637 46.1275 23.0539 46.1275 22.4657 46.2554Z"/>
                <path
                    d="M15.1863 59.0326C12.0539 61.149 9.48039 64.1809 7.91667 67.5967C6.77451 70.0921 6.17157 72.6908 6.01471 75.7867C5.97549 76.584 6.14216 78.4789 6.36765 79.798C7.26471 85.0004 10.1618 89.6861 14.4559 92.8951C16.1863 94.1846 18.0735 95.169 20.1667 95.8728C21.8971 96.4536 23.1667 96.7046 25.3088 96.9015C26.5147 97.0098 27.1127 97.0246 27.6961 96.9557C27.9069 96.9261 28.3578 96.8818 28.6961 96.8523C35.6373 96.242 41.9314 91.9058 45.1471 85.5172C45.4069 85.0004 45.5539 84.6559 45.5343 84.6067C45.4902 84.4787 42.7451 79.7242 42.7304 79.7389C42.7255 79.7488 42.652 80.0096 42.5735 80.3197C41.6373 83.9225 39.4755 87.1611 36.5098 89.4006C34.049 91.2561 31.1569 92.3586 28.0343 92.6244C27.201 92.6933 25.4265 92.6539 24.652 92.5457C21.5735 92.1224 18.848 90.9608 16.4902 89.0708C13.5784 86.7379 11.5098 83.4254 10.7059 79.798C10.2843 77.908 10.1912 75.6046 10.4657 73.8671C10.8578 71.357 11.6667 69.2307 12.9804 67.2226C13.6373 66.2235 14.2059 65.5295 15.152 64.5747L16.0441 63.6739L15.902 61.2032C15.8235 59.8447 15.7549 58.7275 15.7549 58.7176C15.75 58.6684 15.6078 58.7521 15.1863 59.0326Z"/>
            </g>
            <defs>
                <clipPath id="clip0_223_281">
                    <rect width="50" height="63" transform="translate(6 34)"/>
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
)

export default AccessibilityToiletSvg