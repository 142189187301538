import React, {useContext, createContext, useState, FC, useMemo} from "react";
import { Grid } from "@mui/material";
import { HotelInfoContainer } from "../../components/HotelInfoContainer";
import { A11yStandardType } from "../../Models/utils";
import {a11yStandardKeys, LevelSizeMap, METRIC_TRIGGERED_ON} from "../../constants/constants";
import { SizeContainer } from "../../StyledWrappers/Wrappers";
import { AccessibilityWidgetInfoContainer } from "../../components/AccessibilityWidgetInfoContainer";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
// import loadable from "@loadable/component";
import { BuildArea } from "../../Models/BuildArea";
import { Property } from "../../Models/Property";
import MapWrapper from "../../components/map/MapWrapper";
import styled from "styled-components";
import {AccessibilityInfo} from "../../Models/AccessibilityInfo";
// const InformationByDisability = loadable(
//   () => import("../../components/InformationByDisabilityContainer"),
//   {
//     fallback: <div>Loading ...</div>,
//   }
// );
import InformationByDisability from "../../components/InformationByDisabilityContainer";
import useWindowDimensions from "../../hooks/useWindowDementions";
import BuildingAreaBlockInfo from "./components/BuildingAreaBlockInfo";
import {PropertyAccessibilityImages} from "../../Models/PropertyAccessibilityImages";
import {ReactImageGalleryItem} from "react-image-gallery";
import {ImageGalleryRenderer} from "../../components/image-gallery/ImageGalleryRenderer";
import ImageContainer from "../Property/components/ImageContainer";
import {useStorageData} from "../../hooks/useStorageData";
import {AccessibilityUnit} from "../../Models/AccessibilityUnits";
import {Accessibility} from "../../Models/Accessibility";
import Typography from "@mui/material/Typography";
import InformationMapBlock from "../../components/InformationMapBlock";
import MainAccessibility from "../../components/MainAccessibility";
import {log} from "util";
import {Question} from "../../Models/Question";

const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageStyled = styled.img`
  object-fit: contain;
  height: 100%;
  background: white;
  border: 1px solid #E3E5E5;
`

export const MapContextBuildingArea = createContext<[boolean, React.Dispatch<boolean>]>([
  null!,
  () => null,
]);

export const MapContextProviderBuildingArea: FC = ({ children }) => {
  const [showMapBuildingArea, setShowMapBuildingArea] = useState(false);
  return (
    <MapContextBuildingArea.Provider value={[showMapBuildingArea, setShowMapBuildingArea]}>
      {children}
    </MapContextBuildingArea.Provider>
  );
};

export const BuildingAreaPage = (p: {
  buildingArea: BuildArea;
  a11yStandard: A11yStandardType;
  propertiesCount: number;
  shopsCount?: number;
  parkingSpaces: number;
  publicToilets: number;
  toilets: number;
  foodCourtsCount: number;
  generalAccessibilityInfo: AccessibilityInfo[] | undefined;
  properties: Property[] | undefined;
  buildingAreaAccessibilityInfo: AccessibilityInfo[] | undefined;
  buildingAreaId: string;
  fullscreen: boolean;
  accessibilityUnits: AccessibilityUnit[];
  accessibilites: Accessibility[];
  questions: Question[] | undefined
}) => {
  const {
    buildingArea,
    a11yStandard,
    propertiesCount,
    shopsCount,
    parkingSpaces,
    publicToilets,
    toilets,
    // foodCourtsCount,
    generalAccessibilityInfo,
    properties,
    buildingAreaAccessibilityInfo,
    fullscreen,
    buildingAreaId,
    accessibilityUnits,
    accessibilites,
    questions
  } = p;

  const { t } = useTranslation();
  const [showMapBuildingArea] = useContext(MapContextBuildingArea);
  const { height } = useWindowDimensions();
  const disabilityTypes: string[] = useStorageData('disability_types')
  const [currentImageData, setCurrentImageData] = useState<any>({index: 0, id: '', name: ''})
  const carouselImages: ReactImageGalleryItem[] = useMemo(() => {
    if (properties && properties.length > 0) {
      return properties.filter((i) => i.general_image).map<ReactImageGalleryItem>(
        ({ general_image, general_image_description, id }) => ({
          fullscreen: general_image,
          original: general_image,
          thumbnail: general_image,
          renderItem: ImageGalleryRenderer(),
          thumbnailClass: "handiscover-thumbnail-class",
          imageDescription: general_image_description,
          description: general_image_description,
          id: id
        })
      );
    }
    return []
  }, [properties])

  const handlerCurrentImage = (currentIndex: number) => {
    setCurrentImageData({
      index: currentIndex,
      id: carouselImages[currentIndex].id,
      name: carouselImages[currentIndex].imageDescription
    })
  }

  const exclusiveWidgets = [
    '8f718be6-a4cf-428c-b2b9-3c5046b132c5', // helsingborg
    '78e4e95a-c360-44c4-9e1b-1cad77f95f67' // jarfalla
  ]

  return (
    <RootDiv>
        <BuildingAreaBlockInfo
          a11yStandard={a11yStandard}
          propertiesCount={propertiesCount}
          shopsCount={shopsCount}
          parkingSpaces={parkingSpaces}
          publicToilets={publicToilets}
          toilets={toilets}
        />
      <AccessibilityWidgetInfoContainer
        height={height}
        fullscreen={fullscreen}
        buildingAreaId={buildingAreaId}
        showMap={showMapBuildingArea}
        a11yStandard={a11yStandard}
        isBuildingArea={true}
        informationBlockStatusData={false}
      >
        {buildingArea.general_image ? (
          <HotelInfoContainer label={`${t("mainContainer_pictures")}`}>
            <ImageStyled
              src={buildingArea.general_image}
              alt={buildingArea.general_image_description}
            />
          </HotelInfoContainer>
        ) : (
          <ImageContainer
            showThumbnails={false}
            carouselImages={carouselImages}
            propertyImages={properties && properties.length > 0 ? properties.filter((i) => i.general_image) : []}
            onSlide={(currentIndex) => handlerCurrentImage(currentIndex)}
          />
        )}

        <div style={{display: 'flex', flexDirection: 'column'}}>
          {exclusiveWidgets.includes(buildingAreaId) ? (
            <InformationMapBlock id={buildingAreaId} />
          ) : null}
          <MainAccessibility
            currentImageData={undefined}
            accessibilities={accessibilites}
            a11yStandard={a11yStandard}
            propertyItemAccessibilityUnits={accessibilityUnits}
            disabilityTypes={disabilityTypes}
            fullHeight={exclusiveWidgets.includes(buildingAreaId)}
            isBuildingArea={true}
          />
          {!exclusiveWidgets.includes(buildingAreaId) ? (
            <InformationByDisability
              questions={questions}
              accessibilityInfo={buildingAreaAccessibilityInfo}
              dontDisplayHeader={exclusiveWidgets.includes(buildingAreaId)}
            />
          ) :null}
        </div>
        <MapWrapper
          id={buildingAreaId}
          standard={a11yStandard.key}
          // Midpoint formula to calc center of bounding box: c1, c2 = (x1 + x2 / 2), (y1 + y2 / 2)
          mapCenterCoordinates={[
            ((buildingArea.area[0] + buildingArea.area[2]) / 2),
            ((buildingArea.area[1] + buildingArea.area[3]) /2)
          ]}
          activePoiId={''}
          onSetActivePoiId={() => {}}
          poisFromBuildingArea={properties}
          crossButton={false}
          customTitle={t('mainContainer_mapView')}
          selectedPropertyImageFromBuildingArea={currentImageData.id}
        />
      </AccessibilityWidgetInfoContainer>
    </RootDiv>
  );
};
