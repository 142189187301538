import { WidgetSettingsQuery } from "../Models/WidgetSettings";
import { baseUrl, fetchAndParse, headers } from "./utils"

export const retrieveWidgetSettingsWithCompany = (companyId: string) => {
    const url = new URL(`/widget_button_settings/?company=${companyId}`, baseUrl);
    return fetchAndParse<WidgetSettingsQuery>(url.toString(), { method: "GET", headers })
}

export const retrieveWidgetSettings = () => {
    const url = new URL(`/widget_button_settings/`, baseUrl);
    return fetchAndParse<WidgetSettingsQuery>(url.toString(), { method: "GET", headers })
}