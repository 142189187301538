import { HotelInfoContainer } from "./HotelInfoContainer";
import { filterAccessibilityInfoByDisabilities } from "../utils/utils";
import { GeneralInformationContainer } from "./GeneralInformationContainer";
import {AccessibilityInfo, RelatedModel} from "../Models/AccessibilityInfo";
import { useTranslation } from "react-i18next";
import "./../translations/i18n";
import SkeletonList from "./SkeletonList";
import {useStorageData} from "../hooks/useStorageData";
import {FC, useEffect, useState} from "react";
import styled from "styled-components";
import dangerImg from '../images/icons/danger.svg'
import {IconsType} from "../constants/constants";
import {PropertyFreeTextInfo} from "../Models/PropertyFreeTextInfo";
import {Question} from "../Models/Question";
import i18n from "../translations/i18n";

type NoDataWrapperType = {
  visible?: boolean;
}

const NoDataWrapper = styled.div<NoDataWrapperType>`
  height: 100%; 
  width: 100%; 
  display: ${({ visible }) => visible ? 'flex' : 'none'}; 
  align-items: center; 
  justify-content: center;
  text-align: center;
  font-family: "Roboto", "Manrope", sans-serif;
  div {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  p {
    padding: 0 !important;
    color: #414141 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    margin-top: 15px !important;
    margin-bottom: 8px !important;
  }
  span {
    color: #818181;
    font-size: 15px;
  }
  img {
    height: 30px
  }
`

type FilteredAccessibilityInfo = {
  answerText: string,
  titleText: string | null,
  iconsAccessibility: (IconsType | undefined)[],
  relatedModel: string
}

type PropsType = {
  accessibilityInfo?: AccessibilityInfo[]
  freeTextInfo?: PropertyFreeTextInfo[]
  questions?: Question[]
  customLabel?: string;
  showMapToggleBuildingArea?: boolean;
  width?: string | undefined
  dontDisplayHeader?: boolean
}

const InformationByDisabilityContainer: FC<PropsType> = ({
  accessibilityInfo,
  customLabel,
  showMapToggleBuildingArea = false,
  width,
  dontDisplayHeader = true,
  freeTextInfo,
  questions
}) => {
  const { t } = useTranslation();
  const disabilityTypes = useStorageData('disability_types')
  const label = customLabel ? customLabel : `${t("mainContainer_informationByDisability")}`
  const [filteredAccessibilityInfo, setFilteredAccessibilityInfo] = useState<FilteredAccessibilityInfo[] | null>(null)

  useEffect(() => {
    if (accessibilityInfo && questions) {
      setFilteredAccessibilityInfo(
        filterAccessibilityInfoByDisabilities(accessibilityInfo, disabilityTypes, questions)
      )
    }
  }, [accessibilityInfo, disabilityTypes, questions])

  const noHaveData = () => {
    const isDisabilityTypeMessage = disabilityTypes?.length > 0 && filteredAccessibilityInfo?.length === 0
    const isAccessibilityInfoMessage = filteredAccessibilityInfo?.length === 0 && freeTextInfo?.length === 0
    const showMessage = isDisabilityTypeMessage || isAccessibilityInfoMessage

    const data = {
      titleKey: 'informationByDisabilityContainer_noDataIsFound',
      descriptionKey: 'informationByDisabilityContainer_weDoNotHaveTheData'
    }

    if (isDisabilityTypeMessage && accessibilityInfo && accessibilityInfo.length > 0) {
      data.descriptionKey = 'informationByDisabilityContainer_tryAnotherDisabilityFiltering'
    }

    return (
      <NoDataWrapper visible={showMessage}>
        <div>
          <img src={dangerImg} alt={'Danger'}/>
          <p>{t(data.titleKey)}</p>
          <span>{t(data.descriptionKey)}</span>
        </div>
      </NoDataWrapper>
    )
  }


  return (
    <HotelInfoContainer
      id="container"
      label={label}
      width={width}
      styles={{height: '100%'}}
      labelPosition="sticky"
      showMapToggleBuildingArea={showMapToggleBuildingArea}
      dontDisplayHeader={dontDisplayHeader}
    >
        <table style={{width: '100%'}}>
          <tbody>
          {freeTextInfo && freeTextInfo.length > 0 ? (
            freeTextInfo.map((info, index) => (
              <GeneralInformationContainer
                key={index}
                title={''}
                text={info.text[i18n.language] ? info.text[i18n.language] : info.text['en'] }
                hideCheckIcon={true}
              />
            ))
          ) : null}
          {filteredAccessibilityInfo && filteredAccessibilityInfo.length > 0 ? (
            filteredAccessibilityInfo.map((el, index: number) => (
              <GeneralInformationContainer
                key={index}
                title={el.titleText}
                text={el.answerText}
                hideCheckIcon={true}
              />
            ))
          ) : null}
          </tbody>
        </table>
      {!filteredAccessibilityInfo && !freeTextInfo ? (
        <SkeletonList />
      ) : null}
      {noHaveData()}
    </HotelInfoContainer>
  );
};
export default InformationByDisabilityContainer;
