import { FC, useContext } from "react";
import { TabsContext } from "./MainPage";
import { TabContentLayout } from "./TabContentLayout";

export const TabPanel: FC<{ value: number, fullscreen: boolean }> = ({ children, value, fullscreen }) => {
  const [field] = useContext(TabsContext);
  if (field !== value) {
    return null;
  }
  return <TabContentLayout fullscreen={fullscreen}>{children}</TabContentLayout>;
};
