import React, {FC} from "react";
import {ReactImageGalleryItem} from "react-image-gallery";
import ImageContainer from "./ImageContainer";

type PropsType = {
  itemCarouselImages: ReactImageGalleryItem[];
  onSlide?: (currentIndex: number) => void
}

const ItemImageContainer: FC<PropsType> = ({itemCarouselImages, onSlide}) => {
  return (
    <ImageContainer
      propertyImages={[]}
      carouselImages={itemCarouselImages}
      onSlide={onSlide}
    />
  )
}

export default ItemImageContainer
