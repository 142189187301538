import { useContext, useEffect, useState } from "react";
import { refreshContext } from "../contexts/refreshContext";
import { Accessibility } from "../Models/Accessibility";
import { PropertyAccessibilityImages } from "../Models/PropertyAccessibilityImages";
import { AccessibilityUnitLevelQuery } from "../Models/AccessibilityLevel";
import {
  AccessibilityUnit,
} from "../Models/AccessibilityUnits";
import { Property } from "../Models/Property";
import {
  retrieveAccessibilityUnitsWithFields,
  retrieveAccessibilitiyByPropertyId,
  listAccessbilityImagesByPropertyId,
  retrievePropertySubCategoryByKey,
  retrieveAccesibilityUnitsLevelsByPropertyIdAndType,
} from "../services/property.service";
import {replaceAccessibilitiesTitle} from "../utils/utils";
import {A11yStandardType} from "../Models/utils";

export const useFetchDetailedPropertyData = (shop: Property | undefined, a11yStandard: A11yStandardType) => {

  const [loading, setLoading] = useState(false);
  const [refresh] = useContext(refreshContext);

  const [propertyItemAccessibilityUnits, setPropertyItemAccessibilityUnits] = useState<AccessibilityUnit[]>([]);
  const [itemAccessibilites, setItemAccessibilites] = useState<Accessibility[]>([]);
  const [propertyItemImages, setPropertyItemImages] = useState<PropertyAccessibilityImages[]>([]);
  const [propertyItemCategory, setPropertyItemCategory] = useState("");
  const [propertyItemFloors, setPropertyItemFloors] = useState<string>("");

  const handlerFloors = (unitLevel: AccessibilityUnitLevelQuery) => {
    if (unitLevel.results && unitLevel.results.length > 1) {
      let minFloor = unitLevel.results[0].level;
      let maxFloor = unitLevel.results[0].level;
      unitLevel.results.forEach((floorObj) => {
        if (floorObj.level < minFloor) {
          minFloor = floorObj.level;
        }
        if (floorObj.level > maxFloor) {
          maxFloor = floorObj.level;
        }
      })
      if (minFloor === maxFloor) {
        setPropertyItemFloors(unitLevel.results[0].level.toString());
      } else {
        setPropertyItemFloors(`${minFloor} - ${maxFloor}`);
      }
    } else if (unitLevel.results && unitLevel.results.length === 1) {
      setPropertyItemFloors(unitLevel.results[0].level.toString());
    } else {
      setPropertyItemFloors("")
    }
  }

  useEffect(() => {    
    const fetchData = async () => {
      setLoading(true);
      const accessibilityUnits = shop ? (
        await retrieveAccessibilityUnitsWithFields(shop.id, "id,name,accessibility_unit,title,is_accessible_unit,order")
      ) : null;
      setPropertyItemAccessibilityUnits(accessibilityUnits && accessibilityUnits.results ?
        accessibilityUnits.results.sort((a,b) => a.order - b.order) :
        []
      )
      setLoading(false);
    }
    fetchData().catch(console.error);
    return () => {
      setPropertyItemAccessibilityUnits([]);
      setLoading(false);
    }
  }, [refresh, shop])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const accessibilites = shop ? (
        await retrieveAccessibilitiyByPropertyId(shop.id, "id,item,attribute,dynamic_value,item_title,attribute_title,attribute_icon,accessibility_unit,is_absent,disability_types")
      ) : null;
      if (accessibilites && accessibilites.results.length === 0) {
      } else if (accessibilites) {
        const filteredAccessibilities = accessibilites.results.filter(
          (access) => !access.is_absent
        );
        if (filteredAccessibilities) {
          const filterOutNull = filteredAccessibilities.filter((i) => i.attribute_title !== null)
          setItemAccessibilites(replaceAccessibilitiesTitle(filterOutNull, propertyItemAccessibilityUnits, a11yStandard.title));
        }
      }
      setLoading(false);
    }
    fetchData().catch(console.error);
    return () => {
      setItemAccessibilites([]);
      setLoading(false);
    }
  }, [refresh, shop])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const images = shop ? (
        await listAccessbilityImagesByPropertyId(shop.id)
      ) : null;
      if (images) {
        setPropertyItemImages(images.results);        
      }
      setLoading(false);
    }
    fetchData().catch(console.error);
    return () => {
      setPropertyItemImages([]);  
      setLoading(false);
    }
  }, [refresh, shop])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const subCat = shop ? (
        await retrievePropertySubCategoryByKey(shop.sub_category)
      ) : null;
      if (subCat) {
        if (subCat.results.length > 0 && subCat.results[0].title) {
          setPropertyItemCategory(subCat.results[0].title);
        } else {
          setPropertyItemCategory("");
        }
      }
      setLoading(false);
    }
    fetchData().catch(console.error);
    return () => {
      setPropertyItemCategory("");
      setLoading(false);
    }
  }, [refresh, shop])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const floors = shop ? (
        await retrieveAccesibilityUnitsLevelsByPropertyIdAndType(shop.id, "entrance")
      ) : null;
      if (floors) {
        handlerFloors(floors)
      }
      setLoading(false);
    }
    fetchData().catch(console.error);
    return () => {
      setPropertyItemFloors("")
      setLoading(false);
    }
  }, [refresh, shop])

  return {
    loading,
    propertyItemAccessibilityUnits,
    itemAccessibilites,
    propertyItemImages, 
    propertyItemCategory,
    propertyItemFloors,
  };
};