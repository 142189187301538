import {Grid, Skeleton} from "@mui/material";
import Box from "@mui/material/Box";
import React, {FC} from "react";

type PropsType = {
  visible: boolean
}

const SkeletonListOfItems: FC<PropsType> = ({visible}) => {
  return (
    <>
      {visible ? (
        <Box sx={{ m: 2 }} style={{height: '32em'}}>
          <Grid container spacing={0}>
            {Array.from(Array(12)).map((_, i) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                <Box sx={{m:1}}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={140}
                    width={"100%"}
                  />
                  <Skeleton variant="text" animation="wave" height={30} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}
    </>
  )
}

export default SkeletonListOfItems
