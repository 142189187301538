import {Typography} from "@mui/material";
import {GeneralInformationContainerTableHeader, GeneralInformationContainerTableRow} from "../StyledWrappers/Wrappers";
import styled from "styled-components";
import Icons from "./Icons";
import {FC} from "react";

// const ImgWrapper = styled.div`
//   display: flex;
//   justify-content:end;
//   flex-direction: row;
//   margin-top: 4px;
//   img {
//     border-radius: 50%;
//     background: gray;
//     margin-right: 5px;
//     max-width: unset !important;
//     height: 25px
//   }
// `

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledText = styled(Typography)`
  margin-top: 5px;
  font-size: 12px !important;
  font-weight: 400;
  font-style: normal;
  color: #5E6366 !important;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
`

type PropsType = {
  title?: string | null;
  text?: string;
  hideCheckIcon?: boolean;
  padding?: string | undefined;
}

export const GeneralInformationContainer: FC<PropsType> = ({
  title,
  text,
  hideCheckIcon = false,
  padding,
}) => {
  return (
    <GeneralInformationContainerTableRow padding={padding}>
      <GeneralInformationContainerTableHeader>
        <ContentWrapper>
          <TextWrapper>
            {title}
            <StyledText>{text}</StyledText>
          </TextWrapper>
        </ContentWrapper>
        {!hideCheckIcon && <div aria-hidden="true"><Icons.CheckIcon /></div>}
      </GeneralInformationContainerTableHeader>
    </GeneralInformationContainerTableRow>
  );
};
