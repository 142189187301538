import UnitItem from "./UnitItem";
import {Grid as MuiGrid} from "@mui/material";
import React, {FC, useState} from "react";
import {Property} from "../../../Models/Property";
import {AccessibilityUnit} from "../../../Models/AccessibilityUnits";
import {PropertyAccessibilityImages} from "../../../Models/PropertyAccessibilityImages";
import styled from "styled-components";
import SkeletonListOfItems from "./SkeletonListOfItems";
// import MissingInformation from "./MissingInformation";
import { A11yStandardType } from "../../../Models/utils";
import DetailedUnitInfoItem from "./DetailedUnitInfoItem";
import { Mixpanel } from "../../../Mixpanel";
import { MetricMessage } from "../../../utils/metrics";
import { AccessibilityWidgetInfoContainer } from "../../../components/AccessibilityWidgetInfoContainer";
import useWindowDimensions from "../../../hooks/useWindowDementions";
import {getTextByCurrentLanguage, sortPropertyImages} from "../../../utils/utils";

const Grid = styled(MuiGrid)`
  height: 57vh;
  margin-top: 15px;
  @media (max-width: 768px) {
    height: 100%
  }
`

type PropsType = {
  type: string
  a11yStandard: A11yStandardType
  property: Property,
  units: AccessibilityUnit[]
  propertyImages: PropertyAccessibilityImages[],
  fullscreen: boolean,
  displayShops: boolean,
  buildingAreaId: string | null,
}

const TabContentUnits: FC<PropsType> = ({
  type,
  a11yStandard,
  property,
  units,
  propertyImages,
  fullscreen,
  displayShops,
  buildingAreaId,
}) => {
  const sortedPropertyImages = sortPropertyImages(propertyImages, units)
  const [unitItem, setUnitItem] = useState<AccessibilityUnit>();
  const [showDetailedInfoPage, setShowDetailedInfoPage] = useState(false);
  const { height } = useWindowDimensions();

  const fetchUnitItemData = (item: AccessibilityUnit) => {
    Mixpanel.track(`${MetricMessage.accessed_single_unit}`, {
      unitId: item.id,
      a11yStandard: null,
      unitType: item.type,
      unitStandardCategory: null,
      unitStandardSubCategory: null,
    });
    setUnitItem(item);
    setShowDetailedInfoPage(true);
  }

  const getImage = (id: string) => {
    const filterById = sortedPropertyImages.filter((img) => (
      img.accessibility_unit === id
    ))
    if (filterById.length > 0) {
      const removedRelatedImages = filterById.filter((i) => !i.related)
      const generalImages = removedRelatedImages.filter((i) => i.is_general_image)
      if (generalImages.length > 0) {
        return generalImages[0].thumbnails.medium
      }
      if (removedRelatedImages.length > 0) {
        return removedRelatedImages[0].thumbnails.medium
      }
    }
    return undefined
  }

  return (
    <AccessibilityWidgetInfoContainer
      showMap={false}
      height={height}
      fullscreen={fullscreen}
      displayShops={displayShops}
      buildingAreaId={buildingAreaId}
      a11yStandard={a11yStandard}
      displayedOnListTab={true}
      displayedOnListTabDetailedPage={!!showDetailedInfoPage && !!unitItem}
    >
      {/* <SkeletonListOfItems visible={loading} /> */}
      {units && units.length === 0 ? (
        // <MissingInformation />
        <SkeletonListOfItems visible={true} />
      ) : ((units && units.length > 0) && !showDetailedInfoPage) ? (
        <>
          <Grid container spacing={0}>
            {(units && units.length > 0) ? units.map((item, idx) => (
              <UnitItem
                id={item.id}
                key={idx}
                image={getImage(item.id)}
                name={getTextByCurrentLanguage(item.name)}
                onFetchUnitItemData={(id) => {                  
                  const foundItem = units.find((p) => p.id === id)
                  if (foundItem) {
                    fetchUnitItemData(foundItem)
                  }
                }}
              />
            )) : null
          }
          </Grid>
        </>
      ) : (showDetailedInfoPage && unitItem) ?
        <DetailedUnitInfoItem
          type={type}
          a11yStandard={a11yStandard}
          property={property}
          unitItem={unitItem}
          setShowDetailedInfoPage={setShowDetailedInfoPage}
        />
      : null}
    </AccessibilityWidgetInfoContainer>
  )
}

export default TabContentUnits
