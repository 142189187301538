import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {LanguagesISO, LanguagesType} from "../constants/constants";
import {headers} from "../services/utils";
import {TRANSLATIONS_EN} from "./dictionaries/en";
import {TRANSLATIONS_SV} from "./dictionaries/sv";
import {TRANSLATIONS_DA} from "./dictionaries/da";
import {TRANSLATIONS_DE} from "./dictionaries/de";
import {TRANSLATIONS_ES} from "./dictionaries/es";
import {TRANSLATIONS_FR} from "./dictionaries/fr";
import {TRANSLATIONS_NO} from "./dictionaries/no";
import {TRANSLATIONS_PT} from "./dictionaries/pt";
import {TRANSLATIONS_UK} from "./dictionaries/uk";

export const i18n = i18next.use(LanguageDetector).use(initReactI18next);

i18n.init({
  fallbackLng: "en",
  resources: {
    [LanguagesType.English]: {
      translation: TRANSLATIONS_EN,
    },
    [LanguagesType.Swedish]: {
      translation: TRANSLATIONS_SV,
    },
    [LanguagesType.Danish]: {
      translation: TRANSLATIONS_DA,
    },
    [LanguagesType.German]: {
      translation: TRANSLATIONS_DE,
    },
    [LanguagesType.Spanish]: {
      translation: TRANSLATIONS_ES,
    },
    [LanguagesType.French]: {
      translation: TRANSLATIONS_FR,
    },
    [LanguagesType.Norwegian]: {
      translation: TRANSLATIONS_NO,
    },
    [LanguagesType.Portuguese]: {
      translation: TRANSLATIONS_PT,
    },
    [LanguagesType.Ukrainian]: {
      translation: TRANSLATIONS_UK,
    },
  },
});


i18n.on("languageChanged", (language) => {
  headers["Accept-Language"] = LanguagesISO[language] || "";
})

export default i18n;
