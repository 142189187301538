import React, {createContext, FC, useState} from "react";

export const feedbackOpenContext = createContext<
  [boolean, React.Dispatch<boolean>]
>([false, () => null!]);
export const FeedbackOpenContextProvider: FC = ({ children }) => {
  const feedbackState = useState(false);

  return (
    <feedbackOpenContext.Provider value={feedbackState}>
      {children}
    </feedbackOpenContext.Provider>
  );
};
