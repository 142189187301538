export const TRANSLATIONS_EN = {
  "accessibility_button": "Accessibility",
  "sidebar_Hotel": "Hotel",
  "sidebar_Rooms": "Rooms",
  "sidebar_verified": "Verified by Handiscover",
  "hotel_floors": "Floors",
  "hotel_totalRooms": "Total Rooms",
  "hotel_accessibleRooms": "Accessible Rooms",
  "hotel_allergyRooms": "Allergy Rooms",
  "mainContainer_accessibilityInformation": "Accessibility Information",
  "mainContainer_pictures": "Pictures",
  "mainContainer_informationByDisability": "Information by Disability",
  "mainContainer_GeneralHotelInformation": "General Hotel Information",
  "informationByDisability_mobility": "Mobility",
  "informationByDisability_hearing": "Hearing",
  "informationByDisability_vision": "Vision",
  "informationByDisability_other": "Other",
  "rooms_header": "Room Information",
  "accessibility_room_icons": "Accessibility Icons for Room",
  "roomInformation_generalRoomInformation": "General Room Information",
  "shopping_mall_mallFloors": "Mall Floors",
  "shopping_mall_totalShops": "Inventoried shops",
  "shopping_mall_accessibleParking": "Accessible parking",
  "shopping_mall_foodCourts": "Food Courts",
  "mainContainer_GeneralMallInformation": "General Mall Information",
  "rooms_noPictures": "This room has no images",
  "rooms_noInformation": "This room has no information",
  "rooms_noIcons": "No accessibility icons",
  "property_noPictures": "No pictures found for this property",
  "disability_noPictures": "No pictures for the selected Disability",
  "accessibilityIcons_notPresent": "No accessibility icons",
  "shopping_mall": "Mall",
  "building_area_buildings": "Buildings",
  "building_area_shops": "Inventoried shops",
  "building_area_parking_spaces": "Accessible parking",
  "building_area_food_courts": "Food Courts",
  "mainContainer_generalInformation": "General Information",
  "mainContainer_GeneralOfficeInformation": "General Office Information",
  "mainContainer_GeneralShopInformation": "General Shop Information",
  "header_buildingInformation": "Building Information",
  "a11yStandard_shopping_mall": "Shop",
  "a11yStandard_shopping_mall_plural": "Shops",
  "property_floorSignular": "Floor",
  "property_floorPlural": "Floors",
  "property_tabTitle_shops": "Shops information",
  "general_invalid_API_CALL_errorMessage": "A problem occurred! Couldn't fetch data.",
  "general_missingInfo_forItem": "It looks like the information has not been added yet",
  "translated_text": "The title has been translated",
  "accessibility_button_open_ariaLabel": "Open Accessibility Information",
  "mainContainer_mapView": "Map View",
  "mainContainer_generalAccessibility": "General Accessibility",
  "mapView_filterBy": "Filter by",
  "mapView_filter_all": "All",
  "mapView_filter_entrance": "Entrance",
  "mapView_filter_parking": "Parking",
  "mapView_filter_toilet": "Toilet",
  "mapView_floor": "Floor",
  "selectDisabilityType_mobility": "Mobility",
  "selectDisabilityType_hearing": "Hearing",
  "selectDisabilityType_allergy": "Allergy",
  "selectDisabilityType_cognitive": "Cognitive",
  "selectDisabilityType_other": "Other",
  "selectDisabilityType_select": "Select",
  "selectDisabilityType_disability_type": "disability type",
  "selectDisabilityType_select_disability_type": "Select disability type",
  "selectDisabilityType_select_what_type": "Filter by disability",
  "selectDisabilityType_visual": "Visual",
  "go_back": "Go back",
  "tabTitle_meetingRooms": "Meeting rooms",
  "tabTitle_hospitalityRooms": "Hospitality rooms",
  "generalAccessibility_noGeneralAccessibilitiesForThisProperty": "No General Accessibilities was found for this property",
  "generalAccessibility_noGeneralAccessibilitiesForTheseDisabilityFilters": "No General Accessibilities were found for these disability filters",
  "informationByDisabilityContainer_noDataIsFound": "No data is found",
  "informationByDisabilityContainer_weDoNotHaveTheData": "We do not have the data for this property yet",
  "informationByDisabilityContainer_tryAnotherDisabilityFiltering": "Try another disability filtering",
  "playground_information": "Playground information",
  "total_playgrounds": "Total playgrounds",
  "inventoried_playgrounds": "Inventoried playgrounds",
  "general_accessibility_and_information": "General accessibility and Information",
  "detailed_accessibility": "Detailed accessibility",
  "helsingborg_city_has_reviewed": "Helsingborg City has reviewed the availability of 26 of its 200 playgrounds. There you get information about parking, availability of toilets and various play areas.",
  "for_detailed_accessibility_information": "For detailed accessibility information, please choose respective playground.",
  "amount_of_accessible_toilets": "Amount of accessible toilets",
  "mapView_filter_elevator": "Elevator",
  "mapView_filter_reception_infodesk": "Reception",
  "mapView_filter_playground": "Playground",
  "mapView_filter_garden": "Garden",
  "mapView_filter_shop": "Shop",
  "mapView_filter_restaurant": "Restaurant",
  "mapView_filter_conference_room": "Conference room",
  "mapView_filter_auditorium": "Auditorium",
  "jarfalla_municipality_has_reviewed": "Järfälla Municipality City has reviewed the accessibility of 55 playgrounds, including 2 adventure playgrounds that promote creativity. There you get information about parking, accessibility of toilets and the different play areas.",
  "mapView_filter_vac": "Floor level change",
  "languages": "Languages",
  "english": "English",
  "swedish": "Swedish",
  "danish": "Danish",
  "german": "German",
  "spanish": "Spanish",
  "french": "French",
  "norwegian": "Norwegian",
  "portuguese": "Portuguese",
  "ukrainian": "Ukrainian",
  "accessibility_room": "Accessibility room",
  "pool": "Pool",
  "spa": "Spa",
  "bar_restaurant": "Bar\/Restaurant",
  "bathrooms": "Bathrooms",
  "shop": "Shop",
  "exhibition": "Exhibition",
  "rate_your_experience": "Can you help us improve🧑‍🦼🦮🦻? 🙏",
  "did_you_find_the_accessibility_information_you_needed": "Did you find the accessibility information you needed?",
  "how_satisfied_are_you_with_your_experience": "How satisfied are you with your experience with our accessibility widget?",
  "what_do_you_like_the_most": "What do you like the most?",
  "sorry_to_hear_that": "Sorry to hear that! Please comment why, to submit your rating",
  "we_would_love_to_hear_your_suggestions": "We'd love to hear your suggestions",
  "submit": "Submit",
  "book_now": "Book now",
  "very_bad": "Very bad",
  "bad": "Bad",
  "neutral": "Neutral",
  "good": "Good",
  "great": "Great"
}