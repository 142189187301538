import React from 'react';
import {StyledEngineProvider} from "@mui/material";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {theme} from "../theme";
import {ThemeProvider} from "styled-components";
import {FeedbackOpenContextProvider} from "../contexts/feedbackContext";
import {DialogOpenContextProvider} from "../App";
import {RefreshContextProvider} from "../contexts/refreshContext";
import {TabsContextProvider} from "../components/MainPage";
import {I18nextProvider, useTranslation} from "react-i18next";

const Providers = ({children}: {children: React.ReactNode}) => {
    const { i18n } = useTranslation();
    return (
        <I18nextProvider i18n={i18n}>
            <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}>
                        <FeedbackOpenContextProvider>
                            <DialogOpenContextProvider>
                                <RefreshContextProvider>
                                    <TabsContextProvider>
                                        {children}
                                    </TabsContextProvider>
                                </RefreshContextProvider>
                            </DialogOpenContextProvider>
                        </FeedbackOpenContextProvider>
                    </ThemeProvider>
                </MuiThemeProvider>
            </StyledEngineProvider >
        </I18nextProvider>
    );
};

export default Providers;