import {
    IconButton,
    Theme,
    useMediaQuery
} from "@mui/material";
import {useContext, FC, useEffect, useState} from "react";
import styled from "styled-components";
import {borderRadius, drawerWidth} from "../../constants/constants";
import {TabsContext} from "../MainPage";
import Icons from "../Icons";
import {dialogOpenContext} from "../../App";
import "../../translations/i18n";
import {MetricMessage} from "../../utils/metrics";
import {Mixpanel} from "../../Mixpanel";
import LanguagesDropdown from "./components/LanguagesDropdown";
import HandiscoverBadge from "./components/HandiscoverBadge";
import SelectDisabilityType from "./components/SelectDisabilityType";
// import Info from "../../images/icons/info.svg";
import {useTranslation} from "react-i18next";
import {isURLPage} from "../../utils/utils";
import {feedbackOpenContext} from "../../contexts/feedbackContext";
import {useStorageData} from "../../hooks/useStorageData";

const StyledImg = styled.img`
  object-fit: contain;
  overflow: hidden;
  height: 100%;
  width: 100%;
`

const StyledCloseIcons = styled(Icons.CloseIcon)`
  width: 16px;
  height: 16px
`

const HeaderWrapper = styled.div`
  font-size: 22px;
  display: flex;
  height: 66px;
  border-radius: ${borderRadius}px ${borderRadius}px 0px 0px;
  background-color: white;

  ${(props) => props.theme.breakpoints.down("sm")} {
    border-radius: 0px !important;
  }
`;

const ImageContainer = styled.div`
  width: ${drawerWidth}px;
  padding: 10px;
  height: 46px;
  display: grid;
  place-items: center;
  @media (min-width: 768px) {
    border-right: 1px solid #0000001a;
  }
`;

const HeaderItem = styled.div`
  padding: 10px;
  display: grid;
  place-items: center;
  font-family: "Roboto", "Manrope", sans-serif;
  @media (max-width: 768px) {
    font-size: 12px;
    word-break: break-word;
  }
`;

const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
  //flex: 1;
  flex-direction: row-reverse;
  min-width: 43px;
  margin-left: 17px;
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 14px;
  margin-right: 8px;
  @media (max-width: 768px) {
    display: none;
  }
`

const SelectDisabilityTypeContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row-reverse;
`

const TextDisabilityType = styled.p`
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px;
  max-width: 226px;
  margin: 0 !important;
  padding: 0 10px;
`

export const Header: FC<{
    companyLogo: string;
    propertyName: string;
    headerTextMap: string[];
    companyName: string;
    supportedLanguages: string[];
}> = ({companyLogo, propertyName, headerTextMap, companyName, supportedLanguages}) => {
    const {t} = useTranslation();
    const [, setDialogOpen] = useContext(dialogOpenContext);
    const [, setFeedbackOpen] = useContext(feedbackOpenContext);
    const [field] = useContext(TabsContext);
    const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const feedbackShown = useStorageData('feedbackShown')
    const [feedbackTimeout, setFeedbackTimeout] = useState(false)

    useEffect(() => {
        if (!feedbackShown) {
            let feedbackTimeoutLink = setTimeout(() => {
                setFeedbackTimeout(true)
            }, 40000)
            return () => {
                clearTimeout(feedbackTimeoutLink);
            }
        }
    }, []);
    const handleExit = () => {
        if (feedbackShown || !feedbackTimeout) {
            Mixpanel.track(`${MetricMessage.closed_widget_window}`);
            setDialogOpen(false);
        } else if (feedbackTimeout) {
            localStorage.setItem("feedbackShown", "true");
            window.dispatchEvent(new Event('storage'))
            setFeedbackOpen(true)
        }
    };

    const handleExitFullscreen = () => {
        if (feedbackShown || !feedbackTimeout) {
            Mixpanel.track(`${MetricMessage.closed_widget_window}`);
            window.close();
        } else if (feedbackTimeout) {
            localStorage.setItem("feedbackShown", "true");
            window.dispatchEvent(new Event('storage'))
            setFeedbackOpen(true)
        }
    };


    const getCloseButton = () => {
        // Check if document in iframe
        if (window.self !== window.top && isURLPage()) {
            return null
        }
        if (isURLPage()) {
            return (
                <HeaderItem>
                    <IconButton
                        size="medium"
                        onClick={handleExitFullscreen}
                        aria-label="Close Accessibility Information"
                        id="exit-widget-button"
                    >
                        <StyledCloseIcons/>
                    </IconButton>
                </HeaderItem>
            )
        } else {
            return (
                <HeaderItem>
                    <IconButton
                        size="medium"
                        onClick={handleExit}
                        aria-label="Close Accessibility Information"
                        id="exit-widget-button"
                    >
                        <StyledCloseIcons/>
                    </IconButton>
                </HeaderItem>
            )
        }
    }

    return (
        <HeaderWrapper>
            <ImageContainer>
                <StyledImg
                    alt={`${propertyName} logo`}
                    src={companyLogo}
                ></StyledImg>
            </ImageContainer>
            {smallScreen ? null : <HeaderItem>{headerTextMap[field]}</HeaderItem>}
            <SelectDisabilityTypeContainer>
                <SelectDisabilityType/>
                <TextDisabilityType>{t('selectDisabilityType_select_what_type')}</TextDisabilityType>
            </SelectDisabilityTypeContainer>
            <LanguageContainer>
                <LanguagesDropdown supportedLanguages={supportedLanguages}/>
            </LanguageContainer>
            <BadgeContainer>
                <HandiscoverBadge/>
            </BadgeContainer>
            {getCloseButton()}
        </HeaderWrapper>
    );
};
