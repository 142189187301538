export const TRANSLATIONS_DE = {
  "accessibility_button": "Barrierefreiheit",
  "sidebar_Hotel": "Hotel",
  "sidebar_Rooms": "Räume",
  "sidebar_verified": "Verifiziert durch Handiscover",
  "hotel_floors": "Böden",
  "hotel_totalRooms": "Gesamtzahl der Zimmer",
  "hotel_accessibleRooms": "Barrierefreie Zimmer",
  "hotel_allergyRooms": "Allergikerzimmer",
  "mainContainer_accessibilityInformation": "Informationen zur Barrierefreiheit",
  "mainContainer_pictures": "Bilder",
  "mainContainer_informationByDisability": "Informationen nach Behinderung",
  "mainContainer_GeneralHotelInformation": "Allgemeine Hotelinformationen",
  "informationByDisability_mobility": "Mobilität",
  "informationByDisability_hearing": "Hören",
  "informationByDisability_vision": "Vision",
  "informationByDisability_other": "Andere",
  "rooms_header": "Rauminformationen",
  "accessibility_room_icons": "Barrierefreiheitssymbole für den Raum",
  "roomInformation_generalRoomInformation": "Allgemeine Rauminformationen",
  "shopping_mall_mallFloors": "Mall-Böden",
  "shopping_mall_totalShops": "Inventarisierte Geschäfte",
  "shopping_mall_accessibleParking": "Barrierefreie Parkplätze",
  "shopping_mall_foodCourts": "Food-Courts",
  "mainContainer_GeneralMallInformation": "Allgemeine Informationen zum Einkaufszentrum",
  "rooms_noPictures": "Dieser Raum hat keine Bilder",
  "rooms_noInformation": "Für diesen Raum liegen keine Informationen vor",
  "rooms_noIcons": "Keine Barrierefreiheitssymbole",
  "property_noPictures": "Für diese Immobilie wurden keine Bilder gefunden",
  "disability_noPictures": "Keine Bilder für die ausgewählte Behinderung",
  "accessibilityIcons_notPresent": "Keine Barrierefreiheitssymbole",
  "shopping_mall": "Einkaufszentrum",
  "building_area_buildings": "Gebäude",
  "building_area_shops": "Inventarisierte Geschäfte",
  "building_area_parking_spaces": "Barrierefreie Parkplätze",
  "building_area_food_courts": "Food-Courts",
  "mainContainer_generalInformation": "Allgemeine Informationen",
  "mainContainer_GeneralOfficeInformation": "Allgemeine Büroinformationen",
  "mainContainer_GeneralShopInformation": "Allgemeine Shop-Informationen",
  "header_buildingInformation": "Gebäudeinformationen",
  "a11yStandard_shopping_mall": "Geschäft",
  "a11yStandard_shopping_mall_plural": "Geschäfte",
  "property_floorSignular": "Boden",
  "property_floorPlural": "Böden",
  "property_tabTitle_shops": "Informationen zu Geschäften",
  "general_invalid_API_CALL_errorMessage": "Es ist ein Problem aufgetreten! Daten konnten nicht abgerufen werden.",
  "general_missingInfo_forItem": "Es sieht so aus, als ob die Informationen noch nicht hinzugefügt wurden",
  "translated_text": "Der Titel wurde übersetzt",
  "accessibility_button_open_ariaLabel": "Informationen zur Barrierefreiheit öffnen",
  "mainContainer_mapView": "Kartenansicht",
  "mainContainer_generalAccessibility": "Allgemeine Barrierefreiheit",
  "mapView_filterBy": "Filtern nach",
  "mapView_filter_all": "Alle",
  "mapView_filter_entrance": "Eingang",
  "mapView_filter_parking": "Parken",
  "mapView_filter_toilet": "Toilette",
  "mapView_floor": "Boden",
  "selectDisabilityType_mobility": "Mobilität",
  "selectDisabilityType_hearing": "Hören",
  "selectDisabilityType_allergy": "Allergie",
  "selectDisabilityType_cognitive": "Kognitiv",
  "selectDisabilityType_other": "Andere",
  "selectDisabilityType_select": "Wählen",
  "selectDisabilityType_disability_type": "Art der Behinderung",
  "selectDisabilityType_select_disability_type": "Wählen Sie die Art der Behinderung aus",
  "selectDisabilityType_select_what_type": "Nach Behinderung filtern",
  "selectDisabilityType_visual": "Visuell",
  "go_back": "Geh zurück",
  "tabTitle_meetingRooms": "Besprechungszimmer",
  "tabTitle_hospitalityRooms": "Gastfreundschaftsräume",
  "generalAccessibility_noGeneralAccessibilitiesForThisProperty": "Für diese Eigenschaft wurden keine allgemeinen Barrierefreiheiten gefunden",
  "generalAccessibility_noGeneralAccessibilitiesForTheseDisabilityFilters": "Für diese Behinderungsfilter wurden keine allgemeinen Barrierefreiheiten gefunden",
  "informationByDisabilityContainer_noDataIsFound": "Es wurden keine Daten gefunden",
  "informationByDisabilityContainer_weDoNotHaveTheData": "Für diese Immobilie liegen uns noch keine Daten vor",
  "informationByDisabilityContainer_tryAnotherDisabilityFiltering": "Versuchen Sie es mit einer anderen Behinderungsfilterung",
  "playground_information": "Informationen zum Spielplatz",
  "total_playgrounds": "Totale Spielplätze",
  "inventoried_playgrounds": "Inventurierte Spielplätze",
  "general_accessibility_and_information": "Allgemeine Zugänglichkeit und Informationen",
  "detailed_accessibility": "Detaillierte Barrierefreiheit",
  "helsingborg_city_has_reviewed": "Die Stadt Helsingborg hat die Verfügbarkeit von 26 ihrer 200 Spielplätze überprüft. Dort erhalten Sie Informationen zu Parkplätzen, zur Verfügbarkeit von Toiletten und verschiedenen Spielbereichen.",
  "for_detailed_accessibility_information": "Für detaillierte Informationen zur Barrierefreiheit wählen Sie bitte den jeweiligen Spielplatz aus.",
  "amount_of_accessible_toilets": "Anzahl barrierefreier Toiletten",
  "mapView_filter_elevator": "Aufzug",
  "mapView_filter_reception_infodesk": "Rezeption",
  "mapView_filter_playground": "Spielplatz",
  "mapView_filter_garden": "Garten",
  "mapView_filter_shop": "Geschäft",
  "mapView_filter_restaurant": "Restaurant",
  "mapView_filter_conference_room": "Konferenzraum",
  "mapView_filter_auditorium": "Auditorium",
  "jarfalla_municipality_has_reviewed": "Die Stadtverwaltung von Järfälla hat die Zugänglichkeit von 55 Spielplätzen überprüft, darunter 2 Abenteuerspielplätze, die die Kreativität fördern. Dort erhalten Sie Informationen über Parkplätze, die Zugänglichkeit von Toiletten und die verschiedenen Spielbereiche.",
  "mapView_filter_vac": "Änderung des Bodenniveaus",
  "languages": "Sprache",
  "english": "Englisch",
  "swedish": "Schwedisch",
  "danish": "Dänisch",
  "german": "Deutsch",
  "spanish": "Spanisch",
  "french": "Französisch",
  "norwegian": "Norwegisch",
  "portuguese": "Portugiesisch",
  "ukrainian": "Ukrainisch",
  "accessibility_room": "Raum für Zugänglichkeit",
  "pool": "Pool",
  "spa": "Heilbad",
  "bar_restaurant": "Bar/Restaurant",
  "bathrooms": "Bäder",
  "shop": "Shop",
  "exhibition": "Ausstellung",
  "rate_your_experience": "Können Sie uns helfen, zu verbessern🧑‍🦼🦮🦻 ? 🙏",
  "did_you_find_the_accessibility_information_you_needed": "Haben Sie die benötigten Informationen zur Barrierefreiheit gefunden?",
  "how_satisfied_are_you_with_your_experience": "Wie zufrieden sind Sie mit Ihrer Erfahrung mit unserem Leitfaden zur Barrierefreiheit?",
  "what_do_you_like_the_most": "Was gefällt Ihnen am besten?",
  "sorry_to_hear_that": "Bitte kommentieren Sie den Grund, um Ihre Bewertung abzugeben",
  "we_would_love_to_hear_your_suggestions": "Wir würden gerne Ihre Vorschläge hören",
  "submit": "Einreichen",
  "book_now": "Jetzt buchen",
  "very_bad": "Sehr schlecht",
  "bad": "Schlecht",
  "neutral": "Neutral",
  "good": "Gut",
  "great": "Großartig"
}