import { MouseEventHandler } from "react";
import styled from "styled-components";

type ImageBlockProps = {
  url: string
}

const ImageBlock = styled.div<ImageBlockProps>`
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  aspect-ratio: 1;
  background-image: ${(props) => `url(${props.url})`}
`

export const AccessibilityImage = (p: {
  title: string;
  src: string;
  onClick: MouseEventHandler;
}) => {
  return (
      <ImageBlock
        url={p.src}
        title={p.title}
        onClick={p.onClick}
      />
  );
};
