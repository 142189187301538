import {FC} from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`

const Label = styled.div`
  font-family: "Roboto", "Manrope", sans-serif;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #E3E5E5;
  margin-bottom: 8px;
  padding: 0 10px;
`

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  background: #ffffff;
  border: 1px solid #E3E5E5;
  overflow: auto;
`

type PropsType = {
  label?: string
}

const ContentBlock: FC<PropsType> = ({
  label,
  children
}) => {
  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <Content>
        {children}
      </Content>
    </Wrapper>
  )
}

export default ContentBlock