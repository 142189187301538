import React, {FC, useEffect, useState} from "react";
import styled from "styled-components";
import {Tooltip} from "@mui/material";

type CircleType = {
  top: number
  left: number
  backgroundColor: string
  opacity: number
}

const Circle = styled.div<CircleType>`
  transition: all 0.3s;
  cursor: default;
  visibility: ${(props) => props.opacity === 1 ? 'visible' : 'hidden'};
  opacity: ${(props) => props.opacity};
  position: absolute;
  padding: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
`

type PropsType = {
  items: any[]
  selected: boolean
  radius: number;
  startPosition: number
  show: boolean
}

const RevealElements: FC<PropsType> = ({
  items,
  selected,
  radius = 10,
  show,
  startPosition = -90
}) => {
  const [currentRadius, setCurrentRadius] = useState<number>(0);

  const calculateCoordinate = (count: number, r: number, cx: number, cy: number) => {
    const sectors: { x: number; y: number }[] = [];
    let startAngle = startPosition;
    let endAngle = 0;
    const maxCard = 6;

    for (let i = 0; i < count; i++) {
      if (i <= maxCard - 1) {
        const angle = 360 / maxCard;
        endAngle += angle;
        const rad = Math.PI / 180;
        const x = cx + r * Math.cos(startAngle * rad);
        const y = cy + r * Math.sin(startAngle * rad);
        startAngle += angle;
        sectors.push({ x, y });
      } else {
        const angle = 360 / (count - maxCard);
        endAngle += angle;
        const rad = Math.PI / 180;
        const x = cx + r * 2 * Math.cos(startAngle * rad);
        const y = cy + r * 2 * Math.sin(startAngle * rad);
        startAngle += angle;
        sectors.push({ x, y });
      }
    }

    return sectors;
  };

  useEffect(() => {
    if (show || selected) {
      setTimeout(() => {
        setCurrentRadius(radius)
      }, 10)
    } else {
      setCurrentRadius(0)
    }

  }, [show, selected]);

  const calculatedCoordinates = calculateCoordinate(items.length, currentRadius, 10, 10)

  return (
    <>
      {items.map((item, index) => (
        <Tooltip
          enterDelay={400}
          enterNextDelay={400}
          key={index}
          title={item.title}
          placement={'top'}
          arrow={true}
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -5],
                },
              },
            ],
          }}
        >
          <Circle
            opacity={show ? 1 : 0}
            backgroundColor={selected ? '#76232f' : '#2F80ED'}
            top={calculatedCoordinates[index].y}
            left={calculatedCoordinates[index].x}
          >
            <img
              src={item.src}
              height={16}
              width={16}
              style={{objectFit: 'contain'}}
              alt={item.title}
            />
          </Circle>
        </Tooltip>
      ))}
    </>
  )
}

export default RevealElements