import { useContext, useEffect, useState } from "react";
import { refreshContext } from "../contexts/refreshContext";
import { AccessibilityUnitLevel } from "../Models/AccessibilityLevel";
import { BuildArea } from "../Models/BuildArea";
import { Company } from "../Models/Company";
import { Property } from "../Models/Property";
import { A11yStandardType } from "../Models/utils";
import {
  retrieveBuildingAreaById,
  retrieveBuildingAreasAccessibilityByType,
} from "../services/buildingAreas.service";
import { retrieveCompany } from "../services/company.service";
import {
  retrieveAccesibilityUnitsLevelsByPropertyId,
  retrieveAccessibilitiyByBuildingArea,
  retrieveAccessibilityUnitsByBuildingAreaAndType,
  retrieveAccessibilityUnitsByBuildingAreaId,
  retrieveBuildingAreaAccessibilityInfos,
  retrieveBuildingAreaGeneralInfo,
  retrieveProperty,
  retrieveQuestionnaireStandards,
  searchProperties, searchPropertiesWithFields,
} from "../services/property.service";
import { Accessibility } from "../Models/Accessibility";
import {a11yStandardKeys, facilityType} from "../constants/constants";
import {AccessibilityInfo} from "../Models/AccessibilityInfo";
import {AccessibilityUnit} from "../Models/AccessibilityUnits";
import {Question} from "../Models/Question";
import {retrieveQuestions} from "../services/questions.service";
import {replaceAccessibilitiesTitle, replaceNestedAccessibilities} from "../utils/utils";

export const useFetchData = (id: string | null, type: string) => {
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState<Property[] | undefined>([]);
  const [selectedBuildingArea, setSelectedBuildingArea] = useState<
    BuildArea | undefined
  >();
  const [a11yStandard, setA11yStandard] = useState<
    A11yStandardType | undefined
  >(null!);
  const [questions, setQuestions] = useState<
    Question[] | undefined
  >(null!);
  const [parkingSpaces, setParkingSpaces] = useState<
    AccessibilityUnitLevel[] | undefined
  >(null!);
  const [publicToilets, setPublicToilets] = useState<
    AccessibilityUnitLevel[] | undefined
  >(null!);
  const [toilets, setToilets] = useState<
    AccessibilityUnitLevel[] | undefined
  >(null!);
  const [shopsCount, setShopsCount] = useState<number>(0);
  const [company, setCompany] = useState<Company | undefined>(null!);
  const [diningArea, setDiningArea] = useState<number>(0);
  const [generalAccessibilityInfo, setGeneralAccessibilityInfo] = useState<AccessibilityInfo[] | undefined>(null!);
  const [buildingAreaAccessibilityInfo, setBuildingAreaGeneralAccessibilityInfo] = useState<AccessibilityInfo[] | undefined>(null!);
  const [published, setPublished] = useState(true)
  const [unitLevels, setUnitLevels] = useState<
    AccessibilityUnitLevel[] | undefined
  >([]);
  const [roomAccessibilities, ] = useState<
    Accessibility[] | undefined
  >([]);

  const [accessibilityUnits, setAccessibilityUnits] = useState<AccessibilityUnit[]>([])
  const [accessibilities, setAccessibilities] = useState<Accessibility[]>([])

  const [refresh] = useContext(refreshContext);

  const [cachedRefresh, setCachedRefresh] = useState(refresh)

  useEffect(() => {
    if (refresh !== cachedRefresh) {
      setCachedRefresh(refresh)
    }
  }, [refresh]);

  useEffect(() => {
    if (type === facilityType.buildingArea) {
      const fetchData = async () => {
        setLoading(true);
        if (properties) {
          if (properties.length > 0) {
            const company = await retrieveCompany(
              properties.find((prop) => prop.company !== null)!.company
            );
            setCompany(company);
          }
        }
        setLoading(false);
      };
      fetchData().catch(console.error);
    }
    return () => {
      setCompany(undefined);
      setLoading(false);
    };
  }, [properties, type]);

  useEffect(() => {
    if (type === facilityType.buildingArea) {
      const fetchData = async () => {
        setLoading(true);
        const questions = (await retrieveQuestions()).results;
        setQuestions(questions);
        setLoading(false);
      };
      fetchData().catch(console.error);
    }
    return () => {
      setQuestions(undefined);
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (id && type === facilityType.buildingArea) {
      const fetchAccessibilityUnits = async () => {
        setLoading(true)
        const results = (await retrieveAccessibilityUnitsByBuildingAreaId(
          id, 'id,name,title,type,property,related_to,is_accessible_unit'
        )).results
        setAccessibilityUnits(results)
        setLoading(false)
      }
      fetchAccessibilityUnits().catch(console.error)
    }
  }, [id])

  useEffect(() => {
    if (id && type === facilityType.buildingArea) {
      const fetchAccessibilities = async () => {
        setLoading(true)
        if (accessibilities && accessibilities.length === 0 || refresh !== cachedRefresh) {
          const results = (await retrieveAccessibilitiyByBuildingArea(
            id,
            'id,item,attribute,disability_types,accessibility_unit,attribute_icon,attribute_title'
          )).results
          if (results) {
            const filterOutNull = results.filter((i) => i.attribute_title !== null)
            const replacedAccessibilitiesTitle = replaceAccessibilitiesTitle(filterOutNull, accessibilityUnits, a11yStandard?.title)
            const replacedNestedAccessibilities = replaceNestedAccessibilities(replacedAccessibilitiesTitle, accessibilityUnits)
            setAccessibilities(replacedNestedAccessibilities)
          }
        }
        setLoading(false)
      }
      fetchAccessibilities().catch(console.error)
    }
  }, [id, a11yStandard, refresh])

  useEffect(() => {
    if (type === facilityType.buildingArea) {
      const fetchData = async () => {
        setLoading(true);
        if (properties) {
          if (properties.length > 0) {
            const standard = await retrieveQuestionnaireStandards(
              properties[0].a11y_standard
            );

            const currentStandard = standard[0];
            setA11yStandard(currentStandard);
          }
        }
        setLoading(false);
      };
      fetchData().catch(console.error);
    }
    return () => {
      setA11yStandard(undefined);
      setLoading(false);
    };
  }, [properties, type]);

  useEffect(() => {
    if (type === facilityType.buildingArea && id) {
      const fetchData = async () => {
        setLoading(true);
        const buildingArea = await retrieveBuildingAreaById(id);
        const standard = buildingArea.a11y_standards[0];

        setSelectedBuildingArea(buildingArea);
        const propertiesRequest = await searchPropertiesWithFields({
          building_area: buildingArea.id,
          // should reconsider this, either lazy loading or some other solution
          limit: 999,
          a11y_standard: standard,
          fields: 'id,name,general_image,general_image_description,category,company,point,a11y_standard,additional_information',
        });
        setProperties(propertiesRequest.results);

        const shopsRequest = await searchProperties({
          building_area: buildingArea.id,
          limit: 1,
          a11y_standard: a11yStandardKeys.shop,
        });

        setShopsCount(shopsRequest.count);
        setLoading(false);
      };
      fetchData().catch(console.error);
    }
    return () => {
      setSelectedBuildingArea(undefined);
      setSelectedBuildingArea(undefined);
      setShopsCount(0);
      setProperties(undefined);
      setLoading(false);
    };
  }, [type, id]);

  useEffect(() => {
    if (type === facilityType.buildingArea) {
      const fetchData = async () => {
        setLoading(true);
        if (selectedBuildingArea) {
          const foodCourts = (
            await retrieveAccessibilityUnitsByBuildingAreaAndType(
              selectedBuildingArea.id,
              "dining_area"
            )
          ).count;
          setDiningArea(foodCourts);

          const parkingSpaces = (
            await retrieveBuildingAreasAccessibilityByType(
              selectedBuildingArea.id,
              "parking",
              "number_of_units"
            )
          ).results;
          setParkingSpaces(parkingSpaces);

          const publicToilets = (
            await retrieveBuildingAreasAccessibilityByType(
              selectedBuildingArea.id,
              "public_toilet",
              "number_of_units"
            )
          ).results
          setPublicToilets(publicToilets)

          const toilets = (
            await retrieveBuildingAreasAccessibilityByType(
              selectedBuildingArea.id,
              "toilet_room__common",
              "number_of_units"
            )
          ).results
          setToilets(toilets)

          setLoading(false);
        }
      };
      fetchData().catch(console.error);
    }
    return () => {
      setParkingSpaces(undefined);
      setDiningArea(0);
      setLoading(false);
    };
  }, [selectedBuildingArea, type, refresh]);

  useEffect(() => {
    if (type === facilityType.buildingArea) {
      const fetchData = async () => {
        setLoading(true);
        if (selectedBuildingArea) {
          const accessibilityInfo = (
            await retrieveBuildingAreaGeneralInfo(selectedBuildingArea.id)
          ).results;
          setGeneralAccessibilityInfo(accessibilityInfo);
        }
        setLoading(false);
      };
      fetchData().catch(console.error);
    }
    return () => {
      setGeneralAccessibilityInfo(undefined);
      setLoading(false);
    };
  }, [selectedBuildingArea, refresh, type]);

  useEffect(() => {
    if (type === facilityType.buildingArea) {
      const fetchData = async () => {
        setLoading(true);
        if (selectedBuildingArea) {
          const accessibilityInfo = (
            await retrieveBuildingAreaAccessibilityInfos(
              selectedBuildingArea.id
            )
          ).results;
          setBuildingAreaGeneralAccessibilityInfo(accessibilityInfo);
        }
        setLoading(false);
      };
      fetchData().catch(console.error);
    }
    return () => {
      setBuildingAreaGeneralAccessibilityInfo(undefined);
      setLoading(false);
    };
  }, [selectedBuildingArea, type, refresh]);

  useEffect(() => {
    if (type === facilityType.property && id) {
      const fetchData = async () => {
        setLoading(true);

        const propertyRequest = await retrieveProperty(id);
        if (propertyRequest.hasOwnProperty('published')) {
          if (propertyRequest.published) {
            setProperties([propertyRequest]);
          } else {
            setPublished(propertyRequest.published)
          }
        } else {
          setProperties([propertyRequest]);
        }
        setLoading(false);
      };
      fetchData().catch(console.error);
    }
    return () => {
      setProperties(undefined);
      setLoading(false);
    };
  }, [id, type]);

  useEffect(() => {
    if (type === facilityType.property) {
      const fetchData = async () => {
        setLoading(true);
        if (properties) {
          if (properties.length > 0) {
            const company = await retrieveCompany(
              properties.find((prop) => prop.company !== null)!.company
            );
            setCompany(company);

            const standard = await retrieveQuestionnaireStandards(
              properties[0].a11y_standard
            );

            const currentStandard = standard[0];
            setA11yStandard(currentStandard);
          }
        }
        setLoading(false);
      };
      fetchData().catch(console.error);
    }
    return () => {
      setCompany(undefined);
      setA11yStandard(undefined);
      setLoading(false);
    };
  }, [properties, type]);

  useEffect(() => {
    if (type === facilityType.property) {
      const fetchData = async () => {
        setLoading(true);
        if (properties) {
          if (properties.length > 0) {
            const id = properties[0].id;
            const unitLevels = (
              await retrieveAccesibilityUnitsLevelsByPropertyId(id)
            ).results;

            setUnitLevels(unitLevels);
          }
        }
        setLoading(false);
      };
      fetchData().catch(console.error);
    }
    return () => {
      setUnitLevels(undefined);
      setLoading(false);
    };
  }, [properties, type, refresh]);

  return {
    properties,
    a11yStandard,
    company,
    loading,
    selectedBuildingArea,
    shopsCount,
    parkingSpaces,
    publicToilets,
    toilets,
    diningArea,
    generalAccessibilityInfo,
    buildingAreaAccessibilityInfo,
    roomAccessibilities,
    unitLevels,
    published,
    accessibilityUnits,
    accessibilities,
    questions
  };
};
