import { Theme, useMediaQuery } from "@mui/material";
import {useCallback, useEffect, useState, DependencyList} from "react";
import {isIFrame} from "../utils/utils";

export const useElementHeight = ({
    elemId,
    onResize
}: {
    onResize?: boolean;
    elemId: string
}, deps: DependencyList = []) => {
    const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const fn = useCallback(() => {
        const iframe = document.getElementById('handiscover-iframe')
        if (isIFrame(iframe) && iframe.contentWindow) {
            const element = iframe.contentWindow.document.getElementById(elemId);
            if (element) {
                const height = element.clientHeight
                const width = element.clientWidth
                setWidth(width)
                setHeight(height)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const iframe = document.getElementById('handiscover-iframe')
        if (onResize && isIFrame(iframe) && iframe.contentWindow) {
            iframe.contentWindow.window.addEventListener("resize", fn)
        }
        return () => {
            if (isIFrame(iframe) && iframe.contentWindow) {
                iframe.contentWindow.window.removeEventListener("resize", fn);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        fn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sm, elemId, ...deps]);

    return { height, width }
}