import {Property} from "../../../Models/Property";
import {a11yStandardKeys} from "../../../constants/constants";

// 11.5 - 1km
// 15.5 - 50m
// 18 - 20m

const BIGGEST_ZOOM = [
  '78e4e95a-c360-44c4-9e1b-1cad77f95f67', // Järfälla Kommun (building area)
]

export const getZoomOptions = (
  id: string,
  standard: string,
  poisBuildingArea: Property[] | undefined,
  sm: boolean
) => {

  if (BIGGEST_ZOOM.includes(id)) {
    return 11.5
  }
  const isBuildingArea = !!poisBuildingArea && poisBuildingArea.length > 0

  if (isBuildingArea) {
    switch (standard) {
      case a11yStandardKeys.outdoorPlayground:
        return 13
      default:
        return 14.5
    }
  } else {
    switch (standard) {
      case a11yStandardKeys.hotel:
        return sm ? 17 : 18
      case a11yStandardKeys.museum:
        return 17.5
      case a11yStandardKeys.indoorPlayground:
        return 17
      case a11yStandardKeys.outdoorPlayground:
        return 18
      case a11yStandardKeys.office:
        return 17.5
      default:
        return 15.5
    }
  }

  // if (standard === a11yStandardKeys.hotel) {
  //   return sm ? 17 : 18
  // }
  // if (BIGGEST_ZOOM.includes(id)) {
  //   return 11.5
  // }
  // if (standard === a11yStandardKeys.outdoorPlayground && !poisFromProperty && poisBuildingArea && poisBuildingArea.length > 0) {
  //   return 13
  // }
  // if (poisFromProperty && !poisBuildingArea) {
  //   if (standard === a11yStandardKeys.museum) {
  //     return 17.5
  //   }
  //   if (standard === a11yStandardKeys.indoorPlayground) {
  //     return 17
  //   }
  // }
  // return (poisFromProperty?.length > 0 && !poisBuildingArea) ? DEFAULT_ZOOM.property : DEFAULT_ZOOM.buildingArea
}