import {Card, CardContent, CardMedia, Chip, Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, {FC} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {Accessibility} from "../../../Models/Accessibility";
import {AccessibilityUnit} from "../../../Models/AccessibilityUnits";
import Icons from "../../../components/Icons";
import {A11yStandardType} from "../../../Models/utils";
import {useStorageData} from "../../../hooks/useStorageData";
import MainAccessibility from "../../../components/MainAccessibility";
import {Property} from "../../../Models/Property";
import {Mixpanel} from "../../../Mixpanel";
import {MetricMessage} from "../../../utils/metrics";

const WrapperGeneralAccessibility = styled.div`
  margin-top: 10px
`

const StyledCard = styled(Card)`
  border: 1px solid #E3E5E5;
  & img {
    margin-top: 15px !important;
    height: 80px !important;
    object-fit: contain !important;
  }
`;

const StyledCardContent = styled(CardContent)`
  padding: 10px !important;
  margin: 2px !important;
`;

const ListItemBoxName = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  display: inline;
`;

const GoBackBtn = styled(Button)`
  font-size: 13px;
  & img {
    width: 25px;
  }
  & span:first-child {
    margin-right: 5px;
  }
`;

const GoBackBtnWrapper = styled.div`
  height: 40px;
  margin-bottom: 10px;
`;

type PropsType = {
  type: string,
  logo?: string,
  name: string,
  propertyItemFloors: string
  propertyItemCategory?: string
  itemAccessibilites: Array<Accessibility>,
  a11yStandardOrUnitType: string,
  propertyItemAccessibilityUnits: Array<AccessibilityUnit>,
  setListPage: (bool: boolean) => void,
  a11yStandard: A11yStandardType
  currentImageData?: { index: number, accessibility_unit: string, name: string }
  property: Property | undefined
}

const HighlightedInfoContainer: FC<PropsType> = ({
  logo,
  name,
  propertyItemFloors,
  propertyItemCategory,
  itemAccessibilites,
  a11yStandardOrUnitType,
  propertyItemAccessibilityUnits,
  setListPage,
  a11yStandard,
  currentImageData,
  type,
  property
}) => {
  const { t } = useTranslation()
  const disabilityTypes = useStorageData('disability_types')

  const getBookNowButton = () => {
    if (type === 'hotel_room_type' && property && property.additional_information && property.additional_information.link) {
      return (
        <Box display={'flex'} justifyContent={'flex-end'} mt={1.5}>
          <Button
            variant={'contained'}
            size={'small'}
            fullWidth
            onClick={() => {
              Mixpanel.track(`${MetricMessage.clicked_book_now}`);
              window.open(property.additional_information.link, '_blank')
            }}
          >
            {t('book_now')}
          </Button>
        </Box>
      )
    }
    return null
  }

  return (
    <Box>
      <GoBackBtnWrapper>
        <GoBackBtn
          variant="text"
          startIcon={<Icons.GoBackChevron />}
          onClick={(e) => setListPage(false)}
        >
          {`${t("go_back")}`}
        </GoBackBtn>
      </GoBackBtnWrapper>
      <StyledCard elevation={0}>
        {logo && logo.length > 1 ? (
          <CardMedia
            sx={{ objectFit: "contain" }}
            component="img"
            height="80"
            image={logo}
            alt={name}
          />
        ) : null}
        <StyledCardContent>
         <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
           <ListItemBoxName sx={{mr: 1}}>
             {name}
           </ListItemBoxName>
           {(propertyItemFloors) ?
             <Chip
               variant="filled"
               sx={{
                 color: "#000000",
                 border: "1px solid #DDE1E6",
                 background: "transparent",
                 fontWeight: "500",
                 borderRadius: "5px",
                 height: "25px"
               }}
               label={
                 `${t("property_floorSignular")}
                    ${propertyItemFloors}`
               }
             /> : null
           }
         </Box>
          {propertyItemCategory ?
            <Typography
              sx={{mt: 1}}
              variant="caption"
              display="block"
            >
              {propertyItemCategory}
            </Typography> : null
          }
          {getBookNowButton()}
        </StyledCardContent>
      </StyledCard>
      <WrapperGeneralAccessibility>
        <MainAccessibility
          accessibilities={itemAccessibilites}
          a11yStandard={a11yStandard}
          a11yStandardKey={a11yStandardOrUnitType}
          propertyItemAccessibilityUnits={propertyItemAccessibilityUnits}
          disabilityTypes={disabilityTypes}
          currentImageData={currentImageData}
        />
      </WrapperGeneralAccessibility>
    </Box>
  )
}

export default HighlightedInfoContainer
