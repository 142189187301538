import { Property } from "../../Models/Property";
import { A11yStandardType } from "../../Models/utils";
import { PropertyPage } from "./PropertyPage";
import { createContext, useState, FC } from "react";
import {getCompanyName} from "../../utils/utils";

export const MapContext = createContext<[boolean, React.Dispatch<boolean>]>([
  null!,
  () => null,
]);

const MapContextProvider: FC = ({ children }) => {
  const [showMap, setShowMap] = useState<boolean>(false);
  return (
    <MapContext.Provider value={[showMap, setShowMap]}>
      {children}
    </MapContext.Provider>
  );
};

export default function PropertyWrapper({
  property,
  a11yStandard,
  displayShops,
  companyIdAPIKey,
  fullscreen,
  buildingAreaId,
}: {
  property: Property;
  a11yStandard: A11yStandardType;
  displayShops: boolean;
  companyIdAPIKey: string;
  buildingAreaId: string | null;
  fullscreen: boolean;

}) {  

  return (
    <MapContextProvider>
      <PropertyPage
        property={property}
        a11yStandard={a11yStandard}
        displayShops={displayShops}
        companyIdAPIKey={companyIdAPIKey}
        buildingAreaId={buildingAreaId}
        fullscreen={fullscreen}
      />
    </MapContextProvider>
  );
}
