import styled from "styled-components";
import Icons from "../../Icons";
import {Link as MuiLink, Typography as MuiTypography} from "@mui/material";
import {Mixpanel} from "../../../Mixpanel";
import {MetricMessage} from "../../../utils/metrics";

const HandiscoverIcon = styled(Icons.Handiscover_Logo)`
  height: 37px;
  width: 37px;
  @media (max-width: 768px) {
    height: 30px;
    width: 30px;
  }
`

const Link = styled(MuiLink)`
  text-decoration: none !important;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 1.488px solid #E3E5E6;
  border-radius: 147.312px;
  max-height: 33px;
  max-width: 175px;
  padding: 5px 5px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #0000000a
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  max-width: 94px;
  margin-left: 6.34px;
  letter-spacing: 0.372001px;
  b {
    font-family: 'Roboto' !important;
    font-weight: bold !important;
  }
  @media (max-width: 768px) {
    font-size: 11px;
    max-width: 73px;
  }
`

const Typography = styled(MuiTypography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1;
  color: #5E6366 !important;
`
const HandiscoverBadge = () => {
  return (
    <Link href="https://www.haccess.io/" target="_blank" underline="none"
          onClick={() => Mixpanel.track(`${MetricMessage.verified_by_handiscover_click}`)}>
      <Wrapper>
        <HandiscoverIcon/>
        <TextWrapper>
          <Typography>Powered by <b>Handiscover</b></Typography>
        </TextWrapper>
      </Wrapper>
    </Link>
  )
}

export default HandiscoverBadge
