import MyLocationImg from "../icons/my_current-location.svg";
import MyLocationImgBlack from "../icons/my_current-location-black.svg";
import styled from "styled-components";
import {FC} from "react";
import {MapOptions} from "google-map-react";

type GoogleIconProps = {
  bottom?: string;
}

const GoogleIcon = styled.div<GoogleIconProps>`
  background: none;
  z-index: 4;
  position: absolute;
  bottom: ${(props) => props.bottom ? props.bottom : '114px'};
  right: 10px;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 2px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
`;

const GoogleBtn = styled.div`
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img:nth-child(2) {
    display: none;
  }
  &:hover > img:nth-child(1), &:focus > img:nth-child(1) {
    display: none;
  }
  &:hover > img:nth-child(2), &:focus > img:nth-child(2) {
    display: block
  }
`;

type PropsType = {
  map: MapOptions & {
    setZoom: (zoom: number) => void,
    panTo:(obj: { lat: number, lng: number }) => void
  } | undefined,
  setMyGeoLocationCords: (cords: {lat: number, lng: number}) => void
}

const MyGeoLocation: FC<PropsType> = ({ map, setMyGeoLocationCords }) => {
  const handleClick = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      map?.panTo({ lat: position.coords.latitude, lng: position.coords.longitude });
      setMyGeoLocationCords({ lat: position.coords.latitude, lng: position.coords.longitude })
    }, () => null)
  }

  return (
    <GoogleIcon>
      <GoogleBtn onClick={handleClick}>
        <img src={MyLocationImg} alt="my location" />
        <img src={MyLocationImgBlack} alt="my location" />
      </GoogleBtn>
    </GoogleIcon>
  )
}

export default MyGeoLocation