import {a11yStandardKeys, LevelSizeMap} from "../../../constants/constants";
import {InfoCell, SizeContainer} from "../../../StyledWrappers/Wrappers";
import styled from "styled-components";
import {Grid, Skeleton, SvgIcon, Tooltip, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import {A11yStandardType} from "../../../Models/utils";
import React, {FC, useState} from "react";
import FloorsSvg from "../../../assets/svg/FloorsSvg";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import HotelRoomSvg from "../../../assets/svg/HotelRoomSvg";
import AccessibilityToiletSvg from "../../../assets/svg/AccessibilityToiletSvg";
import AccessibleParkingSvg from "../../../assets/svg/AccessibleParkingSvg";
import BusinessIcon from '@mui/icons-material/Business';
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import {useTheme} from "@mui/material/styles";
import PlaygroundSvg from "../../../assets/svg/PlaygroundSvg";

const WrapperSizeContainer = styled(Grid)`
  display: unset !important;
`

const StyledGrid = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(169px,1fr));
  ${(props) => props.theme.breakpoints.down("md")} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr 1fr 1fr;
    .MuiBox-root {
      justify-content: center;
    }
  }
  gap: 1rem;
  padding-top: 24px !important;
`;

const Plug = styled.div`
  @media (max-width: 942px) {
    display: none;
  }
`

type DisplayData = {
  order: number,
  title: string,
  value: number | undefined,
  icon?: any
}

type PropsType = {
  a11yStandard: A11yStandardType;
  propertiesCount: number;
  shopsCount?: number;
  parkingSpaces: number;
  publicToilets: number;
  toilets: number;
  style?: any;
}

const BuildingAreaBlockInfo: FC<PropsType> = ({
  a11yStandard,
  propertiesCount,
  shopsCount,
  parkingSpaces,
  publicToilets,
  toilets,
  style
}) => {
  const { t } = useTranslation();
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const floorData: DisplayData = {
    order: 0,
    title: t("hotel_floors"),
    value: 0,
    icon: <FloorsSvg stroke={'#636363'} height={30} width={30}/>
  }

  const inventoriedShopsData: DisplayData = {
    order: 0,
    title: t("building_area_shops"),
    value: shopsCount ? shopsCount : 0,
    icon: <SvgIcon fill={'#636363'}><ShoppingBasketIcon/></SvgIcon>
  }

  const propertyRoomsData: DisplayData = {
    order: 0,
    title: t("hotel_totalRooms"),
    value: 0
  }

  const accessibleRoomsData: DisplayData = {
    order: 0,
    title: t("hotel_accessibleRooms"),
    value: 0,
    icon: <HotelRoomSvg fill={'#636363'}/>
  }

  const allergyRoomsData: DisplayData = {
    order: 0,
    title: t("hotel_allergyRooms"),
    value: 0
  }

  const buildingsData: DisplayData = {
    order: 0,
    title: t("building_area_buildings"),
    value: propertiesCount,
    icon: <SvgIcon fill={'#636363'}><BusinessIcon/></SvgIcon>
  }

  const inventoriedPlaygroundsData: DisplayData = {
    order: 0,
    title: t('inventoried_playgrounds'),
    value: propertiesCount,
    icon: <PlaygroundSvg fill={'#636363'}/>
  }

  const totalPlaygroundsData: DisplayData = {
    order: 0,
    title: t('total_playgrounds'),
    value: 200
  }

  const parkingSpacesData: DisplayData = {
    order: 0,
    title: t("building_area_parking_spaces"),
    value: parkingSpaces,
    icon: <AccessibleParkingSvg fill={'#636363'}/>
  }

  const publicToiletsData: DisplayData = {
    order: 0,
    title: t('amount_of_accessible_toilets'),
    value: publicToilets,
    icon: <AccessibilityToiletSvg fill={'#636363'}/>
  }

  const toiletsData: DisplayData = {
    order: 0,
    title: t('amount_of_accessible_toilets'),
    value: toilets,
    icon: <AccessibilityToiletSvg fill={'#636363'}/>
  }

  const defaultData: DisplayData[] = [
    {...floorData, order: 1},
    {...inventoriedShopsData, order: 2},
    {...accessibleRoomsData, order: 3},
    {...allergyRoomsData, order: 4},
  ]

  const shoppingMallData: DisplayData[] = [
    {...buildingsData, order: 1},
    {...parkingSpacesData, order: 2},
    {...toiletsData, order: 3},
    {...inventoriedShopsData, order: 4},
  ]

  const museumData: DisplayData[] = [
    {...buildingsData, order: 1},
    {...parkingSpacesData, order: 2},
    {...toiletsData, order: 3},
  ]

  const hotelData: DisplayData[] = [
    {...buildingsData, order: 1},
    {...accessibleRoomsData, order: 2},
    {...parkingSpacesData, order: 3},
  ]

  const officeData: DisplayData[] = [
    {...buildingsData, order: 1},
    {...parkingSpacesData, order: 2},
    {...toiletsData, order: 3},
  ]

  const outdoorPlaygroundData: DisplayData[] = [
    {...inventoriedPlaygroundsData, order: 1},
    {...publicToiletsData, order: 2},
    {order: 2, title: '', value: 0},
  ]

  const getInfoBlock = (standardKey: string) => {
    let data: DisplayData[] = defaultData

    if (a11yStandard.key === a11yStandardKeys.shoppingMall) {
      data = shoppingMallData
    }

    if (a11yStandard.key === a11yStandardKeys.museum) {
      data = museumData
    }

    if (a11yStandard.key === a11yStandardKeys.hotel) {
      data = hotelData
    }

    if (a11yStandard.key === a11yStandardKeys.office) {
      data = officeData
    }

    if (a11yStandard.key === a11yStandardKeys.outdoorPlayground) {
      data = outdoorPlaygroundData
    }

    let sortedValues: DisplayData[] = []
    const positiveValues = data.filter((i) => i.value && i.value > 0)
    sortedValues = [...positiveValues]
    const undefinedValues = data.filter((i) => i.value === undefined)
    sortedValues = [...sortedValues, ...undefinedValues]
    const zeroValues = data.filter((i) => i.value === 0)
    sortedValues = [...sortedValues, ...zeroValues]


    const zeroValueIndex = sortedValues.findIndex((item) => item.value === 0);
    if (zeroValueIndex !== -1) {
      const zeroValueItem = sortedValues.splice(zeroValueIndex, 1)[0];
      sortedValues.push(zeroValueItem)
    }

    if (sortedValues.length > 3 && sortedValues[sortedValues.length - 1].value === undefined) {
      sortedValues.pop()
    }
    if (sortedValues.length > 3 && sortedValues[sortedValues.length - 1].value === 0) {
      sortedValues.pop()
    }

    return (
      <StyledGrid item xs={12} style={style}>
        {sortedValues.map((item, index) => {
          if (typeof item.value === 'undefined') {
            return (
              <Skeleton
                key={index}
                height={48.39}
                variant="rectangular"
                animation="wave"
                style={{borderRadius: 0}}
              />
            )
          }
          if (typeof item.value === 'number' && item.value === 0) {
            return <Plug key={index}/>
          }
          return (
            <Tooltip
                key={index}
                title={item.title}
                enterTouchDelay={sm ? 0 : undefined}
                PopperProps={{sx: { top: '-18px !important' }}}
                disableHoverListener
                arrow
            >
              <InfoCell key={index} display={'flex'} alignItems={'center'}>
                <span className={'number'}>{item.value}</span>
                <Box className={'label'} display={'flex'} alignItems={'center'}>
                  <Box display={'flex'} justifyContent={'center'}>{item.icon}</Box>
                  {!sm ? (<Box ml={1}><span>{item.title}</span></Box>) : null}
                </Box>
              </InfoCell>
            </Tooltip>
          )
        })}
      </StyledGrid>
    )
  }

  return (
    <WrapperSizeContainer container spacing={3}>
      {getInfoBlock(a11yStandard.key) ? getInfoBlock(a11yStandard.key) : null}
    </WrapperSizeContainer>
  )
}

export default BuildingAreaBlockInfo