import styled from "styled-components";
import React, {
  createContext,
  FC,
  useContext, useEffect, useMemo,
  useState,
} from "react";
import { Header } from "./header/Header";
import {Box, IconButton, Paper} from "@mui/material";
import {borderRadius} from "../constants/constants";
// import Icons from "./Icons";
import { A11yStandardType } from "../Models/utils";
import "./../translations/i18n";
import { Property } from "../Models/Property";
import { BuildArea } from "../Models/BuildArea";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Mixpanel } from "../Mixpanel";
import { MetricMessage } from "../utils/metrics";
import {useElementHeight} from "../hooks/useElementHeight";
import {getElementByIdFromIframe, getTextByCurrentLanguage} from "../utils/utils";
import {useTranslation} from "react-i18next";
import Icons from "./Icons";

const AppContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const ContentWrapper = styled.div`
  height: 100%;
`

const StyledCloseIcons = styled(Icons.CloseIcon)`
  width: 12px;
  height: 12px
`

const BlockLabel = styled.div`
  display: flex; 
  align-items: center; 
  justify-content: center;
`

const EmulatedButton = styled.div`
  padding: 5px;
  border-radius: 50%;
  transition: 0.25s;
  margin-left: 5px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`

type MainContentProps = {
  height?: number | string;
}

const MainContent = styled(Paper)<MainContentProps>`
  ${(props) => props.theme.breakpoints.down("sm")} {
    border-radius: 0px !important;
  }
  height: ${(props) => props.height};
  background-color: #f2f4f8 !important;
  background: ${(props) => props.theme.palette.background.default};
  box-shadow: none;
  border-radius: 0px 0px ${borderRadius}px 0px !important;
  
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  box-shadow: 0 12px 24px rgba(37, 42, 49, 0.24),
    0 8px 60px rgba(37, 42, 49, 0.32);
  @media (max-width: 768px) {
    height: 100vh;
    overflow-y: scroll !important;
  }
`;

const StyledTab = styled(Tab)`
  min-height: 25px;
  min-width: 0;
  ${(props) => props.theme.breakpoints.down("sm")} {
    min-width: 68px;
  }
`;

export const TabsContext = createContext<[number, React.Dispatch<number>]>([
  null!,
  () => null,
]);

export const TabsContextProvider: FC = ({ children }) => {
  const [fieldValue, setFieldValue] = useState(0);

  useEffect(() => {
    if (fieldValue) {
      const element = getElementByIdFromIframe('root-content')
      element?.scrollTo(0,0)
    }
  }, [fieldValue])

  return (
    <TabsContext.Provider value={[fieldValue, setFieldValue]}>
      {children}
    </TabsContext.Provider>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const MenuTabs: FC<{
  currentStandard: A11yStandardType;
  properties: Property[];
  buildingArea: BuildArea | undefined;
}> = ({
  buildingArea,
  properties,
  currentStandard
}) => {
  const [field, setField] = useContext(TabsContext);
  const { t } = useTranslation()
  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setField(newValue);
    if (buildingArea && newValue === 0) {
      Mixpanel.track(`${MetricMessage.accessed_building_area}`);
    } else if (buildingArea && newValue > 0) {
      Mixpanel.track(`${MetricMessage.accessed_property_tab}`);
    }
  };

  const getTabs = () => {
    if (currentStandard && currentStandard.key === 'outdoor_playground') {
      return properties.map((property: Property, idx: number) => (
        <StyledTab
          style={{display: idx === field - 1 ? 'inline-flex' : 'none'}}
          tabIndex={(field === (idx+1)) ? -1 : 0}
          key={property.id}
          label={
            <BlockLabel>
              {getTextByCurrentLanguage(property.name)}
              <EmulatedButton
                onClick={() => setField(0)}
              >
                <StyledCloseIcons />
              </EmulatedButton>
            </BlockLabel>
          }
          {...a11yProps(idx+1)}
        />
        ))
    }
    return properties
      .filter((p: Property) => p.category === "mall_shopping_complex")
      .map((property: Property, idx: number) => (
        <StyledTab
          tabIndex={(field === (idx+1)) ? -1 : 0}
          key={property.id}
          label={getTextByCurrentLanguage(property.name)}
          // icon={Icons.Rooms}
          // iconPosition="start"
          // kind={facilityType.property}
          {...a11yProps(idx+1)}
        />
      ))
  }

  const getLabel = () => {
    if (currentStandard && currentStandard.key === 'outdoor_playground') {
      return t('playground_information')
    }
    return buildingArea?.name
  }

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      {buildingArea ?
        <Tabs
          variant="scrollable"
          value={field}
          onChange={onTabChange}
          aria-label="tabs"
          allowScrollButtonsMobile
          // textColor="inherit"
          // selectionFollowsFocus
          // TabIndicatorProps={{style: {backgroundColor: 'black'}}}
        >
          <StyledTab
            tabIndex={(field === 0) ? -1 : 0}
            label={getLabel()}
            {...a11yProps(0)}
          />
          {getTabs()}
      </Tabs>
      : null
      }
    </Box>
  )
}


export const PageLayout: FC<{
  fullScreen: boolean;
  loading: boolean;
  currentStandard: A11yStandardType;
  companyLogo: string;
  propertyName: string;
  properties: Property[];
  headerTextMap: string[];
  buildingArea: BuildArea | undefined;
  companyName: string;
  supportedLanguages: string[];
}> = ({
  fullScreen,
  loading,
  headerTextMap,
  children,
  currentStandard,
  companyLogo,
  propertyName,
  properties,
  buildingArea,
  companyName,
  supportedLanguages,
}) => {

  const { height } = useElementHeight({
    elemId: "content-wrapper",
  }, [children]);

  const getHeight = (height: number, loading: boolean, fullscreen: boolean): string => {
    let result: string = '100%'
    if (fullscreen && loading) {
      result = '100vh'
    }
    if (!fullscreen && loading) {
      result = `${height}px`
    }
    return result
  }

  const getHeightData = useMemo(() => {
    return getHeight(height, loading, fullScreen)
  }, [loading, height])

  return (
    <Root id="root-content">
      <AppContent>
        <Header
          headerTextMap={headerTextMap}
          companyLogo={companyLogo}
          propertyName={propertyName}
          companyName={companyName}
          supportedLanguages={supportedLanguages}
        />
      </AppContent>
      <ContentWrapper id="content-wrapper">
        <MainContent
          square
          height={getHeightData}
        >
          {(properties.length > 1) ?
            <MenuTabs
              buildingArea={buildingArea}
              properties={properties}
              currentStandard={currentStandard}
            /> : null
          }
          {children}
        </MainContent>
      </ContentWrapper>
    </Root>
  );
};
