import React, {FC, useEffect} from "react";
import styled from "styled-components";
import {useLocalStorage} from "../hooks/useLocalStorage";
import {useStorageData} from "../hooks/useStorageData";
import {getAttributeValueFromCurrentURL} from "../utils/utils";

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.2);
`

const Banner = styled.div`
  font-family: "Roboto", "Manrope", sans-serif !important;
  z-index: 9999999;
  background: #ffffff;
  border: 1px solid #E3E5E5;
  margin: 5px;
  color: black;
  box-shadow: 0 2px 5px 0 #000000a8;
  bottom: 8px;
  left: 8px;
  width: calc(100% - 30px);
  border-radius: 5px;
  align-items: center;
  display: flex;
  position: fixed;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Content = styled.div`
  flex: 1 0 300px;
  margin: 15px;
`

const ButtonWrapper = styled.div`
  display: flex;
  margin: 15px;
  @media (max-width: 544px) {
   width: 100%;
  }
  
  .button {
    cursor: pointer;
    border-radius: 5px;
    font-size: 15px;
    padding: 10px;
  }
  
  .accept-button {
    color: #ffffff;
    background: #1976d2;
    border: 1px solid #1976d2;
    @media (max-width: 544px) {
     width: 100%;
    }
  }
  .decline-button {
    background: #ffffff;
    color: rgb(0 0 0 / 84%);
    border: 1px solid rgb(0 0 0 / 50%);
    margin-left: 15px;
  }
`

type PropsType = {
  display: boolean;
  debug?: boolean;
  expires?: number;
}

const CookieBanner: FC<PropsType> = ({
  debug = false,
  display,
  expires = 30, // days
}) => {
  const [, setCookieConsent] = useLocalStorage<string>('cookie-consent', 'false');
  const [, setAnswerDate] = useLocalStorage<string | null>('cookie-answer-date', null);
  const cookiesBannerValue = getAttributeValueFromCurrentURL('cookies-banner');
  const cookieConsent = useStorageData('cookie-consent')
  const cookieAnswerDate = useStorageData('cookie-answer-date')

  const text = `
    We use cookies to enhance your experience on our website. 
    By continuing to browse this site, you consent to the use of cookies.
  `
  const linkText = 'For more info read here'

  const handleDeclineCookies = (): void => {
    setCookieConsent(false)
    setAnswerDate(new Date())
  };

  const handleAcceptCookie = (): void => {
    setCookieConsent(true);
    setAnswerDate(new Date())
  };

  useEffect(() => {
    if (cookiesBannerValue === 'false') {
      display = false
      handleAcceptCookie()
    }
  }, [cookiesBannerValue])

  const isExpired = (date: string | null): boolean => {
    if (date) {
      const answerDate = new Date(date);
      const currentDate = new Date();
      const timeDifference = currentDate.getTime() - answerDate.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      return daysDifference > expires
    }
    return true
  };

  const showBanner = (display: boolean, cookieConsent: string | null, cookieDate: string | null) => {
    if (debug) {
      return true
    }
    return cookieConsent === null && display || isExpired(cookieDate)
  }

  return (
    <Wrapper style={{display: showBanner(display, cookieConsent, cookieAnswerDate) ? 'block' : 'none'}}>
      <Banner>
        <Content>
          {text}
          <a href="https://www.haccess.io/privacy-policy" target='_blank'>{linkText}</a>
        </Content>
        <ButtonWrapper>
          <button className={'button accept-button'} onClick={handleAcceptCookie}>
            Accept
          </button>
          <button className={'button decline-button'} onClick={handleDeclineCookies}>
            Decline
          </button>
        </ButtonWrapper>
      </Banner>
    </Wrapper>
  )
}

export default CookieBanner