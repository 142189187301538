import {Mixpanel} from "../Mixpanel";
import {MetricMessage} from "../utils/metrics";
import React, {FC, useEffect, useRef} from "react";
import styled from "styled-components";
import {isIFrame} from "../utils/utils";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`

type PropsType = {
  children: React.ReactNode;
  locatedOn?: {
    type?: string,
    triggeredOnPage: string,
    section: string
  }
}

const MixpanelTrackingList: FC<PropsType> = ({locatedOn, children}) => {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const isScrolledTo = useRef({10: false, 50: false, 100:false})

  const sendScrollPercentMetric = (percent: number) => {
    if (locatedOn) {
      Mixpanel.track(`${MetricMessage.scroll_percentage}`, {
        type: locatedOn.type,
        percent: `${percent}%`,
        triggeredOnPage: locatedOn.triggeredOnPage,
        section: locatedOn.section
      });
    }
  }

  const touchMoveListener = () => {
    if (scrollContainer && scrollContainer.current) {
      const boundingClientRect = scrollContainer.current.getBoundingClientRect()
      const generalAccessibilityHeight = scrollContainer.current.offsetHeight
      const clientHeight = window.innerHeight
      const { y } = boundingClientRect
      if (Math.sign(y - clientHeight) === -1) {
        const currentPosition = Math.abs(y - clientHeight)
        if (currentPosition >= generalAccessibilityHeight / 100 * 10 && !isScrolledTo.current[10]) {
          isScrolledTo.current[10] = true
          sendScrollPercentMetric(10)
        } else if (currentPosition >= generalAccessibilityHeight / 100 * 50 && !isScrolledTo.current[50]) {
          isScrolledTo.current[50] = true
          sendScrollPercentMetric(50)
        } else if (currentPosition >= generalAccessibilityHeight / 100 * 100 && !isScrolledTo.current[100]) {
          isScrolledTo.current[100] = true
          sendScrollPercentMetric(100)
        }
      }
    }
  }

  const scrollListener = (e: Event & { target: HTMLButtonElement }) => {
    let scrollTop = e.target.scrollTop;
    let scrollHeight = e.target.scrollHeight;
    let offsetHeight = e.target.offsetHeight;
    let contentHeight = scrollHeight - offsetHeight;
    if (contentHeight <= scrollTop && !isScrolledTo.current[100]) {
      isScrolledTo.current[100] = true
      sendScrollPercentMetric(100)
    }
    if ((contentHeight / 2) <= scrollTop && !isScrolledTo.current[50]) {
      isScrolledTo.current[50] = true
      sendScrollPercentMetric(50)
    }
    if ((contentHeight * 0.1) <= scrollTop && !isScrolledTo.current[10]) {
      isScrolledTo.current[10] = true
      sendScrollPercentMetric(10)
    }
  }

  useEffect(() => {
    if (locatedOn) {
      const iframe = document.getElementById('handiscover-iframe')
      if (isIFrame(iframe) && iframe.contentWindow && scrollContainer && scrollContainer.current) {
        const element = iframe.contentWindow.document.getElementById('tab-layout')
        element?.addEventListener("touchmove", touchMoveListener, false);
        scrollContainer.current.addEventListener(
          "scroll",
          (e) => scrollListener(e as Event & { target: HTMLButtonElement }),
          false
        );
        return () => {
          element?.removeEventListener("touchmove", touchMoveListener, false);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          scrollContainer.current?.removeEventListener(
            "scroll",
            (e) => scrollListener(e as Event & { target: HTMLButtonElement }),
            false
          );
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollContainer]);

  return (
    <Wrapper ref={scrollContainer}>
      {children}
    </Wrapper>
  )
}

export default MixpanelTrackingList