import { useContext, useEffect, useState } from "react";
import { refreshContext } from "../contexts/refreshContext";
import { Accessibility } from "../Models/Accessibility";
import { AccessibilityUnitLevel } from "../Models/AccessibilityLevel";
import {
  AccessibilityUnit,
  AccessibilityUnitsQuery,
} from "../Models/AccessibilityUnits";
import { Property } from "../Models/Property";
import { PropertyAccessibilityImages } from "../Models/PropertyAccessibilityImages";
import { AccessibilityInfo } from "../Models/AccessibilityInfo";
import {
  retrievePropertyAccessibilityInfoByPropertyId,
  listAccessbilityImagesByPropertyId,
  retrieveAccessibilitiyByPropertyId,
  retrieveAccesibilityUnitsLevelsByPropertyId,
  retrieveAccessibilityUnits,
  retrieveAccessibilitiyByUnitId,
  retrievePropertiesByRelatedId,
  retrievePropertyAccessibilityByType,
  retrievePropertyFreeTextInfo,
  retrieveAccessibilityUnitsByTypeWithFields,
} from "../services/property.service";
import {
  accessibilityInformationIds, getTextByCurrentLanguage,
  replaceAccessibilitiesTitle,
  replaceNestedAccessibilities, replaceNestedAccessibilityImages
} from "../utils/utils";
import {a11yStandardKeys} from "../constants/constants";
import {PropertyFreeTextInfo} from "../Models/PropertyFreeTextInfo";
import {Question} from "../Models/Question";
import {retrieveQuestions} from "../services/questions.service";
import {A11yStandardType} from "../Models/utils";

export const useFetchInfoFromProperty = (property: Property, id: string, a11yStandard: A11yStandardType) => {
  const [loading, setLoading] = useState(false);

  const [parkingSpaces, setParkingSpaces] = useState<
    AccessibilityUnitLevel[] | undefined
  >(null!);
  const [toiletRoom, setToiletRoom] = useState<
    AccessibilityUnitLevel[] | undefined
  >(undefined);
  const [totalShop, setTotalShop] = useState<number | undefined>(undefined);
  const [foodCourtCount, setFoodCourtCount] = useState(0);

  const [accessibilities, setAccessibilities] = useState<Accessibility[] | undefined>([]);
  const [questions, setQuestions] = useState<Question[] | undefined>([]);

  const [generalAccessibilities, setGeneralAccessibilities] = useState<
    Accessibility[] | undefined
  >([]);
  const [hospitalityRooms, setHospitalityRooms] = useState<AccessibilityUnit[]>([]);
  const [meetingRooms, setMeetingRooms] = useState<AccessibilityUnit[]>([]);
  const [exhibitionRooms, setExhibitionRooms] = useState<AccessibilityUnit[]>([]);
  const [museumShopRooms, setMuseumShopRooms] = useState<AccessibilityUnit[]>([]);
  const [toiletCommonRooms, setToiletCommonRooms] = useState<AccessibilityUnit[]>([]);
  const [propertyImages, setPropertyImages] = useState<
    PropertyAccessibilityImages[]
  >([]);

  const [propImages, setPropImages] = useState<PropertyAccessibilityImages[] | undefined>(
    []
  );

  const [unitLevels, setUnitLevels] = useState<AccessibilityUnitLevel[]>([]);
  const [propertyAccessibilityInfo, setPropertyAccessibilityInfo] = useState<
    AccessibilityInfo[] | undefined
  >(null!);

  const [propertyFreeTextInfo, setPropertyFreeTextInfo] = useState<
    PropertyFreeTextInfo[] | undefined
  >(null!);
  const [accessibleUnits, setAccessibleUnits] = useState<AccessibilityUnit[]>(
    []
  );
  const [hospitalityRoomAccessibilities, setHospitalityRoomAccessibilities] = useState<
    Accessibility[]
  >([]);

  const [units, setUnits] = useState<AccessibilityUnitsQuery>();

  const [refresh] = useContext(refreshContext);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const propertyInfos = (
        await retrievePropertyAccessibilityInfoByPropertyId(id)
      ).results;
      setPropertyAccessibilityInfo(propertyInfos);
      setLoading(false);
    };
    fetchData().catch(console.error);
    return () => {
      setPropertyAccessibilityInfo(undefined);
      setLoading(false);
    };
  }, [refresh, id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      if (!propertyFreeTextInfo) {
        const propertyFreeTextInfoResult = (
          await retrievePropertyFreeTextInfo(id)
        ).results
        setPropertyFreeTextInfo(propertyFreeTextInfoResult)
      }
      setLoading(false)
    }
    fetchData().catch(console.error)
  }, [refresh, id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const propImgs = (await listAccessbilityImagesByPropertyId(id)).results;
      setPropImages(propImgs);
      setLoading(false);
    };
    fetchData().catch(console.error);
    return () => {
      setPropImages(undefined);
      setLoading(false);
    };
  }, [refresh, id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const accessibilities = (
        await retrieveAccessibilitiyByPropertyId(
          id,
          "id,name,item_title,attribute_title,is_absent,accessibility_unit,attribute,attribute_icon,disability_types,item"
        )
      ).results;
      setGeneralAccessibilities(accessibilities);
    };
    fetchData().catch(console.error);
    setLoading(false);
    return () => {
      setGeneralAccessibilities(undefined);
      setLoading(false);
    };
  }, [refresh, id]);

  useEffect(() => {
    setLoading(true);
    if (generalAccessibilities) {
      const filteredAccessibilities = generalAccessibilities.filter(
        (access) => access.is_absent === false
      );
      if (filteredAccessibilities) {
        const filterOutNull = filteredAccessibilities.filter((i) => i.attribute_title !== null)
        const replacedAccessibilitiesTitle = replaceAccessibilitiesTitle(filterOutNull, accessibleUnits, a11yStandard.title)
        const replacedNestedAccessibilities = replaceNestedAccessibilities(replacedAccessibilitiesTitle, accessibleUnits)
        setAccessibilities(replacedNestedAccessibilities);
      }
      setLoading(false);
    }
    return () => {
      setAccessibilities(undefined);
      setLoading(false);
    };
  }, [refresh, generalAccessibilities]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const unitLevels = (await retrieveAccesibilityUnitsLevelsByPropertyId(id))
        .results;
      setUnitLevels(unitLevels);
      setLoading(false);
    };
    fetchData().catch(console.error);
  }, [refresh, id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const units = await retrieveAccessibilityUnits(id);
      setUnits(units);
    };

    fetchData().catch(console.error);
    return () => {
      setUnits(undefined);
      setLoading(false);
    };
  }, [refresh, id]);

  useEffect(() => {
    if (units) {
      const rooms = units.results.filter(({type}) => type === "hotel_room_type");
      const exhibitionRooms = units.results.filter(({ type }) => type === "exhibition");
      const museumShops = units.results.filter(({ type }) => type === "museum_shop");
      const toiletRoomCommon = units.results.filter(({ type }) => type === "toilet_room__common");

      setExhibitionRooms(exhibitionRooms)
      setHospitalityRooms(rooms);
      setMuseumShopRooms(museumShops);
      setToiletCommonRooms(toiletRoomCommon);
      const fetchData = async () => {
        setLoading(true);
        const roomAccessibilities = (
          await Promise.all(
            rooms.map((room) => {
              return retrieveAccessibilitiyByUnitId(room.id);
            })
          )
        )
          .map((data) => data.results)
          .flat();
        const filteredRoomAccessibilities = roomAccessibilities.filter(
          (access) => access.is_absent === false
        );
        setHospitalityRoomAccessibilities(filteredRoomAccessibilities);
        setLoading(false);
      };

      const foodCourtsCount = units.results.filter(
        (unit) => unit.type === "dining_area"
      ).length;
      setFoodCourtCount(foodCourtsCount);
      fetchData().catch(console.error);
    }
  }, [refresh, units]);

  useEffect(() => {
    if (units) {
      const rooms = units.results.filter(({ type }) => type === "meeting_room");
      setMeetingRooms(rooms);
    }
  }, [refresh, units]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (property?.a11y_standard === a11yStandardKeys.shoppingMall) {
        const relatedPropertiesCount = (await retrievePropertiesByRelatedId(id))
          .count;
        setTotalShop(relatedPropertiesCount);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
    return () => {
      setTotalShop(0);
      setLoading(false);
    };
  }, [refresh, id, property?.a11y_standard]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const parkingSpaces = (
        await retrievePropertyAccessibilityByType(
          id,
          "parking",
          "number_of_units"
        )
      ).results;
      setParkingSpaces(parkingSpaces);
      setLoading(false);
    };

    fetchData().catch(console.error);
    return () => {
      setParkingSpaces(undefined);
      setLoading(false);
    };
  }, [refresh, id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const questions = (
        await retrieveQuestions()
      ).results;
      setQuestions(questions);
      setLoading(false);
    };

    fetchData().catch(console.error);
    return () => {
      setQuestions(undefined);
      setLoading(false);
    };
  }, [refresh, id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = (
        await retrievePropertyAccessibilityByType(
          id,
          "toilet_room__common",
          "number_of_units,accessibility_unit"
        )
      ).results;
      setToiletRoom(result);
      setLoading(false);
    };

    fetchData().catch(console.error);
    return () => {
      setToiletRoom(undefined);
      setLoading(false);
    };
  }, [refresh, id]);

  useEffect(() => {
    setLoading(true);
    if (accessibilities) {
      const accList = accessibilityInformationIds(accessibilities);
      if (units) {
        const filteredAccessibilitiesUnits = units.results.filter((access) =>
          accList.map((el) => el === getTextByCurrentLanguage(access.name))
        );
        if (filteredAccessibilitiesUnits.length > 0) {
          setAccessibleUnits(filteredAccessibilitiesUnits.sort((a,b) => a.order - b.order));
        }
        setLoading(false);
      }
    }
    return () => {
      setAccessibleUnits([]);
      setLoading(false)
    };
  }, [refresh, accessibilities, units]);

  useEffect(() => {
    if (propImages) {
      const filteredPropertyImages = propImages.filter(
        (el) => el.is_display_in_output_service === true
      );
      if (units) {
        setPropertyImages(replaceNestedAccessibilityImages(filteredPropertyImages, accessibleUnits));
      }
    }
  }, [units, propImages]);

  return {
    parkingSpaces,
    toiletRoom,
    totalShop,
    foodCourtCount,
    accessibilities,
    hospitalityRooms,
    meetingRooms,
    exhibitionRooms,
    museumShopRooms,
    toiletCommonRooms,
    propertyImages,
    unitLevels,
    propertyAccessibilityInfo,
    propertyFreeTextInfo,
    accessibleUnits,
    hospitalityRoomAccessibilities,
    units,
    loading,
    questions
  };
};
